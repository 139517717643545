import {Component, OnDestroy, OnInit} from '@angular/core';
import {EMPTY, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CompaniesService} from "../../services/companies.service";
import {CompanyDetail} from "../../interfaces/company";
import {BaseItemData, BaseItemDetail, BaseItemFullDetail} from "../../interfaces/base-item";
import {UtilsService} from "../../services/utils.service";
import {SnackService} from "../../services/snack.service";


@Component({
  selector: 'aw-items',
  templateUrl: './items-container.component.html',
  styleUrls: ['./items-container.component.scss']
})
export class ItemsContainerComponent implements OnInit, OnDestroy {

  baseItemToEdit?: BaseItemFullDetail | BaseItemDetail;
  baseItemToDuplicate?: BaseItemFullDetail | BaseItemDetail;

  showBaseItemModal = false
  readOnly = false

  company$: Observable<CompanyDetail>
  company?: CompanyDetail

  loading = false;

  private subs: Subscription[] = []

  constructor(
    private companiesService: CompaniesService,
    private snackService: SnackService,
    private utilsService: UtilsService) {

    this.company$ =
      of(true)
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => utilsService.getUserReference()),
          switchMap(userReference => {
            return this.companiesService.getCompanyById(userReference.companyId);
          }),
          tap(company => {
            this.company = company;
          }),
          catchError((err, caught) => {
            this.loading = false
            if (!err.errors.includes('403')) {
              this.snackService.error('Impossibile caricare i dati di default dell\'azienda')
            }
            return EMPTY;
          })
        )
  }

  ngOnInit(): void {
    this.subs.push(this.company$.subscribe());
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  addBaseItem() {
    this.readOnly = false

    this.baseItemToEdit = {
      defaultBuyVAT: this.company?.defaultBuyVAT,
      defaultSellVAT: this.company?.defaultSellVAT
    } as BaseItemDetail

    this.showBaseItemModal = true
  }


  // getBaseItemToShow(baseItem: BaseItemFullDetail, readonly: boolean) {
  //   this.readOnly = readonly
  //   this.baseItemsService
  //     .getCompanyBaseItem(this.referenceCompanyId, baseItem.id!)
  //     .subscribe(
  //       {
  //         next: item => {
  //           this.baseItemToEdit = item;
  //           this.showBaseItemModal = true;
  //         }
  //       }
  //     )
  // }


  updateBaseItem(baseItem: BaseItemFullDetail) {

    this.readOnly = false
    this.baseItemToEdit = baseItem
    this.showBaseItemModal = true

  }

  duplicateItem(baseItem: BaseItemFullDetail) {
    this.readOnly = false
    this.baseItemToDuplicate = baseItem
    this.showBaseItemModal = true
  }

  // checkBaseItemName(name: string) {
  //   this.baseItemsService.checkBaseItemName(this.referenceCompanyId, name)
  //     .subscribe(value => this.baseItemNameAlreadyInUseSubject.next(value))
  // }

  // getItemVariantToShow(itemVariant: ItemVariantDetail) {
  //   this.itemVariantsService
  //     .getItemVariant(this.referenceCompanyId, itemVariant.baseItemId, itemVariant.id)
  //     .subscribe(
  //       {
  //         next: itemVariant => {
  //           this.itemVariantToShowDetail = itemVariant;
  //           this.showItemVariantDetailDialog = true;
  //         }
  //       }
  //     )
  // }

  // getItemVariantToEdit(itemVariant: ItemVariantDetail) {
  //   this.itemVariantsService
  //     .getItemVariant(this.referenceCompanyId, itemVariant.baseItemId, itemVariant.id!)
  //     .subscribe(
  //       {
  //         next: item => {
  //           this.itemVariantToEdit = item;
  //           this.showItemVariantModal = true;
  //         }
  //       }
  //     );
  // }

  editCompleted(data: BaseItemData | undefined) {
    this.showBaseItemModal = false;

    // resetting baseItems in order to reset the form
    this.baseItemToDuplicate = undefined
    this.baseItemToEdit = undefined
  }

  openFormInReadonly(baseItem: BaseItemFullDetail) {
    this.readOnly = true
    this.baseItemToEdit = baseItem
    this.showBaseItemModal = true
  }


}
