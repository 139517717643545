import {Injectable} from '@angular/core';
import {ApiResponse} from "../classes/api-response";
import {environment} from "../../environments/environment";
import {Constants} from "../utils/constants";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
import {CheckCustomer, CustomerCreate, CustomerData,} from "../interfaces/customer";
import {CustomerInformationCreate, CustomerInformationDetail} from "../interfaces/customer-information";
import {Observable} from "rxjs";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) {
  }

  getCompanyCustomers(companyId: number, paginationParams?: FetchParams): Observable<PageConverter<CustomerData>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers`, {params : sortFilterParams})
      .pipe(map(value => value.data as PageConverter<CustomerData>))

  }

  exportCompanyCustomers(companyId: number, paginationParams?: FetchParams) {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<any>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers/export`, {params : sortFilterParams, responseType: 'blob' as 'json'})

  }

  getCompanyCustomer(companyId: number, customerId: number) {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers/${customerId}`)
      .pipe(map(value => value.data as CustomerData))
  }


  createCompanyCustomer(companyId: number, customer: CustomerCreate) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers`, customer)
      .pipe(map(value => value.data as CustomerData));
  }

  updateCompanyCustomer(companyId: number, customerId: number, customer: CustomerCreate) {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers/${customerId}`, customer)
      .pipe(map(value => value.data as CustomerData));
  }

  deleteCompanyCustomer(companyId: number, customerId: number) {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers/${customerId}`)
      .pipe(map(() => {}));
  }

  getCustomerInformationList(companyId: number, customerId: number, paginationParams?: FetchParams): Observable<PageConverter<CustomerInformationDetail>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers/${customerId}/info`, {params : sortFilterParams})
      .pipe(map(value => value.data as PageConverter<CustomerInformationDetail>))

  }

  getCustomerInformation(companyId: number, customerId: number, infoId: number): Observable<CustomerInformationDetail> {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers/${customerId}/info/${infoId}`)
      .pipe(map(value => value.data as CustomerInformationDetail))

  }

  createCustomerInformation(companyId: number, customerId: number, customerInfo: CustomerInformationCreate) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers/${customerId}/info`,
        customerInfo)
      .pipe(map(value => value.data as CustomerInformationDetail));
  }

  updateCompanyCustomerInformation(companyId: number, customerId: number, infoId: number, info: CustomerInformationCreate) {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers/${customerId}/info/${infoId}`, info)
      .pipe(map(value => value.data as CustomerInformationDetail))
  }

  deleteCompanyCustomerInformation(companyId: number, customerId: number, infoId: number) {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers/${customerId}/info/${infoId}`)
      .pipe(map(() => {}))
  }

  getCustomerCheck(companyId: number, customerId: number, phone: string, name: string, surname: string, isCompany: boolean, vat: string) {

    let params: HttpParams = new HttpParams()

      if(phone){
        params = params.set('phone', phone)
      }

      if(name){
        params =  params.set('name', name)
      }

      if(surname){
        params = params.set('surname', surname)
      }

      params = params.set('isCompany', isCompany)

      if(isCompany){
        params = params.set('vat', vat)
      }


    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/customers/${customerId}/check-customer`, {params})
      .pipe(map(value => value.data as CheckCustomer))
  }


}
