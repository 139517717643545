import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {SaleCreate, SaleDetail, SaleFullCreate, SaleStatusChange} from "../interfaces/sale";
import {ApiResponse} from "../classes/api-response";
import {environment} from "../../environments/environment";
import {Constants} from "../utils/constants";
import {map} from "rxjs/operators";
import {
  SaleContentCreate,
  SaleContentDetail,
  SaleContentStatusChange,
  SaleContentUpdate
} from "../interfaces/sale-content";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";
import {Observable} from "rxjs";
import {UtilsService} from "./utils.service";
import {SellOrderFilter} from "../interfaces/sell-order";

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.companies}`;

  constructor(private http: HttpClient) {
  }

  createSale(companyId: number, shopId: number, sale: SaleCreate) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}/sales`, sale)
      .pipe(map(value => value.data as SaleDetail))

  }

  updateSale(companyId: number, shopId: number, saleId: number, sale: SaleCreate) {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}/sales/${saleId}`, sale)
      .pipe(map(value => value.data as SaleDetail))
  }

  deleteSale(companyId: number, shopId: number, saleId: number) {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}/sales/${saleId}`)
      .pipe(map(() => {
      }))
  }

  getAllCompanySales(companyId: number) {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/sales`)
      .pipe(map(value => value.data as SaleDetail[]))
  }


  getAllShopSales(companyId: number, shopId: number, paginationParams?: FetchParams, saleFilters?: SellOrderFilter): Observable<PageConverter<SaleDetail>> {
    let sortFilterParams = new HttpParams({fromObject: {...paginationParams, ...saleFilters}})
    let queryParams = UtilsService.buildParams([], sortFilterParams)

    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.shops}/${shopId}/sales`, {params : queryParams})
      .pipe(map(value => value.data as PageConverter<SaleDetail>))

  }

  getShopSaleById(companyId: number, shopId: number, saleId: number): Observable<SaleDetail> {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.shops}/${shopId}/sales/${saleId}`)
      .pipe(map(value => value.data as SaleDetail))

  }

  createWarehouseSaleContent(companyId: number, shopId: number, saleId: number, saleContent: SaleContentCreate[]) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}/sales/${saleId}/sale-content`, saleContent)
      .pipe(map(value => value.data as SaleDetail))

  }

  createSaleWithContent(companyId: number, shopId: number, saleWithContent: SaleFullCreate) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}/sale-full`, saleWithContent)
      .pipe(map(value => value.data as SaleDetail))
  }

  createSaleContent(companyId: number, shopId: number, saleId: number, sale: SaleContentCreate[]) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}/sales/${saleId}/sale-content`, sale)
      .pipe(map(value => value.data as SaleContentDetail[]))
  }

  //companies/{cId}/shops/{shId}/sales/{sId}/sale-content/{socId}
  updateSaleContent(companyId: number, shopId: number, saleId: number, socId: number, sale: SaleContentUpdate) {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}/sales/${saleId}/sale-content/${socId}`, sale)
      .pipe(map(value => value.data as SaleDetail))
  }

  updateSaleContentStatus(companyId: number,
                          shopId: number,
                          saleId: number,
                          saleContentId: number,
                          warehouseId: number,
                          manageWarehouse: boolean,
                          saleContentStatusChange: SaleContentStatusChange) {

    let params = new HttpParams()

    // if (warehouseId) {
      params = params.append("manageWarehouse", manageWarehouse ? "true" : "false")
      params = params.append("warehouseId", warehouseId)
    // }

    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sales/${saleId}/sale-content/${saleContentId}/status`, saleContentStatusChange, {
        params
      })
      .pipe(map(value => value.data as SaleContentDetail))
  }

  //companies/{cId}/shops/{shId}/sales/{sId}/sale-content/{socId}
  deleteSaleContent(companyId: number,
                    shopId: number,
                    saleId: number,
                    socId: number) {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}/sales/${saleId}/sale-content/${socId}`)
      .pipe(map(() => {}))
  }

  updateSaleStatus(companyId: number,
                   shopId: number,
                   saleId: number,
                   saleStatusChange: SaleStatusChange) {

    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}/sales/${saleId}/status`, saleStatusChange)
  }
}
