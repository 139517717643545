import {Component, OnDestroy, OnInit} from '@angular/core';
import {EMPTY, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {catchError, map} from "rxjs/operators";
import {CustomerInformationDetail} from "../../../interfaces/customer-information";
import {CustomerData} from "../../../interfaces/customer";
import {CustomersService} from "../../../services/customers.service";
import {ResponsiveService} from "../../../services/responsive.service";
import {FetchParams} from "../../../utils/global-functions-and-types";
import {SnackService} from "../../../services/snack.service";

@Component({
  selector: 'aw-customer-info',
  templateUrl: './customer-info-container.component.html',
  styleUrls: ['./customer-info-container.component.scss']
})
export class CustomerInfoContainerComponent implements OnInit, OnDestroy {
  loading = false
  saving = false
  customerInfo$: Observable<CustomerInformationDetail[]>
  customerInfo: CustomerInformationDetail[] = [];
  customer$: Observable<CustomerData>
  customer?: CustomerData
  referenceCompanyId!: number;
  referenceCustomerId!: number;

  private subs: Subscription[] = []
  readOnly = false;

  constructor(
    private customersService: CustomersService,
    private authService: AuthService,
    private snackService: SnackService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public responsiveService: ResponsiveService) {

    let ids$ = activatedRoute
      .paramMap
      .pipe(
        switchMap(paramsMap => {

          if (!paramsMap.has('customerId'))
            throw Error()

          if (paramsMap.has('companyId')) {
            return of({cid: +paramsMap.get('companyId')!, cuId: +paramsMap.get('customerId')!});
          } else {
            return this.authService.onUserChange().pipe(map(u => {
                return {
                  cid: u?.companyId!,
                  cuId: +paramsMap.get('customerId')!
                }
              }
            ));
          }
        }),
        tap((ids) => {
          this.referenceCompanyId = ids.cid
          this.referenceCustomerId = ids.cuId
        }),
        catchError((err, caught) => {
          router.navigate(['/error']).then()
          return EMPTY
        }))


    this.customer$ =
      ids$
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.customersService.getCompanyCustomer(this.referenceCompanyId, this.referenceCustomerId)),
          tap((customer) => {
            this.loading = false;
            this.customer = customer
          }),
          catchError((err, caught) => {

            this.loading = false;
            if (!err.errors.includes('403')) {
              this.snackService.error('Impossibile caricare i dati del cliente')
            }
            return EMPTY;
          })
        )

    this.customerInfo$ =
      ids$
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => activatedRoute.paramMap),
          switchMap(() => this.customersService.getCustomerInformationList(this.referenceCompanyId, this.referenceCustomerId, {} as FetchParams)),
          switchMap((customerInfo) => {
            this.loading = false;
            this.customerInfo = customerInfo.list
            return of(customerInfo.list)
          }),
          catchError((err, caught) => {
            this.loading = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare le informazioni del cliente')
            return EMPTY;
          })
        )

  }

  ngOnInit(): void {
    this.subs.push(this.customer$.subscribe())
    this.subs.push(this.customerInfo$.subscribe())
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }



}
