<ng-template #loadingGeneral>

  <mat-spinner class="align-self-center mt-8"></mat-spinner>

</ng-template>


<div fxLayout="column">
  <h1 class="font-light">Tipi articolo</h1>

  <ng-container *ngIf="dataSource && !loading else loadingGeneral">


    <aw-actions-bar
      [accessEntity]="accessEntities.items"
      [selectVisible]="selectVisible"
      [displayedColumns]="displayedColumns"
      [selection]="selection"
      (deleteElements)="deleteEntities($event, userReference.companyId)"
      (openCreateForm)="addOption()"
      (load)="loadResults($event, observable$)"
      [isAllSelected]="isAllSelected()"
    >

    </aw-actions-bar>


    <table
      (matSortChange)="loadResults($event, observable$)"
      [dataSource]="dataSource"
      matSortActive="name"
      matSortDirection="asc"
      class="w-100 mat-elevation-z2"
      mat-table
      matSort
      multiTemplateDataRows>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                        (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="name">Nome</th>
        <td *matCellDef="let element" mat-cell> {{element.name}} </td>
      </ng-container>

      <!-- Display Name Column -->
      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="description">Descrizione</th>
        <td *matCellDef="let element" mat-cell> {{element.description}} </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Azioni</th>
        <td *matCellDef="let element" class="hide-mobile" fxLayoutGap="8" mat-cell>

          <button #edit
                  (click)="editOption(element.id);$event.preventDefault();$event.stopPropagation();"
                  *awAccess="{accessLevel:{accessEntity: accessEntities.items, accessLevel: accessLevels.editor}}"

                  [awDisableElement]="{
                        selected: [element],
                        logicalAnd: true,
                        accessLevels: [{accessEntity: accessEntities.items, accessLevel: accessLevels.editor}],
                        component: edit}"

                  color="accent"
                  mat-icon-button
                  matTooltip="Modifica"
                  matTooltipPosition="above"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button #delete
                  (click)="deleteEntities([element.id], userReference.companyId);$event.preventDefault();$event.stopPropagation();"
                  *awAccess="{accessLevel:{accessEntity: accessEntities.items, accessLevel: accessLevels.admin}}"

                  [awDisableElement]="{
                        selected: [element],
                        logicalAnd: true,
                        accessLevels: [{accessEntity: accessEntities.items, accessLevel: accessLevels.admin}],
                        component: delete}"
                  color="warn"
                  mat-icon-button
                  matTooltip="Elimina"
                  matTooltipPosition="above"
          >
            <mat-icon>delete</mat-icon>
          </button>

        </td>

      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns:  displayedColumns" (click)="editOption(row.id)" mat-row></tr>
      <!--      todo add awSlidingRow-->



      <tr *matNoDataRow class="mat-row">
        <td [colSpan]="displayedColumns.length" class="mat-cell text-center">
          Nessun tipo articolo trovato
        </td>
      </tr>

    </table>

    <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </mat-card>

  </ng-container>

  <mat-paginator #paginator
                 (page)="loadResults($event, observable$)"
                 (selectionchange)="logMe($event)"
                 *ngIf="dataSource && numEntities > 0"
                 [length]="numEntities"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 class="mat-elevation-z1"
                 showFirstLastButtons
  >
  </mat-paginator>
</div>
