import {Injectable} from '@angular/core';
import {ApiResponse} from "../classes/api-response";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Constants} from "../utils/constants";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";
import {Observable} from "rxjs";
import {DeliveryTimeSlotCreate, DeliveryTimeSlotData} from "../interfaces/delivery-time-slot";

@Injectable({
  providedIn: 'root'
})
export class DeliveryTimeSlotsService {
  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.companies}`;

  constructor(private http: HttpClient) {
  }

  createDeliveryTimeSlot(companyId: number, deliveryTimeSlot: DeliveryTimeSlotCreate) {
    return this.http
      .post<ApiResponse>(
        `${this.crtlFullPath}/${companyId}/${Constants.entities.deliveryDashTimeSlots}`, deliveryTimeSlot)
      .pipe(map(value => value.data as DeliveryTimeSlotData));
  }

  updateDeliveryTimeSlot(companyId: number, deliveryTimeSlotId: number, deliveryTimeSlot: DeliveryTimeSlotCreate) {
    return this.http
      .patch<ApiResponse>(
        `${this.crtlFullPath}/${companyId}/${Constants.entities.deliveryDashTimeSlots}/${deliveryTimeSlotId}`, deliveryTimeSlot)
      .pipe(map(value => value.data as DeliveryTimeSlotData));

  }

  getDeliveryTimeSlots(companyId: number, paginationParams?: FetchParams): Observable<PageConverter<DeliveryTimeSlotData>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.deliveryDashTimeSlots}`, {params: sortFilterParams})
      .pipe(map(value => value.data as PageConverter<DeliveryTimeSlotData>))
  }


  getDeliveryTimeSlot(companyId: number, deliveryTimeSlotId: number) {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.deliveryDashTimeSlots}/${deliveryTimeSlotId}`)
      .pipe(map(value => value.data as DeliveryTimeSlotData))
  }

  deleteDeliveryTimeSlot(companyId: number, deliveryTimeSlotId: number) {
    return this.http
      .delete<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.deliveryDashTimeSlots}/${deliveryTimeSlotId}`)
      .pipe(map(() => {}))

  }
}
