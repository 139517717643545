import {Component, OnInit} from '@angular/core';
import {Constants} from "../../utils/constants";
import {getOrderStatusColor} from "../../utils/global-functions-and-types";
import {OrderStatusPipe} from "../../pipes/order-status";

@Component({
  selector: 'aw-legend-statuses',
  templateUrl: './legend-statuses.component.html',
  styleUrls: ['./legend-statuses.component.scss']
})
export class LegendStatusesComponent implements OnInit {

  // statuses: {key: string, value: string}[] = Constants.orderStatusKeys().map(k => {
  //   return {
  //     key: (Constants.orderStatuses as any)[k],
  //     value: (Constants.orderStatusesPipe as any)[k]
  //   }
  // })


  statuses = Object.values(Constants.orderStatuses)

  getOrderStatusColor = getOrderStatusColor
  dataSource: { name: string, description: string, key: string }[] = this.statuses.map((status) => {
    return {name: this.orderStatusPipe.transform(status), description: Constants.statusDescription[status], key: status}
  });

  displayedColumns: string[] = ['name', 'description'];

  constructor(private orderStatusPipe: OrderStatusPipe) {
  }

  ngOnInit(): void {
  }

  getStatusDescription(key: string) {
    return Constants.statusDescription[key] ?? 'Nessuna descrizione disponibile'
  }
}
