import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {WarehouseCreate, WarehouseDetail} from "../../../interfaces/warehouse";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {WarehousesService} from "../../../services/warehouses.service";
import {Observable} from "rxjs";
import {UtilsService} from "../../../services/utils.service";


@Component({
  selector: 'aw-warehouses-form',
  templateUrl: './warehouses-form.component.html',
  styleUrls: ['./warehouses-form.component.scss']
})
export class WarehousesFormComponent extends BaseFormComponent<WarehouseCreate, WarehouseDetail>{

  override _entity: WarehouseDetail = {} as WarehouseDetail

  @Input() companyId = -1

  @Input() set entity(value: WarehouseDetail) {
    this._entity = value
    this.formGroup.patchValue(this._entity)
  }

  constructor(
    public warehousesService: WarehousesService,
    public override utilsService: UtilsService) {

    let createFunction = (companyId: number, warehouse: WarehouseCreate): Observable<WarehouseDetail> => warehousesService.createWarehouse(companyId, warehouse)
    let updateFunction = (companyId: number, warehouseId: number, warehouse: WarehouseCreate): Observable<WarehouseDetail> => warehousesService.updateWarehouse(companyId, warehouseId, warehouse)

    super(createFunction, updateFunction, utilsService)

    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      name: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      phone: new FormControl('',)
    })

  }

}
