import {Injectable} from '@angular/core';

import {ApiResponse} from "../classes/api-response";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Constants} from "../utils/constants";
import {ProfileCreate, ProfileDetail} from "../interfaces/profile";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {
  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.profiles}`;

  constructor(private http: HttpClient) {
  }

  getAllCompanyProfiles(companyId: number, paginationParams?: FetchParams): Observable<PageConverter<ProfileDetail>> {
    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.profiles}`, {params: sortFilterParams})
      .pipe(map(value => value.data as PageConverter<ProfileDetail>))
  }

  getProfileById(companyId: number, profileId: number) {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.profiles}/${profileId}`)
      .pipe(map(value => value.data as ProfileDetail))
  }

  createProfile(companyId: number, profile: ProfileCreate) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.profiles}`, profile)
      .pipe(map(value => value.data as ProfileDetail));
  }

  updateProfile(companyId: number, profileId: number, profile: ProfileCreate) {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.profiles}/${profileId}`, profile)
      .pipe(map(value => value.data as ProfileDetail));
  }

  deleteProfile(companyId: number, profileId: number) {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.profiles}/${profileId}`,)
      .pipe(map(() => {
      }))
  }


}
