import {
    OrderContentStatus,
    SellOrderContentCreate,
    SellOrderContentCreateWithOrderContentId,
    SellOrderContentDetail
} from "./sell-order-content";
import {CustomerInformationDetail} from "./customer-information";
import {DeliveryCompanyData} from "./delivery-company";
import {SellOrderEventData} from "./sell-order-event";
import {SellOrderContentSnapshotData} from "./sell-order-content-snapshot";
import {SaleContentState} from "./sale";
import {DeliveryTimeSlotData} from "./delivery-time-slot";
import {GenericFilter} from "../utils/global-functions-and-types";

export interface SellOrderCreate {
  /** @format int64 */
  customerId?: number;

  /** @format int64 */
  recipientId?: number;

  status?:
    | "TO_BE_ASSIGNED"
    | "IN_PROGRESS"
    | "READY_FOR_PICKUP"
    | "READY_FOR_DELIVERY"
    | "SHIPPED"
    | "DELIVERING"
    | "DELIVERED"
    | "DELIVERY_FAILED"
    | "FAILED"
    | "COMPLETED";

  finalPrice?: number;

  paymentStatus?: OrderPaymentStatus
  deliveryOption?: DeliveryOption

  paidPrice?: number;

  note?: string;

  trackingNumber?: string;

  orderDate: string;

  deliveryDate?: string;

  deadlineDate?: string;


  /** @format int64 */
  billingInformationId?: number;

  /** @format int64 */
  shippingInformationId?: number;

  deliveryCompanyId?: number;

  deliveryTimeSlotId?: number;

  deliveryNotes?: string;

  photo?: boolean,

  /** @format int64 */
  shopId?: number;

  mandatoryShop?: boolean
}

export interface SellOrderCreateFull {
  customerId?: number;

  /** @format int64 */
  recipientId?: number;

  status?:
    | "TO_BE_ASSIGNED"
    | "IN_PROGRESS"
    | "READY_FOR_PICKUP"
    | "READY_FOR_DELIVERY"
    | "SHIPPED"
    | "DELIVERING"
    | "DELIVERED"
    | "DELIVERY_FAILED"
    | "FAILED"
    | "COMPLETED";

  finalPrice?: number;

  paymentStatus?: OrderPaymentStatus;
  deliveryOption?: DeliveryOption;

  paidPrice?: number;

  note?: string;

  deliveryNotes?: string;

  photo?: boolean,

  trackingNumber?: string;

  orderDate: string;

  deliveryDate?: string;

  deadlineDate?: string;

  billingInformationId?: number;

  shippingInformationId?: number;

  deliveryCompanyId?: number;
  deliveryTimeSlotId?: number;

  shopId?: number;

  mandatoryShop?: boolean

  content: SellOrderContentCreate[]

}


export interface SellOrderDetail {
  /** @format int64 */
  id: number;

  /** @format int64 */
  customerId?: number;

  /** @format int64 */
  recipientId?: number;

  customerName?: string;

  recipientName?: string;

  status?: string;

  /** @format float */
  calculatedPrice?: number;

  finalPrice?: number;

  paymentStatus?: OrderPaymentStatus;
  deliveryOption?: DeliveryOption;

  paidPrice?: number;

  note?: string;

  deliveryNotes?: string;

  photo?: boolean,

  trackingNumber?: string;

  /** @format date */
  orderDate: string;

  deliveryDate?: string;

  deadlineDate?: string;

  /** @format int64 */
  billingInformation?: CustomerInformationDetail;

  /** @format int64 */
  shippingInformation?: CustomerInformationDetail;

  /** @format int64 */
  shopId?: number;

  shopName?: string;

  content?: SellOrderContentDetail[];

  contentSnapshot?: SellOrderContentSnapshotData[],

  deliveryCompany: DeliveryCompanyData

  deliveryTimeSlot: DeliveryTimeSlotData

  events: SellOrderEventData[]
}

export interface SellOrderFullCreateWithStates {
  sellOrderFullCreate: SellOrderCreateFull,
  sellOrderContentStates: SaleContentState[]
}

export interface SellOrderContentState {
  warehouseId: number
  itemId: number
  state: OrderContentStatus
}

export interface OrderContentUpdateWithStates {
  orderContentToCreate: SellOrderContentCreate[]
  orderContentToUpdate: SellOrderContentCreateWithOrderContentId[]
  orderContentToDelete: number[]
  orderContentStates: SellOrderContentState[]
}



export type DeliveryOption = "SHOP" | "DELIVERY_COMPANY" | "RIDER"
export type OrderPaymentStatus = "TO_BE_PAID" | "PAID" | "ADVANCE"

export interface FilterOptionNames {
  displayName: string, // "Nome cliente"
  fieldName: string // customerName
}

export interface FilterOptionValues {
  field: string // Selected field
  value: string // searched value for that field
}


/**
 * - deliveryFrom: used for orders
 * - deliveryTo: used for orders
 * - deadlineFrom: used for orders
 * - deadlineTo: used for orders
 * - saleFrom: used for sales
 * - saleTo: used for sales
 * - statuses: used for orders
 * - paymentStatuses: used for orders
 */
export class SellOrderFilter implements GenericFilter {

  deliveryFrom? = ""
  deliveryTo? = ""
  deadlineFrom? = ""
  deadlineTo? = ""
  saleFrom? = ""
  saleTo? = ""
  statuses?: string[] = []
  paymentStatuses?: string[] = []

  customerName? = ""

  recipientName? = ""

  shopName? = ""

  billingSearch? = ""

  shippingSearch? = ""
}

export class DateRange {
  startDate?: string;
  endDate?: string
}
