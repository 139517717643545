import {AccessEntities, AccessLevel, EntityIdentifier, RollbackStatus} from './global-functions-and-types';
import {RequiredAccessLevel} from "../classes/required-access-level";
import {MigrationItemChoice} from "../interfaces/item-warehouse";
import {DeliveryOption} from "../interfaces/sell-order";


export class Constants {


  static numerics = {
    minForDecimalQuantities: 0.00000000000000000001
  }

  static pagination = {
    defaultSortDirection: 'asc',
    defaultPage: 0,
    defaultPageSize: 100,
    defaultPageSizeOptions: [10, 25, 50, 100, 200]
  }

  static dialogSizes = {
    mobile: 'w-75-vw',
    desktop: 'w-50-vw',
  }

  static entities = {
    user: 'user',
    users: 'users',
    company: 'company',
    companies: 'companies',
    options: 'options',
    option: 'option',
    customer: 'customer',
    customers: 'customers',
    item: 'item',
    items: 'items',
    baseItem: 'base-item',
    baseItems: 'base-items',
    itemType: 'item-type',
    itemTypes: 'item-types',
    itemCompositions: 'item-compositions',
    warehouse: 'warehouse',
    warehouses: 'warehouses',
    itemWarehouses: 'itemWarehouses',
    deliveryDashCompanies: 'delivery-companies',
    deliveryCompanies: 'deliveryCompanies',
    deliveryDashTimeSlots: 'delivery-time-slots',
    deliveryTimeSlots: 'deliveryTimeSlots',
    shop: 'shop',
    shops: 'shops',
    access: 'access',
    roles: 'roles',
    permissions: 'permissions',
    sellOrders: 'sell-orders',
    sellOrderShops: 'sellOrderShops',
    profiles: 'profiles'
  };

  static roleColorConversion = {
    OWNER: '#009688',
    ADMIN: '#F44336',
    EDITOR: '#9C27B0',
    READER: '#FF9800',
  }

  static profileColor = "#D4AF37"


  static accessLevel = {
    OWNER: 'OWNER',
    ADMIN: 'ADMIN',
    EDITOR: 'EDITOR',
    READER: 'READER'
  }

  static accessLevelsMap: { [key: string]: AccessLevel } = {
    READER: "READER",
    EDITOR: "EDITOR",
    ADMIN: "ADMIN",
    OWNER: "OWNER",
  }

  static accessLevels = {
    reader: Constants.accessLevelsMap[Constants.accessLevel.READER],
    editor: Constants.accessLevelsMap[Constants.accessLevel.EDITOR],
    admin: Constants.accessLevelsMap[Constants.accessLevel.ADMIN],
    owner: Constants.accessLevelsMap[Constants.accessLevel.OWNER],
  }

  static accessLevelOrder: AccessLevel[] = [
    Constants.accessLevels.owner,
    Constants.accessLevels.admin,
    Constants.accessLevels.editor,
    Constants.accessLevels.reader
  ]

  static defaultAccessLevel: RequiredAccessLevel = {
    accessEntity: undefined,
    accessLevel: Constants.accessLevels.reader,
    needsCompany: true,
    checkRolesOnly: false,
    checkCompany: false
  }

  static orderStatuses = {
    toBeAssigned: 'TO_BE_ASSIGNED',
    inProgress: 'IN_PROGRESS',

    readyForPickupShop: 'READY_FOR_PICKUP_SHOP',
    readyForPickupRider: 'READY_FOR_PICKUP_RIDER',
    readyForPickupCourier: 'READY_FOR_PICKUP_COURIER',

    deliveringRider: 'DELIVERING_RIDER',
    deliveringCourier: 'DELIVERING_COURIER',

    deliveryFailedRider: 'DELIVERY_FAILED_RIDER',
    deliveryFailedCourier: 'DELIVERY_FAILED_COURIER',

    deliveredRider: 'DELIVERED_RIDER',
    deliveredCourier: 'DELIVERED_COURIER',

    pickedUp: 'PICKED_UP',
    completed: 'COMPLETED',
    failed: 'FAILED',
  }

  static orderStatusesPipe = {
    toBeAssigned: 'PUNTO VENDITA MANCANTE',
    inProgress: 'IN LAVORAZIONE',

    readyForPickupShop: 'PRONTO PER RITIRO NEL PUNTO VENDITA',
    readyForPickupRider: 'PRONTO PER  RITIRO DAL FATTORINO',
    readyForPickupCourier: 'PRONTO PER RITIRO DAL CORRIERE',

    deliveringRider: 'IN CONSEGNA DAL FATTORINO',
    deliveringCourier: 'IN CONSEGNA DAL CORRIERE',

    deliveryFailedRider: 'CONSEGNA FALLITA FATTORINO',
    deliveryFailedCourier: 'CONSEGNA FALLITA CORRIERE',

    deliveredRider: 'CONSEGNATO DAL FATTORINO',
    deliveredCourier: 'CONSEGNATO DAL CORRIERE',

    pickedUp: 'RITIRATO DAL PUNTO VENDITA',
    failed: 'ANNULLATO',
    completed: 'COMPLETATO',
  }

  static migrationChoice = {
    sum: 'SUM',
    overwrite: 'OVERWRITE',
  }

  static migrationChoicesMap: { [key: string]: MigrationItemChoice } = {
    SUM: "SUM",
    OVERWRITE: "OVERWRITE",
  }

  static migrationChoices = {
    sum: Constants.migrationChoicesMap[Constants.migrationChoice.sum],
    overwrite: Constants.migrationChoicesMap[Constants.migrationChoice.overwrite],
  }

  static deliveryOption = {
    shop: 'SHOP',
    deliveryCompany: 'DELIVERY_COMPANY',
    rider: 'RIDER',
  }

  static deliveryOptionMap: { [key: string]: DeliveryOption } = {
    SHOP: 'SHOP',
    DELIVERY_COMPANY: 'DELIVERY_COMPANY',
    RIDER: 'RIDER',
  }

  static deliveryOptions = {
    shop: Constants.deliveryOptionMap[Constants.deliveryOption.shop],
    deliveryCompany: Constants.deliveryOptionMap[Constants.deliveryOption.deliveryCompany],
    rider: Constants.deliveryOptionMap[Constants.deliveryOption.rider],
  }

  static deliveryOptionsPipe = {
    shop: 'RITIRO IN NEGOZIO',
    deliveryCompany: 'CONSEGNA CON CORRIERE',
    rider: 'CONSEGNA CON RIDER',
  }

  static migrationChoicesPipe = {
    sum: 'SOMMA ALLE GIACENZE GIÀ ESISTENTI',
    overwrite: 'SOVRASCRIVI ALLE GIACENZE GIÀ ESISTENTI',
  }


  static orderPaymentStatuses = {
    toBePaid: 'TO_BE_PAID',
    paid: 'PAID',
    advance: 'ADVANCE',
  }

  static orderPaymentStatusesPipe = {
    toBePaid: 'DA PAGARE',
    paid: 'PAGATO',
    advance: 'ACCONTO',
  }


  static contentInfo = {
    cannotUpdateAnymore: "Non è più possibile modificare il contenuto",
    addItem: "Aggiungi articolo",
    editQuantityOrItemUnitPrice: "Modifica Prezzo unitario o Quantità dell'articolo",
    removeItem: "Rimuovi articolo"
  }

  static orderContentStatuses = {
    notUpdated: 'NOT_UPDATED',
    manuallyUpdated: 'MANUALLY_UPDATED',
    updated: 'UPDATED',
  }

  static orderContentStatusesPipe = {
    notUpdated: 'ARTICOLI NON PRELEVATI',
    manuallyUpdated: 'ARTICOLI PRELEVATI MANUALMENTE',
    updated: "ARTICOLI PRELEVATI DALL'APPLICAZIONE",
  }

  static saleContentStatuses = {
    notUpdated: 'NOT_UPDATED',
    manuallyUpdated: 'MANUALLY_UPDATED',
    updated: 'UPDATED',
  }

  static saleContentUpdatedStatuses = {
    manuallyUpdated: 'MANUALLY_UPDATED',
    updated: 'UPDATED',
  }

  static saleContentStatusesPipe = {
    notUpdated: 'ARTICOLI NON PRELEVATI',
    manuallyUpdated: 'ARTICOLI PRELEVATI MANUALMENTE',
    updated: "ARTICOLI PRELEVATI DALL'APPLICAZIONE",
  }

  static saleStatuses = {
    open: 'OPEN',
    closed: 'CLOSED',
    canceled: 'CANCELED',
  }

  static saleStatusesPipe = {
    open: 'DA COMPLETARE',
    closed: 'COMPLETATA',
    canceled: 'ANNULLATA'
  }

  static accessEntityToEntityIdentifierMap: { [key: string]: EntityIdentifier } = {
    companies: "COMPANIES",
    users: "USERS",
    items: "ITEMS",
    warehouses: "WAREHOUSES",
    itemWarehouses: "ITEMWAREHOUSES",
    shops: "SHOPS",
    sellOrderShops: "SELLORDERSHOPS",
    customers: "CUSTOMERS",
    deliveryCompanies: "DELIVERYCOMPANIES",
    profiles: "PROFILES",
  }

  static entityIdentifierToAccessEntityMap: { [key: string]: AccessEntities } = {
    COMPANIES: "companies",
    USERS: "users",
    ITEMS: "items",
    WAREHOUSES: "warehouses",
    ITEMWAREHOUSES: "itemWarehouses",
    SHOPS: "shops",
    SELLORDERSHOPS: "sellOrderShops",
    CUSTOMERS: "customers",
    DELIVERYCOMPANIES: "deliveryCompanies",
    PROFILES: "profiles",
  }

  static accessEntities = {
    companies: Constants.entityIdentifierToAccessEntityMap[Constants.accessEntityToEntityIdentifierMap[Constants.entities.companies]],
    users: Constants.entityIdentifierToAccessEntityMap[Constants.accessEntityToEntityIdentifierMap[Constants.entities.users]],
    items: Constants.entityIdentifierToAccessEntityMap[Constants.accessEntityToEntityIdentifierMap[Constants.entities.items]],
    warehouses: Constants.entityIdentifierToAccessEntityMap[Constants.accessEntityToEntityIdentifierMap[Constants.entities.warehouses]],
    itemWarehouses: Constants.entityIdentifierToAccessEntityMap[Constants.accessEntityToEntityIdentifierMap[Constants.entities.itemWarehouses]],
    shops: Constants.entityIdentifierToAccessEntityMap[Constants.accessEntityToEntityIdentifierMap[Constants.entities.shops]],
    sellOrderShops: Constants.entityIdentifierToAccessEntityMap[Constants.accessEntityToEntityIdentifierMap[Constants.entities.sellOrderShops]],
    customers: Constants.entityIdentifierToAccessEntityMap[Constants.accessEntityToEntityIdentifierMap[Constants.entities.customers]],
    deliveryCompanies: Constants.entityIdentifierToAccessEntityMap[Constants.accessEntityToEntityIdentifierMap[Constants.entities.deliveryCompanies]],
    profiles: Constants.entityIdentifierToAccessEntityMap[Constants.accessEntityToEntityIdentifierMap[Constants.entities.profiles]],
  }

  static entityIdentifiers = {
    companies: Constants.accessEntityToEntityIdentifierMap[Constants.entityIdentifierToAccessEntityMap[Constants.entities.companies]],
    users: Constants.accessEntityToEntityIdentifierMap[Constants.entityIdentifierToAccessEntityMap[Constants.entities.users]],
    items: Constants.accessEntityToEntityIdentifierMap[Constants.entityIdentifierToAccessEntityMap[Constants.entities.items]],
    warehouses: Constants.accessEntityToEntityIdentifierMap[Constants.entityIdentifierToAccessEntityMap[Constants.entities.warehouses]],
    itemWarehouses: Constants.accessEntityToEntityIdentifierMap[Constants.entityIdentifierToAccessEntityMap[Constants.entities.itemWarehouses]],
    shops: Constants.accessEntityToEntityIdentifierMap[Constants.entityIdentifierToAccessEntityMap[Constants.entities.shops]],
    sellOrderShops: Constants.accessEntityToEntityIdentifierMap[Constants.entityIdentifierToAccessEntityMap[Constants.entities.sellOrderShops]],
    customers: Constants.accessEntityToEntityIdentifierMap[Constants.entityIdentifierToAccessEntityMap[Constants.entities.customers]],
    deliveryCompanies: Constants.accessEntityToEntityIdentifierMap[Constants.entityIdentifierToAccessEntityMap[Constants.entities.deliveryCompanies]],
    profiles: Constants.accessEntityToEntityIdentifierMap[Constants.entityIdentifierToAccessEntityMap[Constants.entities.profiles]],
  }


  static saleStatusTransitionMap: { [key: string]: string[] } = {

    'OPEN': [Constants.saleStatuses.closed, Constants.saleStatuses.canceled],

    'CLOSED': [],

    'CANCELED': [],
  }

  static orderContentStatusTransitionMap: { [key: string]: string[] } = {
    'NOT_UPDATED': ['MANUALLY_UPDATED', 'UPDATED'],

    'MANUALLY_UPDATED': ['NOT_UPDATED', 'UPDATED'],

    'UPDATED': ['NOT_UPDATED', 'MANUALLY_UPDATED'],

  }

  static roleInfo = {
    "READER": "READER: Permesso di leggere la risorsa selezionata",
    "EDITOR": "EDITOR: Permesso di creare e modificare la risorsa selezionata",
    "ADMIN": "ADMIN: Permesso di creare, modificare ed eliminare la risorsa selezionata",
    "OWNER": "OWNER: Permesso di creare, modificare ed eliminare la risorsa selezionata. L'utente potrà modificare i permessi di altri utenti sulla risorsa selezionata.",
  }

  static roleInfoMessage = {
    message: `${Constants.roleInfo.READER}<br>${Constants.roleInfo.EDITOR}<br>${Constants.roleInfo.ADMIN}<br>${Constants.roleInfo.OWNER}`
  }

  static duplicateContentMessage = "È possibile inserire la stessa coppia Articolo - Magazzino soltanto una volta";


  static orderStatusKeys(): string[] {
    return Object.keys(Constants.orderStatuses);
  }

  static orderPaymentStatusKeys(): string[] {
    return Object.keys(Constants.orderPaymentStatuses);
  }

  static orderContentStatusKeys(): string[] {
    return Object.keys(Constants.orderContentStatuses);
  }

  static migrationChoicesKeys(): string[] {
    return Object.keys(Constants.migrationChoice);
  }

  static deliveryOptionsKeys(): string[] {
    return Object.keys(Constants.deliveryOption);
  }

  static saleStatusKeys(): string[] {
    return Object.keys(Constants.saleStatuses);
  }

  static responseStatusCode = {
    itemWarehouseIllegalQuantityException: 400
  }

  //todo turn this into a map for god sake -Y
  static rollbackStates: RollbackStatus[] = [
    {
      name: 'Rollback automatico',
      value: true,
      tooltip: 'Con questa opzione gli articoli saranno reimmessi automaticamente nei magazzini di provenienza'
    },
    {
      name: 'Rollback manuale',
      value: false,
      tooltip: 'Con questa opzione gli articoli dovranno essere reimmessi manualmente nei magazzini di provenienza'
    }
  ]

  //todo I turned this into a map and it's fucking useless -Y
  static rollbackStatusesMap: { [key: string]: RollbackStatus } = {
    auto: {
      name: 'Rollback automatico',
      value: true,
      tooltip: 'Con questa opzione gli articoli saranno reimmessi automaticamente nei magazzini di provenienza'
    },
    manual: {
      name: 'Rollback manuale',
      value: false,
      tooltip: 'Con questa opzione gli articoli dovranno essere reimmessi manualmente nei magazzini di provenienza'
    }
  }

  static rollbackStatusKeys: {
    auto: "auto",
    manual: "manual",
  }

  // used for map Date.getDay() return to italian names
  static daysTranslation = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']

  static statusDescription: { [key: string]: string } = {
    'TO_BE_ASSIGNED': "L'ordine deve essere necessariamente assegnato ad un punto vendita.",

    'IN_PROGRESS': "L'ordine è in lavorazione. In questo stato è possibile modificare l'ordine sotto ogni aspetto.",

    'READY_FOR_PICKUP_SHOP': "L'ordine è pronto e deve essere ritirato dal cliente nel punto vendita.",

    'READY_FOR_PICKUP_RIDER': "L'ordine è pronto e deve essere ritirato dal fattorino.",

    'DELIVERING_RIDER': "Il fattorino sta per consegnare l'ordine al cliente.",

    'DELIVERY_FAILED_RIDER': "Il fattorino non è riuscito a consegnare l'ordine al cliente.",

    'READY_FOR_PICKUP_COURIER': "L'ordine è pronto e deve essere ritirato dal corriere.",

    'DELIVERING_COURIER': "Il corriere sta per consegnare l'ordine al cliente.",

    'DELIVERY_FAILED_COURIER': "Il corriere non è riuscito a consegnare l'ordine al cliente.",

    'DELIVERED_RIDER': "Il fattorino ha consegnato l'ordine al cliente.",

    'DELIVERED_COURIER': "Il corriere ha consegnato l'ordine al cliente.",

    'PICKED_UP': "Il cliente ha ritirato l'ordine dal punto vendita.",

    'COMPLETED': "L'ordine è considerabile concluso. La parte anagrafica è ancora modificabile.",

    'FAILED': "L'ordine è stato annullato e non è più possibile modificarlo.",

  }

  static localStorageKeys = {
    shopsTable: 'shop_table',
    baseItemsTable: 'base_items_table',
    companiesTable: 'companies_table',
    customerInformationTable: 'customer_information_table',
    customersTable: 'customers_table',
    deliveryCompaniesTable: 'delivery_companies_table',
    deliveryTimeSlotsTable: 'delivery_time_slots_table',
    entityPermissionTable: 'entity_permission_table',
    itemTypesTable: 'item_types_table',
    itemCompositionTable: 'item_composition_table',
    itemStocksTable: 'item_stocks_table',
    itemVariantsTable: 'item_variants_table',
    optionValuesTable: 'option_values_table',
    optionsTable: 'options_table',
    orderContentTable: 'order_content_table',
    ordersTable: 'orders_table',
    permissionsTable: 'permissions_table',
    profilePermissionsTable: 'profile_permissions_table',
    profilesTable: 'profiles_table',
    saleContentTable: 'sale_content_table',
    salesTable: 'sales_table',
    usersTable: 'users_table',
    warehousesItemsTable: 'warehouses_item_table',
    warehousesTable: 'warehouses_table',
  }

  static orderStatusTransitionShopMap: { [key: string]: string[] } = {

    'TO_BE_ASSIGNED': [Constants.orderStatuses.inProgress, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'IN_PROGRESS': [Constants.orderStatuses.readyForPickupShop, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'READY_FOR_PICKUP_SHOP': [Constants.orderStatuses.pickedUp, Constants.orderStatuses.readyForPickupRider, Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'PICKED_UP': [Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'COMPLETED': [],

    'FAILED': [],

  }

  static orderStatusTransitionMap:  { [key: string]: { [key: string]: string[] } } = {
    'SHOP': {
      'TO_BE_ASSIGNED': [Constants.orderStatuses.inProgress, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'IN_PROGRESS': [Constants.orderStatuses.readyForPickupShop, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'READY_FOR_PICKUP_SHOP': [Constants.orderStatuses.pickedUp, Constants.orderStatuses.readyForPickupRider, Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'PICKED_UP': [Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'COMPLETED': [],
      'FAILED': [],
    },
    'DELIVERY_COMPANY': {
      'TO_BE_ASSIGNED': [Constants.orderStatuses.inProgress, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'IN_PROGRESS': [Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'READY_FOR_PICKUP_COURIER': [Constants.orderStatuses.deliveringCourier, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'DELIVERING_COURIER': [Constants.orderStatuses.deliveryFailedCourier, Constants.orderStatuses.deliveredCourier, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'DELIVERY_FAILED_COURIER': [Constants.orderStatuses.deliveringCourier, Constants.orderStatuses.readyForPickupRider, Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.readyForPickupShop, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'DELIVERED_COURIER': [Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'COMPLETED': [],
      'FAILED': [],
    },
    'RIDER': {
      'TO_BE_ASSIGNED': [Constants.orderStatuses.inProgress, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'IN_PROGRESS': [Constants.orderStatuses.readyForPickupShop, Constants.orderStatuses.readyForPickupRider, Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'READY_FOR_PICKUP_RIDER': [Constants.orderStatuses.deliveringRider, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'DELIVERING_RIDER': [Constants.orderStatuses.deliveryFailedRider, Constants.orderStatuses.deliveredRider, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'DELIVERY_FAILED_RIDER': [Constants.orderStatuses.deliveringRider, Constants.orderStatuses.readyForPickupRider, Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.readyForPickupShop, Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'DELIVERED_RIDER': [Constants.orderStatuses.failed, Constants.orderStatuses.completed],
      'COMPLETED': [],
      'FAILED': [],
    }

  }


  static originalOrderStatusTransitionMap: { [key: string]: string[] } = {

    'TO_BE_ASSIGNED': [Constants.orderStatuses.inProgress, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'IN_PROGRESS': [Constants.orderStatuses.readyForPickupShop, Constants.orderStatuses.readyForPickupRider, Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'READY_FOR_PICKUP_SHOP': [Constants.orderStatuses.pickedUp, Constants.orderStatuses.readyForPickupRider, Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'READY_FOR_PICKUP_RIDER': [Constants.orderStatuses.deliveringRider, Constants.orderStatuses.readyForPickupShop, Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'DELIVERING_RIDER': [Constants.orderStatuses.deliveryFailedRider, Constants.orderStatuses.deliveredRider ,Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'DELIVERY_FAILED_RIDER': [Constants.orderStatuses.deliveringRider, Constants.orderStatuses.readyForPickupRider, Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.readyForPickupShop, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'READY_FOR_PICKUP_COURIER': [Constants.orderStatuses.deliveringCourier, Constants.orderStatuses.readyForPickupShop, Constants.orderStatuses.readyForPickupRider, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'DELIVERING_COURIER': [Constants.orderStatuses.deliveryFailedCourier, Constants.orderStatuses.deliveredCourier, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'DELIVERY_FAILED_COURIER': [Constants.orderStatuses.deliveringCourier, Constants.orderStatuses.readyForPickupRider, Constants.orderStatuses.readyForPickupCourier, Constants.orderStatuses.readyForPickupShop, Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'DELIVERED_RIDER': [Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'DELIVERED_COURIER': [Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'PICKED_UP': [Constants.orderStatuses.failed, Constants.orderStatuses.completed],

    'COMPLETED': [],

    'FAILED': [],

  }

}
