<mat-spinner *ngIf="paramLoading" class="align-self-center mt-8"></mat-spinner>

<mat-accordion *ngIf="!paramLoading && dataSource.data.length > 0">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Giacenze
      </mat-panel-title>
    </mat-expansion-panel-header>


      <table
        [dataSource]="dataSource"
        class="w-100 mat-elevation-z2"
        mat-table
        matSort
        matSortActive="quantity"
        matSortDirection="asc"
        multiTemplateDataRows>

        <!-- Variant Column -->
        <ng-container matColumnDef="variantName">
          <th *matHeaderCellDef mat-header-cell>Variante</th>
          <td *matCellDef="let element" mat-cell> {{element.variantName}} </td>
        </ng-container>

        <!-- Warehouse Column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell>Magazzino</th>
          <td *matCellDef="let element" mat-cell> {{element.warehouseName}} </td>
        </ng-container>

        <!-- Quantity Column -->
        <ng-container matColumnDef="quantity">
          <th *matHeaderCellDef class="hide-mobile" mat-header-cell mat-sort-header="quantity">Quantità</th>
          <td *matCellDef="let element" [ngClass]=" {'py-4': isEditActive && updatingItemWarehouse !== element}"
              class="hide-mobile" mat-cell>

            <ng-container>

              <div *ngIf="!canEdit(element.warehouseId)">
                {{element.quantity}} {{element?.unit}}
              </div>

              <div
                *ngIf="(updatingItemWarehouse !== element || !isEditActive) && canEdit(element.warehouseId)"
                (click)="!isEditActive ? onRowEditInit(element.warehouseId, element.variantId) : null"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="4%"
                [ngClass]="{'cursor-pointer': !isEditActive}"
              >
                <span>{{element.quantity}} {{element?.unit}}</span>
                <mat-icon>edit</mat-icon>
              </div>

              <div *ngIf="isEditActive && amIUpdatingThis(element)">
                <div [formGroup]="formGroup" fxLayout="row" fxLayoutAlign="center">

                  <button (click)="decreaseQty(element)" mat-icon-button>
                    <mat-icon>remove</mat-icon>
                  </button>
                  <mat-form-field>

                    <input
                      *ngIf="hasUnit(formGroup, element.warehouseId, element.variantId)"
                      [(ngModel)]="element.quantity"
                      [min]="0"
                      [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2}"
                      currencyMask
                      formControlName="quantity"
                      matInput
                      name="quantity"
                      placeholder="Quantità articolo"
                      type="text">

                    <input
                      *ngIf="!hasUnit(formGroup, element.warehouseId, element.variantId)"
                      [(ngModel)]="element.quantity"
                      [min]="0"
                      [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 0}"
                      currencyMask
                      formControlName="quantity"
                      matInput
                      name="quantity"
                      placeholder="Quantità articolo"
                      type="text">

                    <span *ngIf="hasUnit(formGroup, element.warehouseId, element.variantId)"
                          matSuffix>&nbsp;{{getMeasureUnit(element.warehouseId, element.variantId)}}</span>


                  </mat-form-field>

                  <button (click)="increaseQty()" mat-icon-button>
                    <mat-icon>add</mat-icon>
                  </button>
                </div>

                <div *ngIf="getItemVariant(element)!.itemComposers!.length > 0">
                  <div *ngIf="allComposersAlreadyInWarehouse">

                    <div
                      *ngIf="getItemVariant(element)?.itemComposers?.length ?? -1 > 0 && checked && !canAutoscale(getItemVariant(element), element.warehouseId, element.quantity) && element.quantity != 0"
                      fxFlex
                      class="mb-1">


                      <div class="border border-warn disclaimer-error mat-background-light p-2_5 mb-2_5">
                        <span [innerHTML]="getMissingQuantitiesText"></span>
                      </div>
                    </div>

                    <div class="border border-warn disclaimer-warning mat-background-light p-2_5">
                      <span [innerHTML]="getAutoScaleText(getItemVariant(element))"></span>
                    </div>


                    <mat-button-toggle-group [(ngModel)]="checked">

                      <ng-container *ngFor="let state of stateOptions">
                        <mat-button-toggle [value]="state.value">{{state.label}}</mat-button-toggle>
                      </ng-container>
                    </mat-button-toggle-group>
                  </div>

                  <div *ngIf="!allComposersAlreadyInWarehouse">
                    <div class="border border-warn disclaimer-warning inline-block mat-background-light p-2_5 mb-2_5">
                    <span
                      [innerHTML]="'Le giacenze dei seguenti articoli che compongono articolo scelto non esistono in questo magazzino: <br>' + missingItemsNames() + '<br> Lo scalo automatico non è disponibile'"></span>
                    </div>

                  </div>


                  <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="3%" class="mt-2">
                    <button (click)="addItemWarehouseFn(element.warehouseId, element.variantId, element.quantity)"
                            [disabled]="(checked && !canAutoscale(getItemVariant(element), element.warehouseId, element.quantity)) || formGroup.invalid"
                            class="border border-primary"
                            color="primary" fxFlex="30"
                            mat-button>
                      Salva
                    </button>

                    <button (click)="onRowEditCancel(element)" class="border border-warn" color="warn" fxFlex="30"
                            mat-button>Annulla
                    </button>
                  </div>


                </div>


                <div *ngIf="(getItemVariant(element).itemComposers?.length ?? -1) <= 0">
                  <div class="mt-2" fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="3%">
                    <button (click)="addItemWarehouseFn(element.warehouseId, element.variantId, element.quantity)" [disabled]="element.quantity < 0  || formGroup.invalid"
                            class="border border-primary" color="primary" fxFlex="30" mat-button>Salva
                    </button>
                    <button (click)="onRowEditCancel(element)" class="border border-warn" color="warn" fxFlex="30"
                            mat-button>Annulla
                    </button>
                  </div>
                </div>

              </div>
            </ng-container>

          </td>
        </ng-container>



        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>

      <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate"
        >
        </mat-progress-spinner>
      </mat-card>

    <mat-paginator #paginator
                   (page)="loadResults($event, observable$)"
                   (selectionchange)="logMe($event)"
                   *ngIf="dataSource && numEntities > 0"
                   [length]="numEntities"
                   [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                   class="mat-elevation-z1"
                   showFirstLastButtons
    >
    </mat-paginator>

  </mat-expansion-panel>
</mat-accordion>
