import {Component, OnDestroy} from '@angular/core';
import {of, Subscription, switchMap, tap} from "rxjs";
import {UsersService} from "../../../services/users.service";
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {map} from "rxjs/operators";
import {PermissionsService} from "../../../services/permissions.service";
import {Constants} from "../../../utils/constants";
import {ShopDetail} from "../../../interfaces/shop";
import {ShopsService} from "../../../services/shops.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {BaseTabGroupComponent} from "../../../utils/shared-components/base-container/base-tab-group.component";


@Component({
  selector: 'aw-shops-permissions',
  templateUrl: './shops-permissions-container.component.html',
  styleUrls: ['./shops-permissions-container.component.scss']
})
export class ShopsPermissionsContainerComponent extends BaseTabGroupComponent implements OnDestroy {
  loadingShop = false

  shopId!: number

  shop?: ShopDetail

  entities = Constants.entities

  roleInfoMessage = Constants.roleInfoMessage

  accessEntityToEntityIdentifierMap = Constants.accessEntityToEntityIdentifierMap

  subs: Subscription[] = []

  constructor(private usersService: UsersService,
              private shopsService: ShopsService,
              private permissionsService: PermissionsService,
              private authService: AuthService,
              public snackService: SnackService,
              protected utilsService: UtilsService,
              protected dialogService: DialogsService,
              protected override activatedRoute: ActivatedRoute,
              protected override router: Router
              ) {

    super(activatedRoute, router)
    let shopId: number | null = null;

    activatedRoute.paramMap.subscribe(paramMap => {
      if (paramMap.has("shopId"))
        shopId = +paramMap.get("shopId")!
    })


    this.shopId = shopId!

    let obs$ =
      of(true)
        .pipe(
          tap(() => this.loadingShop = true),
          switchMap(() => this.utilsService.getUserReference()),
          switchMap(userReference => this.shopsService.getShop(userReference.companyId, this.shopId)),
          map(shop => {
            this.shop = shop
            this.loadingShop = false;
          })
        )

    this.subs.push(obs$.subscribe())
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe())
  }

}
