<div [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="space-between">

  <mat-form-field fxFlex="45">

    <mat-label>Nome</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="name"
      id="name"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['name'].errors?.['required']"
    >
      È obbligatorio inserire il nome
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45">

    <mat-label>Cognome</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="surname"
      id="surname"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['surname'].errors?.['required']"
    >
      È obbligatorio inserire il cognome
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45">
    <mat-label>Email</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="email"
      id="email"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['email'].errors?.['required']"
    >
      È obbligatorio inserire la mail
    </mat-error>
    <mat-error *ngIf="formGroup.controls['email'].errors?.['pattern']"
    >
      Inserire una mail valida
    </mat-error>
  </mat-form-field>


  <mat-form-field fxFlex="45">
    <mat-label>Telefono</mat-label>
    <input [dropSpecialCharacters]="false"
           [mask]="'+00 000 0*'" [readonly]="readOnly" formControlName="phone" matInput name="phone">
  </mat-form-field>


  <mat-form-field fxFlex="45">

    <mat-label>Indirizzo</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="address"
      id="address"
      matInput
      type="text">
  </mat-form-field>



  <button
    (click)="_entity?.id ? updateEntity(companyId, _entity.id) : createEntity(companyId)"
    [disabled]="formGroup.invalid || saving || readOnly || !formGroup.dirty"
    color="primary"
    mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Salva</span>
  </button>
</div>






