import {Component, OnDestroy} from '@angular/core';
import {EMPTY, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {WarehousesService} from "../../services/warehouses.service";
import {AuthService} from "../../services/auth.service";
import {catchError} from "rxjs/operators";
import {WarehouseDetail} from "../../interfaces/warehouse";
import {ItemVariantsService} from "../../services/item-variants.service";
import {ItemTypesService} from "../../services/item-types.service";
import {UserReference} from "../../utils/global-functions-and-types";
import {Constants} from "../../utils/constants";
import {SnackService} from "../../services/snack.service";
import {UtilsService} from "../../services/utils.service";
import {BaseTabGroupComponent} from "../../utils/shared-components/base-container/base-tab-group.component";

@Component({
  selector: 'aw-warehouse-items',
  templateUrl: './warehouse-items-page.component.html',
  styleUrls: ['./warehouse-items-page.component.scss']
})
export class WarehouseItemsPageComponent extends BaseTabGroupComponent implements OnDestroy {

  accessEntities = Constants.accessEntities
  accessLevels = Constants.accessLevels

  loading = false
  saving = false
  warehouse$: Observable<WarehouseDetail>
  warehouse!: WarehouseDetail
  userReference!: UserReference
  warehouseId!: number;
  private subs: Subscription[] = []

  constructor(private warehousesService: WarehousesService,
              private itemVariantsService: ItemVariantsService,
              private itemTypesService: ItemTypesService,
              private authService: AuthService,
              private snackService: SnackService,
              private utilsService: UtilsService,
              protected override router: Router,
              protected override activatedRoute: ActivatedRoute) {

    super(activatedRoute, router)
    this.warehouse$ =
      of(true)
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.activatedRoute.paramMap),
          switchMap(paramMap => {
            if (paramMap.has('warehouseId'))
              this.warehouseId = +paramMap.get('warehouseId')!
            return this.utilsService.getUserReference()
          }),
          switchMap(userReference => {
            this.userReference = userReference
            return this.warehousesService.getWarehouseById(this.userReference.companyId, this.warehouseId)
          }),
          tap((warehouse) => {
            this.loading = false;
            return this.warehouse = warehouse
          }),
          catchError((err, caught) => {
            this.loading = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i dati del magazzino')
            return EMPTY;
          }))

    this.subs.push(this.warehouse$.subscribe())


  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }


}
