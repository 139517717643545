export class ColumnNameConversion {



  static conversionMap = {
    customerName: 'Cliente',
    name: 'Nome',
    address: 'Indirizzo',
    phone: 'Telefono',
    actions: 'Azioni',
    shopName: 'Punto vendita',
    finalPrice: 'Prezzo',
    shippingAddress: 'Indirizzo consegna',
    deliveryDay: 'Giorno di consegna',
    note: 'Note',
    deliveryDate: 'Data consegna',
    deadlineDate: 'Scadenza prevista',
    status: 'Stato',
    paymentStatus: 'Saldo',
    deliveryNotes: 'Note consegna',
    deliveryTimeSlotId: 'Fascia Oraria',
    deliveryCompanyId: 'Corriere'
  }

  static conversionMapKeys(): string[] {
    return Object.keys(ColumnNameConversion.conversionMap);
  }
}

