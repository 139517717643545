<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>

<mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="matTabChange($event)">

  <mat-tab label="Anagrafica" *awAccess="{accessLevel:{accessEntity: accessEntities.companies, accessLevel: accessLevels.editor}}">
    <h1 class="font-light">{{company?.name}}</h1>
    <aw-companies-form [entity]="company!"></aw-companies-form>
  </mat-tab>

  <mat-tab label="Utenti" *awAccess="{accessLevel:{accessEntity: accessEntities.users, accessLevel: accessLevels.reader}}" >
    <aw-users></aw-users>
  </mat-tab>

  <mat-tab label="Profili" *awAccess="{accessLevel:{accessEntity: accessEntities.profiles, accessLevel: accessLevels.reader}}">
    <aw-profiles></aw-profiles>
  </mat-tab>


  <mat-tab label="Tipi Articoli" *awAccess="{accessLevel:{accessEntity: accessEntities.items, accessLevel: accessLevels.reader}}">
      <aw-item-types-table></aw-item-types-table>
  </mat-tab>

  <mat-tab label="Opzioni" *awAccess="{accessLevel:{accessEntity: accessEntities.items, accessLevel: accessLevels.reader}}">
      <aw-options-table></aw-options-table>
  </mat-tab>

  <mat-tab label="Corrieri" *awAccess="{accessLevel:{accessEntity: accessEntities.deliveryCompanies, accessLevel: accessLevels.reader}}">
    <aw-delivery-companies-table></aw-delivery-companies-table>
  </mat-tab>

  <mat-tab label="Fasce orarie" *awAccess="{accessLevel:{accessEntity: accessEntities.deliveryCompanies, accessLevel: accessLevels.reader}}">
    <aw-delivery-time-slots-table></aw-delivery-time-slots-table>
  </mat-tab>

</mat-tab-group>

