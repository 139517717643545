<div [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="space-between">

  <div fxFlex class="py-2">
    <ng-container *ngIf="!_entity.id">
      <mat-checkbox (change)="cleanSurnameVATFields()" formControlName="isCompany">
        Inserisci azienda
      </mat-checkbox>
    </ng-container>
  </div>

  <mat-form-field fxFlex="45">

    <mat-label>{{!formGroup.controls['isCompany'].value ? "Nome" : "Ragione Sociale"}}</mat-label>
    <input
      (keyup)="readOnly ? undefined : checkCustomer($event)"
      [readonly]="readOnly"
      formControlName="name"
      id="name"
      matInput
      type="text">

    <span *ngIf="checkingFullName && formGroup.value.name" matSuffix><i
      class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

    <mat-icon *ngIf="nameAlreadyInUse"
              [matTooltip]="'Attenzione, questo nome è già utilizzato'"
              color="warn" matSuffix>
      warning_amber
    </mat-icon>

    <mat-icon *ngIf="!nameAlreadyInUse && formGroup.controls['name'].dirty && formGroup.value.length > 0"
              matSuffix>done
    </mat-icon>


    <mat-error *ngIf="formGroup.controls['name'].errors?.['required']">
      È obbligatorio inserire il nome
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45" *ngIf="!formGroup.controls['isCompany'].value">
    <mat-label>Cognome</mat-label>
    <input
      (keyup)="readOnly ? undefined : checkCustomer($event)"
      [readonly]="readOnly"
      formControlName="surname"
      id="surname"
      matInput
      type="text"
      *ngIf="!formGroup.controls['isCompany'].value"
      [required]="!formGroup.controls['isCompany'].value">

    <span *ngIf="checkingFullName" matSuffix><i
      class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

    <mat-icon *ngIf="nameAlreadyInUse"
              [matTooltip]="'Attenzione, questo nome è già utilizzato'"
              color="warn" matSuffix>
      warning_amber
    </mat-icon>

    <mat-icon *ngIf="!nameAlreadyInUse && formGroup.controls['surname'].dirty && formGroup.value.length > 0"
              matSuffix>done
    </mat-icon>
    <mat-error *ngIf="formGroup.controls['surname'].errors?.['required']">
      È obbligatorio inserire il cognome
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45" *ngIf="formGroup.controls['isCompany'].value">
    <mat-label>Partita IVA</mat-label>
    <input
      (keyup)="readOnly ? undefined : checkCustomer($event)"
      [readonly]="readOnly"
      formControlName="vat"
      id="vat"
      matInput
      type="text">

    <span *ngIf="checkingVat" matSuffix><i
      class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

    <mat-icon *ngIf="vatAlreadyInUse"
              [matTooltip]="'Attenzione, questo codice è già utilizzato'"
              color="warn" matSuffix>
      warning_amber
    </mat-icon>

    <mat-icon *ngIf="!vatAlreadyInUse && formGroup.controls['vat'].dirty && formGroup.value.length > 0"
              matSuffix>done
    </mat-icon>

  </mat-form-field>

  <mat-form-field fxFlex="45">

    <mat-label>Email</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="email"
      id="email"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['email'].errors?.['pattern']"
               class="p-error block">
      Inserire una mail valida
    </mat-error>
  </mat-form-field>


  <mat-form-field fxFlex="45">
    <mat-label>Telefono</mat-label>


    <input
      (keyup)="readOnly ? undefined : checkCustomer($event)"
      [readonly]="readOnly"
      [dropSpecialCharacters]="false" [mask]="'+00 000 0*'" formControlName="phone" matInput name="phone"
    >

    <span *ngIf="checkingPhone" matSuffix><i
      class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

    <mat-error *ngIf="formGroup.controls['phone'].errors?.['required']">
      È obbligatorio inserire il numero di telefono
    </mat-error>

    <mat-icon *ngIf="phoneAlreadyInUse"
              [matTooltip]="'Attenzione, questo numero è già utilizzato'"
              color="warn" matSuffix>
      warning_amber
    </mat-icon>

    <mat-icon *ngIf="!phoneAlreadyInUse && formGroup.controls['phone'].dirty && formGroup.value.length > 0"
              matSuffix>done
    </mat-icon>

  </mat-form-field>


  <div *ngIf="vatAlreadyInUse || phoneAlreadyInUse || nameAlreadyInUse"
       class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5 warning-container">

    <div class="container">

      <mat-icon class="left-columnn warning-icon">warning</mat-icon>


      <div class="right-column">
        <!-- Colonna 2 -->
        <div fxLayout="column" class="warning-prints">
          <div *ngIf="nameAlreadyInUse">
            <p>
              <strong> {{ formGroup.value.isCompany ? " - Ragione sociale già utilizzata" : " - Nome e cognome già utilizzati" }} </strong>
            </p>
          </div>

          <div *ngIf="phoneAlreadyInUse">
            <p>
              <strong>- Il telefono è già in uso da:</strong>
            </p>

            <ul>
              <li *ngFor="let name of getUsedPhonesNames()" >
                {{ name }}
              </li>
            </ul>
          </div>

          <div *ngIf="vatAlreadyInUse">
            <p>
             <strong>- La Partita IVA è già in uso da:</strong>
            </p>

            <ul>
              <li *ngFor="let name of getUsedVatsNames()">
                {{ name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!--  Fine div dei warnings -->

  <button
    (click)="_entity?.id ? updateEntity(companyId, _entity.id) : createEntity(companyId)"
    color="primary"
    mat-raised-button
    [disabled]="formGroup.invalid || saving || readOnly || !formGroup.dirty"
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Salva</span>
  </button>


</div>






