import {Component} from '@angular/core';
import {CompanyData} from "../../../interfaces/company";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {ResponsiveService} from "../../../services/responsive.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {hasPermissionOnEntity} from "../../../utils/global-functions-and-types";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {CompaniesService} from "../../../services/companies.service";
import {CompaniesFormComponent} from "../companies-form/companies-form.component";
import {Constants} from "../../../utils/constants";


@Component({
  selector: 'aw-companies-table',
  templateUrl: './companies-table.component.html',
  styleUrls: ['./companies-table.component.scss']
})
export class CompaniesTableComponent extends BaseTableComponent<CompanyData> {

  constructor(private companiesService: CompaniesService,
              public responsiveService: ResponsiveService,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
  ) {

    let deleteFunction =
      (companyId: number): Observable<void> =>
        companiesService.deleteCompany(companyId)

    super('name', Constants.localStorageKeys.companiesTable, deleteFunction, utilsService, snackService, dialogService)


    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(userReference => {
            this.userReference = userReference
            return this.companiesService.getAllCompanies(this.fetchParams)
          }),
          switchMap(companies => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<CompanyData>(companies.list)
            this.entityList.push(...companies.list)
            this.numEntities = companies.num
            return of(companies.list)
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare le aziende');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())

    this.displayedColumns = [
      "name",
      "owner",
      "action",
    ]

  }


  addCompany() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Azienda',
        componentData: {companyId: this.userReference.companyId},
        component: CompaniesFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: () => this.snackService.success('Azienda creata'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  editCompany(companyId: number) {
    this.companiesService.getCompanyById(companyId)
      .subscribe(company => {
        let readonly = !hasPermissionOnEntity(this.userReference.user, {
          accessEntity: this.accessEntities.companies,
          accessLevel: this.accessLevels.editor
        }, company)

        this.dialogService.open(GeneralDialogComponent, {
          data: {
            dialogTitle: readonly ? `DETTAGLIO – ${company.name}` : `MODIFICA – ${company.name}`,
            componentData: {entity: company, readOnly: readonly},
            component: CompaniesFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Azienda aggiornata');
            },
            error: () => {
              this.snackService.success('Impossibile aggiornare l\'azienda');
            },
          })
      })
  }


}
