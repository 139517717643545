import {Component, OnDestroy, OnInit} from '@angular/core';
import {EMPTY, forkJoin, Observable, of, Subject, Subscription, switchMap, tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {catchError, map} from "rxjs/operators";
import {DeliveryOption, OrderContentUpdateWithStates, SellOrderDetail} from "../../../interfaces/sell-order";
import {OrdersService} from "../../../services/orders.service";
import {AuthService} from "../../../services/auth.service";
import {Constants} from "../../../utils/constants";
import {FormControl, FormGroup} from "@angular/forms";
import {SellOrderEventData} from "../../../interfaces/sell-order-event";
import {WarehouseData, WarehouseDetail} from "../../../interfaces/warehouse";
import {ItemWarehouseDetail, WarehouseStocks} from "../../../interfaces/item-warehouse";
import {WarehousesService} from "../../../services/warehouses.service";
import {ItemVariantDetail} from "../../../interfaces/item-variant";
import {
  SellOrderContentCancel,
  SellOrderContentClose,
  SellOrderContentCreate,
  SellOrderContentDetail,
  OrderContentStatus,
  SellOrderContentUpdateStatus
} from "../../../interfaces/sell-order-content";
import {ItemVariantsService} from "../../../services/item-variants.service";
import {ClosingWrapper} from "../../../components/orders/orders-form/orders-form.component";
import {SaleContentStatus} from "../../../interfaces/sale-content";
import {ShopDetail} from "../../../interfaces/shop";
import {ShopsService} from "../../../services/shops.service";
import {RollbackStatus} from "../../../utils/global-functions-and-types";
import {SnackService} from "../../../services/snack.service";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {DialogsService} from "../../../services/dialogs.service";
import {TimelineComponent} from "../../../utils/shared-components/timeline/timeline.component";
import {CustomersService} from "../../../services/customers.service";
import {CustomerData} from "../../../interfaces/customer";

@Component({
  selector: 'aw-order-details',
  templateUrl: './order-details-container.component.html',
  styleUrls: ['./order-details-container.component.scss']
})
export class OrderDetailsContainerComponent implements OnInit, OnDestroy {

  formGroup: FormGroup

  disabled = false
  showButton = true
  saving = false
  loading = false
  loadingOrder = false
  loadingWarehouses = false
  loadingItemWarehouses = false
  loadingItems = false
  loadingAllStocks = false
  manageWarehouseAutomatically = false
  orderDetail$: Observable<SellOrderDetail>
  items$: Observable<ItemVariantDetail[]>;
  warehouses$: Observable<WarehouseData[]>
  theBigThree$: Observable<any>
  allWarehousesStocks$: Observable<WarehouseData[]>
  order!: SellOrderDetail;
  events: SellOrderEventData[] = []
  warehouses: WarehouseDetail[] = []
  items: ItemVariantDetail[] = []
  warehouseItems: ItemWarehouseDetail[] = [];
  allWarehouseStocks: any = [];
  itemWarehouseSubject = new Subject<ItemWarehouseDetail[]>()
  itemsSubject = new Subject<ItemVariantDetail[]>()
  createdOrderContentSubject = new Subject<boolean>()
  removedOrderContentSubject = new Subject<boolean>()
  createdOrderContentObjectSubject = new Subject<SellOrderContentDetail>()
  referenceCompanyId!: number;
  referenceOrderId!: number;
  shopId = 0
  orderStatuses = Constants.orderStatuses
  orderContentStatuses = Constants.orderContentStatuses
  statusTransitionMap = Constants.orderStatusTransitionMap
  originalOrderStatusTransitionMap = Constants.originalOrderStatusTransitionMap
  selectedStatus: string = ''
  selectedDeliveryOption?: DeliveryOption
  availableStates: string[] = []
  errorToShow = ''
  quantityErrorsToShow: string[] = [];
  // questa funzione ritorna un observable delle giacenze in base al warehouseId passato come parametro
  warehouseItemsFn: (warehouseId: number) => Observable<ItemWarehouseDetail[]>;
  allWarehousesItemsFn: (warehouseId: number) => Observable<ItemWarehouseDetail[]>;
  showOrderContentModal = false;
  contentToEdit?: SellOrderContentDetail = {} as SellOrderContentDetail;
  cannotUpdate = false;
  updatedStates: string[] = Object.values(Constants.saleContentUpdatedStatuses)
  rollbackStates: RollbackStatus[] = Constants.rollbackStates
  generalSelectedRollbackStatus?: RollbackStatus;
  generalSelectedSellOrderContentStatus?: OrderContentStatus;
  isOrderFailed: boolean = false;
  autoscaleErrorOnSellOrderClose: boolean = false;
  savingStatus: boolean = false;
  itemToHandle: SellOrderContentDetail[] = []
  currentWarehouseItemWarehouseDetails: WarehouseStocks[] = []
  closeOrderContentWrapper: ClosingWrapper[] = []
  shop?: ShopDetail
  shop$: Observable<ShopDetail>
  orderCustomer: CustomerData | undefined
  private subs: Subscription[] = []
  readOnly = false;
  constructor(
    private ordersService: OrdersService,
    private warehousesService: WarehousesService,
    private itemsService: ItemVariantsService,
    private authService: AuthService,
    private shopsService: ShopsService,
    private snackService: SnackService,
    private dialogService: DialogsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customersService: CustomersService) {

    this.formGroup = new FormGroup({
      status: new FormControl(null),
      checked: new FormControl(null)
    })

    let ids$ = activatedRoute
      .paramMap
      .pipe(switchMap(paramsMap => {

        if (paramsMap.has('shopId'))
          this.shopId = +paramsMap.get('shopId')!

        if (!paramsMap.has('orderId'))
          throw Error()

        if (paramsMap.has('companyId')) {
          return of({cid: +paramsMap.get('companyId')!, oId: +paramsMap.get('orderId')!});
        } else {
          return this.authService.onUserChange().pipe(map(u => {
            return {
              cid: u?.companyId!, oId: +paramsMap.get('orderId')!
            }
          }));
        }
      }), tap((ids) => {
        this.referenceCompanyId = ids.cid
        this.referenceOrderId = ids.oId
      }), catchError((err, caught) => {
        router.navigate(['/error']).then()
        return EMPTY
      }))

    this.orderDetail$ = ids$
      .pipe(
        tap(() => {
          this.loading = true
          this.loadingOrder = true
        }),
        switchMap(() => {

          return this.shopId ?
            this.ordersService.getCompanyShopOrder(this.referenceCompanyId, this.shopId, this.referenceOrderId) :
            this.ordersService.getCompanyOrder(this.referenceCompanyId, this.referenceOrderId)

        }), tap((sellOrder) => {
          this.order = sellOrder

          // Se è stato gestito automaticamente lo risetto per evitare che quando si passa da uno stato di
          // "consegna fallita" a uno stato di "Pronto per la consegna/ritiro" cerchi di cambiare lo stato dell'orderContent
          // (cambia proprio in quegli stati)
          if (sellOrder.content && sellOrder.content.length > 0)
            this.manageWarehouseAutomatically = sellOrder.content[0].status === this.orderContentStatuses.updated

          if (sellOrder.events && sellOrder.events.length > 0)
            this.events = sellOrder.events.sort((e1, e2) => (e1.createdAt > e2.createdAt ? 1 : -1))

          this.selectedStatus = sellOrder.status!
          this.selectedDeliveryOption = sellOrder.deliveryOption

          if (this.selectedDeliveryOption)
            this.availableStates = [...this.statusTransitionMap[this.selectedDeliveryOption][this.selectedStatus], this.selectedStatus];
          else
            this.availableStates = [...this.originalOrderStatusTransitionMap[this.selectedStatus], this.selectedStatus];


          if (this.availableStates.length < 2) {
            this.disabled = true
            this.showButton = false
          }

          this.loadingOrder = false;
          this.loading = false;
        }),
        switchMap(() => {
          if (this.order.customerId)
            return customersService.getCompanyCustomer(this.referenceCompanyId, this.order.customerId)

          return of(undefined)
        }),
        switchMap((customer) => {

          if (customer)
            this.orderCustomer = customer

          return of(this.order)
        }),
        catchError((err, caught) => {
          this.loading = false;
          this.loadingOrder = false;
          if (!err.errors.includes('403'))
            this.snackService.error("Impossibile caricare il dettaglio dell'ordine")
          return EMPTY;
        }))

    this.warehouses$ = ids$
      .pipe(
        tap(() => this.loadingWarehouses = true),
        switchMap(() => {
          return this.authService.onUserChange().pipe((map((u) => u?.access.warehouses)))
        }),
        switchMap(warehouses => {
          return this.warehousesService.getAllCompanyWarehouses(this.referenceCompanyId,
            warehouses?.userAuthority != undefined ? [] : warehouses!.permission.map(value => value.entityId), undefined)
        }),
        map(warehouses => {
          this.warehouses = warehouses.list
          this.loadingWarehouses = false;
          this.loading = false;
          return warehouses.list
        }),
        catchError((err, caught) => {
          this.loading = false;
          this.loadingWarehouses = false;
          if (!err.errors.includes('403'))
            this.snackService.error("Impossibile caricare i magazzini dell'azienda")
          return EMPTY;
        }))

    this.items$ = ids$
      .pipe(
        tap(() => this.loadingItems = true),
        switchMap(() => this.itemsService.getAllCompanyItems(this.referenceCompanyId, undefined)),
        switchMap(items => {
          this.loadingItems = false;
          return of(this.items = items.list)
        }),
        catchError((err, caught) => {
          this.loading = false;
          this.loadingItems = false;
          if (!err.errors.includes('403'))
            this.snackService.error("Impossibile caricare gli articoli dell'azienda")
          return EMPTY;
        }))

    this.warehouseItemsFn =
      (warehouseId: number) =>
        of(true)
          .pipe(
            tap(() => this.loadingItemWarehouses = true),
            switchMap(() => this.warehousesService.getWarehouseItems(this.referenceCompanyId, warehouseId, undefined)),
            map((warehouseItems) => {
              this.warehouseItems = warehouseItems.list
              this.loadingItemWarehouses = true
              return warehouseItems.list
            }),
            catchError((err, caught) => {
              this.loading = false;
              this.loadingItemWarehouses = false;

              if (!err.errors.includes('403'))
                this.snackService.error("Impossibile caricare le giacenze del magazzino")
              return EMPTY;
            }))

    this.allWarehousesItemsFn =
      (warehouseId: number) =>
        of(true)
          .pipe(
            tap(() => {
            }),
            switchMap(() => this.warehousesService.getWarehouseItemsTmp(this.referenceCompanyId, warehouseId)),
            tap((warehouseItems) => this.allWarehouseStocks[`${warehouseId}`] = warehouseItems)),
      catchError((err, caught) => {

        if (!err.errors.includes('403'))
          this.snackService.error("Impossibile caricare le giacenze del magazzino")
        return EMPTY;
      })

    this.allWarehousesStocks$ =
      of(true)
        .pipe(
          tap(() => this.loadingAllStocks = true),
          switchMap(() => {
            let warehouseIds: number[] = []
            // per ogni order content prendo il warehouseId, se esiste
            if (this.order.content && this.order.content.length > 0) {
              this.order.content.forEach(content => {
                if (content.warehouse != undefined && content.warehouse.id && !(warehouseIds.find(warehouseId => warehouseId === content.warehouse?.id))) {
                  warehouseIds.push(content.warehouse.id)
                }
              })
            }

            // Se c'è roba, faccio la chiamata
            if (warehouseIds.length > 0)
              return forkJoin(warehouseIds.map(warehouseId => this.allWarehousesItemsFn(warehouseId)))
            else {
              this.loadingAllStocks = false
            }
            return new Observable<any>

          }),
          tap(() => {
            this.loadingAllStocks = false
            this.loading = false
          }),
          catchError((err, caught) => {
            this.loadingAllStocks = false;
            this.loading = false

            if (!err.errors.includes('403'))
              this.snackService.error("Impossibile caricare le giacenze del magazzino")
            return EMPTY;
          }))


    this.theBigThree$ =
      of(true)
        .pipe(
          tap(() => {
            this.loading = true
          }),
          switchMap(value => this.orderDetail$),
          switchMap(value => this.warehouses$),
          switchMap(value => this.allWarehousesStocks$),
          tap(() => {
            this.loading = false
          }),
        )

    this.shop$ =
      ids$
        .pipe(
          switchMap(() => {
            if (!this.shopId)
              throw Error()
            return of(true)
          }),
          switchMap(() => this.shopsService.getShop(this.referenceCompanyId, this.shopId, true)),
          tap((shop) => {
            this.shop = shop
          }),
          catchError((err, caught) => {
            if (err.errors.includes('403')) {
              this.shopsService.getShop(this.referenceCompanyId, this.shopId)
                .subscribe(
                  {
                    next: (shop) => {
                      this.shop = shop
                    },
                    error: (err) => {
                      if (!err.errors.includes('403')) {
                        this.snackService.error('Impossibile caricare i dati del punto vendita')
                      }
                    }
                  }
                )
            }
            return EMPTY;
          })
        )

  }

  ngOnInit(): void {
    this.subs.push(this.orderDetail$.subscribe(() => {
      this.getItemWarehouses(this.order.content?.filter(value => value.status === Constants.orderContentStatuses.notUpdated && value.warehouse != undefined).map(value => value.warehouse!.id) ?? [])
      this.subs.push(this.warehouses$.subscribe(() =>
        this.subs.push(this.allWarehousesStocks$.subscribe(() => {
        }))
      ))
    }))

    this.subs.push(this.shop$.subscribe())
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  applyStatus(status: string) {
    this.savingStatus = true

    if (status === Constants.orderStatuses.failed) {
      let orderContents: SellOrderContentCancel[] = []
      this.closeOrderContentWrapper.forEach(value => orderContents.push({
        warehouseId: value.warehouseId,
        sellOrderContentId: value.sellOrderContentId,
        autoRollback: value.selectedRollback?.value ?? false
      }))
      this.cancelOrder(orderContents)
    } else {
      let orderContents: SellOrderContentUpdateStatus[] = []
      this.closeOrderContentWrapper.forEach((value => orderContents.push({
        sellOrderContentId: value.sellOrderContentId,
        state: value.selectedStatus!,
        warehouseId: value.warehouseId
      })))
      this.changeOrderStatus({
        orderStatus: this.selectedStatus,
        orderContents: orderContents
      })
    }
  }

  // Con questa chiamata possiamo andare in tutti gli stati tranne che 'Annullato'

  // in quanto nello stato 'Annullato' bisogna gestire il rollback


  changeOrderStatus(sellOrderContentClose: SellOrderContentClose) {
    of(true)
      .pipe(
        switchMap(() => {
          if (sellOrderContentClose.orderContents.length <= 0)
            return of(true)

          return forkJoin(sellOrderContentClose.orderContents.map(value => {
            return this.shopId ?
              this.ordersService.updateOrderContentStatusFromShop(this.referenceCompanyId, this.shopId, this.referenceOrderId, value.sellOrderContentId, value.state, value.state === 'UPDATED', value.warehouseId) :
              this.ordersService.updateOrderContentStatus(this.referenceCompanyId, this.referenceOrderId, value.sellOrderContentId, value.state, value.state === 'UPDATED', value.warehouseId)
          }))
        }),
        switchMap(() => this.shopId ?
          this.ordersService.updateOrderStatusFromShop(this.referenceCompanyId, this.shopId, this.referenceOrderId, sellOrderContentClose.orderStatus) :
          this.ordersService.updateOrderStatus(this.referenceCompanyId, this.referenceOrderId, sellOrderContentClose.orderStatus)
        ),
        switchMap(() => {
          // this.params = new HttpParams({fromObject: {num: 10}})
          return this.orderDetail$
        })
      ).subscribe({
      next: () => {
        this.savingStatus = false
        this.snackService.success("Stato dell'ordine aggiornato")
      },
      error: (err) => {
        this.savingStatus = false
        this.snackService.error("Impossibile aggiornare lo stato dell'ordine")
      }
    })

  }

  cancelOrder(sellOrderContentCancels: SellOrderContentCancel[]) {
    of(true)
      .pipe(
        switchMap(() => {
          if (sellOrderContentCancels.length <= 0)
            return of(true)

          return forkJoin(sellOrderContentCancels.map(value => {
            return this.shopId ? this.ordersService.updateOrderContentStatusFromShop(this.referenceCompanyId, this.shopId, this.referenceOrderId, value.sellOrderContentId, 'NOT_UPDATED', value.autoRollback, value.warehouseId) :
              this.ordersService.updateOrderContentStatus(this.referenceCompanyId, this.referenceOrderId, value.sellOrderContentId, 'NOT_UPDATED', value.autoRollback, value.warehouseId)
          }))
        }),
        switchMap(() => this.shopId ?
          this.ordersService.updateOrderStatusFromShop(this.referenceCompanyId, this.shopId, this.referenceOrderId, 'FAILED') :
          this.ordersService.updateOrderStatus(this.referenceCompanyId, this.referenceOrderId, 'FAILED')
        ),
        switchMap(() => {
          // this.params = new HttpParams({fromObject: {num: 10}})
          return this.orderDetail$
        })
      ).subscribe({
      next: () => {
        this.savingStatus = false
        this.snackService.success("Stato dell'ordine aggiornato")
      },
      error: (err) => {
        this.savingStatus = false
        this.snackService.error("Impossibile aggiornare lo stato dell'ordine")
      }
    })
  }

  canTransition(appliedStatus: string) {

    let canTransition = false


    switch (appliedStatus) {
      case this.orderStatuses.deliveringRider:
      case this.orderStatuses.deliveringCourier:
      case this.orderStatuses.deliveryFailedRider:
      case this.orderStatuses.deliveryFailedCourier:
      case this.orderStatuses.deliveredRider:
      case this.orderStatuses.deliveredCourier:
      case this.orderStatuses.pickedUp:
      case this.orderStatuses.completed:
      case this.orderStatuses.failed:
        this.errorToShow = ''
        canTransition = true
        break;

      case this.orderStatuses.readyForPickupShop:
        canTransition = !!this.order.shopId
        if (!canTransition)
          this.errorToShow = 'Devi prima assegnare un punto vendita'
        break;
      case this.orderStatuses.inProgress:
        if (this.order.status !== this.orderStatuses.inProgress) {
          canTransition = !!this.order.shopId
          if (!canTransition)
            this.errorToShow = 'Devi prima assegnare un punto vendita'
        }
        break;

      case this.orderStatuses.readyForPickupRider:
      case this.orderStatuses.readyForPickupCourier:

        canTransition = !!this.order.deliveryCompany
        if (!canTransition)
          this.errorToShow = 'Devi prima scegliere il corriere'
    }
    return canTransition;
  }

  hasManagedWarehouses() {
    if (this.order.content && this.order!.content.length == 0)
      return true
    return !(this.order.content?.find(value => value.warehouse?.id != null && value.status == this.orderContentStatuses.notUpdated) != undefined)
  }

  handleWarehouseItemsRequest(warehouseId: number) {
    this.warehouseItemsFn(warehouseId).subscribe(value => this.itemWarehouseSubject.next(value))
  }

  handleCompanyItemsRequest() {
    this.items$.subscribe(value => this.itemsSubject.next(value))
  }

  createOrderContent(orderContent: SellOrderContentCreate) {
    this.loading = true

    let obs$ = this.shopId ?
      this.ordersService.createOrderContentFromShop(this.referenceCompanyId, this.shopId, this.order.id, [orderContent]) :
      this.ordersService.createOrderContent(this.referenceCompanyId, this.order.id, [orderContent])

    obs$
      .pipe(
        switchMap(result => {
          this.createdOrderContentObjectSubject.next(result?.[0] ?? [])
          return this.theBigThree$
        })
      )
      .subscribe({
        next: () => {
          this.snackService.success("Contenuto dell'ordine aggiornato")
          this.createdOrderContentSubject.next(true)
          this.loading = false
          this.showOrderContentModal = false
        },
        error: err => {
          this.snackService.error("Impossibile aggiornare il contenuto dell'ordine")
          this.loading = false
        }
      })
  }

  updateOrderContent(orderContentUpdate: OrderContentUpdateWithStates) {
    of(true)
      .pipe(
        switchMap(() => {
          // Elimino gli articoli rimossi
          if (orderContentUpdate.orderContentToDelete.length <= 0)
            return of(true)

          return forkJoin(orderContentUpdate.orderContentToDelete.map((socId) => {
            return this.shopId ? this.ordersService.deleteSellOrderContentFromShop(this.referenceCompanyId, this.shopId, this.referenceOrderId, socId) :
              this.ordersService.deleteSellOrderContent(this.referenceCompanyId, this.referenceOrderId, socId)
          }))
        }),
        switchMap(() => {
          // Aggiungo gli articoli nuovi
          if (orderContentUpdate.orderContentToCreate.length > 0)
            return this.shopId ? this.ordersService.createOrderContentFromShop(this.referenceCompanyId, this.shopId, this.referenceOrderId, orderContentUpdate.orderContentToCreate) :
              this.ordersService.createOrderContent(this.referenceCompanyId, this.referenceOrderId, orderContentUpdate.orderContentToCreate)
          else return of([])
        }),
        // Aggiorno lo stato degli item appena inseriti
        switchMap((result) => {

          if (result?.length <= 0)
            return of(true)

          return forkJoin(result.map(orderContent => {
            let currentState: SaleContentStatus = orderContentUpdate.orderContentStates.find(it => it.itemId === orderContent.item.id && it.warehouseId === orderContent.warehouse?.id)?.state ?? 'NOT_UPDATED'

            // Se l'utente seleziona lo stato NOT_UPDATED, allora non devo aggiornare lo stato iniziale
            if (currentState === 'NOT_UPDATED')
              return of(true)

            return this.shopId ? this.ordersService.updateOrderContentStatusFromShop(this.referenceCompanyId,
              this.shopId,
              this.referenceOrderId,
              orderContent.id,
              currentState,
              currentState === 'UPDATED',
              orderContent.warehouse?.id!
            ) : this.ordersService.updateOrderContentStatus(this.referenceCompanyId,
              this.referenceOrderId,
              orderContent.id,
              currentState,
              currentState === 'UPDATED',
              orderContent.warehouse?.id!
            )
          }))
        }),
        switchMap(() => {
          // Aggiorno gli articoli modificati
          if (orderContentUpdate.orderContentToUpdate.length <= 0)
            return of(true)

          return forkJoin(orderContentUpdate.orderContentToUpdate.map(
            scu => this.shopId ? this.ordersService.updateOrderContentFromShop(this.referenceCompanyId, this.shopId, this.referenceOrderId, scu.orderContentId, scu) :
              this.ordersService.updateOrderContent(this.referenceCompanyId, this.referenceOrderId, scu.orderContentId, scu)))
        }),
        // Aggiorno lo stato degli item aggiornati dall'utente
        switchMap(() => {

          if (orderContentUpdate.orderContentToUpdate.length <= 0)
            return of(true)

          return forkJoin(orderContentUpdate.orderContentToUpdate.map(scu => {

            let currentState: SaleContentStatus = orderContentUpdate.orderContentStates.find(it => it.itemId === scu.itemVariantId && it.warehouseId === scu.warehouseId)?.state ?? 'NOT_UPDATED'

            // Se l'utente seleziona lo stato NOT_UPDATED, allora non devo aggiornare lo stato iniziale
            if (currentState === 'NOT_UPDATED')
              return of(true)

            return this.shopId ? this.ordersService.updateOrderContentStatusFromShop(this.referenceCompanyId,
                this.shopId,
                this.referenceOrderId,
                scu.orderContentId,
                currentState,
                currentState === 'UPDATED',
                scu.warehouseId!) :
              this.ordersService.updateOrderContentStatus(this.referenceCompanyId,
                this.referenceOrderId,
                scu.orderContentId,
                currentState,
                currentState === 'UPDATED',
                scu.warehouseId!)
          }))
        }),
        switchMap(() => {
          // this.params = new HttpParams({fromObject: {num: 10}})
          return this.orderDetail$
        })
      )
      .subscribe({
        next: () => {
          this.snackService.success("Contenuto dell'ordine aggiornato!")
          this.showOrderContentModal = false
          // this.orderToEdit = {} as SellOrderDetail
        },
        error: err => {
          if (err.status === Constants.responseStatusCode.itemWarehouseIllegalQuantityException) {
            this.snackService.error("Alcuni elementi non sono stati scalati dal magazzino")
          } else {
            this.snackService.error("Impossibile aggiornare il contenuto dell'ordine")
          }
          this.showOrderContentModal = false
          // this.orderToEdit = {} as SellOrderDetail
        }
      })
  }

  deleteOrderContent(orderContents: SellOrderContentDetail[]) {

    this.loading = true

    let allRequests$: Observable<any>[] = orderContents.map(content => this.shopId ? this.ordersService.deleteSellOrderContentFromShop(this.referenceCompanyId, this.shopId, this.order.id, content.id)
      : this.ordersService.deleteSellOrderContent(this.referenceCompanyId, this.order.id, content.id))


    forkJoin(allRequests$)
      .pipe(switchMap(() => this.theBigThree$))
      .subscribe({
        next: () => {
          this.snackService.success("Articoli selezionati rimossi con successo dall'ordine")
          this.loading = false
          this.removedOrderContentSubject.next(true)
        },
        error: err => {
          this.snackService.error("Impossibile rimuovere gli articoli selezionati dall'ordine!")
          this.loading = false
          this.removedOrderContentSubject.next(false)
        }
      })
  }

  addContent() {
    this.contentToEdit = {} as SellOrderContentDetail
    this.showOrderContentModal = true
  }

  // showManageWarehouseButton(status: string) {
  //
  //   if (!this.order.content || this.order.content.length < 1)
  //     return false
  //
  //   switch (status) {
  //     case Constants.orderStatuses.readyForPickupShop:
  //       return (!this.hasManagedWarehouses())
  //
  //     case Constants.orderStatuses.readyForPickupRider:
  //     case Constants.orderStatuses.readyForPickupCourier:
  //       if (this.order.deliveryCompany)
  //         return !this.hasManagedWarehouses()
  //       return false
  //     // Qui non la nego perché la uso al contrario
  //     case Constants.orderStatuses.failed:
  //       if (this.order.status !== Constants.orderStatuses.failed)
  //         return this.hasManagedWarehouses()
  //       break;
  //
  //     case Constants.orderStatuses.completed:
  //       if (this.order.status !== Constants.orderStatuses.completed)
  //         return (!this.hasManagedWarehouses())
  //   }
  //
  //   return false
  //
  // }

  showManageWarehouseButton(status: string) {
    if (!this.order.content || this.order.content.length < 1)
      return false

    switch (status) {
      case Constants.orderStatuses.readyForPickupShop:
        return (!this.hasManagedWarehouses())

      case Constants.orderStatuses.readyForPickupRider:
      case Constants.orderStatuses.readyForPickupCourier:
        if (this.order.deliveryCompany)
          return !this.hasManagedWarehouses()
        return false
      // Qui non la nego perché la uso al contrario
      case Constants.orderStatuses.failed:
        if (this.order.status !== Constants.orderStatuses.failed)
          return !this.hasNotManagedWarehouses()
        break;

      case Constants.orderStatuses.completed:
        if (this.order.status !== Constants.orderStatuses.completed)
          return !this.hasManagedWarehouses()
    }

    return false

  }


  getContentToEdit($event: SellOrderContentDetail) {

    let obs$ = this.shopId ?
      this.ordersService
        .getOrderContentFromShop(this.referenceCompanyId, this.shopId, this.order.id, $event.id) :
      this.ordersService
        .getOrderContent(this.referenceCompanyId, this.order.id, $event.id)

    obs$
      .subscribe(
        {
          next: content => {
            this.contentToEdit = content;
            this.showOrderContentModal = true;
          }
        }
      );
  }

  checkIfQuantityIsEnough(event: any) {

    this.quantityErrorsToShow = []
    this.cannotUpdate = false

    if (!event.value)
      return

    let contents = this.order.content

    let contentsNotYetUpdated = []

    if (contents && contents.length > 0) {

      // Prendo tutti i contenuti con magazzino, le cui giacenze risultano non aggiornate
      contentsNotYetUpdated = contents.filter(content => content.status === this.orderContentStatuses.notUpdated && content.warehouse != undefined)

      // Questo controllo mi dà la sicurezza che si sta andando a uno di questi stati
      // con contenuti ancora non aggiornati (quindi, ad esempio, non sta cambiando da corriere a fattorino)
      if (contentsNotYetUpdated.length > 0) {

        // La funzione gira solo quando si va ad uno stato dove si può scalare automaticamente dalle giacenze
        if (this.selectedStatus === this.orderStatuses.readyForPickupRider ||
          this.selectedStatus === this.orderStatuses.readyForPickupRider ||
          this.selectedStatus === this.orderStatuses.readyForPickupCourier ||
          this.selectedStatus === this.orderStatuses.completed) {

          // per ogni contenuto dell'ordine non aggiornato guardo la giacenza del magazzino di provenienza
          // Se la quantità nel magazzino non è sufficiente, non può aggiornare automaticamente
          contentsNotYetUpdated?.forEach(content => {
            // Per ogni contenuto con warehouse

            let itemWarehouses: ItemWarehouseDetail[] = this.allWarehouseStocks[content.warehouse!.id]
            let itemWarehouse: ItemWarehouseDetail | undefined = itemWarehouses.find(value => value?.item?.id === content?.item?.id)
            if (itemWarehouse) {
              if (content.quantity > itemWarehouse.quantity) {
                this.cannotUpdate = true
                let quantityError = itemWarehouse.item.unit ?
                  `Stai cercando di prelevare ${content.quantity} ${itemWarehouse.item.unit} di "${itemWarehouse.item.name}" dal magazzino "${content.warehouse!.name}", ma dalle giacenze ne risultano ${itemWarehouse.quantity}` :
                  `Stai cercando di prelevare ${content.quantity} unità dell'articolo "${itemWarehouse.item.name}" dal magazzino "${content.warehouse!.name}", ma dalle giacenze ne risultano ${itemWarehouse.quantity}`
                this.quantityErrorsToShow.push(quantityError)
              }
            }
          })
        }

      }
    }

  }

  isApplyStatusButtonDisabled() {
    if (
      this.selectedStatus === this.order?.status ||
      !this.canTransition(this.selectedStatus) ||
      this.saving ||
      this.loadingAllStocks ||
      this.cannotUpdate
    ) {
      return true
    }

    return false
  }

  onStatusChange() {
    this.isOrderFailed = this.selectedStatus === Constants.orderStatuses.failed
    this.closeOrderContentWrapper = []
    if (this.showManageWarehouseButton(this.selectedStatus)) {
      this.itemToHandle = this.order.content?.filter(value => value.warehouse != undefined && ((!this.isOrderFailed && value.status === 'NOT_UPDATED') || (this.isOrderFailed && value.status !== 'NOT_UPDATED'))) ?? []
      this.itemToHandle.forEach(value => this.closeOrderContentWrapper.push({
        itemId: value.item.id,
        warehouseId: value.warehouse!.id,
        selectedStatus: !this.isOrderFailed ? this.generalSelectedSellOrderContentStatus : undefined,
        selectedRollback: this.isOrderFailed ? this.generalSelectedRollbackStatus : undefined,
        sellOrderContentId: value.id,
        name: value.item.name,
        quantity: value.quantity,
        unit: value.item.unit
      }))

    }

  }

  checkIfCanAutoscaleOnOrderClose() {
    this.closeOrderContentWrapper.forEach(
      (value, index) => {

        // Se non devo mostrare la sezione 'manage warehouse' resetto showAutoscaleErrorMessage
        if (this.showManageWarehouseButton(this.selectedStatus))
          this.closeOrderContentWrapper[index].showAutoscaleError = false


        if (value.selectedStatus !== "UPDATED") {
          this.closeOrderContentWrapper[index].showAutoscaleError = false
        } else {
          let itemWarehouse = this.currentWarehouseItemWarehouseDetails.find(wid => wid.warehouseId === value.warehouseId)
          let warehouseQty = itemWarehouse?.itemWarehouse.find(iw => iw.item.id === value.itemId)?.quantity ?? 0

          if (warehouseQty < value.quantity) {
            this.closeOrderContentWrapper[index].autoscaleError = value?.unit ?
              `Attenzione! Stai cercando di prelevare ${value.quantity} ${value?.unit} di "${value?.name}" dal magazzino, ma dalle giacenze ne risultano ${warehouseQty}` :
              `Attenzione! Stai cercando di prelevare ${value.quantity} unità dell'articolo "${value?.name}" dal magazzino, ma dalle giacenze ne risultano ${warehouseQty}`
            this.closeOrderContentWrapper[index].showAutoscaleError = true
          } else {
            this.closeOrderContentWrapper[index].showAutoscaleError = false
          }
        }
      })
  }

  setGlobalErrorMessageOnSellOrderClose() {
    this.autoscaleErrorOnSellOrderClose = this.closeOrderContentWrapper.filter(value => value.showAutoscaleError).length > 0
  }

  updateOrderContentStatusAndCheckErrorsOnCloseSale() {
    if (!this.isOrderFailed) {
      this.closeOrderContentWrapper.forEach(value => value.selectedStatus = this.generalSelectedSellOrderContentStatus)
      this.checkIfCanAutoscaleOnOrderClose()
      this.setGlobalErrorMessageOnSellOrderClose()
    } else {
      this.closeOrderContentWrapper.forEach(value => value.selectedRollback = this.generalSelectedRollbackStatus)
    }
  }

  getItemWarehouses(itemWarehouseIds: number[]) {
    of(true)
      .pipe(
        switchMap(() => {
          if (itemWarehouseIds.length <= 0)
            return of(true)
          return forkJoin(itemWarehouseIds.map(warehouseId => {
            return this.warehousesService.getWarehouseItems(this.referenceCompanyId, warehouseId, undefined).pipe(
              tap((result) => this.currentWarehouseItemWarehouseDetails.push({
                warehouseId: warehouseId,
                itemWarehouse: result.list
              }))
            )
          }))
        }))
      .subscribe()
  }

  getDefaultWarehouse() {
    return this.shop?.defaultWarehouse
  }

  private hasNotManagedWarehouses() {
    return !(this.order?.content?.find(value => value.warehouse?.id != null && value.status != this.orderContentStatuses.notUpdated) != undefined)
  }

  openTimeline() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'Storico eventi',
        componentData: {events: this.events},
        component: TimelineComponent
      }
    })
      .afterClosed()
      .subscribe()

  }
}
