import {Injectable} from '@angular/core';
import {ApiResponse} from "../classes/api-response";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Constants} from "../utils/constants";
import {SellOrderCreate, SellOrderCreateFull, SellOrderDetail, SellOrderFilter} from "../interfaces/sell-order";
import {SellOrderContentCreate, SellOrderContentDetail, SellOrderContentUpdate} from "../interfaces/sell-order-content";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";
import {Observable} from "rxjs";
import {UtilsService} from "./utils.service";

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.companies}`;

  constructor(private http: HttpClient) {
  }

  //region FROM COMPANY

  getCompanyOrders(companyId: number, paginationParams?: FetchParams, orderFilters?: SellOrderFilter): Observable<PageConverter<SellOrderDetail>> {

    let filterParams = new HttpParams({fromObject: {...paginationParams, ...orderFilters}})

    let queryParams = UtilsService.buildParams([], filterParams)

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders`, {params: queryParams})
      .pipe(map(value => value.data as PageConverter<SellOrderDetail>))
  }

  getCompanyOrdersCount(companyId: number, paginationParams?: FetchParams, orderFilters?: SellOrderFilter): Observable<number> {
    let filterParams = new HttpParams({fromObject: {...paginationParams, ...orderFilters}})

    let queryParams = UtilsService.buildParams([], filterParams)
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders/count`, {params: queryParams})
      .pipe(map(value => value.data as number))
  }

  getCompanyOrder(companyId: number, orderId: number) {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders/${orderId}`)
      .pipe(map(value => value.data as SellOrderDetail))
  }

  createSellOrderWithContent(companyId: number, sellOrderFull: SellOrderCreateFull, sendEmailToClient?: boolean) {
    let params = new HttpParams()
    params = params.append("sendNotificationEmail", sendEmailToClient ? "true" : "false")

    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-order-full`, sellOrderFull, {params})
      .pipe(map(value => value.data as SellOrderDetail))
  }


  createOrder(companyId: number, sellOrder: SellOrderCreate) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders`, sellOrder)
      .pipe(map(value => value.data as SellOrderDetail))
  }

  updateOrder(companyId: number, sellOrderId: number, sellOrder: SellOrderCreate) {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders/${sellOrderId}`, sellOrder)
      .pipe(map(value => value.data as SellOrderDetail))
  }

  deleteSellOrder(companyId: number, sellOrderId: number) {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders/${sellOrderId}`,)
      .pipe(map(() => {
      }))
  }

  updateOrderStatus(companyId: number, orderId: number, status: string, sendEmailToClient?: boolean) {
    let params = new HttpParams()
    params = params.append("sendNotificationEmail", sendEmailToClient ? "true" : "false")

    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders/${orderId}/status`, {status: status}, {params})
      .pipe(map(value => value.data as SellOrderDetail));
  }


//region CONTENT

  updateOrderContentStatus(companyId: number, sellOrderId: number, sellOrderContentId: number, status: string, manageWarehouse: boolean, warehouseId?: number) {

    let params = new HttpParams()
    if (warehouseId) {
      params = params.append("manageWarehouse", manageWarehouse ? "true" : "false")
      params = params.append("warehouseId", warehouseId)
    }

    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders/${sellOrderId}/sell-order-content/${sellOrderContentId}/status`, {status: status}, {
        params
      })
      .pipe(map(value => value.data as SellOrderDetail))
  }

  getOrderContent(companyId: number, sellOrderId: number, sellOrderContentId: number) {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders/${sellOrderId}/sell-order-content/${sellOrderContentId}`)
      .pipe(map(value => value.data as SellOrderContentDetail))
  }

  createOrderContent(companyId: number, sellOrderId: number, sellOrder: SellOrderContentCreate[]) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders/${sellOrderId}/sell-order-content`, sellOrder)
      .pipe(map(value => value.data as SellOrderContentDetail[]))
  }

  updateOrderContent(companyId: number, sellOrderId: number, sellOrderContentId: number, sellOrderContent: SellOrderContentUpdate) {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders/${sellOrderId}/sell-order-content/${sellOrderContentId}`, sellOrderContent)
      .pipe(map(value => value.data as SellOrderContentDetail))
  }

  deleteSellOrderContent(companyId: number, sellOrderId: number, sellOrderContentId: number) {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/sell-orders/${sellOrderId}/sell-order-content/${sellOrderContentId}`,)
      .pipe(map(() => {
      }))
  }

  //endregion CONTENT


  //endregion FROM COMPANY


  //region FROM SHOP


  getCompanyShopOrders(companyId: number, shopId: number, paginationParams?: FetchParams, orderFilters?: SellOrderFilter): Observable<PageConverter<SellOrderDetail>> {
    let filterParams = new HttpParams({fromObject: {...paginationParams, ...orderFilters}})
    let queryParams = UtilsService.buildParams([], filterParams)

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders`, {params: queryParams})
      .pipe(map(value => value.data as PageConverter<SellOrderDetail>))
  }

  getCompanyShopOrdersCount(companyId: number, shopId: number, paginationParams?: FetchParams, orderFilters?: SellOrderFilter): Observable<number> {
    let sortFilterParams = new HttpParams({fromObject: {...paginationParams, ...orderFilters}})
    let queryParams = UtilsService.buildParams([], sortFilterParams)

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders/count`, {params: queryParams})
      .pipe(map(value => value.data as number))
  }

  getCompanyShopOrder(companyId: number, shopId: number, orderId: number) {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders/${orderId}`)
      .pipe(map(value => value.data as SellOrderDetail))
  }

  createOrderFromShop(companyId: number, shopId: number, sellOrder: SellOrderCreate) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders`, sellOrder)
      .pipe(map(value => value.data as SellOrderDetail))
  }

  createSellOrderWithContentFromShop(companyId: number, shopId: number, sellOrderFull: SellOrderCreateFull, sendEmailToClient?: boolean) {
    let params = new HttpParams()
    params = params.append("sendNotificationEmail", sendEmailToClient ? "true" : "false")


    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-order-full`, sellOrderFull, {params})
      .pipe(map(value => value.data as SellOrderDetail))
  }

  updateOrderFromShop(companyId: number, shopId: number, sellOrderId: number, sellOrder: SellOrderCreate) {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders/${sellOrderId}`, sellOrder)
      .pipe(map(value => value.data as SellOrderDetail))
  }

  updateOrderStatusFromShop(companyId: number, shopId: number, sellOrderId: number, status: string, sendEmailToClient?: boolean) {

    let params = new HttpParams()
    params = params.append("sendNotificationEmail", sendEmailToClient ? "true" : "false")

    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders/${sellOrderId}/status`, {status: status}, {params})
      .pipe(map(value => value.data as SellOrderDetail))
  }

  deleteSellOrdersFromShop(companyId: number, shopId: number, sellOrderId: number) {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders/${sellOrderId}`,)
      .pipe(map(() => {
      }))
  }


  //region CONTENT

  updateOrderContentStatusFromShop(companyId: number, shopId: number, sellOrderId: number, sellOrderContentId: number, status: string, manageWarehouse: boolean, warehouseId?: number) {

    let params = new HttpParams()

    if (warehouseId) {
      params = params.append("manageWarehouse", manageWarehouse ? "true" : "false")
      params = params.append("warehouseId", warehouseId)
    }

    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders/${sellOrderId}/sell-order-content/${sellOrderContentId}/status`, {status: status}, {
        params
      })
      .pipe(map(value => value.data as SellOrderDetail))
  }

  getOrderContentFromShop(companyId: number, shopId: number, sellOrderId: number, sellOrderContentId: number) {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders/${sellOrderId}/sell-order-content/${sellOrderContentId}`)
      .pipe(map(value => value.data as SellOrderContentDetail))
  }


  createOrderContentFromShop(companyId: number, shopId: number, sellOrderId: number, sellOrder: SellOrderContentCreate[]) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders/${sellOrderId}/sell-order-content`, sellOrder)
      .pipe(map(value => value.data as SellOrderContentDetail[]))
  }

  updateOrderContentFromShop(companyId: number, shopId: number, sellOrderId: number, sellOrderContentId: number, sellOrderContent: SellOrderContentUpdate) {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders/${sellOrderId}/sell-order-content/${sellOrderContentId}`, sellOrderContent)
      .pipe(map(value => value.data as SellOrderContentDetail))
  }

  deleteSellOrderContentFromShop(companyId: number, shopId: number, sellOrderId: number, sellOrderContentId: number) {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/shops/${shopId}/sell-orders/${sellOrderId}/sell-order-content/${sellOrderContentId}`,)
      .pipe(map(() => {
      }))
  }

  //endregion CONTENT


  //endregion FROM SHOP


}
