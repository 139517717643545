import {Component} from '@angular/core';

@Component({
  selector: 'aw-users',
  templateUrl: './users-container.component.html',
  styleUrls: ['./users-container.component.scss']
})
export class UsersContainerComponent {

}


