import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ItemVariantData, ItemVariantDetail} from "../../../interfaces/item-variant";
import {forkJoin, Observable, of, switchMap, tap} from "rxjs";
import {
  ItemCompositionCreate,
  ItemCompositionCreateList,
  ItemCompositionData,
  ItemCompositionDetail
} from 'src/app/interfaces/item-composition';
import {ItemCompositionsService} from "../../../services/item-compositions.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {ItemVariantsService} from "../../../services/item-variants.service";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {UtilsService} from "../../../services/utils.service";


@Component({
  selector: 'aw-items-composition-form',
  templateUrl: './items-composition-form.component.html',
  styleUrls: ['./items-composition-form.component.scss']
})
export class ItemsCompositionFormComponent extends BaseFormComponent<any, any> implements OnInit {

  // Form degli items che già compongono
  compositionFormGroups: FormGroup[] = []

  // Form del nuovo item composer
  newCompositionFormGroup: FormGroup

  // Dice l'indice del composer da rimuovere una volta che la chiamata va a buon fine
  indexToSplice = 0

  // Item che sta venendo modificato
  selectedItem: ItemVariantDetail | undefined

  itemVariants$: Observable<ItemVariantDetail[]>
  // tutti gli items del magazzino (in forma Detail e in forma Data)
  itemVariants: ItemVariantDetail[] = [];
  itemsData: ItemVariantData[] = [];

  // items selezionati già compongono
  itemComposersSelected: ItemVariantData[] = []

  // dice se sto aggiungendo un composer
  adding = false;

  // dice se le quantity sono cambiate
  hasChanged = false;
  newItemComposer!: ItemCompositionCreate;

  // Used to bind the filtered list returned by aw-search-option
  searchBoxFilteredItems: ItemVariantData[] = []

  measureUnit = ''

  constructor(
    private itemCompositionsService: ItemCompositionsService,
    private itemVariantsService: ItemVariantsService,
    private dialogsService: DialogsService,
    private snackService: SnackService,
    public override utilsService: UtilsService
  ) {

    let createFunction = () => of(true)
    let updateFunction = () => of(true)


    super(createFunction, updateFunction, utilsService);

    this.itemVariants$ = of(true).pipe(
      switchMap(() => this.userReference$),
      switchMap(userReference => itemVariantsService.getAllCompanyItems(userReference.companyId)),
      switchMap((items) => {
        this.itemVariants = items.list
        return of(items.list)
      })
    )

    this.newCompositionFormGroup = this.buildFormGroup();
  }


  @Input() set itemVariantToEdit(itemVariant: ItemVariantDetail | undefined) {
    if (itemVariant?.id) {

      this.itemComposersSelected = itemVariant.itemComposers!.map(value => {
        return {
          id: value.itemComposer.id, name: value.itemComposer.name, baseItemId: value.itemComposer.baseItemId
        }
      })

      this.selectedItem = itemVariant

      itemVariant.itemComposers?.forEach(itemComposer => {

        let formGroup = this.buildFormGroup()

        //this works
        formGroup.controls["id"].setValue(itemComposer.itemComposer.id)
        formGroup.controls["quantity"].setValue(itemComposer.quantity)

        this.compositionFormGroups.push(formGroup)

      })

    }
  }


  ngOnInit(): void {
    this.itemVariants$.subscribe(value => {
      this.itemVariants.forEach(item => {

        if (item.id !== this.selectedItem?.id) {
          let itemData: ItemVariantData = {
            id: item.id, name: item.name, baseItemId: item.baseItemId
          }
          this.itemsData.push(itemData)
        }
      })

      this.searchBoxFilteredItems = this.getFilteredList()

    })
  }


  buildFormGroup(): FormGroup {

    return new FormGroup({
      id: new FormControl<number | null>(null, [Validators.required]),
      quantity: new FormControl<number>(0, [Validators.min(0), Validators.required])
    })

  }


  addComposerItemFn() {
    this.saving = true
    let newItemComposer: ItemCompositionCreate = {
      itemComposerId: this.newCompositionFormGroup.controls["id"].value,
      quantity: this.newCompositionFormGroup.controls["quantity"].value
    }

    let list: ItemCompositionCreateList = {
      itemComposedId: this.selectedItem!.id,
      itemsComposers: [newItemComposer],
    }

    this.newItemComposer = newItemComposer
    this.createItemComposition(list)
    // this.createItemComposition.emit(list)

  }


  createItemComposition(composition: ItemCompositionCreateList) {
    let obs$: Observable<ItemCompositionDetail>;

    obs$ =
      this.utilsService.getUserReference().pipe(
        switchMap(userReference => this.itemCompositionsService.createCompanyItemComposition(userReference.companyId, composition)))

    obs$.subscribe({
      next: (item) => {
        let itemComposer: ItemVariantData = {
          id: this.newItemComposer.itemComposerId,
          name: this.itemVariants.find(value => value.id == this.newItemComposer.itemComposerId)?.name!,
          baseItemId: this.selectedItem?.baseItemId!
        }
        this.itemComposersSelected.push(itemComposer)
        let newFormGroup: FormGroup = this.buildFormGroup()
        newFormGroup.controls["id"].setValue(this.newCompositionFormGroup.controls["id"].value)
        newFormGroup.controls["quantity"].setValue(this.newCompositionFormGroup.controls["quantity"].value)
        this.compositionFormGroups.push(newFormGroup)

        this.adding = false
        this.saving = false

        this.snackService.success('Articolo aggiunto alla composizione')
      },
      error: err => {
        this.adding = false
        this.saving = false
        this.snackService.error('Impossibile aggiungere l\'articolo alla composizione')
      }
    })
  }

  getFilteredList() {

    //per ogni item, crea la sua lista di dropdown
    // che contiene sé stesso e ciò che non è selezionato nelle altre dropdown

    let listForThisItemSelected: ItemVariantData[] = []


    this.itemsData?.forEach(itemData => {

      let itemFound = this.itemComposersSelected?.find(i => i.id === itemData.id)

      if (!itemFound)
        listForThisItemSelected.push(itemData)

    })

    return listForThisItemSelected

  }


  addNewItemComposerForm() {
    this.adding = true
    this.newCompositionFormGroup.reset()
    this.newCompositionFormGroup.controls["quantity"].setValue(0)
  }

  removeItemComposer(itemToRemove: ItemVariantData) {

    this.saving = true


    this.dialogsService.confirm('Conferma rimozione', 'Sei sicuro di voler rimuovere questo articolo dalla composizione?')
      .pipe(tap(value => {
        if (value) {
          this.indexToSplice = this.itemComposersSelected.indexOf(itemToRemove)

          let obj = {
            composedId: this.selectedItem?.id,
            composerId: itemToRemove?.id,
          }
          this.removeComposerFromItemComposition(obj)
        } else {
          this.saving = false
        }
      })).subscribe()
  }


  removeComposerFromItemComposition($event: any) {

    let obs$: Observable<void>;


    obs$ =
      this.utilsService.getUserReference().pipe(
        switchMap(userReference => this.itemCompositionsService.removeComposerFromItemComposition(userReference.companyId, $event.composedId, $event.composerId)))

    obs$.subscribe({
      next: () => {
        this.itemComposersSelected.splice(this.indexToSplice, 1)
        this.compositionFormGroups.splice(this.indexToSplice, 1)
        this.saving = false
        this.snackService.success("Articolo rimosso dalla composizione")
      },
      error: err => {
        this.snackService.success("Impossibile rimuovere l'articolo dalla composizione")
      }
    })

  }

  editAllComposersQuantities() {
    let listToEmit: ItemCompositionCreate[] = []

    this.compositionFormGroups.forEach(formGroup => {
      let item: ItemCompositionCreate = {
        itemComposerId: formGroup.controls["id"].value,
        quantity: formGroup.controls["quantity"].value
      }

      listToEmit.push(item)
    })


    this.updateItemComposition(listToEmit)
    // this.updateItemComposition.emit(listToEmit)

  }

  updateItemComposition(composition: ItemCompositionCreate[]) {

    let obs$: Observable<ItemCompositionData>;

    obs$ =
      this.utilsService.getUserReference().pipe(
        switchMap(userReference => this.itemCompositionsService.patchCompanyItemComposition(userReference.companyId, this.selectedItem?.id!, composition)))

    obs$.subscribe({
      next: () => {
        this.hasChanged = false;
        this.saving = false
        this.snackService.success("Quantità composizione articolo aggiornata")
      },
      error: err => {
        this.snackService.error('Impossibile aggiornare quantità composizione')
      }
    })
  }

  deleteItemComposition(items: ItemVariantDetail[]) {

    forkJoin(items.map(item =>
      this.utilsService.getUserReference().pipe(
        switchMap(userReference => this.itemCompositionsService.deleteCompanyItemComposition(userReference.companyId, item.id)))))
      .subscribe({
        next: () => {
          this.snackService.success("Composizione eliminata")
        },
        error: err => {
          this.snackService.error('Impossibile eliminare la composizione')
        }
      })
  }

  hasUnit(formGroup: FormGroup, itemId?: number) {
    let item =
      itemId ?
      this.itemVariants.find(iv => iv.id === itemId) :
      this.itemVariants.find(iv => iv.id === formGroup.value.id)

    if (item && item.unit)
      this.measureUnit = item.unit

    return !!item?.unit
  }

  getMeasureUnit(itemId: number) {
    return this.itemVariants.find(iv => iv.id === itemId)!.unit
  }
}
