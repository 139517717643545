import {Pipe, PipeTransform} from '@angular/core';
import {Constants} from "../utils/constants";

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(status: string) {

    return (Constants.orderStatusesPipe as any)[Constants.orderStatusKeys()
      .find(k => (Constants.orderStatuses as any)[k] === status) as any]
  }

}
