import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ItemCompositionData} from "../../../interfaces/item-composition";
import {ItemVariantDetail} from "../../../interfaces/item-variant";
import {ItemWarehouseCreateRequest, ItemWarehouseDetail} from "../../../interfaces/item-warehouse";
import {ResponsiveService} from "../../../services/responsive.service";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {UtilsService} from "../../../services/utils.service";
import {WarehousesService} from "../../../services/warehouses.service";
import {SnackService} from "../../../services/snack.service";
import {catchError} from "rxjs/operators";
import {ItemVariantsService} from "../../../services/item-variants.service";
import {FetchParams} from "../../../utils/global-functions-and-types";


@Component({
  selector: 'aw-warehouses-items-form',
  templateUrl: './warehouses-items-form.component.html',
  styleUrls: ['./warehouses-items-form.component.scss']
})
export class WarehousesItemsFormComponent extends BaseFormComponent<ItemWarehouseCreateRequest, any> implements OnInit {
  stateOptions = [{label: 'Si', value: true}, {label: 'No', value: false}];
  autoScale = false
  addNotExisting = false
  filteredOptions!: ItemVariantDetail[];

  selectedItem?: ItemVariantDetail = undefined;

  @Input() warehouseId!: number

  _items: ItemVariantDetail[] = []


  itemsWarehouse$: Observable<ItemWarehouseDetail[]>
  _itemsWarehouse: ItemWarehouseDetail[] = [];


  items$!: Observable<ItemVariantDetail[]>

  createMissingComposers = false;
  isItemComposed = false;
  allComposersAlreadyInWarehouse = false;
  notYetList: ItemCompositionData[] = [];
  measureUnit = ''

  constructor(public responsiveService: ResponsiveService,
              public override utilsService: UtilsService,
              private warehousesService: WarehousesService,
              private snackService: SnackService,
              private itemVariantsService: ItemVariantsService) {

    let createFunction = () => of()
    let updateFunction = () => of()

    super(createFunction, updateFunction, utilsService);


    this.items$ =
      this.userReference$
        .pipe(
          tap(() => this.loading = true),
          switchMap((userReference) => this.itemVariantsService.getAllCompanyItems(userReference.companyId, undefined)),
          switchMap(itemVariants => {
            this.loading = false;
            return of(this._items = itemVariants.list);
          }),
          tap(() => this.filteredOptions = this.items),
          catchError((err, caught) => {
            this.loading = false;
            if (!err.errors.includes('403')) {
              this.snackService.error('Impossibile caricare gli articoli')
            }
            return EMPTY;
          })
        )


    this.itemsWarehouse$ =
      this.userReference$
        .pipe(
          switchMap((userReference) => this.warehousesService.getWarehouseItems(userReference.companyId, this.warehouseId, {} as FetchParams)),
          switchMap((itemWarehouses) => {
            this._itemsWarehouse = itemWarehouses.list
            return of(itemWarehouses.list)
          }),
          catchError((err, caught) => {
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare gli articoli del magazzino')
            return EMPTY;
          }))


    this.formGroup = new FormGroup({
      id: new FormControl(null),
      quantity: new FormControl('', [Validators.required, Validators.min(0)]),
    })

  }


  get items(): ItemVariantDetail[] {
    return this._items.filter(itemVariant => !this._itemsWarehouse.map(iw => iw.item.id).includes(itemVariant.id));
  }


  private _missingQuantitiesText: string = ""

  get missingQuantitiesText(): string {
    return "Al momento mancano:<br>" + this._missingQuantitiesText;
  }

  ngOnInit(): void {
    this.subs.push(this.items$.subscribe())
    this.subs.push(this.itemsWarehouse$.subscribe())
  }

  getAutoScaleText() {
    let headerText = "L'articolo che stai aggiungendo è composto da:<br>"
    let footerText = "Vuoi scalare in automatico gli articoli dal magazzino corrente?"
    let str = ""
    this.selectedItem!.itemComposers!.forEach(composer => str = str + `&#8226; ${composer.itemComposer.name + ' x' + composer.quantity} <br>`);

    return headerText + str + footerText
  }

  addItemWarehouse() {

    let request: ItemWarehouseCreateRequest = {
      item: {
        item: this.selectedItem,
        quantity: this.formGroup.value.quantity,
        autoscaling: this.selectedItem!.itemComposers!.length > 0 ? this.autoScale : false,
      },
      createMissingComposers: this.addNotExisting
    }

    // this.addItemWarehouse.emit(request)
    this.saveItemWarehouse(request)
  }

  saveItemWarehouse(itemWarehouseCreateRequest: ItemWarehouseCreateRequest) {
    of(true)
      .pipe(
        tap(() => this.saving = true),
        switchMap(() => {
            if (itemWarehouseCreateRequest.item?.autoscaling) {
              return this.warehousesService.postItemWarehouse(this.userReference.companyId, this.warehouseId, itemWarehouseCreateRequest.item)
            }
            return this.warehousesService.putItemWarehouse(this.userReference.companyId, this.warehouseId, itemWarehouseCreateRequest.item, itemWarehouseCreateRequest.createMissingComposers)
          }
        ),
        tap(() => this.saving = false),
      ).subscribe({
      next: () => {
        this.snackService.success('Giacenze aggiornate')
        this.dialogRef.close()
      },
      error: err => {
        this.snackService.error('Impossibile aggiornare le giacenze')
        this.dialogRef.close()
      }
    })
  }


  canAutoscale(item: ItemVariantDetail) {

    let itemsIdQty = this._itemsWarehouse.map(iw => {
      return {
        id: iw.item.id,
        qty: iw.quantity
      }
    })

    let x = item.itemComposers?.map((composed) => {
      return {
        itemId: composed.itemComposer.id,
        itemName: composed.itemComposer.name,
        qtyRequired: composed.quantity * this.formGroup.value.quantity,
        qtyAvailable: itemsIdQty.find((value => value.id == composed.itemComposer.id))?.qty ?? 0
      }
    }) ?? []


    let missingItems = x.filter(value => value.qtyRequired > value.qtyAvailable).map(value =>
      "&#8226; " + value.itemName + " x" + (value.qtyRequired - value.qtyAvailable))

    this._missingQuantitiesText = missingItems.join('<br>')

    return x.every((d) => d.qtyRequired <= d.qtyAvailable)
  }


  setStateIfQtyZero($event: any) {
    if ($event.target.value == 0) {
      this.autoScale = false
    }
  }

  isThisItemComposed(item: ItemVariantDetail) {
    this.notYetList = []
    this.allComposersAlreadyInWarehouse = true
    if (item && item.itemComposers && item.itemComposers.length > 0) {
      this.isItemComposed = item.itemComposers.length > 0

      // se il componente è nella lista di items inseribili allora non è censito, quindi se lo trovo poi chiedo se vuole aggiungerlo
      // Lo aggiungo ad una lista che uso per comunicare quali items verranno aggiunti alle giacenze

      item.itemComposers.forEach(composer => {
        let found = this._itemsWarehouse.find(iw => iw.item.id === composer.itemComposer.id)
        if (!found) {
          this.notYetList.push(composer)
          this.allComposersAlreadyInWarehouse = false
        }

      })

    }
  }

  missingItemsNames(): string {
    return this.notYetList.map(value => "&#8226; " + value.itemComposer.name).join('<br>')
  }

  hasUnit(formGroup: FormGroup, itemId?: number) {
    let item =
      itemId ?
        this.items.find(iv => iv.id === itemId) :
        this.items.find(iv => iv.id === formGroup.value.id)

    if (item && item.unit)
      this.measureUnit = item.unit

    return !!item?.unit
  }

  getMeasureUnit(itemId: number) {
    return this.items.find(iv => iv.id === itemId)!.unit
  }

}
