import {Pipe, PipeTransform} from '@angular/core';
import {Constants} from "../utils/constants";

@Pipe({
  name: 'migrationChoicesPipe'
})
export class MigrationChoicesPipe implements PipeTransform {

  transform(status: string) {
    return (Constants.migrationChoicesPipe as any)[Constants.migrationChoicesKeys()
      .find(k => (Constants.migrationChoice as any)[k] === status) as any]
  }

}
