import {Component} from '@angular/core';
import {hasPermissionOnEntity} from "../../../../utils/global-functions-and-types";
import {ResponsiveService} from "../../../../services/responsive.service";
import {SnackService} from "../../../../services/snack.service";
import {DialogsService} from "../../../../services/dialogs.service";
import {UtilsService} from "../../../../services/utils.service";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {GeneralDialogComponent} from "../../../../utils/shared-components/general-dialog/general-dialog.component";
import {OptionsService} from "../../../../services/options.service";
import {BaseTableComponent} from "../../../../utils/shared-components/base-table/base-table.component";
import {OptionData} from "../../../../interfaces/option";
import {OptionsFormComponent} from "../options-form/options-form.component";
import {Constants} from "../../../../utils/constants";

@Component({
  selector: 'aw-options-table',
  templateUrl: './options-table.component.html',
  styleUrls: ['./options-table.component.scss']
})
export class OptionsTableComponent extends BaseTableComponent<OptionData> {

  constructor(private optionsService: OptionsService,
              public responsiveService: ResponsiveService,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
  ) {

    let deleteFunction =
      (companyId: number, optionId: number): Observable<void> =>
        optionsService.deleteCompanyOption(companyId, optionId)

    super('displayName', Constants.localStorageKeys.optionsTable, deleteFunction, utilsService, snackService, dialogService)


    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(userReference => {
            this.userReference = userReference
            return this.optionsService.getAllCompanyOptions(this.userReference.companyId, this.fetchParams)
          }),
          switchMap(options => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<OptionData>(options.list)
            this.entityList.push(...options.list)
            this.numEntities = options.num
            return of(options.list)
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare le opzioni dell\'azienda');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())

    this.displayedColumns = [
      "name",
      "displayName",
      "action",
    ]

  }


  addOption() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Opzione',
        componentData: {companyId: this.userReference.companyId},
        component: OptionsFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: () => this.snackService.success('Opzione creata'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  editOption(optionId: number) {
    this.optionsService.getCompanyOption(this.userReference.companyId, optionId)
      .subscribe(option => {
        let readonly = !hasPermissionOnEntity(this.userReference.user, {
          accessEntity: this.accessEntities.items,
          accessLevel: this.accessLevels.editor
        }, option)
        this.dialogService.open(GeneralDialogComponent, {
          data: {
            dialogTitle: readonly ? `DETTAGLIO – ${option.name}` : `MODIFICA – ${option.name}`,
            componentData: {entity: option, companyId: this.userReference.companyId, readOnly: readonly},
            component: OptionsFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Opzione aggiornata');
            },
            error: () => {
              this.snackService.success('Impossibile aggiornare l\'opzione');
            },
          })
      })
  }

}
