<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>

<h2 class="font-light">Profili assegnati</h2>
<div
     *ngIf="!loading else loadingTemplate"
     fxLayout="row"
     fxLayoutAlign="start center"
     fxLayoutGap="3%"
>

  <form [formGroup]="formGroup">
    <mat-form-field fxFlex>
      <mat-label>Profili</mat-label>
      <mat-select
        [(ngModel)]="selectedProfiles"
        (selectionChange)="shouldISave()"
        formControlName="profiles"
        id="profiles"
        multiple
      >
<!--        todo: fix this-->
<!--        <aw-search-option (filteredOptions)="filteredProfiles = $event" *ngIf="_profiles.length > 0"-->
<!--                          [key]="'name'" [options]="_profiles"></aw-search-option>-->

        <mat-option *ngFor="let profile of _profiles" [value]="profile">
          {{profile.name}}
        </mat-option>
        <mat-option *ngIf="_profiles.length === 0">
          Nessun profilo trovato.
        </mat-option>
        <button
                mat-button
                [routerLink]="['../../../profiles']"
                color="primary"
                matSuffix
        >
          Vai a Gestione Profili
        </button>
      </mat-select>
    </mat-form-field>
  </form>

  <button color="primary"
          (click)="saveProfiles()"
          [disabled]="saving || !shouldISave() || loading"
          mat-raised-button
          matTooltip="Salva le modifiche"
          matTooltipPosition="above"
          type="button"
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Salva</span>
  </button>



</div>
