import {Component, Input} from '@angular/core';
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {SellOrderDetail} from "../../../interfaces/sell-order";
import {Constants} from "../../../utils/constants";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {ResponsiveService} from "../../../services/responsive.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {OrdersService} from "../../../services/orders.service";
import {ActivatedRoute} from "@angular/router";
import {SellOrderContentSnapshotData} from "../../../interfaces/sell-order-content-snapshot";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {OrdersFormComponent} from "../orders-form/orders-form.component";


@Component({
  selector: 'aw-order-content-table',
  templateUrl: './order-content-table.component.html',
  styleUrls: ['./order-content-table.component.scss']
})
export class OrderContentTableComponent extends BaseTableComponent<SellOrderContentSnapshotData> {

  sellOrderStates = Constants.orderStatuses

  @Input() shopId: number | null = null;

  constructor(private ordersService: OrdersService,
              public responsiveService: ResponsiveService,
              public activatedRoute: ActivatedRoute,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
  ) {

    let shopId: number | null = null;

    activatedRoute.paramMap.subscribe(paramMap => {
      if (paramMap.has("shopId"))
        shopId = +paramMap.get("shopId")!
    })

    let deleteFunction =
      (companyId: number, shopId: number, sellOrderId: number, contentId: number): Observable<void> =>
        shopId ?
          this.ordersService.deleteSellOrderContentFromShop(companyId, shopId, sellOrderId, contentId) :
          this.ordersService.deleteSellOrderContent(companyId, sellOrderId, contentId)

    super('', Constants.localStorageKeys.orderContentTable, deleteFunction, utilsService, snackService, dialogService)

    this.shopId = shopId


    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(() =>
            this.shopId ?
              this.ordersService.getCompanyShopOrder(this.userReference.companyId, this.shopId, this._sellOrder?.id!) :
              this.ordersService.getCompanyOrder(this.userReference.companyId, this._sellOrder?.id!)
          ),
          switchMap(order => {
            this._sellOrder = order
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<SellOrderContentSnapshotData>(order.contentSnapshot)
            if (order.content?.length != 0) {
              this.entityList.push(...order.contentSnapshot!)
              this.numEntities = order.content?.length ?? 0
            }
            return of([] as SellOrderContentSnapshotData[])
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error("Impossibile caricare l'ordine");
            return EMPTY;
          })
        )


    this.displayedColumns = [
      "warehouse",
      "item",
      "barcode",
      "sku",
      "quantity",
      "itemUnitPrice",
      "total",
      "warehouseStocks",
      "action",
    ]

    this.requiredDeleteAccessLevels = [
      {accessEntity: Constants.accessEntities.sellOrderShops, accessLevel: Constants.accessLevels.admin},
      {accessEntity: Constants.accessEntities.shops, accessLevel: Constants.accessLevels.reader},
    ]
  }

  _sellOrder: SellOrderDetail | null = null

  @Input() set sellOrder(sellOrder: SellOrderDetail) {
    if (sellOrder) {
      this._sellOrder = sellOrder
      this.subs.push(this.observable$.subscribe())

    }
  }

  isDeleteButtonDisabled(element: SellOrderContentSnapshotData) {

    return (this._sellOrder?.status !== Constants.orderStatuses.inProgress && this._sellOrder?.status !== Constants.orderStatuses.toBeAssigned) ||
      (element.status !== Constants.orderContentStatuses.notUpdated && !!element.warehouseName)
  }


  addContent() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'AGGIORNA - Ordine',
        componentData: {
          companyId: this.userReference.companyId,
          entity: this._sellOrder,
          shopId: this.shopId,
          managingSellOrderContent: true
        },
        component: OrdersFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: (value) => this.snackService.success('Ordine aggiornato'),
        error: (error) => this.snackService.error(error.message),
      })
  }


}
