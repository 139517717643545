import {Pipe, PipeTransform} from '@angular/core';
import {Constants} from "../utils/constants";

@Pipe({
  name: 'deliveryOptionsPipe'
})
export class DeliveryOptionsPipe implements PipeTransform {

  transform(status: string) {
    return (Constants.deliveryOptionsPipe as any)[Constants.deliveryOptionsKeys()
      .find(k => (Constants.deliveryOption as any)[k] === status) as any]
  }

}
