import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {find, Observable, of, switchMap, withLatestFrom} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {AccessEntities, AccessLevel, hasPermission, partialAccessSanitize} from "../utils/global-functions-and-types";
import {RequiredAccessLevel} from "../classes/required-access-level";


interface Role {
  entity: AccessEntities,
  permission: AccessLevel
}


@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {


  constructor(private router: Router, private authService: AuthService) {
  }


  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const requiredAccesses = route.data['requiredAccess'] as RequiredAccessLevel[];

    return of(...requiredAccesses)
      .pipe(
        withLatestFrom(this.authService.onUserChange()),

        find((accessUserZip) => {
          let requiredAccess = accessUserZip[0]!
          let user = accessUserZip[1]
          if (!user)
            return false

          return hasPermission(user, partialAccessSanitize((requiredAccess)));
        }),

        switchMap(value => {
          if (!!value)
            return of(true)
          return of(false)
        }),

        switchMap((hasAccess) => {
            return hasAccess ? of(hasAccess) : of(this.router.createUrlTree(['/reserved']))
          }
        )
      )


  }

}
