<div class="h-100-vh" fxLayout="column" fxLayoutAlign="start">

  <mat-toolbar color="primary" class="h-4rem">

    <button (click)="sidenav.toggle()"
            mat-icon-button
            disableRipple
    >
      <mat-icon>menu</mat-icon>

      <img
        [src]="loggedUser?.companyLogo ?? 'assets/svg/flower.svg'"
        alt=""
        class="mb-1 h-3rem ml-3 mr-2 pointer"
        routerLink="/reserved"
      >
    </button>

    <span fxFlex></span>
    <button (click)="authService.logout()" class="p-button-outlined p-button-sm p-button-rounded p-button-danger"
            mat-button
            matTooltip="Logout"
    >
      <mat-icon>logout</mat-icon>
    </button>

    <button mat-mini-fab color="primary">
      {{loggedUser!.name![0] + '' + loggedUser!.surname![0]}}
    </button>


    <div *ngIf="!loggedUser?.companyId" class="ml-2_5">
      {{version}}
    </div>
  </mat-toolbar>

  <mat-sidenav-container fxFlex>

    <mat-sidenav #sidenav mode="side">

      <div class="h-100 w-min-10-vw" fxLayout="column">
        <mat-accordion fxFlex>


          <mat-nav-list (click)="sidenav.close()">


            <mat-list-item
              *awAccess="{accessLevel:{accessEntity: accessEntities.companies, checkCompany: true, needsCompany: false}}"
              [routerLinkActive]="'mat-background-primary-light'"
              routerLink="/reserved/companies">

              Aziende
            </mat-list-item>

            <mat-list-item
              *awAccess="{accessLevel:{accessEntity: accessEntities.companies, checkCompany: true}}"
              [routerLinkActive]="'mat-background-primary-light'"
              routerLink="/reserved/details"
            >
              <img
                [src]="loggedUser?.companyLogo ? loggedUser?.companyLogo : 'assets/svg/flower.svg'"
                alt=""
                class="w-5-rem mt-2 h-2rem ml-2 mr-2 pointer"
                routerLink="/reserved"
              >
              {{loggedUser?.companyName}}
            </mat-list-item>

            <mat-list-item
              *awAccess="{accessLevel:{accessEntity:accessEntities.shops, checkCompany: true}}"
              [routerLinkActive]="'mat-background-primary-light'"
              routerLink="/reserved/shops">
              Punti vendita
            </mat-list-item>

            <mat-list-item
              *awAccess="{accessLevel:{accessEntity:accessEntities.items, checkCompany: true}}"
              [routerLinkActive]="'mat-background-primary-light'"
              routerLink="/reserved/items">
              Articoli
            </mat-list-item>

            <mat-list-item
              *awAccess="{accessLevel:{accessEntity:accessEntities.warehouses, checkCompany: true}}"
              [routerLinkActive]="'mat-background-primary-light'"
              routerLink="/reserved/warehouses">
              Magazzini
            </mat-list-item>

            <mat-list-item
              *awAccess="{accessLevel:{accessEntity:accessEntities.sellOrderShops, checkCompany: true, checkRolesOnly: true}}"
              [routerLinkActive]="'mat-background-primary-light'"
              routerLink="/reserved/orders">
              Ordini
            </mat-list-item>

            <mat-list-item
              *awAccess="{accessLevel:{accessEntity:accessEntities.customers, checkCompany: true}}"
              [routerLinkActive]="'mat-background-primary-light'"
              routerLink="/reserved/customers">
              Clienti
            </mat-list-item>


          </mat-nav-list>

        </mat-accordion>

      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="ph-2_5 pv-1">
        <button (click)="backClicked()"
                class="border border-accent mr-1"
                color="primary"
                mat-fab
                *ngIf="isPresent()"
        >
          <mat-icon>
            arrow_back
          </mat-icon>
        </button>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>
