import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ProfileDetail} from "../../../interfaces/profile";
import {FormControl, FormGroup} from "@angular/forms";
import {EMPTY, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {SnackService} from "../../../services/snack.service";
import {PermissionsService} from "../../../services/permissions.service";
import {FetchParams, UserReference} from "../../../utils/global-functions-and-types";
import {ProfilesService} from "../../../services/profiles.service";
import {catchError, map} from "rxjs/operators";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: 'aw-profiles-user-dropdown',
  templateUrl: './profiles-user-dropdown.component.html',
  styleUrls: ['./profiles-user-dropdown.component.scss']
})
export class ProfilesUserDropdownComponent implements OnInit, OnDestroy {

  @Input() userId = 0

  @Input() initialProfiles: ProfileDetail[] = []

  @Output() onUserProfileChange = new EventEmitter<void>();


  userReference!: UserReference;
  observable$: Observable<void> = new Observable<void>();

  formGroup: FormGroup

  subs: Subscription[] = []
  _profiles: ProfileDetail[] = []
  _initialProfiles: ProfileDetail[] = []
  selectedProfiles: ProfileDetail[] = []

  // Used to bind the filtered list returned by aw-search-option
  filteredProfiles: ProfileDetail[] = []

  loading = false
  saving = false

  constructor(
    private snackService: SnackService,
    private permissionsService: PermissionsService,
    private profilesService: ProfilesService,
    private utilsService: UtilsService,
  ) {
    this.formGroup = new FormGroup({
      profiles: new FormControl<number[]>([], []),
    })

    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.utilsService.getUserReference()),
          switchMap(userReference => {
            this.userReference = userReference
            return this.profilesService.getAllCompanyProfiles(this.userReference.companyId, {sort: "name asc"} as FetchParams)
          }),
          map(profiles => {
            this._profiles = profiles.list
            this.filteredProfiles = profiles.list
            let userProfiles = profiles.list.filter(profile => profile.id === this.initialProfiles.find(p => p.id === profile.id)?.id)
            this.selectedProfiles = userProfiles
            this._initialProfiles = userProfiles
            let ids = this.selectedProfiles.map(it => it.id) ?? []
            this.formGroup.controls["profiles"].setValue(this.selectedProfiles.filter(it => ids.includes(it.id)).map(it => it.id))
          }),
          tap(() => this.loading = false),
          catchError((err) => {
            this.loading = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i profili')
            return EMPTY;
          })
        )


  }

  ngOnInit() {
    this.subs.push(this.observable$.subscribe())

  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  shouldISave() {
    return !(this.utilsService.getDifference(this._initialProfiles, this.selectedProfiles).length == 0 &&
      this.utilsService.getDifference(this.selectedProfiles, this._initialProfiles).length == 0)
  }

  saveProfiles() {
    let profiles: ProfileDetail[] = this.formGroup.value.profiles ?? []
    of(true)
      .pipe(
        tap(() => this.saving = true),
        switchMap(() => {

          let toSend = profiles.filter(value => !this._initialProfiles.find(a => a.id == value.id))
            .map((value) => {
              return {
                userId: this.userId,
                profileId: value.id!,
              }
            })

          if (toSend.length > 0) return this.permissionsService.setProfilesToUser(this.userReference.companyId, toSend)

          return of(true)
        }),
        switchMap(() => {

          let toSend = this._initialProfiles.filter(value =>
            !profiles.find(a => a.id == value.id)).map(value => {
            return {
              userId: this.userId,
              profileId: value.id!,
            }
          })

          if (toSend.length > 0) return this.permissionsService.removeProfilesFromUser(this.userReference.companyId, toSend)
          return of(true)
        }),
        switchMap(() => {
          this.onUserProfileChange.emit()
          return this.observable$
        }),
      ).subscribe({
      next: () => {
        this.saving = false
        this.snackService.success("Profili dell'utente aggiornati")
      },
      error: err => {
        console.log(err)
        this.saving = false
        this.snackService.error('Impossibile rimuovere i profili selezionati')
      },
    })
  }

}
