<ng-template #template>

  <td *ngIf="responsiveService.isDesktop">
    <div (click)="disableClick()"
         (pointerup)="disableClick()"
         class="flex flex-row justify-content-evenly align-items-center h-full w-full">

      <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>

    </div>
  </td>


  <tr
    (click)="disableClick()"
    (pointerup)="disableClick()"
    *ngIf="responsiveService.isMobile"
    [style.display]="swipedLeftId() == tr.element.id || swipingLeftId() == tr.element.id ? 'block':'none'"
    class="bg-gray-200 p-0 "
    style="position: absolute; height: 100%"
    [ngStyle]="{'width': tr.currentMove + 'px'}"
  >
    <div class="flex flex-row justify-content-evenly align-items-center h-full w-full slider-content">

      <button (click)="tr.unSwipe()" class="p-button-text p-button-danger flex flex-1 h-full"
              icon="fa-solid fa-arrow-right"
              pButton pRipple pTooltip="Chiudi" tooltipPosition="top"></button>

      <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>

    </div>
  </tr>

</ng-template>


<ng-template #tempOutlet>
  <ng-content></ng-content>
</ng-template>
