import {Component} from '@angular/core';
import {OptionValueData} from "../../../../interfaces/option-value";
import {ResponsiveService} from "../../../../services/responsive.service";
import {SnackService} from "../../../../services/snack.service";
import {DialogsService} from "../../../../services/dialogs.service";
import {UtilsService} from "../../../../services/utils.service";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {hasPermissionOnEntity} from "../../../../utils/global-functions-and-types";
import {MatTableDataSource} from "@angular/material/table";
import {OptionData} from "../../../../interfaces/option";
import {catchError} from "rxjs/operators";
import {GeneralDialogComponent} from "../../../../utils/shared-components/general-dialog/general-dialog.component";
import {BaseTableComponent} from "../../../../utils/shared-components/base-table/base-table.component";
import {OptionValuesService} from "../../../../services/option-values.service";
import {ActivatedRoute} from "@angular/router";
import {OptionsService} from "../../../../services/options.service";
import {OptionValuesFormComponent} from "../option-values-form/option-values-form.component";
import {Constants} from "../../../../utils/constants";

@Component({
  selector: 'aw-option-values-table',
  templateUrl: './option-values-table.component.html',
  styleUrls: ['./option-values-table.component.scss']
})
export class OptionValuesTableComponent extends BaseTableComponent<OptionValueData> {

  optionId = -1

  option$: Observable<OptionData>

  option!: OptionData

  constructor(private optionValuesService: OptionValuesService,
              private optionsService: OptionsService,
              public responsiveService: ResponsiveService,
              public activatedRoute: ActivatedRoute,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
  ) {

    let deleteFunction =
      (companyId: number, optionId: number, optionValueId: number): Observable<void> =>
        optionValuesService.deleteOptionValue(companyId, optionId, optionValueId)

    super('displayName', Constants.localStorageKeys.optionValuesTable, deleteFunction, utilsService, snackService, dialogService)

    this.subs.push(
      this.activatedRoute.paramMap
        .subscribe(paraMap => {
          this.optionId = +paraMap.get("optionId")!
        })
    )

    this.option$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(userReference => {
            this.userReference = userReference
            return this.optionsService.getCompanyOption(this.userReference.companyId, this.optionId)
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare l\'opzione');
            return EMPTY;
          })
        )

    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.option$),
          switchMap(option => {
            this.option = option
            return this.optionValuesService.getAllOptionValues(this.userReference.companyId, this.optionId, this.fetchParams)
          }),
          switchMap(optionValues => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<OptionValueData>(optionValues.list)
            this.entityList.push(...optionValues.list)
            this.numEntities = optionValues.num
            return of(optionValues.list)
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i valori di questa opzione');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())

    this.displayedColumns = [
      "name",
      "displayName",
      "action",
    ]

  }


  addOptionValue() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Valore opzione',
        componentData: {companyId: this.userReference.companyId, optionId: this.optionId},
        component: OptionValuesFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: () => this.snackService.success('Valore dell\'opzione creato'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  editOption(valueId: number) {
    this.optionValuesService.getOptionValue(this.userReference.companyId, this.optionId, valueId)
      .subscribe(optionValue => {
        let readonly = !hasPermissionOnEntity(this.userReference.user, {
          accessEntity: this.accessEntities.items,
          accessLevel: this.accessLevels.editor
        }, optionValue)
        this.dialogService.open(GeneralDialogComponent, {
          data: {
            dialogTitle: readonly ? `DETTAGLIO – ${optionValue.name}` : `MODIFICA – ${optionValue.name}`,
            componentData: {
              entity: optionValue,
              optionId: this.optionId,
              companyId: this.userReference.companyId,
              readOnly: readonly
            },
            component: OptionValuesFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Valore dell\'opzione aggiornato');
            },
            error: () => {
              this.snackService.success('Impossibile aggiornare il alore dell\'opzione');
            },
          })
      })
  }
}
