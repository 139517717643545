// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://floweers-backend-run-stg-lib5hxcpca-ew.a.run.app',
  firebase: {
    apiKey: "AIzaSyATIwn9DfNfbo3Ii3mPNMQdF5WUEfT4MWs",
    authDomain: "floweers.firebaseapp.com",
    projectId: "floweers",
    storageBucket: "floweers.appspot.com",
    messagingSenderId: "1053658484563",
    appId: "1:1053658484563:web:bd94adae469ecfc5d59ba6"
  },
  maxSlideWindowRatio: .75,
  version: '__VERSION__'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
