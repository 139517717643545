import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackService extends MatSnackBar {


  success(message: string): void{
    this.open(message, 'Ok', {
      panelClass: ['mat-background-light', 'color-dark', 'border-warn'],
      duration: 2000
    });
  }

  info(message: string): void{
    this.open(message, 'Ok', {
      panelClass: ['mat-background-light', 'color-dark', 'border-warn']
    });
  }

  error(message: string): void{
    this.open(message, 'Ok', {
      panelClass: ['mat-background-light', 'color-dark', 'border-warn', 'border'],
    });
  }

}
