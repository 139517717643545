import {Component, Input, Output} from '@angular/core';
import {FilterOptionNames, SellOrderDetail, SellOrderFilter} from "../../../interfaces/sell-order";
import {Constants} from "../../../utils/constants";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {ResponsiveService} from "../../../services/responsive.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {OrdersService} from "../../../services/orders.service";
import {
  getOrderStatusColor,
  getPaymentStatusColor,
  hasPermission,
  hasPermissionOnEntity
} from "../../../utils/global-functions-and-types";
import {ActivatedRoute, Router} from "@angular/router";
import {ShopsService} from "../../../services/shops.service";
import {ShopDetail} from "../../../interfaces/shop";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {OrdersFormComponent} from "../orders-form/orders-form.component";
import {DateFilterOutput} from "../../../utils/shared-components/date-filter/date-filter.component";
import {OptionFilterItem} from "../../../utils/shared-components/status-filter/column-filter.component";
import {LegendStatusesComponent} from "../../legend-statuses/legend-statuses.component";
import {BaseOrderSaleTableComponent} from "../../../utils/shared-components/base-table/base-order-sale-table.component";

@Component({
    selector: 'aw-orders-table',
    templateUrl: './orders-table.component.html',
    styleUrls: ['./orders-table.component.scss']
})
export class OrdersTableComponent extends BaseOrderSaleTableComponent<SellOrderDetail> {

    @Input() shopId: number | null = null;

    @Output() filterField: string[] = []

    shop!: ShopDetail;
    orders: SellOrderDetail[] = [];
    overdueOrdersCount = 0;
    overdueOrdersCount$: Observable<number>
    expandedOrders: number[] = [];

    searchableFields: FilterOptionNames[] = [
        {fieldName: "customerName", displayName: "Nome cliente"},
        {fieldName: 'recipientName', displayName: 'Nome Cliente Ricevente'},
        {fieldName: 'shopName', displayName: 'Nome del negozio'},
        {fieldName: 'billingSearch', displayName: 'Info fatturazione'},
        {fieldName: 'shippingSearch', displayName: 'Info consegna'}
    ]

    path: any[] = []

    options = Constants.orderStatusKeys().map(k => {
        return {
            value: (Constants.orderStatuses as any)[k],
            name: (Constants.orderStatusesPipe as any)[k]
        }
    })


    availableOrderStatuses: OptionFilterItem[] = Constants.orderStatusKeys().map(k => {
        return {
            key: (Constants.orderStatuses as any)[k],
            value: (Constants.orderStatusesPipe as any)[k]
        }
    })

    availablePaymentStatuses: OptionFilterItem[] = Constants.orderPaymentStatusKeys().map(k => {
        return {
            key: (Constants.orderPaymentStatuses as any)[k],
            value: (Constants.orderPaymentStatusesPipe as any)[k]
        }
    })

    orderStates = Constants.orderStatuses
    paymentStates = Constants.orderPaymentStatuses


    getOrderStatusColor = getOrderStatusColor
    getPaymentStatusColor = getPaymentStatusColor
    readonly = false

    override filterObject: SellOrderFilter = {} as SellOrderFilter


    constructor(
        private ordersService: OrdersService,
        private shopsService: ShopsService,
        public responsiveService: ResponsiveService,
        public activatedRoute: ActivatedRoute,
        public router: Router,
        public override snackService: SnackService,
        protected override dialogService: DialogsService,
        protected override utilsService: UtilsService
    ) {

        let shopId: number | null = null;

        activatedRoute.paramMap.subscribe(paramMap => {
            if (paramMap.has("shopId"))
                shopId = +paramMap.get("shopId")!
        })


        let deleteFunction =
            (companyId: number, shopId: number, sellOrderId: number): Observable<void> =>
                shopId ?
                    this.ordersService.deleteSellOrdersFromShop(companyId, shopId, sellOrderId) :
                    this.ordersService.deleteSellOrder(companyId, sellOrderId)


        super("deliveryDate", Constants.localStorageKeys.ordersTable, deleteFunction, utilsService, snackService, dialogService)

        this.shopId = shopId


        this.availableColumns = [
            "customerName",
            "finalPrice",
            "shippingAddress",
            "deliveryDate",
            "deliveryDay",
            "deadlineDate",
            "note",
            "status",
            "paymentStatus",
            "deliveryNotes",
            "deliveryCompanyId",
            "deliveryTimeSlotId",
            "action"
        ]


        this.localStorageColumns = this.getDisplayedColumnsFromLocalStorage()

        if (!this.shopId)
            this.availableColumns.splice(0, 0, 'shopName')

        this.displayedColumns = [...this.localStorageColumns]


        this.observable$ =
            of(true)
                .pipe(
                    tap(() => this.paramLoading = true),
                    switchMap(() => this.userReference$),
                    switchMap(() => {
                        return this.shopId ?
                            this.ordersService.getCompanyShopOrders(this.userReference.companyId, this.shopId, this.fetchParams, this.filterObject) :
                            this.ordersService.getCompanyOrders(this.userReference.companyId, this.fetchParams, this.filterObject)
                    }),
                    switchMap(orders => {
                        this.paramLoading = false
                        this.dataSource = new MatTableDataSource<SellOrderDetail>(orders.list)
                        this.entityList.push(...orders.list)
                        //todo per avere tutte le righe già espanse, scommentare
                        // la lascio commentata perché secondo me viene una merda
                        // this.expandedOrders.push(...orders.list.map(order => order.id))
                        this.numEntities = orders.num
                        return of(orders.list)
                    }),
                    catchError((err, caught) => {
                        this.paramLoading = false
                        if (!err.errors.includes('403'))
                            this.snackService.error('Impossibile caricare gli ordini');
                        return EMPTY;
                    })
                )

        this.overdueOrdersCount$ =
            of(true).pipe(
                switchMap(() => this.userReference$),
                switchMap(userReference => {
                    this.userReference = userReference
                    let today = new Date()
                    let deliveryTo = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0).toISOString()

                    return this.shopId ?
                        this.ordersService.getCompanyShopOrdersCount(userReference.companyId, this.shopId, {
                            ...this.fetchParams
                        }, {
                            deliveryTo: deliveryTo,
                            deliveryFrom: '',
                            statuses: this.availableOrderStatuses.filter(avs => avs.key != Constants.orderStatuses.completed && avs.key != Constants.orderStatuses.failed).map(avs => avs.key)
                        }) :
                        this.ordersService.getCompanyOrdersCount(this.userReference.companyId, {
                            ...this.fetchParams
                        }, {
                            deliveryTo: deliveryTo,
                            deliveryFrom: '',
                            statuses: this.availableOrderStatuses.filter(avs => avs.key != Constants.orderStatuses.completed && avs.key != Constants.orderStatuses.failed).map(avs => avs.key)
                        })
                }),
                tap((count) => this.overdueOrdersCount = count)
            )

        this.getAllButCompletedAndCanceled()
        this.subs.push(this.overdueOrdersCount$.subscribe())


        this.requiredCreateAccessLevels = [
            {accessEntity: Constants.accessEntities.sellOrderShops, accessLevel: Constants.accessLevels.editor},
            {accessEntity: Constants.accessEntities.shops, accessLevel: Constants.accessLevels.reader},
        ]

        this.requiredDeleteAccessLevels = [
            {accessEntity: Constants.accessEntities.sellOrderShops, accessLevel: Constants.accessLevels.admin},
            {accessEntity: Constants.accessEntities.shops, accessLevel: Constants.accessLevels.reader},
        ]


        if (this.shopId)
            this.subs.push(
                this.shopsService.getShop(this.userReference.companyId, this.shopId, false)
                    .subscribe(shop => {
                        this.shop = shop
                    }))


    }

    getFilterResult(sellOrderFilter: SellOrderFilter) {
        console.log("E' ARRIVATO IL FILTRO", sellOrderFilter)
        this.filterObject = sellOrderFilter
        this.subs.push(this.observable$.subscribe(() => this.paramLoading = false))
    }

    getColorForDay(deliveryDate: string) {
        if (deliveryDate == null)
            return '#bebebe'

        switch (new Date(deliveryDate).getDay()) {
            case 0:
                return '#f593a8'
            case 1:
                return '#bf93f5'
            case 2:
                return '#ebad73'
            case 3:
                return '#88adf2'
            case 4:
                return '#ed6f6f'
            case 5:
                return '#6fedbb'
            case 6:
                return '#c4f29d'
        }

        return '#bebebe'
    }

    addOrder() {
        this.dialogService.open(GeneralDialogComponent, {
            data: {
                dialogTitle: 'CREAZIONE – Ordine',
                componentData: {companyId: this.userReference.companyId, entity: {}, shopId: this.shopId},
                component: OrdersFormComponent
            }
        })
            .afterClosed()
            .pipe(switchMap(value => value ? this.observable$ : EMPTY))
            .subscribe({
                next: () => this.snackService.success('Ordine creato'),
                error: (error) => this.snackService.error(error.message),
            })

    }

    getOrderDetailObs(orderId: number) {
        return this.shopId ?
            this.ordersService.getCompanyShopOrder(this.userReference.companyId, this.shopId, orderId) :
            this.ordersService.getCompanyOrder(this.userReference.companyId, orderId)
    }


    openEditDialog(order: SellOrderDetail, openStatusDialog: boolean) {

        this.readonly = !hasPermission(this.userReference.user, {
            accessEntity: this.accessEntities.sellOrderShops,
            accessLevel: this.accessLevels.editor
        })

        return this.dialogService.open(GeneralDialogComponent, {
            data: {
                dialogTitle: this.readonly ? `DETTAGLIO – Ordine` : `MODIFICA – Ordine`,
                componentData: this.getComponentData(order, openStatusDialog),
                component: OrdersFormComponent
            }
        })
    }

    openPaymentStatusDialog(order: SellOrderDetail, openStatusDialog: boolean) {
        this.readonly = !hasPermission(this.userReference.user, {
            accessEntity: this.accessEntities.sellOrderShops,
            accessLevel: this.accessLevels.editor
        })

        return this.dialogService.open(GeneralDialogComponent, {
            data: {
                dialogTitle: this.readonly ? `DETTAGLIO – Ordine` : `MODIFICA – Ordine`,
                componentData: this.getPaymentComponentData(order, openStatusDialog),
                component: OrdersFormComponent
            }
        })
    }


    editOrder(orderId: number, openStatusDialog: boolean) {

        let obs$ = this.getOrderDetailObs(orderId)

        obs$.subscribe(order => {

            this.openEditDialog(order, openStatusDialog)
                .afterClosed()
                .pipe(switchMap(value => value ? this.observable$ : EMPTY))
                .subscribe({
                    next: () => this.snackService.success('Ordine aggiornato'),
                    error: (error) => this.snackService.error(error.message),
                })
        })
    }

    editOrderPaymentStatus(orderId: number, openStatusDialog: boolean) {
        let obs$ = this.getOrderDetailObs(orderId)

        obs$.subscribe(order => {

            this.openPaymentStatusDialog(order, openStatusDialog)
                .afterClosed()
                .pipe(switchMap(value => value ? this.observable$ : EMPTY))
                .subscribe({
                    next: () => this.snackService.success('Ordine aggiornato'),
                    error: (error) => this.snackService.error(error.message),
                })
        })
    }

    filterByDeliveryDate(dateRange: DateFilterOutput) {
        this.loadOrderSaleResults({
            deliveryFrom: dateRange.startDate != undefined ? dateRange.startDate.toISOString() : undefined,
            deliveryTo: dateRange.endDate != undefined ? dateRange.endDate.toISOString() : undefined
        }, this.observable$)
    }

    filterByDeadlineDate(dateRange: DateFilterOutput) {
        this.loadOrderSaleResults({
            deadlineFrom: dateRange.startDate != undefined ? dateRange.startDate.toISOString() : undefined,
            deadlineTo: dateRange.endDate != undefined ? dateRange.endDate.toISOString() : undefined
        }, this.observable$)
    }

    resetDeliveryDateFilter() {
        this.loadOrderSaleResults({deliveryFrom: "", deliveryTo: ""}, this.observable$)
    }

    filterByOrderStatus(statuses: string[]) {
        this.loadOrderSaleResults({statuses}, this.observable$)
    }

    filterByPaymentStatus(paymentStatuses: string[]) {
        this.loadOrderSaleResults({paymentStatuses}, this.observable$)
    }

    resetOrderStatusFilter() {
        this.loadOrderSaleResults({statuses: []}, this.observable$)
    }

    resetOrderPaymentStatusFilter() {
        this.loadOrderSaleResults({paymentStatuses: []}, this.observable$)
    }

    resetDisplayedColumnsToLocalStorage() {
        this.displayedColumns = [...this.localStorageColumns]
    }

    getAllButCompletedAndCanceled() {
        // // this.insertColumn('status', false)
        // // this.insertColumn('paymentStatus', false)
        // // this.insertColumn('deliveryDate', false)
        // // this.insertColumn('deliveryDay', false)
        // // this.insertColumn('deadlineDate', false)

        this.loadOrderSaleResults({
                deliveryFrom: "",
                deliveryTo: "",
                deadlineFrom: "",
                deadlineTo: "",
                statuses: Object.values(this.orderStates).filter(state => state != this.orderStates.completed && state != this.orderStates.failed),
                paymentStatuses: []
            },
            this.observable$)
    }

    resetDeadlineDateFilter() {
        this.loadOrderSaleResults({deadlineFrom: "", deadlineTo: ""}, this.observable$)
    }

    getOrdersToDeliverToday() {
        // // this.insertColumn('status', false)
        // // this.insertColumn('paymentStatus', false)
        let today = new Date()
        let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

        let todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0)
        let todayEnd = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 0, 0)
        // let todayStartFormatted = this.formatDateToDesiredFormat(todayStart);
        // let todayEndFormatted = this.formatDateToDesiredFormat(todayEnd);


        this.loadOrderSaleResults({
            deliveryFrom: todayStart.toISOString(),
            deliveryTo: todayEnd.toISOString(),
            deadlineFrom: "",
            deadlineTo: "",
            statuses: [],
            paymentStatuses: []
        }, this.observable$)

        // removing date columns
        // this.removeColumn('deliveryDate', false)
        // this.removeColumn('deliveryDay', false)
        // this.removeColumn('deadlineDate', false)
    }

    getOrdersToDeliverTomorrow() {
        // this.insertColumn('status', false)
        // this.insertColumn('paymentStatus', false)

        let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        let afterTomorrow = new Date(new Date().getTime() + 48 * 60 * 60 * 1000)

        let tomorrowStart = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 0, 0)
        let tomorrowEnd = new Date(afterTomorrow.getFullYear(), afterTomorrow.getMonth(), afterTomorrow.getDate(), 0, 0)

        this.loadOrderSaleResults({
            deliveryFrom: tomorrowStart.toISOString(),
            deliveryTo: tomorrowEnd.toISOString(),
            deadlineFrom: "",
            deadlineTo: "",
            statuses: [],
            paymentStatuses: []
        }, this.observable$)

        // this.removeColumn('deliveryDate', false)
        // this.removeColumn('deliveryDay', false)
        // this.removeColumn('deadlineDate', false)
    }

    resetDeliveryDateButtonFilter() {
        // this.insertColumn('status', false)
        // this.insertColumn('paymentStatus', false)
        // this.insertColumn('deliveryDate', false)
        // this.insertColumn('deliveryDay', false)
        // this.insertColumn('deadlineDate', false)
        this.resetDeliveryDateFilter()
    }

    getStatusBasedOrders(status: string) {
        // this.insertColumn('deliveryDate', false)
        // this.insertColumn('deliveryDay', false)
        // this.insertColumn('deadlineDate', false)
        // this.insertColumn('paymentStatus', false)


        // console.log("status based: ", this.filterObject.statuses, " payment ", this.filterObject.paymentStatuses)
        this.loadOrderSaleResults({
            deliveryFrom: "",
            deliveryTo: "",
            deadlineFrom: "",
            deadlineTo: "",
            statuses: [status],
            paymentStatuses: []

        }, this.observable$)

        // this.removeColumn('status', false)

    }

    getPaymentStatusBasedOrders(paymentStatuses: string[]) {
        // this.insertColumn('status', false)
        // this.insertColumn('deliveryDate', false)
        // this.insertColumn('deliveryDay', false)
        // this.insertColumn('deadlineDate', false)

        // console.log("Payment Based :", this.filterObject.statuses, " payment ", this.filterObject.paymentStatuses)
        this.loadOrderSaleResults({
            deliveryFrom: "",
            deliveryTo: "",
            deadlineFrom: "",
            deadlineTo: "",
            paymentStatuses: paymentStatuses,
            statuses: []
        }, this.observable$)

        // this.removeColumn('paymentStatus', false)
    }

    getOverdueOrders() {
        // this.insertColumn('paymentStatus', false)
        // this.insertColumn('status', false)
        // this.insertColumn('deliveryDate', false)
        // this.insertColumn('deliveryDay', false)
        // this.insertColumn('deadlineDate', false)

        let today = new Date()
        let deliveryTo = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0).toISOString()

        this.loadOrderSaleResults({
            ...this.fetchParams,
            deliveryTo: deliveryTo,
            deliveryFrom: '',
            deadlineFrom: "",
            deadlineTo: "",
            statuses: this.availableOrderStatuses.filter(avs => avs.key != Constants.orderStatuses.completed &&
                avs.key != Constants.orderStatuses.failed &&
                avs.key != Constants.orderStatuses.deliveredCourier &&
                avs.key != Constants.orderStatuses.deliveredRider &&
                avs.key != Constants.orderStatuses.pickedUp).map(avs => avs.key),
            paymentStatuses: []
        }, this.observable$)
    }


    openLegendStatusesDialog() {
        this.dialogService.open(GeneralDialogComponent, {
            data: {
                dialogTitle: 'Legenda stati',
                componentData: {},
                component: LegendStatusesComponent
            }
        })
    }

    getConditionalStatus(status: string) {
        return (status !== Constants.orderStatuses.completed && status !== Constants.orderStatuses.failed)
    }

    getConditionalPaymentStatus(status: string) {
        console.log(status !== Constants.orderPaymentStatuses.paid)
        return (status !== Constants.orderPaymentStatuses.paid)
    }

    disableDeletionIfContentNotUpdated(sellOrder: SellOrderDetail) {
        return !!sellOrder.contentSnapshot?.find(content => content.status !== Constants.orderContentStatuses.notUpdated)
    }

    isDeleteActionButtonVisibile() {
        return hasPermissionOnEntity(
            this.userReference.user,
            {
                accessEntity: this.accessEntities.sellOrderShops,
                accessLevel: this.accessLevels.admin
            },
            this.shopId ? {id: this.shopId} : undefined
        )
    }

    override toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSource.data.filter(element => !this.disableDeletionIfContentNotUpdated(element)))
    }

    navigateToOrder(orderId: number) {
        this.router.navigate(this.shopId ? ['orders', orderId] : [orderId], {relativeTo: this.activatedRoute})
    }

    private getComponentData(order: SellOrderDetail, openStatusDialog: boolean) {
        return {
            companyId: this.userReference.companyId,
            entity: order,
            managingSellOrderStatus: openStatusDialog,
            shopId: this.shopId,
            readonly: this.readonly
        }
    }

    private getPaymentComponentData(order: SellOrderDetail, openPaymentStatusDialog: boolean) {
        return {
            companyId: this.userReference.companyId,
            entity: order,
            managingPaymentStatus: openPaymentStatusDialog,
            shopId: this.shopId,
            readonly: this.readonly
        }
    }

  clearFilters() {
    this.loadOrderSaleResults({
      deliveryFrom: "",
      deliveryTo: "",
      deadlineFrom: "",
      deadlineTo: "",
      statuses: [],
      paymentStatuses: []

    }, this.observable$)
  }
}
