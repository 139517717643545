<div [formGroup]="formGroup" fxLayout="row wrap" fxLayoutAlign="space-between">

  <mat-form-field fxFlex="45">
    <mat-label>Nome azienda</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="name"
      id="name"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['name'].errors?.['required']"
    >
      È obbligatorio inserire il nome
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45">
    <mat-label>Nome proprietario</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="ownerName"
      id="ownerName"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['ownerName'].errors?.['required']"
    >
      È obbligatorio inserire il nome del proprietario
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45">
    <mat-label>Cognome proprietario</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="ownerSurname"
      id="ownerSurname"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['ownerSurname'].errors?.['required']"
    >
      È obbligatorio inserire il cognome del proprietario
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45">
    <mat-label>Email</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="email"
      id="email"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['email'].errors?.['required']"
    >
      È obbligatorio inserire la mail
    </mat-error>
    <mat-error *ngIf="formGroup.controls['email'].errors?.['pattern']"
    >
      Inserire una mail valida
    </mat-error>
  </mat-form-field>


  <mat-form-field fxFlex="45">
    <mat-label>Telefono</mat-label>
    <input
      [dropSpecialCharacters]="false"
      [mask]="'+00 000 0*'" [readonly]="readOnly" formControlName="phone" matInput name="phone">
    <mat-error *ngIf="formGroup.controls['phone'].errors?.['required']">
      È obbligatorio inserire il numero di telefono
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45">
    <mat-label>Indirizzo</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="address"
      id="address"
      matInput
      type="text">
  </mat-form-field>

  <mat-form-field fxFlex="45">
    <mat-label>Iva acquisto (%)</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="defaultBuyVAT"
      id="defaultBuyVAT"
      matInput
      currencyMask
      [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2}"
      type="text"
    >
    <span matSuffix>&nbsp;%</span>

  </mat-form-field>

  <mat-form-field fxFlex="45">
    <mat-label>Iva vendita (%)</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="defaultSellVAT"
      id="defaultSellVAT"
      matInput
      type="text"
      currencyMask
      [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2}"
    >
    <span matSuffix>&nbsp;%</span>
  </mat-form-field>

  <mat-form-field fxFlex="45">
    <mat-label>Nome App</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="shownAppName"
      id="shownAppName"
      matInput
      type="text">
  </mat-form-field>


  <div fxFlex="45">

    <div fxLayout="column">

        <mat-label class="mb-2_5">Logo azienda</mat-label>

          <img *ngIf="_entity?.companyLogo && !logo" [src]="_entity?.companyLogo" alt="" class="logo">
          <img *ngIf="logo" [src]="logo" alt="" class="logo">
          <aw-image-compress
            (newCompressedImage)="storeImage($event)"
            [aspectRatio]="112/69"
            [readonly]="readOnly"
            id="logo"
          >
          </aw-image-compress>
    </div>
  </div>


</div>
<div fxLayout="row" fxLayoutAlign="end">

  <button
    (click)="_entity?.id ? updateEntity(_entity.id) : createEntity()"
    [disabled]="formGroup.invalid || saving || readOnly || (!formGroup.dirty && !imageHasChanged)"
    color="primary"
    mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Salva</span>
  </button>
</div>




