import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DOC_ORIENTATION, NgxImageCompressService} from "ngx-image-compress";
import {ImageCroppedEvent} from "ngx-image-cropper";

@Component({
  selector: 'aw-image-compress',
  templateUrl: './aw-image-compress.component.html',
  styleUrls: ['./aw-image-compress.component.css']
})
export class AwImageCompressComponent implements OnInit {

  imageToCrop: any;
  loadingImage = false;
  imageToCompress?: string | null;
  compressing = false;
  @Input() doNotCropping?: boolean;
  @Input() circlePicture?: boolean;
  @Input() aspectRatio = 1;
  @Output() newCompressedImage = new EventEmitter<string>();
  @Output() changingImage = new EventEmitter<boolean>();
  @Output() canceled = new EventEmitter<void>();
  private orientation?: DOC_ORIENTATION;

  initializing = false;
  confirmed = false;
  @Input() readonly = false;

  constructor(private imageCompress: NgxImageCompressService) {
  }

  ngOnInit(): void {}

  chooseFile() {
    this.imageCompress
      .uploadFile()
      .then(({image, orientation }) => {
        this.orientation = orientation;
        if (this.doNotCropping) {
          this.imageToCompress = image;
          this.changingImage.emit(true);

        } else {
          this.imageToCrop = image;
          this.changingImage.emit(true);
        }
        this.initializing = false
      });
  }

  cancelSelectedImage() {
    this.changingImage.emit(false);
    this.imageToCrop = null;
    this.imageToCompress = null;
  }

  imageLoaded() {
    this.loadingImage = true;
  }

  cropperReady() {
    this.loadingImage = false;
  }

  imageCropped(firstEvent: ImageCroppedEvent) {
    if (firstEvent.base64)
      this.imageToCompress = firstEvent.base64;
  }


  saveAndCompressImage() {
    this.compressing = true;

    const image = new Image();
    if (this.imageToCompress)
      image.src = this.imageToCompress;
    image.onload = (event: any) => {
      let originalLength;
      const path = event.composedPath() || event.composedPath;
      if (path[0].width > path[0].height) {
        originalLength = path[0].width;
      } else {
        originalLength = path[0].height;
      }
      if (originalLength > 940) {
        this.imageCompress
          .compressFile(
            this.imageToCompress!,
            this.orientation!,
            (940 * 100) / originalLength,
            this.imageCompress.byteCount(this.imageToCompress!) >= 3300000 ? 30 : 80)
          .then(
              (result: string | undefined) => {
              this.compressing = false;
              this.newCompressedImage.emit(result);
              this.imageToCrop = null;
              // this.imageToCompress = null;
              this.changingImage.emit(false);
            }
          );
      } else {
        this.compressing = false;
        this.newCompressedImage.emit(this.imageToCompress!);

        this.imageToCrop = null;
        this.imageToCompress = null;
        this.changingImage.emit(false);
      }
    };
  }


}
