import {Injectable} from '@angular/core';
import {Constants} from "../utils/constants";
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse} from "../classes/api-response";
import {map} from "rxjs/operators";
import {WarehouseCreate, WarehouseData, WarehouseDetail} from "../interfaces/warehouse";
import {
  ItemStocksData,
  ItemWarehouseCreate,
  ItemWarehouseDetail,
  ItemWarehouseMigrate
} from "../interfaces/item-warehouse";
import {UtilsService} from "./utils.service";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";

@Injectable({
  providedIn: 'root'
})
export class WarehousesService {

  private readonly entities = Constants.entities;

  constructor(private http: HttpClient) {
  }

  getWarehouseById(companyId: number, warehouseId: number): Observable<WarehouseDetail> {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}/${warehouseId}`)
      .pipe(
        map(value => value.data as WarehouseDetail)
      )
  }


  getAllCompanyWarehouses(companyId: number, warehouseIds: number[], paginationParams?: FetchParams): Observable<PageConverter<WarehouseData>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})
    let queryParams = UtilsService.buildParams(warehouseIds, sortFilterParams);

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}`, {params: queryParams})
      .pipe(map(value => value.data as PageConverter<WarehouseData>))
  }


  createWarehouse(companyId: number, warehouse: WarehouseCreate): Observable<WarehouseDetail> {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}`, warehouse)
      .pipe(
        map(value => value.data as WarehouseDetail)
      )
  }

  updateWarehouse(companyId: number, warehouseId: number, warehouse: WarehouseCreate): Observable<WarehouseDetail> {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}/${warehouseId}`, warehouse)
      .pipe(map(value => value.data as WarehouseDetail))
  }

  deleteWarehouse(companyId: number, warehouseId: number): Observable<void> {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}/${warehouseId}`)
      .pipe(map(() => {
      }))
  }


  getWarehouseItems(companyId: number, warehouseId: number, paginationParams?: FetchParams): Observable<PageConverter<ItemWarehouseDetail>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}/${warehouseId}/items`, {params: sortFilterParams})
      .pipe(
        map(value => value.data as PageConverter<ItemWarehouseDetail>)
      )
  }

  getWarehouseItemsTmp(companyId: number, warehouseId: number): Observable<ItemWarehouseDetail[]> {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}/${warehouseId}/items`)
      .pipe(
        map(value => value.data as ItemWarehouseDetail[])
      )
  }

  putItemWarehouse(companyId: number, warehouseId: number, item: ItemWarehouseCreate | undefined, createMissingComposers: boolean | undefined) {

    let params = new HttpParams()
    params = params.append("createMissingComposers", createMissingComposers ? "true" : "false")
    return this.http
      .put<ApiResponse>(
        `${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}/${warehouseId}/${this.entities.items}/${item?.item?.id}?quantity=${item?.quantity}`, {}, {
          params
        })
      .pipe(
        map(value => value.data as ItemWarehouseDetail[])
      )

  }

  postItemWarehouse(companyId: number, warehouseId: number, item: ItemWarehouseCreate) {
    return this.http
      .post<ApiResponse>(
        `${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}/${warehouseId}/${this.entities.items}/${item?.item?.id}?quantity=${item.quantity}`,
        {})
      .pipe(
        map(value => value.data as ItemWarehouseDetail)
      )
  }

  migrateStocks(companyId: number, stocksMigration: ItemWarehouseMigrate[]) {
    return this.http
      .put<ApiResponse>(
        `${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}/migrate`,
        stocksMigration)
      .pipe(map(() => {}))
  }

  deleteItemWarehouse(companyId: number, warehouseId: number, itemId: number) {
    return this.http
      .delete<ApiResponse>(
        `${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.warehouses}/${warehouseId}/${this.entities.items}/${itemId}`)
      .pipe(map(() => {}))
  }

  getAllItemStocks(companyId: number, variantId: number, warehouseIds: number[], paginationParams?: FetchParams): Observable<PageConverter<ItemStocksData>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})
    let queryParams = UtilsService.buildParams(warehouseIds, sortFilterParams);

    return this.http.get<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.items}/${variantId}/${this.entities.warehouses}`, {params: queryParams})
      .pipe(map(value => value.data as PageConverter<ItemStocksData>))
  }
}
