<ng-template #loadingTemplate>
  <mat-spinner class="align-self-center mt-8"></mat-spinner>
</ng-template>

<mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="matTabChange($event)">

  <mat-tab *awAccess="{selected: {id: warehouseId}, accessLevel: {accessEntity: accessEntities.warehouses, accessLevel: accessLevels.editor}}"
           label="Anagrafica">
    <h1 class="font-light">MODIFICA – {{warehouse?.name}}</h1>
    <aw-warehouses-form
      [companyId]="userReference.companyId"
      [entity]="warehouse"
    >
    </aw-warehouses-form>
  </mat-tab>

  <mat-tab *awAccess="{selected: {id: warehouseId}, accessLevel: {accessEntity: accessEntities.itemWarehouses, accessLevel: accessLevels.reader}}"
           label="Giacenze">
    <h1 class="font-light">GIACENZE – {{warehouse?.name}}</h1>

    <aw-warehouses-items-table
      [warehouseId]="warehouseId"
      *ngIf="!loading; else loadingTemplate"
    >
    </aw-warehouses-items-table>

  </mat-tab>
</mat-tab-group>




