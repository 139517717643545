import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {BehaviorSubject, from, Observable, skipWhile, switchMap, tap} from "rxjs";
import {UsersService} from "./users.service";
import {Router} from "@angular/router";
import {UserMeDetail} from "../interfaces/user";


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private behaviorUser = new BehaviorSubject<UserMeDetail | undefined>(undefined);
  private _internalUser?: UserMeDetail;
  private readonly _internalUser$: Observable<any>;
  private isInit = false;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private usersService: UsersService) {


    this._internalUser$ =
      this.usersService
        .getMyself()
        .pipe(
          tap((u) => {
            this._internalUser = u
            this.behaviorUser.next(this._internalUser)
          }),
        )

      afAuth.user.subscribe((value) => {
        this.isInit = true;
        if (value) {
          this._internalUser$.subscribe(
            this.redirectUrl ?
              () => router.navigate([this.redirectUrl]).then(value1 => this.redirectUrl = undefined) :
              null
          )
        } else {
          this._internalUser = undefined;
          this.behaviorUser.next(this._internalUser);
          this.router.navigate(['/']).then();
        }
      })

  }

  private _redirectUrl?: string;

  get redirectUrl(): string | undefined {
    return this._redirectUrl;
  }

  set redirectUrl(value: string | undefined) {
    this._redirectUrl = value;
  }

  login(email: string, password: string) {
    return from(this.afAuth.signInWithEmailAndPassword(email, password))
      .pipe(switchMap(uc => this._internalUser$))
  }

  logout() {
    return from(this.afAuth.signOut())
  }

  forgotPassword(passwordResetEmail: string) {
    return from(this.afAuth.sendPasswordResetEmail(passwordResetEmail));
  }

  /**
   * Notifies proprietary user change.
   *
   * Wait until Firebase service initializes and recognizes
   * the stored jwt (if exists), that is, skips any emitted
   * value from the behaviourUser until FB auth
   * service emits its first user (that can be null, if no jwt is stored)
   */
  onUserChange(): Observable<UserMeDetail | undefined> {
    return this.behaviorUser.asObservable()
      .pipe(
        skipWhile((c) => {
          return !this.isInit;
        })
      );
  }

}
