import {Component, Input} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ShopData, ShopDetail} from "../../../interfaces/shop";
import {CustomerData} from "../../../interfaces/customer";
import {CustomerInformationDetail} from "../../../interfaces/customer-information";
import {DeliveryCompanyData} from "../../../interfaces/delivery-company";
import {Constants} from "../../../utils/constants";
import {ItemVariantDetail} from "../../../interfaces/item-variant";
import {ItemWarehouseDetail, WarehouseStocks} from "../../../interfaces/item-warehouse";
import {WarehouseData} from "../../../interfaces/warehouse";
import {
  SellOrderContentCancel,
  SellOrderContentClose,
  SellOrderContentCreate,
  SellOrderContentCreateWithOrderContentId,
  SellOrderContentDetail,
  OrderContentStatus,
  SellOrderContentUpdateStatus
} from "../../../interfaces/sell-order-content";
import {
  DeliveryOption,
  OrderContentUpdateWithStates,
  SellOrderContentState,
  SellOrderCreate,
  SellOrderCreateFull,
  SellOrderDetail,
  SellOrderFullCreateWithStates
} from "../../../interfaces/sell-order";
import {OrderContentStatusPipe} from "../../../pipes/order-content-status.pipe";
import {ActivatedRoute, Router} from "@angular/router";
import {EMPTY, forkJoin, NEVER, Observable, of, switchMap, tap} from "rxjs";
import {UtilsService} from "../../../services/utils.service";
import {catchError, map} from "rxjs/operators";
import {
  FetchParams,
  hasPermission,
  hasPermissionOnEntity,
  PageConverter,
  RollbackStatus
} from "../../../utils/global-functions-and-types";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {ShopsService} from "../../../services/shops.service";
import {OrdersService} from "../../../services/orders.service";
import {CustomersService} from "../../../services/customers.service";
import {WarehousesService} from "../../../services/warehouses.service";
import {DeliveryCompaniesService} from "../../../services/delivery-companies.service";
import {SnackService} from "../../../services/snack.service";
import {ItemVariantsService} from "../../../services/item-variants.service";
import {MatSelectChange} from "@angular/material/select";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {CustomersFormComponent} from "../../customers/customers-form/customers-form.component";
import {DialogsService} from "../../../services/dialogs.service";
import {
  DeliveryCompaniesFormComponent
} from "../../delivery-companies/delivery-companies-form/delivery-companies-form.component";
import {CustomersInfoFormComponent} from "../../customers/customers-info-form/customers-info-form.component";
import {SellOrderContentSnapshotData} from "../../../interfaces/sell-order-content-snapshot";
import {DeliveryTimeSlotData} from "../../../interfaces/delivery-time-slot";
import {DeliveryTimeSlotsService} from "../../../services/delivery-time-slots.service";
import { DeliveryTimeSlotsFormComponent } from '../../delivery-time-slots/delivery-time-slots-form/delivery-time-slots-form.component';

@Component({
  selector: 'aw-orders-form',
  templateUrl: './orders-form.component.html',
  styleUrls: ['./orders-form.component.scss']
})
export class OrdersFormComponent extends BaseFormComponent<SellOrderCreate | SellOrderCreateFull | SellOrderFullCreateWithStates, SellOrderDetail> {

  formGroupOrder: FormGroup
  formGroupOrderContent: FormGroup
  duplicateContentMessage = Constants.duplicateContentMessage
  errorToShow = ''
  anagraphicOpened = true
  addingOrderContent = false
  noWarehousesAvailable = false
  savingOrderAndContent = false
  isEditing = false
  savingOrderContent = false
  updatingOrder = false
  updatingOrderContent = false
  savingOrder = false
  showDuplicateContentMessage = false
  autoscaleErrorOnSellOrderClose = false
  isOrderFailed = false
  savingStatus = false
  mandatoryShop = false
  sendEmailToClientOnStatusChange: boolean = false
  sendEmailToClientOnCreation: boolean = false
  isCustomerAlsoRecipient = false
  loadingWarehouses = false
  loadingItems = false
  loadingShops = false
  loadingCustomers = false
  loadingCustomerInfo = false
  loadingRecipientInfo = false
  loadingDeliveryCompanies = false
  loadingDeliveryTimeSlots = false
  loadingStocks = false
  orderContentToDelete: number[] = [];
  generalContentStatus?: OrderContentStatus = Constants.orderContentStatuses.manuallyUpdated as OrderContentStatus
  generalContentRollback?: RollbackStatus = Constants.rollbackStatusesMap['manual']
  selectedShippingInfo?: CustomerInformationDetail | null = null
  selectedBillingInfo?: CustomerInformationDetail | null = null
  selectedDeliveryCompany?: DeliveryCompanyData;
  selectedDeliveryTimeSlot?: DeliveryTimeSlotData;
  selectedShop?: ShopData
  creationDate: Date = new Date();
  deliveryDate?: Date | null;
  deadlineDate?: Date | null;
  calculatedPrice = 0;
  orderStatuses = Constants.orderStatuses
  selectedStatus = ''
  selectedPaymentStatus = ''
  availableOrderStates: string[] = []
  orderContentStates: string[] = Object.values(Constants.orderContentStatuses)
  deliveryOptions: string[] = Object.values(Constants.deliveryOptions)
  availableDeliveryOptions = Constants.deliveryOptions
  selectedDeliveryOption?: DeliveryOption
  orderContentStatuses = Constants.orderContentStatuses
  stateOptions = [{label: 'Non prelevare da magazzino', value: false}, {label: 'Preleva da magazzino', value: true}];
  statusTransitionMap = Constants.orderStatusTransitionMap
  originalOrderStatusTransitionMap = Constants.originalOrderStatusTransitionMap
  updatedStates: string[] = Object.values(Constants.saleContentUpdatedStatuses)
  rollbackStates: RollbackStatus[] = Constants.rollbackStates
  warehouses: WarehouseData[] = []
  warehouseItems: ItemWarehouseDetail[] = [];
  shops: ShopData[] = []
  filteredShopsOptions: any[] = [];
  companyItems: ItemVariantDetail[] = []
  itemsToHandle: SellOrderContentDetail[] = []
  selectedCustomer?: CustomerData;
  selectedRecipient?: CustomerData;
  customersAndRecipients: CustomerData[] = []
  filteredCustomers: CustomerData[] = [];
  filteredRecipients: CustomerData[] = [];
  paymentStatusValues = Object.values(Constants.orderPaymentStatuses)
  paymentStates = Constants.orderPaymentStatuses
  customerInformation: CustomerInformationDetail[] = []
  recipientInformation: CustomerInformationDetail[] = []
  filteredShippingInformation: CustomerInformationDetail[] = []
  filteredBillingInformation: CustomerInformationDetail[] = []
  deliveryCompanies: DeliveryCompanyData[] = [];
  deliveryTimeSlots: DeliveryTimeSlotData[] = [];
  deliveryCompaniesFiltered: DeliveryCompanyData[] = [];
  deliveryTimeSlotsFiltered: DeliveryTimeSlotData[] = [];
  defaultWarehouseId?: number
  currentWarehouseStocks: WarehouseStocks[] = []
  creationWrappers: Wrapper[] = []
  closingWrappers: ClosingWrapper[] = []
  shopContext = false
  showOnlyContent = false
  readOnlyOrder = false
  readOnlyContent = false
  showShopCheckbox = true
  showPriceChangedMessage = false;
  currentIndex: number = -1
  currentWarehouseId: number = -1
  itemToShow: number | undefined

  // todo: chissà a che cazzo serve -Y
  warehouseToShow: number | undefined;

  // Retrieves shop ID. Consequentially, valorizes shopContext
  shopContext$: Observable<void> = new Observable<void>()
  manageInputOrder$: Observable<any> = new Observable<any>()
  shops$: Observable<ShopDetail[]> = new Observable<ShopDetail[]>()
  shop$: Observable<ShopDetail | undefined> = new Observable<ShopDetail>()
  customers$: Observable<CustomerData[]> = new Observable<CustomerData[]>()
  customer$: Observable<CustomerData> = new Observable<CustomerData>()
  recipient$: Observable<CustomerData> = new Observable<CustomerData>()
  customersInfo$: Observable<CustomerInformationDetail[]> = new Observable<CustomerInformationDetail[]>()
  recipientsInfo$: Observable<CustomerInformationDetail[]> = new Observable<CustomerInformationDetail[]>()
  items: ItemVariantDetail[] = []
  items$: Observable<ItemVariantDetail[]>
  warehouses$: Observable<WarehouseData[]> = new Observable<WarehouseData[]>()
  deliveryCompanies$: Observable<DeliveryCompanyData[]> = new Observable<DeliveryCompanyData[]>()
  deliveryCompany$: Observable<DeliveryCompanyData> = new Observable<DeliveryCompanyData>()
  deliveryTimeSlots$: Observable<DeliveryTimeSlotData[]> = new Observable<DeliveryTimeSlotData[]>()
  deliveryTimeSlot$: Observable<DeliveryTimeSlotData> = new Observable<DeliveryTimeSlotData>()
  // questa funzione ritorna un observable delle giacenze in base al warehouseId passato come parametro
  warehouseItemsFn: (warehouseId: number) => Observable<ItemWarehouseDetail[]>
  // questa funzione ritorna un observable delle customerInformation in base al customerId passato come parametro
  customerInfoFn: (customerId: number, clientType: 'customer' | 'recipient') => Observable<CustomerInformationDetail[]>;

  constructor(
    public formBuilder: FormBuilder,
    public orderContentStatusPipe: OrderContentStatusPipe,
    public shopsService: ShopsService,
    public customersService: CustomersService,
    public warehousesService: WarehousesService,
    public itemVariantsService: ItemVariantsService,
    public deliveryCompaniesService: DeliveryCompaniesService,
    public deliveryTimeSlotsService: DeliveryTimeSlotsService,
    public ordersService: OrdersService,
    public snackService: SnackService,
    public dialogService: DialogsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    protected override utilsService: UtilsService,
  ) {
    super(() => new Observable<any>(), () => new Observable<any>(), utilsService)

    // Should fire on customer dropdown change
    this.customerInfoFn =
      (customerId: number, clientType: 'customer' | 'recipient') =>
        of(true)
          .pipe(
            tap(() => {
              if (clientType === 'customer') {
                this.loadingCustomerInfo = true
                this.customerInformation = []
              } else {
                this.loadingRecipientInfo = true
                this.recipientInformation = []
              }

            }),
            switchMap(() => this.customersService.getCustomerInformationList(this.userReference.companyId, customerId, {sort: "addressName asc"} as FetchParams)),
            switchMap((customerInformationList) => {
              if (clientType === 'customer') {
                this.customerInformation = customerInformationList.list
                this.filteredBillingInformation = customerInformationList.list
                this.loadingCustomerInfo = false
                return of(customerInformationList.list)
              } else {
                this.recipientInformation = customerInformationList.list
                this.filteredShippingInformation = customerInformationList.list
                this.loadingRecipientInfo = false
                return of(customerInformationList.list)
              }
            }),
            catchError((err, caught) => {
              if (clientType === 'customer')
                this.loadingCustomerInfo = false
              else
                this.loadingRecipientInfo = false
              if (!err.errors.includes('403'))
                this.snackService.error('Impossibile caricare le informazioni del cliente')

              return of([] as CustomerInformationDetail[]);
            }))

    this.shopContext$ =
      of(true)
        .pipe(
          switchMap(() => activatedRoute.paramMap),
          map(paramMap => {
            if (paramMap.has("shopId")) {
              this._shopId = +paramMap.get("shopId")!
              this.shopContext = true
            }
          })
        )

    this.shops$ =
      of(true)
        .pipe(
          tap(() => this.loadingShops = true),
          switchMap(() => {
            if (!this.shopContext)
              return this.shopsService.getAllCompanyShops(this.userReference.companyId, this.utilsService.getEntityAccessIdsForUser(this.userReference.user.access.shops), {sort: 'name asc'} as FetchParams)
            return new Observable<PageConverter<ShopDetail>>()
          }),
          switchMap(shops => {
            this.shops?.push(...shops.list)
            this.filteredShopsOptions = shops.list
            this.loadingShops = false
            return of(shops.list)
          }),
          catchError((err, caught) => {
            this.loadingShops = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i punti vendita')
            return of([] as ShopDetail[]);
          })
        )

    this.shop$ =
      of(true)
        .pipe(
          switchMap(() => {

              let shopId: number | null = null

              // For better legibility
              if (this.shopContext)
                shopId = this._shopId
              else if (this._entity?.shopId)
                shopId = this._entity?.shopId
              else if (this.formGroupOrder.controls["shopId"].value)
                shopId = this.formGroupOrder.controls["shopId"].value

              if (shopId)
                return this.shopsService.getShop(this.userReference.companyId, shopId, hasPermissionOnEntity(this.userReference.user,
                  {
                    accessEntity: this.accessEntities.warehouses,
                    accessLevel: this.accessLevels.reader
                  }, {id: this.shops.find(shop => shop.id === shopId)!.defaultWarehouseId})
                )
              else return of({} as ShopDetail)

            }
          ),
          switchMap(shop => {
            if (shop.id) {
              this.defaultWarehouseId = shop.defaultWarehouseId
              this.selectedShop = shop
              this.formGroupOrder.controls['shopId'].setValue(shop.id)
              this.shops.push(shop as ShopDetail)
            }
            return of(shop)
          }),
          catchError((err, caught) => {
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i dati del punto vendita')
            return of({} as ShopDetail);
          })
        )

    this.customers$ =
      of(true)
        .pipe(
          tap(() => this.loadingCustomers = true),
          switchMap(() => this.customersService.getCompanyCustomers(this.userReference.companyId, {sort: 'name asc'} as FetchParams)),
          switchMap(customers => {
            this.loadingCustomers = false;
            this.customersAndRecipients = customers.list
            this.filteredCustomers = customers.list
            this.filteredRecipients = customers.list
            return of(customers.list)
          }),
          catchError((err, caught) => {
            this.loadingCustomers = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i clienti')
            return of({} as CustomerData[])
          })
        )

    this.customer$ =
      of(true)
        .pipe(
          switchMap(() => {
            if (this._entity?.customerId)
              return this.customersService.getCompanyCustomer(this.userReference.companyId, this._entity?.customerId)
            else return of({} as CustomerData)

          }),
          map(customer => {
            if (customer?.id) {
              this.selectedCustomer = customer
              this.formGroupOrder.controls['customerId'].setValue(customer.id)
            }
            return customer
          }),
          catchError((err, caught) => {
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare il cliente')
            return of({} as CustomerData)
          })
        )

    this.customersInfo$ =
      of(true)
        .pipe(
          tap(() => this.loadingCustomerInfo = true),
          switchMap(() => {

            // Need to do this because if you change customerId, Customer Info must be fetched again
            let customerId: number | undefined
            customerId = this._entity?.customerId
            if (this.formGroupOrder.controls["customerId"].value)
              customerId = +this.formGroupOrder.controls["customerId"].value

            if (customerId)
              return this.customersService.getCustomerInformationList(this.userReference.companyId, customerId, {sort: 'addressName asc'} as FetchParams)
            return of({list: [], num: 0} as PageConverter<CustomerInformationDetail>)
          }),
          switchMap(customerInfo => {
            this.loadingCustomers = false;
            this.customerInformation = customerInfo.list
            return of(customerInfo.list)
          }),
          catchError((err, caught) => {
            this.loadingCustomers = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i clienti')
            return of([] as CustomerInformationDetail[]);
          })
        )


    this.recipient$ =
      of(true)
        .pipe(
          switchMap(() => {
            if (this._entity?.recipientId)
              return this.customersService.getCompanyCustomer(this.userReference.companyId, this._entity?.recipientId)
            else return of({} as CustomerData)

          }),
          map(recipient => {
            if (recipient?.id) {
              this.selectedRecipient = recipient
              this.formGroupOrder.controls['recipientId'].setValue(recipient.id)
            }
            return recipient
          }),
          catchError((err, caught) => {
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare il cliente')
            return of({} as CustomerData)
          })
        )

    this.recipientsInfo$ =
      of(true)
        .pipe(
          tap(() => this.loadingRecipientInfo = true),
          switchMap(() => {

            // Need to do this because if you change recipientId, Recipient Info must be fetched again
            let recipientId: number | undefined
            recipientId = this._entity?.recipientId
            if (this.formGroupOrder.controls["recipientId"].value)
              recipientId = +this.formGroupOrder.controls["recipientId"].value

            if (recipientId)
              return this.customersService.getCustomerInformationList(this.userReference.companyId, recipientId, {sort: 'addressName asc'} as FetchParams)
            return of({list: [], num: 0} as PageConverter<CustomerInformationDetail>)
          }),
          switchMap(customerInfo => {
            this.loadingCustomers = false;
            this.recipientInformation = customerInfo.list
            return of(customerInfo.list)
          }),
          catchError((err, caught) => {
            this.loadingCustomers = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i clienti')
            return of([] as CustomerInformationDetail[]);
          })
        )


    this.deliveryCompanies$ =
      of(true)
        .pipe(
          tap(() => this.loadingDeliveryCompanies = true),
          switchMap(() => this.deliveryCompaniesService.getDeliveryCompanies(this.userReference.companyId, {sort: "name asc"} as FetchParams)),
          switchMap(deliveryCompanies => {
            this.loadingDeliveryCompanies = false;
            this.deliveryCompanies = deliveryCompanies.list
            this.deliveryCompaniesFiltered = deliveryCompanies.list
            return of(deliveryCompanies.list)
          }),
          catchError((err, caught) => {
            this.loadingDeliveryCompanies = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i corrieri')
            return of({} as DeliveryCompanyData[]);
          })
        )

    this.deliveryCompany$ =
      of(true)
        .pipe(
          switchMap(() => {
            if (this._entity?.deliveryCompany?.id)
              return this.deliveryCompaniesService.getDeliveryCompany(this.userReference.companyId, this._entity?.deliveryCompany.id)
            return of({} as DeliveryCompanyData)
          }),
          switchMap(deliveryCompany => {
            if (deliveryCompany?.id) {
              this.selectedDeliveryCompany = deliveryCompany
              this.formGroupOrder.controls['deliveryCompanyId'].setValue(deliveryCompany.id)
            }
            return of(deliveryCompany)
          }),
          catchError((err, caught) => {
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i corrieri')
            return of({} as DeliveryCompanyData);
          })
        )

    this.deliveryTimeSlots$ =
      of(true)
        .pipe(
          tap(() => this.loadingDeliveryTimeSlots = true),
          switchMap(() => this.deliveryTimeSlotsService.getDeliveryTimeSlots(this.userReference.companyId, {sort: "name asc"} as FetchParams)),
          switchMap(deliveryTimeSlots => {
            this.loadingDeliveryTimeSlots = false;
            this.deliveryTimeSlots = deliveryTimeSlots.list
            this.deliveryTimeSlotsFiltered = deliveryTimeSlots.list
            return of(deliveryTimeSlots.list)
          }),
          catchError((err, caught) => {
            this.loadingDeliveryTimeSlots = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare le fasce orarie')
            return of({} as DeliveryTimeSlotData[]);
          })
        )

    this.deliveryTimeSlot$ =
      of(true)
        .pipe(
          switchMap(() => {
            if (this._entity?.deliveryTimeSlot?.id)
              return this.deliveryTimeSlotsService.getDeliveryTimeSlot(this.userReference.companyId, this._entity?.deliveryTimeSlot.id)
            return of({} as DeliveryTimeSlotData)
          }),
          switchMap(deliveryTimeSlot => {
            if (deliveryTimeSlot?.id) {
              this.selectedDeliveryTimeSlot = deliveryTimeSlot
              this.formGroupOrder.controls['deliveryTimeSlotId'].setValue(deliveryTimeSlot.id)
            }
            return of(deliveryTimeSlot)
          }),
          catchError((err, caught) => {
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare le fasce orarie')
            return of({} as DeliveryTimeSlotData);
          })
        )

    this.manageInputOrder$ =
      of(true)
        .pipe(
          tap(() => this.loading = true),
          tap(() => this.manageReadonly()),
          // Retrieving user
          switchMap(() => this.userReference$),
          // Retrieving Shop context
          switchMap(() => this.shopContext$),
          // Retrieving all Shops (if not in Shop context)
          switchMap(() => this.shops$),
          // Retrieving and selecting Shop (based on shop context of shopId in the order)
          switchMap(() => this.shop$),
          // Retrieving all Customers
          switchMap(() => this.customers$),
          // Retrieving and selecting order Customer
          switchMap(() => this.customer$),
          // Retrieving and selecting Customer Info
          switchMap(customer => customer?.id ? this.customerInfoFn(customer.id, 'customer') : of({})),
          // Retrieving and selecting order Recipient
          switchMap(() => this.recipient$),
          // Retrieving and selecting Recipient Info
          switchMap(recipient => recipient?.id ? this.customerInfoFn(recipient.id, 'recipient') : of({})),
          // Selecting Customer Info
          map(() => this.findSelectedCustomerAndRecipientInfo()),
          // Retrieving Delivery Companies
          switchMap(() => this.deliveryCompanies$),
          // Selecting Delivery Company
          switchMap(() => this.deliveryCompany$),
          // Retrieving Delivery Time Slots
          switchMap(() => this.deliveryTimeSlots$),
          // Selecting Delivery Time Slot
          switchMap(() => this.deliveryTimeSlot$),
          // Retrieving Warehouses
          switchMap(() => this.warehouses$),
          tap(() => {
            this._entity.content?.forEach(value => {
              this.addOrderContent(value)
            })
            this.computeCalculatedPrice()

            if (this._managingSellOrderStatus)
              this.openUpdateOrderContentSection()
          }),
          tap(() => this.computeIsCustomerEqualsToRecipient()),
          catchError((err, caught) => {
            this.loading = true
            this.router.navigate(['/error']).then()
            return EMPTY
          }))

    this.warehouseItemsFn =
      (warehouseId: number) =>
        of(true)
          .pipe(
            tap(() => this.loadingStocks = true),
            switchMap(() => this.warehousesService.getWarehouseItems(this.userReference.companyId, warehouseId, {} as FetchParams)),
            switchMap((res) => {
              this.warehouseItems = res.list
              this.loadingStocks = false
              return of(res.list)
            }),
            catchError((err, caught) => {
              this.loadingStocks = false;
              if (!err.errors.includes('403'))
                this.snackService.error('Impossibile caricare le giacenze')
              return of([] as ItemWarehouseDetail[]);
            }))


    this.items$ =
      of(true)
        .pipe(
          tap(() => this.loadingItems = true),
          switchMap(() => this.itemVariantsService.getAllCompanyItems(this.userReference.companyId, {sort: "name asc"} as FetchParams)),
          switchMap(items => {
            this.items = items.list
            this.loadingItems = false
            return of(items.list)
          }),
          catchError((err, caught) => {
            this.loadingItems = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i magazzini')
            return of([] as ItemVariantDetail[]);
          }))

    this.warehouses$ =
      of(true)
        .pipe(
          tap(() => this.loadingWarehouses = true),
          switchMap(() => {
            return this.warehousesService.getAllCompanyWarehouses(this.userReference.companyId,
              this.utilsService.getEntityAccessIdsForUser(this.userReference.user.access.warehouses), {sort: "name asc"} as FetchParams)
          }),
          switchMap(warehouses => {
            this.warehouses = warehouses.list
            this.loadingWarehouses = false
            return of(warehouses.list)
          }),
          catchError((err, caught) => {
            this.loadingWarehouses = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i magazzini')
            return of([] as WarehouseData[]);
          }))

    this.formGroupOrder = new FormGroup({
      id: new FormControl(undefined),
      customerId: new FormControl<number | null>(null),
      recipientId: new FormControl<number | null>(null),
      finalPrice: new FormControl(null),
      note: new FormControl(''),
      orderDate: new FormControl(new Date(), {validators: [Validators.required]}),
      deliveryDate: new FormControl(),
      deadlineDate: new FormControl(),
      deliveryOption: new FormControl<DeliveryOption | undefined>(undefined),
      billingInformationId: new FormControl(null),
      shippingInformationId: new FormControl(null),
      deliveryCompanyId: new FormControl(null),
      deliveryTimeSlotId: new FormControl(null),
      deliveryNotes: new FormControl(''),
      shopId: new FormControl(this.selectedShop?.id ? this.selectedShop?.id : null),
      mandatoryShop: new FormControl(false),
      paymentStatus: new FormControl(this.paymentStates.toBePaid),
      paidPrice: new FormControl(0),
      photo: new FormControl(false)
    })

    this.formGroupOrderContent = this.formBuilder.group({orderContents: this.formBuilder.array([])});
  }

  _managingSellOrderStatus = false

  @Input() set managingSellOrderStatus(value: boolean) {
    this._managingSellOrderStatus = value
  }

  _managingSellOrderContent = false

  @Input() set managingSellOrderContent(value: boolean) {
    this._managingSellOrderContent = value
  }

  _managingPaymentStatus = false
  @Input() set managingPaymentStatus(value: boolean){
    this._managingPaymentStatus = value
  }

  _shopId: number | null = null;

  @Input() set shopId(shopId: number | null) {
    if (shopId) {
      this._shopId = shopId
      this.shopContext = true
    }
  }

  override _entity: SellOrderDetail = {} as SellOrderDetail

  @Input() set entity(value: SellOrderDetail) {

    this._entity = value

    this.subs.push(this.manageInputOrder$.subscribe(() => {
      this.loading = false
    }))

    this.creationDate = new Date(Date.now())
    this.deliveryDate = null
    this.deadlineDate = null

    if (value?.id) {

      this.selectedDeliveryOption = value.deliveryOption

      this.selectedStatus = value.status!
      if (this.selectedDeliveryOption)
        this.availableOrderStates = [...this.statusTransitionMap[this.selectedDeliveryOption][this.selectedStatus], this.selectedStatus];
      else {
        this.availableOrderStates = [...this.originalOrderStatusTransitionMap[this.selectedStatus], this.selectedStatus];
      }

      this.isEditing = true

      // setto oggetto e form
      this.formGroupOrder.patchValue(value)

      // setto la data come la vuole lui
      if (this._entity?.orderDate)
        this.creationDate = new Date(this._entity?.orderDate)


      // setto la data come la vuole lui
      if (this._entity?.deliveryDate)
        this.deliveryDate = new Date(this._entity?.deliveryDate!)

      // setto la data come la vuole lui
      if (this._entity?.deadlineDate)
        this.deadlineDate = new Date(this._entity?.deadlineDate!)
    }
  }

  customerPredicate: (item: CustomerData, searchValue: string) => boolean = (item, searchValue) =>
    (item.name.toLowerCase().includes(searchValue) ||
      (item?.surname?.toLowerCase().includes(searchValue) ?? false) ||
      ((item.name.toLowerCase() + " " + item?.surname?.toLowerCase()).includes(searchValue) ?? false) ||
      (item?.email?.toLowerCase().includes(searchValue) ?? false) ||
      (item?.phone?.toLowerCase().includes(searchValue) ?? false) ||
      (item?.vat?.toLowerCase().includes(searchValue) ?? false))

  itemPredicate: (item: ItemVariantDetail, searchValue: string) => boolean = (item, searchValue) =>
    (item.name.toLowerCase().includes(searchValue) ||
      (item?.sku?.toLowerCase().includes(searchValue) ?? false) ||
      (item?.barcode?.toLowerCase().includes(searchValue) ?? false))

  customerInfoPredicate: (item: CustomerInformationDetail, searchValue: string) => boolean = (item, searchValue) =>
    ((item?.address?.toLowerCase().includes(searchValue) ?? false) ||
      (item?.addressName?.toLowerCase().includes(searchValue) ?? false) ||
      (item?.city?.toLowerCase().includes(searchValue) ?? false) ||
      (item?.zipCode?.toLowerCase().includes(searchValue) ?? false) ||
      (item?.phone?.toLowerCase().includes(searchValue) ?? false))

  findSelectedCustomerAndRecipientInfo() {
    if (this._entity?.shippingInformation) {
      this.selectedShippingInfo = this.recipientInformation.find(info => info.id === this._entity?.shippingInformation?.id)
      this.formGroupOrder.controls['shippingInformationId'].setValue(this.selectedShippingInfo?.id)
    }
    if (this._entity?.billingInformation) {
      this.selectedBillingInfo = this.customerInformation.find(info => info.id === this._entity?.billingInformation?.id)
      this.formGroupOrder.controls['billingInformationId'].setValue(this.selectedBillingInfo?.id)
    }
  }

  orderContents(): FormArray {
    return this.formGroupOrderContent.get("orderContents") as FormArray
  }

  newOrderContent(value: SellOrderContentDetail | undefined): FormGroup {
    let selectedWarehouse = undefined

    if (this.defaultWarehouseId && !value?.id)
      selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === this.defaultWarehouseId)
    else if (value?.id && value.warehouse?.id)
      selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === value.warehouse?.id)

    let wrapper: Wrapper = {
      checked: true,
      selectedWarehouse: selectedWarehouse,
      warehouseId: selectedWarehouse?.id,
      selectedItem: value?.item,
      itemUnitPrice: value?.itemUnitPrice,
      loadingItems: false,
      disabledInput: value !== undefined,
      snapshot: this._entity.contentSnapshot?.find(snap => snap.sellOrderContentId === value?.id),
      filteredWarehouse: this.warehouses,
      filteredItems: []
    }

    if (value != undefined) {
      wrapper.warehouseItems = [value.item]
    }

    this.creationWrappers.push(wrapper)

    return this.formBuilder.group({
      id: new FormControl(value?.id ?? undefined),
      warehouse: new FormControl(selectedWarehouse ?? undefined),
      item: new FormControl(value?.item ?? undefined, [Validators.required]),
      quantity: new FormControl<number | undefined>(value?.quantity, {validators: [Validators.required, Validators.min(Constants.numerics.minForDecimalQuantities)]}),
      itemUnitPrice: new FormControl<number | undefined>(value?.itemUnitPrice ?? undefined, [Validators.required]),
      state: new FormControl<string>(value?.status ?? Constants.orderContentStatuses.notUpdated),
      checked: new FormControl<boolean>(true)
    })
  }

  addOrderContent(value?: SellOrderContentDetail) {

    this.orderContents().push(this.newOrderContent(value));

    if (this.defaultWarehouseId) {
      this.currentIndex = this.orderContents().length - 1
      this.currentWarehouseId = this.defaultWarehouseId
      this.creationWrappers[this.currentIndex].selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === this.defaultWarehouseId)
      this.creationWrappers[this.currentIndex].loadingItems = true

      this.warehouseItemsFn(this.defaultWarehouseId)
        .subscribe(itemWarehouse => this.manageOrderContentCreationWrapper(itemWarehouse))
    }

  }

  removeOrderContent(i: number) {
    let itemToRemove = this.orderContents().controls[i].value
    this.orderContents().removeAt(i)

    if (this.isEditing &&
      this._entity?.content?.find(orderItem => orderItem.item.id === itemToRemove.item.id && orderItem.warehouse?.id == itemToRemove.warehouse?.id)) {

      this._entity?.content?.splice(i, 1)
      this.orderContentToDelete.push(itemToRemove.id)
    }

    this.creationWrappers.splice(i, 1)

    this.showDuplicateMessageIfError()
  }

  getStocks(selectedValue: boolean, index: number) {

    (this.orderContents().controls[index] as FormGroup).reset();
    (this.orderContents().controls[index] as FormGroup).controls['checked'].setValue(selectedValue);
    (this.orderContents().controls[index] as FormGroup).controls['state'].setValue(Constants.orderContentStatuses.notUpdated);


    this.currentIndex = index
    this.creationWrappers[index].selectedItem = undefined
    this.creationWrappers[index].warehouseItems = []

    this.creationWrappers[index].checked = selectedValue
    // Non prelevare da magazzino
    if (!selectedValue) {
      // Svuoto l'item selezionato e la lista associata se il warehouse scelto cambia
      this.unsetSelectedWarehouse(index)

      this.creationWrappers[index].selectedWarehouse = undefined
      this.creationWrappers[index].showAutoscaleError = false


      // If I already have companyItems, I use them
      if (this.companyItems.length > 0) {
        this.creationWrappers[index].warehouseItems = this.companyItems
        this.creationWrappers[index].filteredItems = this.companyItems
      } else {
        // Else I fetch them
        this.creationWrappers[index].loadingItems = true

        this.subs.push(this.items$.subscribe(items => {
          this.companyItems = items
          if (!this.noWarehousesAvailable) {
            this.creationWrappers[this.currentIndex].warehouseItems = items
            this.creationWrappers[this.currentIndex].filteredItems = items
            this.creationWrappers[this.currentIndex].loadingItems = false
          }
        }))
      }
    } else {
      // Preleva da magazzino
      this.creationWrappers[index].selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === this.defaultWarehouseId);

      (this.orderContents().controls[index] as FormGroup).controls['warehouse'].setValue(this.creationWrappers[index].selectedWarehouse)

      if (this.creationWrappers[index].selectedWarehouse?.id) {
        this.creationWrappers[index].loadingItems = true
        this.warehouseItemsFn(this.creationWrappers[index].selectedWarehouse?.id!)
          .subscribe(itemWarehouses => this.manageOrderContentCreationWrapper(itemWarehouses))
      }
    }

    this.showDuplicateMessageIfError()
  }

  emitChosenWarehouse(warehouseId: number, index: number) {
    this.currentIndex = index
    this.currentWarehouseId = warehouseId

    this.creationWrappers[index].selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === warehouseId)

    this.creationWrappers[index].selectedItem = undefined;
    (this.orderContents().controls[index] as FormGroup).controls['item'].setValue(undefined)

    this.creationWrappers[index].warehouseItems = []
    this.creationWrappers[index].loadingItems = true

    this.showDuplicateMessageIfError()

    this.warehouseItemsFn(warehouseId)
      .subscribe(itemWarehouses => this.manageOrderContentCreationWrapper(itemWarehouses))
  }

  chosenItem(item: ItemVariantDetail, index: number) {
    this.creationWrappers[index].selectedItem = this.creationWrappers?.[index]?.checked ?
      this.creationWrappers?.[index]?.warehouseItems?.find(it => it.id === item.id) :
      this.companyItems.find(it => it.id === item.id)
    this.creationWrappers[index].itemUnitPrice = item.minimumSellNetPrice;
    (this.orderContents().controls[index] as FormGroup).controls['itemUnitPrice'].setValue(item.minimumSellNetPrice);
    (this.orderContents().controls[index] as FormGroup).controls['quantity'].setValue(1)
    this.showDuplicateMessageIfError()
    this.computeCalculatedPrice(index, 1, item.minimumSellNetPrice)
  }

  saveOrderAndContent() {
    this.savingOrderAndContent = true

    let currentOrderItems = this.orderContents().controls.map(it => it.value)
    let orderContentList: SellOrderContentCreate[] = []
    let orderContentStates: SellOrderContentState[] = []

    currentOrderItems.forEach(orderItem => {
      orderContentList.push({
        itemId: orderItem.item.id,
        warehouseId: orderItem?.warehouse?.id,
        quantity: orderItem.quantity,
        itemUnitPrice: orderItem.itemUnitPrice,
      })

      orderContentStates.push({
        itemId: orderItem.item.id,
        warehouseId: orderItem?.warehouse?.id,
        state: orderItem.state,
      })
    })


    let p = this.manageDates(this.formGroupOrder.value)


    let orderWitContent: SellOrderCreateFull = {
      ...p,
      content: orderContentList
    }

    this.createOrderWithContent({
      sellOrderFullCreate: orderWitContent,
      sellOrderContentStates: orderContentStates
    })
  }


  createOrderWithContent(sellOrderFullCreateWithStates: SellOrderFullCreateWithStates) {
    of(true)
      .pipe(
        switchMap(() => this._shopId ? this.ordersService.createSellOrderWithContentFromShop(this.userReference.companyId, this._shopId, sellOrderFullCreateWithStates.sellOrderFullCreate, this.sendEmailToClientOnCreation) :
          this.ordersService.createSellOrderWithContent(this.userReference.companyId, sellOrderFullCreateWithStates.sellOrderFullCreate, this.sendEmailToClientOnCreation)),
        switchMap((result) => {
          if ((result?.content?.length ?? 0) <= 0)
            return of(true)
          return forkJoin(result.content!.map(orderContent => {
            let currentState: OrderContentStatus = sellOrderFullCreateWithStates.sellOrderContentStates.find(it => it.itemId === orderContent.item.id && it.warehouseId === orderContent.warehouse?.id)?.state ?? 'NOT_UPDATED'

            // Se l'utente seleziona lo stato NOT_UPDATED, allora non devo aggiornare lo stato iniziale
            if (currentState === 'NOT_UPDATED')
              return of(true)

            return this._shopId ? this.ordersService.updateOrderContentStatusFromShop(this.userReference.companyId,
              this._shopId,
              result.id,
              orderContent.id,
              currentState,
              currentState === 'UPDATED',
              orderContent.warehouse?.id!
            ) : this.ordersService.updateOrderContentStatus(this.userReference.companyId,
              result.id,
              orderContent.id,
              currentState,
              currentState === 'UPDATED',
              orderContent.warehouse?.id!
            )
          }))
        }),
      ).subscribe({
      next: () => {
        if (this.dialogRef)
          this.dialogRef.close(true)
      },
      error: err => {
        if (err.status === Constants.responseStatusCode.itemWarehouseIllegalQuantityException)
          this.snackService.error("Alcuni elementi non sono stati scalati dal magazzino")
        else this.snackService.error("Impossibile creare l'ordine")

        if (this.dialogRef)
          this.dialogRef.close()
      }
    })
  }

  getCustomerInfoFn() {
    if (this.selectedCustomer) {
      this.subs.push(this.customerInfoFn(this.selectedCustomer.id, 'customer')
        .subscribe(() => this.clearSelectedCustomerInfo()))
    }

    if (this.isCustomerAlsoRecipient)
      this.getRecipientInfoFn()

  }

  getRecipientInfoFn() {
    if (this.selectedRecipient) {
      this.subs.push(this.customerInfoFn(this.selectedRecipient.id, 'recipient')
        .subscribe(() => this.clearSelectedRecipientInfo()))
    }
  }

  clearSelectedCustomerInfo() {
    this.selectedBillingInfo = undefined
    this.formGroupOrder.controls['billingInformationId'].setValue(null)

    if (this.isCustomerAlsoRecipient)
      this.clearSelectedRecipientInfo()

  }

  clearSelectedRecipientInfo() {
    this.selectedShippingInfo = undefined
    this.formGroupOrder.controls['shippingInformationId'].setValue(null)
  }

  openUpdateOrderContentSection() {
    this.loadingStocks = true

    // In modifica posso modificare solamente gli articoli con status === 'NOT_UPDATED'
    this.getStocksForAutoscale()

  }

  updateOrder() {
    this.loading = true
    this.savingOrder = true

    let p = this.manageDates(this.formGroupOrder.value)

    let sellOrder: SellOrderCreate = {
      ...p
    }

    let shopId = this.shopContext ? this._shopId : undefined

    let obs$ = shopId ?
      this.ordersService.updateOrderFromShop(this.userReference.companyId, shopId, this._entity!.id, sellOrder) :
      this.ordersService.updateOrder(this.userReference.companyId, this._entity!.id, sellOrder);

    this.subs.push(obs$.subscribe(
      {
        next: (updatedOrder) => {
          this._entity = updatedOrder
          this.formGroupOrder.reset()
          this.formGroupOrderContent.reset()

          this.formGroupOrder.patchValue(updatedOrder)


          this.saving = false
          this.loading = false
          this.snackService.success("Ordine aggiornato")
          if (this.dialogRef)
            this.dialogRef.close(updatedOrder)

        },
        error: () => {
          this.loading = false
          this.snackService.error('Impossibile aggiornare l\'ordine')
        }

      }))
  }

  updateOrderContentPre() {
    this.savingOrderContent = true

    let currentOrderItems = this.orderContents().controls.map(it => it.value)

    let sellOrderStates: SellOrderContentState[] = []

    let sellOrderContentToUpdate: SellOrderContentCreateWithOrderContentId[] = []
    let sellOrderContentToCreate: SellOrderContentCreate[] = []

    currentOrderItems.forEach(coi => {
      let found = this._entity?.content?.find(sc => sc.item.id === coi.item.id && (sc?.warehouse?.id === coi?.warehouse?.id ||
        (sc?.warehouse === undefined && coi?.warehouse === undefined)))
      if (found) {
        if (found.status === this.orderContentStatuses.notUpdated) {
          let orderContent: SellOrderContentCreateWithOrderContentId = {
            orderContentId: found.id,
            itemVariantId: coi?.item?.id,
            warehouseId: coi?.warehouse?.id,
            quantity: coi?.quantity,
            itemUnitPrice: coi?.itemUnitPrice
          }
          sellOrderContentToUpdate.push(orderContent)
        }
      } else {
        let orderContent: SellOrderContentCreate = {
          itemId: coi?.item.id,
          warehouseId: coi?.warehouse?.id,
          quantity: coi?.quantity,
          itemUnitPrice: coi?.itemUnitPrice
        }
        sellOrderContentToCreate.push(orderContent)
      }
    })

    currentOrderItems.forEach(saleItem => {
      sellOrderStates.push({
        itemId: saleItem.item.id,
        warehouseId: saleItem?.warehouse?.id,
        state: saleItem.state,
      })
    })

    let orderContentUpdateStates: OrderContentUpdateWithStates = {
      orderContentToCreate: sellOrderContentToCreate,
      orderContentToUpdate: sellOrderContentToUpdate,
      orderContentToDelete: this.orderContentToDelete,
      orderContentStates: sellOrderStates
    }
    this.updateOrderContent(orderContentUpdateStates)
  }

  showDuplicateMessageIfError() {
    this.showDuplicateContentMessage = false
    let currentOrderItems = this.orderContents().controls.map(it => it.value)

    if (currentOrderItems.length > 1) {
      let isDuplicate = false

      currentOrderItems.forEach(orderItem => {
        isDuplicate = isDuplicate || currentOrderItems.filter(it => {
          if (it.item === undefined || orderItem.item === undefined)
            return false
          return it.item.id === orderItem.item.id && it.warehouse?.id == orderItem.warehouse?.id
        }).length > 1

      })
      this.showDuplicateContentMessage = isDuplicate
    }
  }

  canAutoscaleOnOrderClose() {

    this.closingWrappers.forEach(
      (value, index) => {

        // Se non devo mostrare la sezione 'manage warehouse' resetto showAutoscaleErrorMessage
        if (this.showManageWarehouseButton(this.selectedStatus))
          this.closingWrappers[index].showAutoscaleError = false

        if (value.selectedStatus !== "UPDATED") {
          this.closingWrappers[index].showAutoscaleError = false
        } else {

          let itemWarehouse = this.currentWarehouseStocks.find(wid => wid.warehouseId === value.warehouseId)
          let warehouseQty = itemWarehouse?.itemWarehouse.find(iw => iw.item.id === value.itemId)?.quantity ?? 0

          if (warehouseQty < value.quantity) {
            this.closingWrappers[index].autoscaleError = value?.unit ?
              `Stai cercando di prelevare ${value.quantity} ${value?.unit} di "${value?.name}" dal magazzino, ma dalle giacenze ne risultano ${warehouseQty}` :
              `Stai cercando di prelevare ${value.quantity} unità dell'articolo "${value?.name}" dal magazzino, ma dalle giacenze ne risultano ${warehouseQty}`
            this.closingWrappers[index].showAutoscaleError = true
          } else {
            this.closingWrappers[index].showAutoscaleError = false
          }
        }
      })
  }

  checkIfCanAutoscale(index: number, formQty?: number) {

    if ((this.orderContents().controls[index] as FormGroup).controls['state'].value !== 'UPDATED') {
      this.creationWrappers[index].showAutoscaleError = false
      this.creationWrappers[index].autoscaleError = ''
      return
    }

    let currentQty = formQty !== undefined ? formQty : +(this.orderContents().controls[index] as FormGroup).controls['quantity'].value

    let currentItem = this.creationWrappers[index].selectedItem
    let itemWarehouse: ItemWarehouseDetail[]
    if (!this.isEditing ||
      this.currentWarehouseStocks
        .find(value => value.warehouseId === this.creationWrappers[index].selectedWarehouse?.id)?.itemWarehouse === undefined) {
      itemWarehouse = this.creationWrappers[index].warehouseDetails ?? []
    } else {
      itemWarehouse = this.currentWarehouseStocks
          .find(value => value.warehouseId === this.creationWrappers[index].selectedWarehouse?.id)?.itemWarehouse
        ?? []
    }

    let warehouseQty = itemWarehouse?.find(value => value.item.id === currentItem?.id)?.quantity ?? 0
    if (warehouseQty < currentQty) {
      this.creationWrappers[index].autoscaleError = currentItem?.unit ?
        `Stai cercando di prelevare ${currentQty} ${currentItem?.unit} di "${currentItem?.name}" dal magazzino, ma dalle giacenze ne risultano ${warehouseQty}` :
        `Stai cercando di prelevare ${currentQty} unità dell'articolo "${currentItem?.name}" dal magazzino, ma dalle giacenze ne risultano ${warehouseQty}`

      this.creationWrappers[index].showAutoscaleError = true
    } else {
      this.creationWrappers[index].showAutoscaleError = false
      this.creationWrappers[index].autoscaleError = ''
    }
  }

  computeCalculatedPrice(i?: number, formQty?: number, itemUnitPrice?: number) {
    let currentSaleItems = this.orderContents().controls.map(it => it.value)

    this.calculatedPrice = 0

    if (i)
      this.creationWrappers[i].itemUnitPrice = itemUnitPrice

    currentSaleItems.forEach((saleItem, index) =>
      this.calculatedPrice += (index === i && formQty ? formQty : saleItem.quantity) * (index === i && itemUnitPrice ? itemUnitPrice : saleItem.itemUnitPrice)
    )

    this.displayPriceChangedMessage()
  }

  displayPriceChangedMessage() {
    this.showPriceChangedMessage = !!(this.isEditing && this._entity.finalPrice && this._entity.calculatedPrice !== this.calculatedPrice);
  }

  openManageSellOrderStatusSection() {
    this._managingSellOrderStatus = true

    // Prendo le giacenze dei magazzini degli articoli con stato NOT_UPDATE
    let list = this._entity?.content?.filter(
      value => value.warehouse != undefined && value.status === this.orderContentStatuses.notUpdated) ?? []

    forkJoin(list.map(value => value?.warehouse?.id ??

      this.subs.push(this.warehouseItemsFn(value.warehouse!.id)
        .subscribe(itemWarehouses => {
          this.currentWarehouseStocks.push({
            warehouseId: value.warehouse!.id,
            itemWarehouse: itemWarehouses
          })
        }))
    )).subscribe(() => {
      this.loadingStocks = false
      this.updatingOrderContent = true
    })


  }

  canTransition(appliedStatus: string) {

    let canTransition = false


    switch (appliedStatus) {
      case this.orderStatuses.deliveringRider:
      case this.orderStatuses.deliveringCourier:
      case this.orderStatuses.deliveryFailedRider:
      case this.orderStatuses.deliveryFailedCourier:
      case this.orderStatuses.deliveredRider:
      case this.orderStatuses.deliveredCourier:
      case this.orderStatuses.pickedUp:
      case this.orderStatuses.completed:
      case this.orderStatuses.failed:
        this.errorToShow = ''
        canTransition = true
        break;

      case this.orderStatuses.readyForPickupShop:
        canTransition = !!this._entity?.shopId
        if (!canTransition)
          this.errorToShow = 'Devi prima assegnare un punto vendita'
        break;
      case this.orderStatuses.inProgress:
        if (this._entity?.status !== this.orderStatuses.inProgress) {
          canTransition = !!this._entity?.shopId
          if (!canTransition)
            this.errorToShow = 'Devi prima assegnare un punto vendita'
        }
        break;

      case this.orderStatuses.readyForPickupRider:
      case this.orderStatuses.readyForPickupCourier:

        canTransition = !!this._entity?.deliveryCompany
        if (!canTransition)
          this.errorToShow = 'Devi prima scegliere il corriere'
    }
    return canTransition;
  }

  applyStatusFn(selectedStatus: string) {
    this.savingStatus = true
    if (!this.isOrderFailed) {
      let orderContents: SellOrderContentUpdateStatus[] = []
      this.closingWrappers.forEach((value => orderContents.push({
        sellOrderContentId: value.sellOrderContentId,
        state: value.selectedStatus!,
        warehouseId: value.warehouseId
      })))
      this.applyStatus({orderStatus: selectedStatus, orderContents: orderContents})
    } else {
      //  se annullo l'ordine devo gestire il rollback
      let orderContents: SellOrderContentCancel[] = []
      this.closingWrappers.forEach(value => orderContents.push({
        warehouseId: value.warehouseId,
        sellOrderContentId: value.sellOrderContentId,
        autoRollback: value.selectedRollback?.value ?? false
      }))

      this.cancelOrder(orderContents)
    }
  }

  cancelOrder(sellOrderContentCancels: SellOrderContentCancel[]) {

    let shopId = this.shopContext ? this._shopId : this._entity?.shopId ?? undefined

    of(true)
      .pipe(
        switchMap(() => {
          if (sellOrderContentCancels.length <= 0)
            return of(true)


          return forkJoin(sellOrderContentCancels.map(value => {
            return shopId ? this.ordersService.updateOrderContentStatusFromShop(this.userReference.companyId, shopId, this._entity!.id, value.sellOrderContentId, 'NOT_UPDATED', value.autoRollback, value.warehouseId) :
              this.ordersService.updateOrderContentStatus(this.userReference.companyId, this._entity!.id, value.sellOrderContentId, 'NOT_UPDATED', value.autoRollback, value.warehouseId)
          }))
        }),
        switchMap(() => shopId ?
          this.ordersService.updateOrderStatusFromShop(this.userReference.companyId, shopId, this._entity!.id, 'FAILED') :
          this.ordersService.updateOrderStatus(this.userReference.companyId, this._entity!.id, 'FAILED')
        ),
      ).subscribe({
      next: () => {
        this._entity = {} as SellOrderDetail
        this.snackService.success("Stato dell'ordine aggiornato")

        if (this.dialogRef)
          this.dialogRef.close(true)
      },
      error: (err) => {
        this._entity = {} as SellOrderDetail
        this.snackService.error("Impossibile aggiornare lo stato dell'ordine")
        if (this.dialogRef)
          this.dialogRef.close()

      }
    })
  }

  isApplyStatusButtonDisabled() {
    return this.selectedStatus === this._entity?.status ||
      !this.canTransition(this.selectedStatus)
  }

  isApplyPaymentStatusButtonDisabled() {
    return this.selectedPaymentStatus === this._entity?.paymentStatus || !this.selectedPaymentStatus
  }

  showManageWarehouseButton(status: string) {
    if (!this._entity?.content || this._entity?.content.length < 1)
      return false

    switch (status) {
      case Constants.orderStatuses.readyForPickupShop:
        return (!this.hasManagedWarehouses())

      case Constants.orderStatuses.readyForPickupRider:
      case Constants.orderStatuses.readyForPickupCourier:
        if (this._entity?.deliveryCompany)
          return !this.hasManagedWarehouses()
        return false
      // Qui non la nego perché la uso al contrario
      case Constants.orderStatuses.failed:
        if (this._entity?.status !== Constants.orderStatuses.failed)
          return !this.hasNotManagedWarehouses()
        break;

      case Constants.orderStatuses.completed:
        if (this._entity?.status !== Constants.orderStatuses.completed)
          return !this.hasManagedWarehouses()
    }

    return false

  }

  // Ritorna false se c'è un articolo non gestito
  onStatusChange() {
    this.isOrderFailed = this.selectedStatus === Constants.orderStatuses.failed
    this.closingWrappers = []

    if (this.showManageWarehouseButton(this.selectedStatus)) {
      this.itemsToHandle = this._entity?.content?.filter(value => value.warehouse != undefined && ((!this.isOrderFailed && value.status === this.orderContentStatuses.notUpdated) || (this.isOrderFailed && value.status !== this.orderContentStatuses.notUpdated))) ?? []
      this.itemsToHandle.forEach(value => this.closingWrappers.push({
        itemId: value.item.id,
        warehouseId: value.warehouse!.id,
        selectedStatus: !this.isOrderFailed ? this.generalContentStatus : undefined,
        selectedRollback: this.isOrderFailed ? this.generalContentRollback : undefined,
        sellOrderContentId: value.id,
        name: value.item.name,
        quantity: value.quantity,
        unit: value.item.unit
      }))
    }
  }

  hasManagedWarehouses() {
    // if (this._entity?.content && this._entity?.content.length == 0)
    //   return true
    return !(this._entity?.content?.find(value => value.warehouse?.id != null && value.status == this.orderContentStatuses.notUpdated) != undefined)
  }

  // Ritorna false se c'è un articolo gestito
  hasNotManagedWarehouses() {
    // if (this._entity?.content && this._entity?.content.length == 0)
    //   return true
    return !(this._entity?.content?.find(value => value.warehouse?.id != null && value.status != this.orderContentStatuses.notUpdated) != undefined)
  }

  updateContentStatusAndCheckErrorsOnOrderClose() {
    if (!this.isOrderFailed) {
      this.closingWrappers.forEach(value => value.selectedStatus = this.generalContentStatus)
      this.canAutoscaleOnOrderClose()
      this.setGlobalErrorOnOrderClose()
    } else {
      this.closingWrappers.forEach(value => value.selectedRollback = this.generalContentRollback)
    }
  }

  setGlobalErrorOnOrderClose() {
    this.autoscaleErrorOnSellOrderClose = this.closingWrappers.filter(value => value.showAutoscaleError).length > 0
  }

  setGeneralStatus(event: MatSelectChange) {
    this.generalContentStatus = event.value
    this.closingWrappers.forEach(wrapper => wrapper.selectedStatus = this.generalContentStatus)
  }

  setGeneralRollback(event: MatSelectChange) {
    this.generalContentRollback = event.value
    this.closingWrappers.forEach(wrapper => wrapper.selectedRollback = this.generalContentRollback)
  }

  generateCustomerInfoTooltip(info: CustomerInformationDetail | null | undefined) {
    if (!info) return ''
    return `Recapito: ${info.addressName}\nIndirizzo: ${info.address}, ${info.city}, ${info.zipCode}\nTelefono: ${info.phone}`
  }

  generateCustomerTooltip(customer: CustomerData | null | undefined) {
    if (!customer) return ''
    return `Nome: ${customer.name}\nEmail: ${customer.email}\nTelefono: ${customer.phone}`
  }

  manageShopFlag($event: MatSelectChange) {

    if ($event.value)
      this.selectedShop = this.shops.find(shop => shop.id === $event.value)

    this.showShopCheckbox = !$event.value
    this.formGroupOrder.controls["mandatoryShop"].setValue(false)
    this.mandatoryShop = false
  }

  setGlobalAutoscaleDropdown() {
    let matchingStatus = this.closingWrappers[0].selectedStatus
    if (this.closingWrappers.every(value => value.selectedStatus === matchingStatus))
      this.generalContentStatus = matchingStatus
    else
      this.generalContentStatus = undefined
  }

  setGlobalRollbackDropdown() {
    let matchingStatus = this.closingWrappers[0]?.selectedRollback
    if (this.closingWrappers.every(value => value.selectedRollback?.name === matchingStatus?.name))
      this.generalContentRollback = matchingStatus
    else
      this.generalContentRollback = undefined
  }

  openCustomerForm(formControlName: 'customerId' | 'recipientId') {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Cliente',
        componentData: {companyId: this.userReference.companyId},
        component: CustomersFormComponent
      }
    })
      .afterClosed()
      .pipe(
        switchMap((createdCustomer: CustomerData) => {
          if (createdCustomer?.id) {

            this.customersAndRecipients.push(createdCustomer)

            if (!this.isCustomerAlsoRecipient && formControlName == "customerId") {
              this.clearSelectedCustomerInfo()
              this.selectedCustomer = createdCustomer
              this.formGroupOrder.controls["customerId"].setValue(createdCustomer.id)
            } else if (!this.isCustomerAlsoRecipient && formControlName == "recipientId") {
              this.clearSelectedRecipientInfo()
              this.selectedRecipient = createdCustomer
              this.formGroupOrder.controls["recipientId"].setValue(createdCustomer.id)
            } else {
              this.selectedCustomer = createdCustomer
              this.selectedRecipient = createdCustomer
              this.clearSelectedCustomerInfo()
              this.clearSelectedRecipientInfo()
              this.formGroupOrder.controls["recipientId"].setValue(createdCustomer.id)
              this.formGroupOrder.controls["customerId"].setValue(createdCustomer.id)
            }
            return of(createdCustomer)
          }
          return NEVER
        })
      )
      .subscribe({
        next: () => this.snackService.success('Cliente creato'),
        error: (error) => this.snackService.error(error.message),
      })

  }

  openCustomerInfoForm(formControlName: 'billingInformationId' | 'shippingInformationId') {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Informazioni Cliente',
        componentData: {
          companyId: this.userReference.companyId,
          customerId: formControlName === 'billingInformationId' ? this.selectedCustomer?.id : this.selectedRecipient?.id
        },
        component: CustomersInfoFormComponent
      }
    })
      .afterClosed()
      .pipe(
        switchMap((createdCustomerInfo: CustomerInformationDetail) => {

          if (createdCustomerInfo) {

            if (this.isCustomerAlsoRecipient) {

              this.customerInformation.push(createdCustomerInfo)
              this.recipientInformation.push(createdCustomerInfo)


              this.filteredShippingInformation = [...this.customerInformation]
              this.filteredBillingInformation = [...this.recipientInformation]

              if (formControlName == 'billingInformationId')
                this.selectedBillingInfo = createdCustomerInfo;
              else
                this.selectedShippingInfo = createdCustomerInfo;

            } else {

              if (formControlName == 'billingInformationId') {
                this.customerInformation.push(createdCustomerInfo)
                this.filteredBillingInformation.push(createdCustomerInfo)
                this.selectedBillingInfo = createdCustomerInfo;
              } else {
                this.recipientInformation.push(createdCustomerInfo)
                this.filteredShippingInformation.push(createdCustomerInfo)
                this.selectedShippingInfo = createdCustomerInfo;
              }
            }

            this.formGroupOrder.controls[formControlName].setValue(createdCustomerInfo.id)
            this.formGroupOrder.markAsDirty()
            return of(createdCustomerInfo)
          }
          return NEVER

        })
      )
      .subscribe({
        next: () => this.snackService.success('Informazioni cliente create'),
        error: (error) => this.snackService.error(error.message),
      })

  }

  openDeliveryCompanyForm() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Corriere',
        componentData: {companyId: this.userReference.companyId},
        component: DeliveryCompaniesFormComponent
      }
    })
      .afterClosed()
      .pipe(
        switchMap((createdDeliveryCompany: DeliveryCompanyData) => {
          if (createdDeliveryCompany?.id) {
            this.deliveryCompanies.push(createdDeliveryCompany)
            this.selectedDeliveryCompany = createdDeliveryCompany
            this.formGroupOrder.controls["deliveryCompanyId"].setValue(createdDeliveryCompany.id)
            return of(createdDeliveryCompany)
          }
          return NEVER
        })
      )
      .subscribe({
        next: () => this.snackService.success('Corriere creato'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  openDeliveryTimeSlotForm() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Fascia oraria',
        componentData: {companyId: this.userReference.companyId},
        component: DeliveryTimeSlotsFormComponent
      }
    })
      .afterClosed()
      .pipe(
        switchMap((createdDeliveryTimeSlot: DeliveryTimeSlotData) => {
          if (createdDeliveryTimeSlot?.id) {
            this.deliveryTimeSlots.push(createdDeliveryTimeSlot)
            this.selectedDeliveryTimeSlot = createdDeliveryTimeSlot
            this.formGroupOrder.controls["deliveryTimeSlotId"].setValue(createdDeliveryTimeSlot.id)
            return of(createdDeliveryTimeSlot)
          }
          return NEVER
        })
      )
      .subscribe({
        next: () => this.snackService.success('Fascia oraria creata'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  applyStatus(sellOrderContentClose: SellOrderContentClose) {

    let shopId = this.shopContext ? this._shopId : this._entity?.shopId ?? undefined

    of(true)
      .pipe(
        switchMap(() => {
          if (sellOrderContentClose.orderContents.length <= 0)
            return of(true)

          return forkJoin(sellOrderContentClose.orderContents.map(value => {
            return shopId ?
              this.ordersService.updateOrderContentStatusFromShop(this.userReference.companyId, shopId, this._entity!.id, value.sellOrderContentId, value.state, value.state === 'UPDATED', value.warehouseId) :
              this.ordersService.updateOrderContentStatus(this.userReference.companyId, this._entity!.id, value.sellOrderContentId, value.state, value.state === 'UPDATED', value.warehouseId)
          }))
        }),
        switchMap(() => shopId ?
          this.ordersService.updateOrderStatusFromShop(this.userReference.companyId, shopId, this._entity!.id, sellOrderContentClose.orderStatus, this.sendEmailToClientOnStatusChange) :
          this.ordersService.updateOrderStatus(this.userReference.companyId, this._entity!.id, sellOrderContentClose.orderStatus, this.sendEmailToClientOnStatusChange)
        ),
      ).subscribe({
      next: () => {
        this._entity = {} as SellOrderDetail
        this.snackService.success("Stato dell'ordine aggiornato")
        if (this.dialogRef)
          this.dialogRef.close(true)
      },
      error: (err) => {
        this._entity = {} as SellOrderDetail
        this.snackService.error("Impossibile aggiornare lo stato dell'ordine")
      }
    })
  }

  getPanelTitle(index: number, content: any) {

    let useSnapshot = this.creationWrappers[index].snapshot != undefined

    let snapshot = this.creationWrappers[index].snapshot!

    let itemName = useSnapshot ? snapshot.variantItemName : this.creationWrappers?.[index]?.selectedItem?.name ?? "";

    let itemQuantity = useSnapshot ? snapshot.quantity : !!this.creationWrappers?.[index]?.selectedItem?.name ? ' x' + (content.value.quantity != null ? content.value.quantity : '?') : ' ';

    let warehouseName = useSnapshot ? snapshot.warehouseName ?? " " : !!this.creationWrappers?.[index]?.selectedWarehouse?.name ? `da ${this.creationWrappers?.[index]?.selectedWarehouse?.name}` : ' ';

    let stockStatus = this.orderContentStatusPipe.transform(content.value.state) != undefined ? `[${this.orderContentStatusPipe.transform(content.value.state)}]` : ""

    let panelTitle = `${itemName} ${itemQuantity} ${warehouseName} ${stockStatus}`

    if (panelTitle.trim() === "")
      return "Nuovo articolo"

    return panelTitle

  }

  getSelectedItem(index: number) {
    return !!this.creationWrappers?.[index]?.selectedItem
  }

  updateOrderContent(orderContentUpdate: OrderContentUpdateWithStates) {

    let shopId = this.shopContext ? this._shopId : this._entity?.shopId ?? undefined

    of(true)
      .pipe(
        switchMap(() => {
          // Elimino gli articoli rimossi
          if (orderContentUpdate.orderContentToDelete.length <= 0)
            return of(true)

          return forkJoin(orderContentUpdate.orderContentToDelete.map((socId) => {
            return shopId ? this.ordersService.deleteSellOrderContentFromShop(this.userReference.companyId, shopId, this._entity!.id, socId) :
              this.ordersService.deleteSellOrderContent(this.userReference.companyId, this._entity!.id, socId)
          }))
        }),
        switchMap(() => {
          // Aggiungo gli articoli nuovi
          if (orderContentUpdate.orderContentToCreate.length > 0)
            return shopId ? this.ordersService.createOrderContentFromShop(this.userReference.companyId, shopId, this._entity!.id, orderContentUpdate.orderContentToCreate) :
              this.ordersService.createOrderContent(this.userReference.companyId, this._entity!.id, orderContentUpdate.orderContentToCreate)
          else return of([])
        }),
        // Aggiorno lo stato degli item appena inseriti
        switchMap((result) => {

          if (result?.length <= 0)
            return of(true)

          return forkJoin(result.map(orderContent => {
            let currentState: OrderContentStatus = orderContentUpdate.orderContentStates.find(it => it.itemId === orderContent.item.id && it.warehouseId === orderContent.warehouse?.id)?.state ?? 'NOT_UPDATED'

            // Se l'utente seleziona lo stato NOT_UPDATED, allora non devo aggiornare lo stato iniziale
            if (currentState === 'NOT_UPDATED')
              return of(true)

            return shopId ? this.ordersService.updateOrderContentStatusFromShop(this.userReference.companyId,
              shopId,
              this._entity!.id,
              orderContent.id,
              currentState,
              currentState === 'UPDATED',
              orderContent.warehouse?.id!
            ) : this.ordersService.updateOrderContentStatus(this.userReference.companyId,
              this._entity!.id,
              orderContent.id,
              currentState,
              currentState === 'UPDATED',
              orderContent.warehouse?.id!
            )
          }))
        }),

        switchMap(() => {
          // Aggiorno gli articoli modificati
          if (orderContentUpdate.orderContentToUpdate.length <= 0)
            return of(true)

          return forkJoin(orderContentUpdate.orderContentToUpdate.map(
            scu => shopId ? this.ordersService.updateOrderContentFromShop(this.userReference.companyId, shopId, this._entity!.id, scu.orderContentId, scu) :
              this.ordersService.updateOrderContent(this.userReference.companyId, this._entity!.id, scu.orderContentId, scu)))
        }),
        // Aggiorno lo stato degli item aggiornati dall'utente
        switchMap(() => {

          if (orderContentUpdate.orderContentToUpdate.length <= 0)
            return of(true)

          return forkJoin(orderContentUpdate.orderContentToUpdate.map(scu => {

            let currentState: OrderContentStatus = orderContentUpdate.orderContentStates.find(it => it.itemId === scu.itemVariantId && it.warehouseId === scu.warehouseId)?.state ?? 'NOT_UPDATED'

            // Se l'utente seleziona lo stato NOT_UPDATED, allora non devo aggiornare lo stato iniziale
            if (currentState === 'NOT_UPDATED')
              return of(true)

            return shopId ? this.ordersService.updateOrderContentStatusFromShop(this.userReference.companyId,
                shopId,
                this._entity!.id,
                scu.orderContentId,
                currentState,
                currentState === 'UPDATED',
                scu.warehouseId!) :
              this.ordersService.updateOrderContentStatus(this.userReference.companyId,
                this._entity!.id,
                scu.orderContentId,
                currentState,
                currentState === 'UPDATED',
                scu.warehouseId!)
          }))
        })
      )
      .subscribe({
        next: () => {
          this.snackService.success("Contenuto dell'ordine aggiornato")
          this._entity = {} as SellOrderDetail
          if (this.dialogRef)
            this.dialogRef.close(true)
        },
        error: err => {
          if (err.status === Constants.responseStatusCode.itemWarehouseIllegalQuantityException) {
            this.snackService.success("Alcuni elementi non sono stati scalati dal magazzino")
          } else {
            this.snackService.success("Impossibile aggiornare il contenuto dell'ordine")
          }
          this._entity = {} as SellOrderDetail
          if (this.dialogRef)
            this.dialogRef.close()
        }
      })

  }

  setMandatoryShop($event: MatCheckboxChange) {
    this.mandatoryShop = $event.checked
    // Resetting flag to avoid sending email even if mandatoryShop is true
    this.sendEmailToClientOnCreation = false
    this.formGroupOrder.controls["mandatoryShop"].setValue($event.checked)
  }

  setSelectedCustomer($event: MatSelectChange) {
    this.selectedCustomer = this.customersAndRecipients.find(customer => customer.id == $event.value)

    if (this.isCustomerAlsoRecipient) {
      this.setSelectedRecipient($event)
      this.formGroupOrder.controls["recipientId"].setValue(this.selectedRecipient?.id)
    }
  }

  setSelectedRecipient($event: MatSelectChange) {
    this.selectedRecipient = this.customersAndRecipients.find(customer => customer.id == $event.value)
  }

  setSelectedShippingInfo($event: MatSelectChange) {
    this.selectedShippingInfo = this.customerInformation.find(customerInfo => customerInfo.id == $event.value)
  }

  setSelectedBillingInfo($event: MatSelectChange) {
    this.selectedBillingInfo = this.customerInformation.find(customerInfo => customerInfo.id == $event.value)
  }

  setSelectedDeliveryCompany($event: MatSelectChange) {
    this.selectedDeliveryCompany = this.deliveryCompanies.find(deliveryCompany => deliveryCompany.id == $event.value)
  }

  setSelectedDeliveryTimeSlot($event: MatSelectChange) {
    this.selectedDeliveryTimeSlot = this.deliveryTimeSlots.find(deliveryTimeSlot => deliveryTimeSlot.id == $event.value)
  }

  unsetShop() {
    this.showShopCheckbox = true;
    this.selectedShop = undefined;
    this.formGroupOrder.controls["shopId"].setValue(null)
    this.formGroupOrder.markAsDirty()
  }

  canEditContent() {
    return (this._entity.status === Constants.orderStatuses.toBeAssigned || this._entity.status === Constants.orderStatuses.inProgress)
  }

  unsetSelectedCustomer() {
    this.selectedCustomer = undefined;
    this.formGroupOrder.controls["customerId"].setValue(null)
    this.unsetSelectedCustomerBillingInfo()

    if (this.isCustomerAlsoRecipient)
      this.unsetSelectedRecipient()
    this.formGroupOrder.markAsDirty()
  }

  unsetSelectedRecipient() {
    this.selectedRecipient = undefined;
    this.formGroupOrder.controls["recipientId"].setValue(null)
    this.unsetSelectedCustomerShippingInfo()
    this.formGroupOrder.markAsDirty()
  }

  unsetSelectedCustomerShippingInfo() {
    this.selectedShippingInfo = undefined;
    this.formGroupOrder.controls["shippingInformationId"].setValue(null)
    this.formGroupOrder.markAsDirty()
  }

  unsetSelectedCustomerBillingInfo() {
    this.selectedBillingInfo = undefined;
    this.formGroupOrder.controls["billingInformationId"].setValue(null)
    this.formGroupOrder.markAsDirty()
  }

  setSelectedDeliveryOption(deliveryOption: DeliveryOption) {
    this.selectedDeliveryOption = deliveryOption;
    this.formGroupOrder.controls["deliveryOption"].setValue(deliveryOption)

    if (deliveryOption == this.availableDeliveryOptions.shop)
      this.unsetSelectedDeliveryCompany()

    this.formGroupOrder.markAsDirty()
  }
  unsetSelectedDeliveryOption() {
    this.selectedDeliveryOption = undefined;
    this.formGroupOrder.controls["deliveryOption"].setValue(null)
    this.formGroupOrder.markAsDirty()
  }

  unsetSelectedDeliveryCompany() {
    this.selectedDeliveryCompany = undefined;
    this.formGroupOrder.controls["deliveryCompanyId"].setValue(null)
    this.formGroupOrder.markAsDirty()
  }

  unsetSelectedDeliveryTimeSlot() {
    this.selectedDeliveryTimeSlot = undefined;
    this.formGroupOrder.controls["deliveryTimeSlotId"].setValue(null)
    this.formGroupOrder.controls["deliveryNotes"].setValue(null)
    this.formGroupOrder.markAsDirty()
  }


  unsetSelectedWarehouse(index: number) {
    this.creationWrappers[index].selectedWarehouse = undefined
    this.creationWrappers[index].warehouseId = undefined;
    (this.orderContents().controls[index] as FormGroup).controls['warehouse'].setValue(null)
    this.unsetSelectedItem(index)
    this.formGroupOrder.markAsDirty()
  }

  unsetSelectedItem(index: number) {
    this.creationWrappers[index].selectedItem = undefined;
    this.creationWrappers[index].itemUnitPrice = undefined;
    (this.orderContents().controls[index] as FormGroup).controls['item'].setValue(null);
    (this.orderContents().controls[index] as FormGroup).controls['itemUnitPrice'].setValue(null);
    (this.orderContents().controls[index] as FormGroup).controls['quantity'].setValue(null);
  }

  unsetGeneralContentStatus() {
    this.generalContentStatus = undefined
    this.setGlobalAutoscaleDropdown()
  }

  unsetGeneralContentRollback() {
    this.generalContentRollback = undefined
    this.setGlobalRollbackDropdown()
  }

  unsetAllContentStatus() {
    this.closingWrappers.forEach(value => value.selectedStatus = undefined)
  }

  unsetAllContentRollback() {
    this.closingWrappers.forEach(value => value.selectedRollback = undefined)
  }

  setSelectedOrderStatus($event: MatSelectChange) {
    this.selectedStatus = $event.value
  }

  setSelectedPaymentStatus($event: MatSelectChange) {
    this.selectedPaymentStatus = $event.value
    this.formGroupOrder.controls['paymentStatus'].setValue($event.value)

  }

  setContentStatus($event: MatSelectChange, index: number) {
    this.closingWrappers[index].selectedStatus = this.updatedStates.find(state => state === $event.value) as OrderContentStatus
  }

  resetPaidPrice() {
    this.formGroupOrder.controls['paidPrice'].setValue(this._entity.paidPrice)
  }

  setCustomerPhoto(photo: boolean) {
    this.formGroupOrder.controls['photo'].setValue(photo)
  }

  setIsCustomerEqualsToRecipient($event: MatCheckboxChange) {
    this.isCustomerAlsoRecipient = $event.checked;

    if (this.isCustomerAlsoRecipient) {
      this.selectedRecipient = this.selectedCustomer
      this.formGroupOrder.controls["recipientId"].setValue(this.selectedRecipient?.id)
      this.getRecipientInfoFn()
    } else {
      this.unsetSelectedRecipient()
    }

  }

  // Trovare un modo per salvare la data in UTC e riutilizzarla correttamente ovunque nel mondo senza fare sti giri
  /**
   * Manages dates in order to avoid to change days (because it tends to subtract 1 hour and switch the day to yesterday. This breaks date search.)
   * @param p the entity
   * @private
   */
  private manageDates(p: any) {
    if (p.deadlineDate) {
      p.deadlineDate = new Date(p.deadlineDate);
      p.deadlineDate.setHours(12)
    }

    if (p.deliveryDate) {
      p.deliveryDate = new Date(p.deliveryDate);
      p.deliveryDate.setHours(12)
    }

    return p
  }

  private getStocksForAutoscale() {

    let list = this._entity?.content?.filter(
      value => value.warehouse != undefined && value.status === this.orderContentStatuses.notUpdated) ?? []


    if (list.length != 0) {
      forkJoin(
        list.map(value => value?.warehouse?.id ?
          this.subs.push(
            this.warehouseItemsFn(value.warehouse!.id)
              .pipe(
                tap(itemWarehouses => {
                    this.currentWarehouseStocks.push({
                      warehouseId: value.warehouse!.id,
                      itemWarehouse: itemWarehouses
                    })
                    this.loadingStocks = false
                    this.updatingOrderContent = true

                  }
                )
              )
              .subscribe(() => {
              })) : NEVER))
    }

    this.updatingOrderContent = true

  }

  private manageOrderContentCreationWrapper(itemWarehouses: ItemWarehouseDetail[]) {
    this.creationWrappers[this.currentIndex].warehouseId = this.currentWarehouseId
    this.creationWrappers[this.currentIndex].warehouseDetails = itemWarehouses
    this.creationWrappers[this.currentIndex].warehouseItems = itemWarehouses.map(it => it.item)
    this.creationWrappers[this.currentIndex].filteredItems = itemWarehouses.map(it => it.item)
    this.creationWrappers[this.currentIndex].loadingItems = false
  }

  private manageReadonly() {
    this.readOnlyOrder = !hasPermission(this.userReference.user, {
      accessEntity: this.accessEntities.sellOrderShops,
      accessLevel: this.accessLevels.editor
    })

    this.readOnlyContent = this.readOnlyOrder && !this.canEditContent()

  }

  private computeIsCustomerEqualsToRecipient() {
    // need to check only in editing mode
    this.isCustomerAlsoRecipient = this.isEditing && this._entity.customerId === this._entity.recipientId

  }
}

// Used for presentation of content creation
export interface Wrapper {
  checked?: boolean
  loadingItems?: boolean
  disabledInput?: boolean
  selectedItem?: ItemVariantDetail
  selectedWarehouse?: WarehouseData
  itemUnitPrice?: number
  warehouseId?: number
  warehouseDetails?: ItemWarehouseDetail[]
  warehouseItems?: ItemVariantDetail[]
  showAutoscaleError?: boolean
  autoscaleError?: string
  snapshot?: SellOrderContentSnapshotData

  filteredWarehouse: any[]

  filteredItems: any[]
}

// Used when you're transitioning to a status that requires stocks management
export interface ClosingWrapper {
  unit?: string
  quantity: number
  name: string
  autoscaleError?: string
  showAutoscaleError?: boolean
  itemId: number
  warehouseId: number
  selectedStatus?: OrderContentStatus
  sellOrderContentId: number
  selectedRollback?: RollbackStatus
}
