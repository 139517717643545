<div class="p-1">
  <div class="grid justify-content-center p-0">

    <ng-container *ngFor="let ev of events; index as i">

      <div class="col-5 text-right py-0 left-text"> {{ev.createdAt | date: 'EEEE, dd/MM/yyyy'}}</div>
      <div class="col-1 py-0"><div class="circle mx-auto" [ngStyle]="{'background-color': getColor(ev)}"></div></div>
      <div class="col-5 py-0"> {{ev.appliedStatus | orderStatus}}</div>


      <ng-container *ngIf="i != events.length - 1">
        <div class="col-5 py-0"></div>
        <div class="col-1 pipe py-0"></div>
        <div class="col-5 py-0"></div>
      </ng-container>

    </ng-container>
  </div>
</div>



