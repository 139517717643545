import {Component, OnDestroy} from '@angular/core';
import {UsersService} from "../../../services/users.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Constants} from "../../../utils/constants";
import {WarehousesService} from "../../../services/warehouses.service";
import {WarehouseDetail} from "../../../interfaces/warehouse";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {of, Subscription, switchMap, tap} from "rxjs";
import {map} from "rxjs/operators";
import {BaseTabGroupComponent} from "../../../utils/shared-components/base-container/base-tab-group.component";


@Component({
  selector: 'aw-warehouse-permissions',
  templateUrl: './warehouse-permissions-container.component.html',
  styleUrls: ['./warehouse-permissions-container.component.scss']
})
export class WarehousePermissionsContainerComponent extends BaseTabGroupComponent implements OnDestroy {

  loadingWarehouse = false

  warehouseId!: number

  warehouse?: WarehouseDetail

  entities = Constants.entities

  accessEntityToEntityIdentifierMap = Constants.accessEntityToEntityIdentifierMap

  roleInfoMessage = Constants.roleInfoMessage

  subs: Subscription[] = []

  constructor(private usersService: UsersService,
              private warehousesService: WarehousesService,
              public snackService: SnackService,
              protected utilsService: UtilsService,
              protected dialogService: DialogsService,
              protected override router: Router,
              protected override activatedRoute: ActivatedRoute) {

    super(activatedRoute, router)

    let warehouseId: number | null = null;

    activatedRoute.paramMap.subscribe(paramMap => {
      if (paramMap.has("warehouseId"))
        warehouseId = +paramMap.get("warehouseId")!
    })


    this.warehouseId = warehouseId!

    let obs$ =
      of(true)
        .pipe(
          tap(() => this.loadingWarehouse = true),
          switchMap(() => this.utilsService.getUserReference()),
          switchMap(userReference => this.warehousesService.getWarehouseById(userReference.companyId, this.warehouseId)),
          map(warehouse => {
            this.warehouse = warehouse
            this.loadingWarehouse = false;
          })
        )

    this.subs.push(obs$.subscribe())
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe())
  }

}
