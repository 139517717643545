<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>

<div *ngIf="!loading && !saving else loadingTemplate">

  <h2 class="font-light">Ruolo</h2>

  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="3%"
  >
    <mat-form-field>
      <mat-label>Ruolo</mat-label>
      <mat-select [(value)]="_role" [disabled]="selectVisible" (selectionChange)="shouldISave()">
        <mat-option *ngFor="let authority of accessLevelOrder" [value]="authority">
          {{authority}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-raised-button
      *ngIf="_role && initialRole"
      (click)="removeRole()"
      [disabled]="showSaveButton"
      color="warn"
      matTooltip="Rimuovi ruolo"
      matTooltipPosition="above"
      type="button"
    >
      Rimuovi Ruolo
    </button>

  </div>


  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="3%"
  >
    <mat-form-field *ngIf="dataSource.data.length > 0">
      <mat-label>Permesso globale</mat-label>
      <mat-select
        [(value)]="globalUserAuthority"
        (selectionChange)="setMultiplePermission($event)"
        [disabled]="selectVisible"
      >
        <mat-option *ngFor="let authority of accessLevelOrder" [value]="authority">
          {{authority}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="showSaveButton">
      <button (click)="saveRoleAndPermissions()"
              *awAccess="{accessLevel: {accessEntity: accessEntities.profiles, accessLevel: accessLevels.editor, checkRolesOnly: true}}"
              color="primary"
              mat-raised-button
              matTooltip="Aggiungi"
              matTooltipPosition="above"
              fxLayoutAlign="end"
      >
        Salva
      </button>

      <button (click)="reset()"
              color="accent"
              mat-raised-button
              matTooltip="Reset"
              matTooltipPosition="above"
              fxLayoutAlign="end"

      >
        Reset
      </button>
    </ng-container>
  </div>

  <div *ngIf="dataSource.data.length > 0">

    <h2 class="font-light">Accessi specifici</h2>


    <aw-actions-bar
      class="ml-auto"
      (deleteObjects)="deleteEntity()"
      (load)="loadResults($event, observable$)"
      [displayedColumns]="displayedColumns"
      [isCreatable]="false"
      [isSearchable]="false"
      [isSelectable]="isSelectable()"
      [accessEntity]="accessEntities.users"
      [isAllSelected]="isAllSelected()"
      [selection]="selection"
      [(selectVisible)]="selectVisible"
    >

    </aw-actions-bar>

    <table
      (matSortChange)="loadResults($event, observable$)"
      [dataSource]="dataSource"
      class="w-100 mat-elevation-z2"
      mat-table
      matSort
      multiTemplateDataRows>

      <!--- Note that these columns can be deFfined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                        (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(row)"
                        *ngIf="isDeletable(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>


      <!-- Entity Column -->
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>Entità</th>
        <td *matCellDef="let element" mat-cell> {{element.name}} </td>
      </ng-container>

      <!-- Permission Column -->
      <ng-container matColumnDef="permission">
        <th *matHeaderCellDef mat-header-cell>Permesso</th>
        <td *matCellDef="let element" mat-cell>

          <mat-form-field>
            <mat-select [(value)]="element.userAuthority"
                        [disabled]="selectVisible"
                        (selectionChange)="globalUserAuthority = undefined; shouldISave()"
            >
              <mat-option *ngFor="let authority of accessLevelOrder " [value]="authority">
                {{authority}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Azioni</th>
        <td *matCellDef="let element" class="hide-mobile" fxLayoutGap="8" mat-cell>

          <ng-container *ngIf="isDeletable(element)">
            <button #delete
                    (click)="deleteEntity(element);$event.preventDefault();$event.stopPropagation();"
                    *awAccess="{accessLevel:{accessEntity: accessEntities.profiles, accessLevel: accessLevels.admin}}"
                    color="warn"
                    mat-icon-button
                    matTooltip="Elimina"
                    matTooltipPosition="above"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>

        </td>

      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

      <tr *matNoDataRow class="mat-row">
        <td [colSpan]="displayedColumns.length" class="mat-cell text-center">
          Nessun permesso trovato
        </td>
      </tr>

    </table>
    <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </mat-card>
  </div>


  <mat-paginator #paginator
                 (page)="loadResults($event, observable$)"
                 (selectionchange)="logMe($event)"
                 *ngIf="dataSource && numEntities > 0"
                 [length]="numEntities"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 class="mat-elevation-z1"
                 showFirstLastButtons
  >
  </mat-paginator>
</div>


