import {Component} from '@angular/core';
import {UserMeDetail} from "../../interfaces/user";
import {AuthService} from "../../services/auth.service";
import {CompaniesService} from "../../services/companies.service";
import {Router} from "@angular/router";

@Component({
  selector: 'aw-dashboard',
  templateUrl: './dashboard-container.component.html',
  styleUrls: ['./dashboard-container.component.scss']
})
export class DashboardContainerComponent  {

  loggedUser?: UserMeDetail;
  constructor(public authService: AuthService, public companiesService: CompaniesService, public router: Router) {
    authService.onUserChange().subscribe((u) => {
      this.loggedUser = u;
    })
  }

}
