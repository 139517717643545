<ng-template #loadingGeneral>
  <mat-spinner class="center"></mat-spinner>
</ng-template>


<div fxLayout="column">
  <h1 class="font-light">Tutti gli articoli</h1>

  <ng-container *ngIf="dataSource && !loading else loadingGeneral">

    <aw-actions-bar
      (deleteElements)="deleteEntities($event, userReference.companyId)"
      (load)="loadResults($event, observable$)"
      (openCreateForm)="addBaseItem.emit()"
      [displayedColumns]="displayedColumns"
      [accessEntity]="accessEntities.items"
      [isAllSelected]="isAllSelected()"
      [selectVisible]="selectVisible"
      [selection]="selection"
    >
    </aw-actions-bar>

    <table
      (matSortChange)="loadResults($event, observable$)"
      [dataSource]="dataSource"
      class="w-100 mat-elevation-z2"
      mat-table
      matSort
      matSortActive="deliveryDate"
      matSortDirection="asc"
      multiTemplateDataRows>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                        (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(row)"
                        *ngIf="row.isDeletable"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>Nome</th>
        <td *matCellDef="let element" mat-cell>
          <p>{{element.name}}</p>
        </td>
      </ng-container>

      <!-- min sell net price Column -->
      <ng-container matColumnDef="minimumSellNetPrice">
        <th *matHeaderCellDef mat-header-cell>Vendita (IVA)</th>
        <td *matCellDef="let element" mat-cell>
          <!--        TODO se i campi sono undefined quando ordini ti toglie la riga dalla tabella-->
          {{element.minimumSellNetPrice   | currency:'EUR' }}
          ({{element.defaultSellVAT ?? defaultCompany?.defaultSellVAT | currency:'':'':'0.0-2'}}%)
        </td>
      </ng-container>

      <!-- max buy net price Column -->
      <ng-container matColumnDef="maximumBuyNetPrice">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Acquisto (IVA)</th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell>
          {{element.maximumBuyNetPrice | currency:'EUR' }}
          ({{element.defaultBuyVAT ?? defaultCompany?.defaultBuyVAT| currency:'':'':'0.0-2'}}%)
        </td>
      </ng-container>

      <!-- unit Column -->
      <ng-container matColumnDef="unit">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Unità di misura</th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell>{{element.unit}}</td>
      </ng-container>


      <!-- Item Types Column -->
      <ng-container matColumnDef="itemTypes">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>
          <ng-container *ngIf="availableTypes.length >= 1">
            <aw-column-filter (onFilter)="filterByItemTypes($event)" (onResetFilter)="resetItemTypesFilter()"
                              [options]="availableTypes" [placeholder]="'Tipologia articolo'"
                              headerName="Tipologia"></aw-column-filter>
          </ng-container>

          <ng-container *ngIf="availableTypes.length < 1">
            Tipologie
          </ng-container>

        </th>
        <td *matCellDef="let element" mat-cell>

          <div [ngClass]="hasVariants(element) ? 'border-primary bg-primary': 'bg-accent border-accent'" class="border border-round-md inline-flex py-1 px-1 text-tag">
            {{hasVariants(element) ? 'Con Varianti' : 'Base'}}
          </div>

          <div *ngFor="let type of element.itemTypes" class="border border-round-md inline-flex py-1 px-1 type-chip text-tag ml-1">
            {{type.name}}
          </div>
        </td>
      </ng-container>


      <!-- Action -->
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Azioni</th>
        <td *matCellDef="let element" class="hide-mobile" fxLayoutGap="8" mat-cell>
          <button #edit
                  (click)="editBaseItem.emit(element); $event.stopPropagation()"
                  *awAccess="{accessLevel:{accessEntity: accessEntities.items, accessLevel: accessLevels.editor}}"
                  color="accent"
                  mat-icon-button
                  matTooltip="Modifica"
                  matTooltipPosition="above"
          >
            <mat-icon>edit</mat-icon>
          </button>


          <button #delete
                  (click)="deleteEntities([element.id], userReference.companyId);$event.preventDefault();$event.stopPropagation();"
                  *awAccess="{accessLevel:{accessEntity: accessEntities.items, accessLevel: accessLevels.admin}}"
                  [awDisableElement]="{
                    selected: [element],
                    condition: !element.isDeletable,
                    accessLevels: [{accessEntity: accessEntities.sellOrderShops, accessLevel: accessLevels.admin}],
                  component: delete}"
                  color="warn"
                  mat-icon-button
                  matTooltip="Elimina"
                  matTooltipPosition="above"
          >
            <mat-icon>delete</mat-icon>
          </button>

          <button
            (click)="manageExpandedBaseItemRows(element.id);$event.preventDefault();$event.stopPropagation();"
            *awAccess="{accessLevel:{accessEntity: accessEntities.items, accessLevel: accessLevels.reader}}"
            [disabled]="!hasVariants(element)"
            [matTooltip]="hasVariants(element) ? 'Mostra varianti' : 'Articolo base'"
            color="accent"
            mat-icon-button
            matTooltipPosition="above"
          >
            <mat-icon>category</mat-icon>
          </button>


          <button
            (click)="manageExpandedComposerItemRows(element.id);$event.preventDefault();$event.stopPropagation();"
            *awAccess="{accessLevel:{accessEntity: accessEntities.items, accessLevel: accessLevels.reader}}"
            [disabled]="!(!hasVariants(element) && element.variants[0].itemComposers.length > 0)"
            color="accent"
            mat-icon-button
            matTooltip="Mostra composizione"
            matTooltipPosition="above"
          >
            <mat-icon>inventory</mat-icon>
          </button>

          <button
            (click)="duplicate(element);$event.preventDefault();$event.stopPropagation();"
            *awAccess="{accessLevel:{accessEntity: accessEntities.items, accessLevel: accessLevels.editor}}"
            matTooltip="Duplica"
            color="accent"
            mat-icon-button
            matTooltipPosition="above"
          >
            <mat-icon>content_copy</mat-icon>
          </button>

        </td>

      </ng-container>


      <ng-container matColumnDef="expandedBaseItem">
          <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="pr-1 pl-8 py-1" mat-cell>
            <aw-item-variants-table *ngIf="expandedBaseItems.includes(element.id)" [variants]="element.variants"></aw-item-variants-table>
          </td>
      </ng-container>


      <ng-container matColumnDef="expandedComposerItem">
        <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="pr-1 pl-8 py-1" mat-cell>
          <aw-item-composition-table *ngIf="expandedComposerItems.includes(element.id)" [itemComposers]="element.variants[0].itemComposers"></aw-item-composition-table>
        </td>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>

      <tr (click)="editBaseItemOrOpenDetail(row)" *matRowDef="let row; columns:  displayedColumns" mat-row></tr>

      <tr *matRowDef="let row; columns: ['expandedBaseItem']"
          [fxShow]="expandedBaseItems.includes(row.id)" mat-row class="bg-gray-100"></tr>

      <tr *matRowDef="let row; columns: ['expandedComposerItem']"
          [fxShow]="expandedComposerItems.includes(row.id)" mat-row class="bg-gray-100"></tr>

      <tr *matNoDataRow class="mat-row">
        <td [colSpan]="displayedColumns.length" class="mat-cell text-center">
          Nessun articolo trovato
        </td>
      </tr>


    </table>

    <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </mat-card>

  </ng-container>

  <mat-paginator #paginator
                 (page)="loadResults($event, observable$)"
                 (selectionchange)="logMe($event)"
                 *ngIf="dataSource && numEntities > 0"
                 [length]="numEntities"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 class="mat-elevation-z1"
                 showFirstLastButtons
  >
  </mat-paginator>
</div>
