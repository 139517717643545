<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>

<ng-container *ngIf="!loadingShop else loadingTemplate">

  <h1 class="font-light">Gestione Accessi "{{shop?.name}}"</h1>

  <div fxLayoutAlign="center center" class="border border-blue-info disclaimer-warning mat-background-light">
    <p [innerHTML]="roleInfoMessage.message">
      <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
    </p>
  </div>

  <mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="matTabChange($event)">
    <mat-tab label="Punti vendita">
      <aw-entity-permission-table
        [entity]="{entityName: accessEntityToEntityIdentifierMap[entities.shops], entityId: shopId}"
      ></aw-entity-permission-table>
    </mat-tab>


    <mat-tab label="Ordini e Vendite">
      <aw-entity-permission-table
        [entity]="{entityName: accessEntityToEntityIdentifierMap[entities.sellOrderShops], entityId: shopId}"
      ></aw-entity-permission-table>
    </mat-tab>

  </mat-tab-group>


</ng-container>
