import {Component, Input,} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CompanyCreate, CompanyDetail} from "../../../interfaces/company";
import {merge} from "lodash-es";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {UtilsService} from "../../../services/utils.service";
import {Observable} from "rxjs";
import {CompaniesService} from "../../../services/companies.service";

@Component({
  selector: 'aw-companies-form',
  templateUrl: './companies-form.component.html',
  styleUrls: ['./companies-form.component.scss']
})
export class CompaniesFormComponent extends BaseFormComponent<CompanyCreate, CompanyDetail>{


  @Input()
  set entity(value: CompanyDetail) {
    this.formGroup.patchValue(value)
    this._entity = value
  }

  override _entity: CompanyDetail = {} as CompanyDetail

  imageHasChanged = false;
  logo = ''
  companyLogo = ''

  constructor(
    public companiesService: CompaniesService,
    public override utilsService: UtilsService) {

    let createFunction = (company: CompanyCreate):
      Observable<CompanyDetail> =>
      companiesService.createCompany(company)

    let updateFunction = (companyId: number, company: CompanyCreate):
      Observable<CompanyDetail> =>
      companiesService.updateCompany(companyId, company)

    super(createFunction, updateFunction, utilsService)

    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      name: new FormControl('', [Validators.required]),
      ownerName: new FormControl('', [Validators.required]),
      ownerSurname: new FormControl('', [Validators.required]),
      shownAppName: new FormControl('', ),
      email: new FormControl('',
        [
        Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"),
      ]),
      phone: new FormControl(''),
      defaultBuyVAT: new FormControl(''),
      defaultSellVAT: new FormControl(''),
      address: new FormControl('')
    })

  }

  override createEntity() {
    this.manageForm()
    super.createEntity()
  }

  override updateEntity(entityId: number) {
    this.manageForm()
    super.updateEntity(entityId)
  }

  manageForm() {
    let formValue = this.formGroup.value

    if (!formValue.id)
      delete formValue.id

    this.formEntity = merge({} as CompanyDetail, this._entity, formValue)
    this.formEntity.companyLogo = this.companyLogo

    if (!this.imageHasChanged)
      delete this.formEntity.companyLogo

  }

  storeImage(image: string) {

    this.logo = image
    let split = image.split(',')
    let image64 = split[split.length - 1]
    this.imageHasChanged = true
    this.companyLogo = image64
  }

  private nextFunction(company: CompanyDetail) {
    this._entity = company
    this.formGroup.reset()
    this.formGroup.patchValue(company)
    if (this.dialogRef)
      this.dialogRef.close(company)
    this.saving = false

  }
}
