import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OptionCreate, OptionData} from 'src/app/interfaces/option';
import {UtilsService} from "../../../../services/utils.service";
import {Observable} from "rxjs";
import {BaseFormComponent} from "../../../../utils/shared-components/base-form/base-form.component";
import {OptionsService} from "../../../../services/options.service";

@Component({
  selector: 'aw-options-form',
  templateUrl: './options-form.component.html',
  styleUrls: ['./options-form.component.scss']
})
export class OptionsFormComponent extends BaseFormComponent<OptionCreate, OptionData> {

  @Input() companyId = -1

  constructor(
    public optionsService: OptionsService,
    public override utilsService: UtilsService) {

    let createFunction = (companyId: number, option: OptionCreate):
      Observable<OptionData> =>
      optionsService.createCompanyOption(companyId, option)

    let updateFunction = (companyId: number, optionId: number, option: OptionCreate):
      Observable<OptionData> =>
      optionsService.updateCompanyOption(companyId, optionId, option)

    super(createFunction, updateFunction, utilsService)

    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      name: new FormControl('', [Validators.required]),
      displayName: new FormControl('', [Validators.required]),
    })

  }

  override _entity: OptionData = {} as OptionData

  @Input() set entity(value: OptionData) {
    this._entity = value
    this.formGroup.patchValue(this._entity)
  }
}
