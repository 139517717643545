<div fxLayout="column">


  <div *ngIf="itemComposersSelected.length === 0 && !adding"
       class="border border-yellow-warn disclaimer-box mat-background-light p-2_5 mb-2_5 mt-2_5"
       fxFlex="100">
    <p fxLayoutAlign="center center">
      <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
      Nessun articolo componente.
    </p>
  </div>

  <!-- EXISTING -->
  <div *ngFor="let item of itemComposersSelected; let i = index" fxLayout="column">
    <div [formGroup]="compositionFormGroups[i]" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2%">
      <mat-form-field fxFlex>
        <mat-label>Articolo componente</mat-label>
        <mat-select [disabled]="true" formControlName="id">
          <mat-option *ngFor="let itemComposer of itemComposersSelected" [value]="itemComposer.id">
            {{itemComposer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field fxFlex>

        <mat-label>Quantità</mat-label>
        <input
          *ngIf="hasUnit(compositionFormGroups[i], itemComposersSelected[i].id)"
          (keydown)="hasChanged = true"
          [disabled]="adding"
          [min]="0"
          [readonly]="readOnly"
          [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2}"
          currencyMask
          formControlName="quantity"
          id="quantity"
          matInput
          type="text">

        <input
          *ngIf="!hasUnit(compositionFormGroups[i], itemComposersSelected[i].id)"
          (keydown)="hasChanged = true"
          [disabled]="adding"
          [min]="0"
          [readonly]="readOnly"
          [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 0}"
          currencyMask
          formControlName="quantity"
          id="quantity"
          matInput
          type="text">

        <span *ngIf="hasUnit(compositionFormGroups[i], itemComposersSelected[i].id)"
              matSuffix>&nbsp;{{getMeasureUnit(itemComposersSelected[i].id)}}</span>

      </mat-form-field>


      <button (click)="removeItemComposer(itemComposersSelected[i])"
              *ngIf="!adding && !readOnly" [disabled]="hasChanged" color="warn" class="border border-round border-warn"
              fxFlex mat-button>Elimina
        componente
        <mat-icon matSuffix>delete</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="5%">
    <button (click)="addNewItemComposerForm()" *ngIf="!adding && !readOnly" [disabled]="hasChanged" color="primary"
            fxFlex mat-raised-button>Aggiungi articolo
    </button>
    <button (click)="editAllComposersQuantities()" *ngIf="!adding && itemComposersSelected.length > 0 && !readOnly"
            [disabled]="!hasChanged"
            color="accent"
            fxFlex
            mat-raised-button
    >Modifica quantità
    </button>
  </div>


  <!-- NEW -->
  <div *ngIf="adding" fxLayout="column">
    <div [formGroup]="newCompositionFormGroup" fxLayout="row" fxLayoutGap="2%">
      <mat-form-field fxFlex>
        <mat-label>Articolo componente</mat-label>
        <mat-select formControlName="id">

          <aw-search-option (filteredOptions)="searchBoxFilteredItems = $event" *ngIf="getFilteredList().length > 0"
                            [key]="'name'" [options]="getFilteredList()"></aw-search-option>


          <mat-option *ngIf="searchBoxFilteredItems.length <= 0">
            Nessun articolo trovato!
          </mat-option>

          <mat-option *ngFor="let itemComposer of searchBoxFilteredItems" [value]="itemComposer.id">
            {{itemComposer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex>

        <mat-label>Quantità</mat-label>
        <input
          currencyMask
          *ngIf="hasUnit(newCompositionFormGroup)"
          [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2}"
          [min]="0"
          [readonly]="readOnly"
          formControlName="quantity"
          id="quantity"
          matInput
          type="text">

        <input
          currencyMask
          *ngIf="!hasUnit(newCompositionFormGroup)"
          [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 0}"
          [min]="0"
          [readonly]="readOnly"
          formControlName="quantity"
          id="quantity"
          matInput
          type="text">

        <span *ngIf="hasUnit(newCompositionFormGroup)"
              matSuffix>&nbsp;{{measureUnit}}</span>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutGap="3%">
      <button (click)="addComposerItemFn()" *ngIf="adding" [disabled]="newCompositionFormGroup.invalid || saving"
              color="primary"
              mat-raised-button>
        Salva nuovo componente
      </button>
      <button (click)="adding = false" *ngIf="adding" color="warn" mat-button>Annulla</button>
    </div>
  </div>


</div>
