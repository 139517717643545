<div fxLayout="row" fxLayoutAlign="start center">

  <ng-container *ngIf="removable">
    <button (click)="$event.preventDefault(); $event.stopPropagation(); openRemoveColumnDialog()" mat-icon-button>
      <mat-icon class="close-icon" color="warn" inline="true">
        cancel
      </mat-icon>
    </button>
  </ng-container>
  <div>{{headerName}}</div>

  <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="appMenu" mat-icon-button>
    <mat-icon [ngStyle]="{'color': getColor() }" class="icon-small" inline="true">today</mat-icon>
  </button>

  <mat-menu #appMenu="matMenu">
    <div class="my-2 mx-4">
      <div [formGroup]="formGroup">
        <p class="font-normal "> Filtra per <span class="font-light">{{headerName.toLowerCase()}}</span></p>
        <mat-form-field>
          <mat-label>Seleziona l'intervallo</mat-label>
          <mat-date-range-input [rangePicker]="myRangePicker">
            <!--            todo: trovare un modo per mascherare l'input. Con ngx mask non funziona (errore: 'More than one custom value accessor matches form control with unspecified name attribute')-->
            <input (click)="$event.stopPropagation()" formControlName="startDate" matStartDate placeholder="dd/mm/yyyy"
                   (dateChange)="this.isFilterChanged = true; this.isFiltered = false">
            <input (click)="$event.stopPropagation()" formControlName="endDate" matEndDate placeholder="dd/mm/yyyy"
                   (dateChange)="this.isFilterChanged = true; this.isFiltered = false">
          </mat-date-range-input>
          <mat-datepicker-toggle [for]="myRangePicker" matSuffix></mat-datepicker-toggle>
          <mat-date-range-picker #myRangePicker
                                 [touchUi]="responsiveService.isMobile"></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <button (click)="emitOnFilter()" class="border border-primary mr-1" color="primary" fxFlex mat-button>Filtra
        </button>
        <button (click)="resetFilter()" class="border border-accent ml-1" color="accent" fxFlex mat-button>Reset
        </button>
      </div>


    </div>
  </mat-menu>

</div>
