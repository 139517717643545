import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Constants} from "../../../utils/constants";
import {FetchParams, hasPermission, RollbackStatus} from "../../../utils/global-functions-and-types";
import {WarehouseData, WarehouseDetail} from "../../../interfaces/warehouse";
import {ItemWarehouseDetail, WarehouseStocks} from "../../../interfaces/item-warehouse";
import {ItemVariantDetail} from "../../../interfaces/item-variant";
import {EMPTY, forkJoin, NEVER, Observable, of, switchMap, tap} from "rxjs";
import {ShopsService} from "../../../services/shops.service";
import {WarehousesService} from "../../../services/warehouses.service";
import {ItemVariantsService} from "../../../services/item-variants.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UtilsService} from "../../../services/utils.service";
import {catchError, map} from "rxjs/operators";
import {Component, Input} from "@angular/core";
import {
  SaleContentCancel,
  SaleContentClose,
  SaleContentCreate,
  SaleContentCreateWithSaleContentId,
  SaleContentDetail,
  SaleContentStatus,
  SaleContentUpdateStatus
} from "../../../interfaces/sale-content";
import {MatSelectChange} from "@angular/material/select";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {
  SaleContentState,
  SaleContentUpdateWithStates,
  SaleCreate,
  SaleDetail,
  SaleFullCreate,
  SaleFullCreateWithStates,
  SaleStatus
} from "../../../interfaces/sale";
import {SaleContentStatusPipe} from "../../../pipes/sale-content-status.pipe";
import {SalesService} from "../../../services/sales.service";
import {SaleContentSnapshotData} from "../../../interfaces/sale-content-snapshot";

@Component({
  selector: 'aw-sales-form',
  templateUrl: './sales-form.component.html',
  styleUrls: ['./sales-form.component.scss']
})
export class SalesFormComponent extends BaseFormComponent<SaleCreate | SaleFullCreate | SaleFullCreateWithStates, SaleDetail> {
  formGroupSale: FormGroup
  formGroupSaleContent: FormGroup

  duplicateContentMessage = Constants.duplicateContentMessage
  errorToShow = ''

  anagraphicOpened = true
  addingSaleContent = false
  noWarehousesAvailable = false
  savingSaleAndContent = false
  isEditing = false
  savingSaleContent = false
  updatingSale = false
  updatingSaleContent = false
  savingSale = false
  showDuplicateContentMessage = false
  autoscaleErrorOnSaleClose = false
  isSaleFailed = false
  savingStatus = false
  loadingWarehouses = false
  loadingItems = false
  loadingStocks = false
  saleContentToDelete: number[] = [];
  generalContentStatus?: SaleContentStatus = Constants.saleContentStatuses.manuallyUpdated as SaleContentStatus
  generalContentRollback?: RollbackStatus = Constants.rollbackStatusesMap['manual']
  creationDate: Date = new Date();
  calculatedPrice = 0;
  saleStatuses = Constants.saleStatuses
  selectedStatus = ''
  availableSaleStates: string[] = []
  saleContentStates: string[] = Object.values(Constants.saleContentStatuses)
  saleContentStatuses = Constants.saleContentStatuses
  stateOptions = [{label: 'Non prelevare da magazzino', value: false}, {label: 'Preleva da magazzino', value: true}];
  statusTransitionMap = Constants.saleStatusTransitionMap
  updatedStates: string[] = Object.values(Constants.saleContentUpdatedStatuses)
  rollbackStates: RollbackStatus[] = Constants.rollbackStates
  warehouses: WarehouseData[] = []
  warehouseItems: ItemWarehouseDetail[] = [];
  companyItems: ItemVariantDetail[] = []
  itemsToHandle: SaleContentDetail[] = []
  currentWarehouseStocks: WarehouseStocks[] = []
  creationWrappers: Wrapper[] = []
  closingWrappers: ClosingWrapper[] = []
  showOnlyContent = false
  readOnlySale = false
  readOnlyContent = false
  showPriceChangedMessage = false;

  currentIndex: number = -1
  currentWarehouseId: number = -1
  itemToShow: number | undefined
  warehouseToShow: number | undefined;
  manageInputSale$: Observable<any> = new Observable<any>()
  items: ItemVariantDetail[] = []
  items$: Observable<ItemVariantDetail[]>
  warehouses$: Observable<WarehouseData[]> = new Observable<WarehouseData[]>()


  itemPredicate: (item: ItemVariantDetail, searchValue: string) => boolean = (item, searchValue) =>
    (item.name.toLowerCase().includes(searchValue) ||
      (item?.sku?.toLowerCase().includes(searchValue) ?? false) ||
      (item?.barcode?.toLowerCase().includes(searchValue) ?? false))

  // questa funzione ritorna un observable delle giacenze in base al warehouseId passato come parametro
  warehouseItemsFn: (warehouseId: number) => Observable<ItemWarehouseDetail[]>

  @Input() shopId: number | null = null;
  @Input() defaultWarehouseId?: number


  constructor(
    public formBuilder: FormBuilder,
    public saleContentStatusPipe: SaleContentStatusPipe,
    public shopsService: ShopsService,
    public warehousesService: WarehousesService,
    public itemVariantsService: ItemVariantsService,
    public salesService: SalesService,
    public snackService: SnackService,
    public dialogService: DialogsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    protected override utilsService: UtilsService,
  ) {
    super(() => new Observable<any>(), () => new Observable<any>(), utilsService)

    this.manageInputSale$ =
      of(true)
        .pipe(
          tap(() => this.loading = true),
          tap(() => this.manageReadonly()),
          // Retrieving user
          switchMap(() => this.userReference$),
          // Retrieving Warehouses
          switchMap(() => this.warehouses$),
          tap(() => {
            this._entity.content?.forEach(value => {
              this.addSaleContent(value)
            })
            this.computeCalculatedPrice()

            if (this._managingSaleStatus)
              this.openUpdateSaleContentSection()
          }),
          catchError((err, caught) => {
            this.loading = true
            this.router.navigate(['/error']).then()
            return EMPTY
          }))

    this.warehouseItemsFn =
      (warehouseId: number) =>
        of(true)
          .pipe(
            tap(() => this.loadingStocks = true),
            switchMap(() => this.warehousesService.getWarehouseItems(this.userReference.companyId, warehouseId, {} as FetchParams)),
            switchMap((res) => {
              this.warehouseItems = res.list
              this.loadingStocks = false
              return of(res.list)
            }),
            catchError((err, caught) => {
              this.loadingStocks = false;
              if (!err.errors.includes('403'))
                this.snackService.error('Impossibile caricare le giacenze')
              return of([] as ItemWarehouseDetail[]);
            }))


    this.items$ =
      of(true)
        .pipe(
          tap(() => this.loadingItems = true),
          switchMap(() => this.itemVariantsService.getAllCompanyItems(this.userReference.companyId, {sort: "name asc"} as FetchParams)),
          switchMap(items => {
            this.items = items.list
            this.loadingItems = false
            return of(items.list)
          }),
          catchError((err, caught) => {
            this.loadingItems = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i magazzini')
            return of([] as ItemVariantDetail[]);
          }))

    this.warehouses$ =
      of(true)
        .pipe(
          tap(() => this.loadingWarehouses = true),
          switchMap(() => {
            return this.warehousesService.getAllCompanyWarehouses(this.userReference.companyId,
              this.utilsService.getEntityAccessIdsForUser(this.userReference.user.access.warehouses), {sort: "name asc"} as FetchParams)
          }),
          switchMap(warehouses => {
            this.warehouses = warehouses.list
            this.loadingWarehouses = false
            return of(warehouses.list)
          }),
          catchError((err, caught) => {
            this.loadingWarehouses = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i magazzini')
            return of([] as WarehouseDetail[]);
          }))

    this.formGroupSale = new FormGroup({
      id: new FormControl(undefined),
      finalPrice: new FormControl(null),
      note: new FormControl(''),
      saleDate: new FormControl(new Date(), {validators: [Validators.required]}),
      shopId: new FormControl(this.shopId ? this.shopId : null),
    })


    this.formGroupSaleContent = this.formBuilder.group({
      saleContents: this.formBuilder.array([]),
    });
  }

  _managingSaleStatus = false

  @Input() set managingSaleStatus(value: boolean) {
    this._managingSaleStatus = value
  }

  _managingSaleContent = false

  @Input() set managingSaleContent(value: boolean) {
    this._managingSaleContent = value
  }

  override _entity: SaleDetail = {} as SaleDetail

  @Input() set entity(value: SaleDetail) {

    this._entity = value

    this.subs.push(this.manageInputSale$.subscribe(() => {
      this.loading = false
    }))

    this.creationDate = new Date(Date.now())

    if (value?.id) {

      this.selectedStatus = value.status!
      this.availableSaleStates = [...this.statusTransitionMap[this.selectedStatus], this.selectedStatus]

      // Questo non ho idea di cosa sia
      if (this.availableSaleStates.length < 2) {
        //todo:
        // this.disabled = true
        // this.showButton = false
      }

      this.isEditing = true

      // setto oggetto e form
      this.formGroupSale.patchValue(value)

      // setto la data come la vuole lui
      if (this._entity?.saleDate)
        this.creationDate = new Date(this._entity?.saleDate)
    }
  }


  saleContents(): FormArray {
    return this.formGroupSaleContent.get("saleContents") as FormArray
  }

  newSaleContent(value: SaleContentDetail | undefined): FormGroup {
    let selectedWarehouse = undefined

    if (this.defaultWarehouseId && !value?.id)
      selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === this.defaultWarehouseId)
    else if (value?.id && value.warehouse?.id)
      selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === value.warehouse?.id)

    let wrapper: Wrapper = {
      checked: true,
      selectedWarehouse: selectedWarehouse,
      warehouseId: selectedWarehouse?.id,
      selectedItem: value?.itemVariant,
      itemUnitPrice: value?.itemUnitPrice,
      isWarehouseManagedInCreation: (selectedWarehouse && value?.status != Constants.saleContentStatuses.notUpdated) || !selectedWarehouse,
      loadingItems: false,
      disabledInput: value !== undefined,
      snapshot: this._entity.contentSnapshot?.find(snap => snap.saleContentId === value?.id),
      filteredWarehouse: this.warehouses,
      filteredItems: []

    }

    if (value != undefined) {
      wrapper.warehouseItems = [value.itemVariant]
    }

    this.creationWrappers.push(wrapper)

    return this.formBuilder.group({
      id: new FormControl(value?.id ?? undefined),
      warehouse: new FormControl(selectedWarehouse ?? undefined),
      item: new FormControl(value?.itemVariant ?? undefined, [Validators.required]),
      quantity: new FormControl<number | undefined>(value?.quantity, {validators: [Validators.required, Validators.min(Constants.numerics.minForDecimalQuantities)]}),
      itemUnitPrice: new FormControl<number | undefined>(value?.itemUnitPrice ?? undefined, [Validators.required]),
      state: new FormControl<string>(value?.status ?? Constants.saleContentStatuses.notUpdated),
      checked: new FormControl<boolean>(true)
    })
  }

  addSaleContent(value?: SaleContentDetail) {
    this.saleContents().push(this.newSaleContent(value));

    if (this.defaultWarehouseId) {
      this.currentIndex = this.saleContents().length - 1
      this.currentWarehouseId = this.defaultWarehouseId
      this.creationWrappers[this.currentIndex].selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === this.defaultWarehouseId)
      this.creationWrappers[this.currentIndex].loadingItems = true

      this.warehouseItemsFn(this.defaultWarehouseId)
        .subscribe(itemWarehouse => this.manageSaleContentCreationWrapper(itemWarehouse))
    }

  }

  removeSaleContent(i: number) {
    let itemToRemove = this.saleContents().controls[i].value
    this.saleContents().removeAt(i)

    if (this.isEditing &&
      this._entity?.content?.find(saleItem => saleItem.itemVariant.id === itemToRemove.item.id && saleItem.warehouse?.id == itemToRemove.warehouse?.id)) {

      this._entity?.content?.splice(i, 1)
      this.saleContentToDelete.push(itemToRemove.id)
    }

    this.creationWrappers.splice(i, 1)

    this.showDuplicateMessageIfError()
  }

  getStocks(selectedValue: boolean, index: number) {

    (this.saleContents().controls[index] as FormGroup).reset();
    (this.saleContents().controls[index] as FormGroup).controls["checked"].setValue(selectedValue);
    (this.saleContents().controls[index] as FormGroup).controls["state"].setValue(Constants.saleContentStatuses.notUpdated);

    // (this.saleContents().controls[index] as FormGroup).controls['state'].value !== Constants.saleContentStatuses.notUpdated

    this.currentIndex = index
    this.creationWrappers[index].selectedItem = undefined
    this.creationWrappers[index].warehouseItems = []

    this.creationWrappers[index].checked = selectedValue

    // Non prelevare da magazzino
    if (!selectedValue) {

      this.creationWrappers[index].isWarehouseManagedInCreation = true
      // Svuoto l'item selezionato e la lista associata se il warehouse scelto cambia
      this.unsetSelectedWarehouse(index)

      this.creationWrappers[index].selectedWarehouse = undefined
      this.creationWrappers[index].showAutoscaleError = false


      // If I already have companyItems, I use them
      if (this.companyItems.length > 0) {
        this.creationWrappers[index].warehouseItems = this.companyItems
        this.creationWrappers[index].filteredItems = this.companyItems
      } else {
        // Else I fetch them
        this.creationWrappers[index].loadingItems = true

        this.subs.push(this.items$.subscribe(items => {
          this.companyItems = items
          if (!this.noWarehousesAvailable) {
            this.creationWrappers[this.currentIndex].warehouseItems = items
            this.creationWrappers[this.currentIndex].filteredItems = items
            this.creationWrappers[this.currentIndex].loadingItems = false
          }
        }))
      }
    } else {
      // Preleva da magazzino

      this.creationWrappers[index].isWarehouseManagedInCreation = false;

      this.creationWrappers[index].selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === this.defaultWarehouseId);
      (this.saleContents().controls[index] as FormGroup).controls['warehouse'].setValue(this.creationWrappers[index].selectedWarehouse)


      if (this.creationWrappers[index].selectedWarehouse?.id) {
        this.creationWrappers[index].loadingItems = true
        this.warehouseItemsFn(this.creationWrappers[index].selectedWarehouse?.id!)
          .subscribe(itemWarehouses => this.manageSaleContentCreationWrapper(itemWarehouses))
      }
    }

    this.showDuplicateMessageIfError()
  }

  emitChosenWarehouse(warehouseId: number, index: number) {
    this.currentIndex = index
    this.currentWarehouseId = warehouseId

    this.creationWrappers[index].selectedWarehouse = this.warehouses.find(warehouse => warehouse.id === warehouseId)

    this.creationWrappers[index].selectedItem = undefined;
    (this.saleContents().controls[index] as FormGroup).controls['item'].setValue(undefined)

    this.creationWrappers[index].warehouseItems = []
    this.creationWrappers[index].loadingItems = true

    this.showDuplicateMessageIfError()
    this.warehouseItemsFn(warehouseId)
      .subscribe(itemWarehouses => {
        this.manageSaleContentCreationWrapper(itemWarehouses)
      })
  }

  chosenItem(item: ItemVariantDetail, index: number) {
    this.creationWrappers[index].selectedItem = this.creationWrappers?.[index]?.checked ?
      this.creationWrappers?.[index]?.warehouseItems?.find(it => it.id === item.id) :
      this.companyItems.find(it => it.id === item.id)
    this.creationWrappers[index].itemUnitPrice = item.minimumSellNetPrice;
    (this.saleContents().controls[index] as FormGroup).controls['itemUnitPrice'].setValue(item.minimumSellNetPrice);
    (this.saleContents().controls[index] as FormGroup).controls['quantity'].setValue(1)
    this.showDuplicateMessageIfError()
    this.computeCalculatedPrice(index, 1, item.minimumSellNetPrice)
  }


  saveAndCloseSaleAndContent() {

    of(true)
      .pipe(
        switchMap(() => this.saveSaleAndContent()),
        map(() => this.applyStatusFn(Constants.saleStatuses.closed))
      )
      .subscribe({
        next: () => {
          this.snackService.success('Nuova vendita creata')
          if (this.dialogRef)
            this.dialogRef.close(true)
        },
        error: err => {
          if (err.status === Constants.responseStatusCode.itemWarehouseIllegalQuantityException)
            this.snackService.error("Alcuni elementi non sono stati scalati dal magazzino")
          else this.snackService.error("Impossibile creare la vendita")

          if (this.dialogRef)
            this.dialogRef.close()
        }
      })


  }

  saveNormally() {
    this.saveSaleAndContent().subscribe({

      next: () => {
        this._entity = {} as SaleDetail
        this.snackService.success("Vendita creata")

        if (this.dialogRef)
          this.dialogRef.close(true)


      },
      error: (err) => {
        this._entity = {} as SaleDetail
        this.snackService.error("Impossibile creare la vendita")
        if (this.dialogRef)
          this.dialogRef.close()

      }

    })
  }


  saveSaleAndContent() {

    this.savingSaleAndContent = true

    let currentSaleItems = this.saleContents().controls.map(it => it.value)
    let saleContentList: SaleContentCreate[] = []
    let saleContentStates: SaleContentState[] = []

    currentSaleItems.forEach(saleItem => {
      saleContentList.push({
        itemVariantId: saleItem.item.id,
        warehouseId: saleItem?.warehouse?.id,
        quantity: saleItem.quantity,
        itemUnitPrice: saleItem.itemUnitPrice,
      })

      saleContentStates.push({
        itemId: saleItem.item.id,
        warehouseId: saleItem?.warehouse?.id,
        state: saleItem.state,
      })
    })

    let p = this.formGroupSale.value

    let saleWithContent: SaleFullCreate = {
      ...p,
      content: saleContentList
    }

    return this.createSaleWithContent({
      saleFullCreate: saleWithContent,
      saleContentStates: saleContentStates
    })
  }

  createSaleWithContent(saleFullCreateWithStates: SaleFullCreateWithStates) {

    return of(true)
      .pipe(
        switchMap(() => this.salesService.createSaleWithContent(this.userReference.companyId, this.shopId!, saleFullCreateWithStates.saleFullCreate)),
        switchMap((result) => {


          this._entity = result

          if ((result?.content?.length ?? 0) <= 0)
            return of(true)
          return forkJoin(result.content!.map(saleContent => {

            let currentState: SaleContentStatus = saleFullCreateWithStates.saleContentStates.find(it => it.itemId === saleContent.itemVariant.id && it.warehouseId === saleContent.warehouse?.id)?.state ?? Constants.saleContentStatuses.notUpdated as SaleContentStatus

            // Se l'utente seleziona lo stato NOT_UPDATED, allora non devo aggiornare lo stato iniziale
            if (currentState === Constants.saleContentStatuses.notUpdated)
              return of(true)

            return this.salesService.updateSaleContentStatus(
              this.userReference.companyId,
              this.shopId!,
              result.id,
              saleContent.id,
              saleContent.warehouse?.id!,
              currentState === Constants.saleContentStatuses.updated,
              {status: currentState},
            )
          }))
        }),
      )
  }


  openUpdateSaleContentSection() {
    this.loadingStocks = true

    // In modifica posso modificare solamente gli articoli con status === 'NOT_UPDATED'
    this.getStocksForAutoscale()

  }

  updateSale() {
    this.loading = true
    this.savingSale = true

    let p = this.formGroupSale.value

    let sale: SaleCreate = {
      ...p
    }

    this.subs.push(this.salesService.updateSale(this.userReference.companyId, this.shopId!, this._entity!.id, sale).subscribe(
      {
        next: (updatedSale) => {
          this._entity = updatedSale
          this.formGroupSale.reset()
          this.formGroupSaleContent.reset()

          this.formGroupSale.patchValue(updatedSale)


          this.saving = false
          this.loading = false
          this.snackService.success("Vendita aggiornata")
          if (this.dialogRef)
            this.dialogRef.close(updatedSale)

        },
        error: () => {
          this.loading = false
          this.snackService.error('Impossibile aggiornare la vendita')
        }

      }))
  }

  updateSaleContentPre() {
    this.savingSaleContent = true

    let currentSaleItems = this.saleContents().controls.map(it => it.value)
    let saleContentStates: SaleContentState[] = []

    let saleContentToUpdate: SaleContentCreateWithSaleContentId[] = []
    let saleContentToCreate: SaleContentCreate[] = []

    currentSaleItems.forEach(coi => {
      let found = this._entity?.content?.find(sc => sc.itemVariant.id === coi.item.id && (sc?.warehouse?.id === coi?.warehouse?.id ||
        (sc?.warehouse === undefined && coi?.warehouse === undefined)))
      if (found) {
        if (found.status === this.saleContentStatuses.notUpdated) {
          let saleContent: SaleContentCreateWithSaleContentId = {
            saleContentId: found.id,
            itemVariantId: coi?.item?.id,
            warehouseId: coi?.warehouse?.id,
            quantity: coi?.quantity,
            itemUnitPrice: coi?.itemUnitPrice
          }
          saleContentToUpdate.push(saleContent)
        }
      } else {
        let saleContent: SaleContentCreate = {
          itemVariantId: coi?.item.id,
          warehouseId: coi?.warehouse?.id,
          quantity: coi?.quantity,
          itemUnitPrice: coi?.itemUnitPrice
        }
        saleContentToCreate.push(saleContent)
      }
    })

    currentSaleItems.forEach(saleItem => {
      saleContentStates.push({
        itemId: saleItem.item.id,
        warehouseId: saleItem?.warehouse?.id,
        state: saleItem.state,
      })
    })

    let saleContentUpdateStates: SaleContentUpdateWithStates = {
      saleContentToCreate: saleContentToCreate,
      saleContentToUpdate: saleContentToUpdate,
      saleContentToDelete: this.saleContentToDelete,
      saleContentStates: saleContentStates
    }
    this.updateSaleContent(saleContentUpdateStates)
  }

  showDuplicateMessageIfError() {
    this.showDuplicateContentMessage = false
    let currentSaleItems = this.saleContents().controls.map(it => it.value)

    if (currentSaleItems.length > 1) {
      let isDuplicate = false

      currentSaleItems.forEach(saleItem => {
        isDuplicate = isDuplicate || currentSaleItems.filter(it => {
          if (it.item === undefined || saleItem.item === undefined)
            return false
          return it.item?.id === saleItem.item.id && it.warehouse?.id == saleItem.warehouse?.id
        }).length > 1

      })
      this.showDuplicateContentMessage = isDuplicate
    }
  }

  canAutoscaleOnSaleClose() {

    this.closingWrappers.forEach(
      (value, index) => {

        // Se non devo mostrare la sezione 'manage warehouse' resetto showAutoscaleErrorMessage
        if (this.showManageWarehouseButton(this.selectedStatus))
          this.closingWrappers[index].showAutoscaleError = false

        if (value.selectedStatus !== "UPDATED") {
          this.closingWrappers[index].showAutoscaleError = false
        } else {

          let itemWarehouse = this.currentWarehouseStocks.find(wid => wid.warehouseId === value.warehouseId)
          let warehouseQty = itemWarehouse?.itemWarehouse.find(iw => iw.item.id === value.itemId)?.quantity ?? 0

          if (warehouseQty < value.quantity) {
            this.closingWrappers[index].autoscaleError = value?.unit ?
              `Attenzione! Stai cercando di prelevare ${value.quantity} ${value?.unit} di "${value?.name}" dal magazzino, ma dalle giacenze ne risultano ${warehouseQty}` :
              `Attenzione! Stai cercando di prelevare ${value.quantity} unità dell'articolo "${value?.name}" dal magazzino, ma dalle giacenze ne risultano ${warehouseQty}`
            this.closingWrappers[index].showAutoscaleError = true
          } else {
            this.closingWrappers[index].showAutoscaleError = false
          }
        }
      })
  }

  canSaveAndComplete() {
    return this.creationWrappers.every(creationWrapper => creationWrapper.isWarehouseManagedInCreation) || this.creationWrappers.length == 0
  }

  checkIfCanAutoscale(index: number, formQty?: number) {

    this.creationWrappers[index].isWarehouseManagedInCreation = (this.creationWrappers[index].checked && (this.saleContents().controls[index] as FormGroup).controls['state'].value !== Constants.saleContentStatuses.notUpdated) || !this.creationWrappers[index].checked

    if ((this.saleContents().controls[index] as FormGroup).controls['state'].value !== Constants.saleContentStatuses.updated) {
      this.creationWrappers[index].showAutoscaleError = false
      this.creationWrappers[index].autoscaleError = ''
      return
    }

    let currentQty = formQty !== undefined ? formQty : +(this.saleContents().controls[index] as FormGroup).controls['quantity'].value

    let currentItem = this.creationWrappers[index].selectedItem

    let itemWarehouse: ItemWarehouseDetail[]
    if (!this.isEditing ||
      this.currentWarehouseStocks
        .find(value => value.warehouseId === this.creationWrappers[index].selectedWarehouse?.id)?.itemWarehouse === undefined) {
      itemWarehouse = this.creationWrappers[index].warehouseDetails ?? []
    } else {
      itemWarehouse = this.currentWarehouseStocks
          .find(value => value.warehouseId === this.creationWrappers[index].selectedWarehouse?.id)?.itemWarehouse
        ?? []
    }

    let warehouseQty = itemWarehouse?.find(value => value.item.id === currentItem?.id)?.quantity ?? 0

    if (warehouseQty < currentQty) {
      this.creationWrappers[index].autoscaleError = currentItem?.unit ?
        `Attenzione! Stai cercando di prelevare ${currentQty} ${currentItem?.unit} di "${currentItem?.name}" dal magazzino, ma dalle giacenze ne risultano ${warehouseQty}` :
        `Attenzione! Stai cercando di prelevare ${currentQty} unità dell'articolo "${currentItem?.name}" dal magazzino, ma dalle giacenze ne risultano ${warehouseQty}`

      this.creationWrappers[index].showAutoscaleError = true
    } else {
      this.creationWrappers[index].showAutoscaleError = false
      this.creationWrappers[index].autoscaleError = ''
    }
  }

  computeCalculatedPrice(i?: number, formQty?: number, itemUnitPrice?: number) {
    let currentSaleItems = this.saleContents().controls.map(it => it.value)

    this.calculatedPrice = 0

    if (i)
      this.creationWrappers[i].itemUnitPrice = itemUnitPrice

    currentSaleItems.forEach((saleItem, index) =>
      this.calculatedPrice += (index === i && formQty ? formQty : saleItem.quantity) * (index === i && itemUnitPrice ? itemUnitPrice : saleItem.itemUnitPrice)
    )

    this.displayPriceChangedMessage()
  }

  displayPriceChangedMessage() {
    if (this.isEditing && this._entity.finalPrice && this._entity.calculatedPrice !== this.calculatedPrice)
      this.showPriceChangedMessage = true
    else
      this.showPriceChangedMessage = false
  }

  openManageSaleStatusSection() {
    this._managingSaleStatus = true

    // Prendo le giacenze dei magazzini degli articoli con stato NOT_UPDATE
    let list = this._entity?.content?.filter(
      value => value.warehouse != undefined && value.status === this.saleContentStatuses.notUpdated) ?? []

    forkJoin(list.map(value => value?.warehouse?.id ??

      this.subs.push(this.warehouseItemsFn(value.warehouse!.id)
        .subscribe(itemWarehouses => {
          this.currentWarehouseStocks.push({
            warehouseId: value.warehouse!.id,
            itemWarehouse: itemWarehouses
          })
        }))
    )).subscribe(() => {
      this.loadingStocks = false
      this.updatingSaleContent = true
    })


  }

  canTransition(appliedStatus: string) {

    return this.availableSaleStates.includes(appliedStatus)

  }

  applyStatusFn(selectedStatus: string) {

    this.savingStatus = true
    if (!this.isSaleFailed) {
      let saleContents: SaleContentUpdateStatus[] = []
      this.closingWrappers.forEach((value => saleContents.push({
        saleContentId: value.saleContentId,
        warehouseId: value.warehouseId,
        state: value.selectedStatus!,
        itemVariantId: value.itemId
      })))
      this.applyStatus({saleStatus: selectedStatus, saleContents: saleContents})
    } else {
      //  se annullo la vendita devo gestire il rollback

      let saleContents: SaleContentCancel[] = []
      this.closingWrappers.forEach(value => saleContents.push({
        warehouseId: value.warehouseId,
        saleContentId: value.saleContentId,
        autoRollback: value.selectedRollback?.value ?? false
      }))

      this.cancelSale(saleContents)
    }

  }

  cancelSale(saleContents: SaleContentCancel[]) {


    of(true)
      .pipe(
        switchMap(() => {
          if (saleContents.length <= 0)
            return of(true)

          return forkJoin(saleContents.map(value =>
            this.salesService.updateSaleContentStatus(
              this.userReference.companyId,
              this.shopId!,
              this._entity!.id,
              value.saleContentId,
              value.warehouseId,
              value.autoRollback,
              {status: this.saleContentStatuses.notUpdated as SaleContentStatus})))
        }),
        switchMap(() => this.salesService.updateSaleStatus(this.userReference.companyId, this.shopId!, this._entity!.id, {status: Constants.saleStatuses.canceled as SaleStatus})),
      ).subscribe({
      next: () => {
        this._entity = {} as SaleDetail
        this.snackService.success("Stato della vendita aggiornato")

        if (this.dialogRef)
          this.dialogRef.close(true)


      },
      error: (err) => {
        this._entity = {} as SaleDetail
        this.snackService.error("Impossibile aggiornare lo stato della vendita")
        if (this.dialogRef)
          this.dialogRef.close()

      }
    })
  }

  isApplyStatusButtonDisabled() {
    return this.selectedStatus === this._entity?.status ||
      !this.canTransition(this.selectedStatus)
  }

  // todo check
  showManageWarehouseButton(status: string) {
    if (!this._entity?.content || this._entity?.content.length < 1)
      return false

    switch (status) {
      case Constants.saleStatuses.canceled:
        if (this._entity?.status !== Constants.saleStatuses.canceled)
          return !this.hasNotManagedWarehouses()
        break;
      case Constants.saleStatuses.closed:
        if (this._entity?.status !== Constants.saleStatuses.closed)
          return !this.hasManagedWarehouses()

    }

    return false

  }

  // Ritorna false se c'è un articolo non gestito
  onStatusChange() {
    this.isSaleFailed = this.selectedStatus === Constants.saleStatuses.canceled
    this.closingWrappers = []

    if (this.showManageWarehouseButton(this.selectedStatus)) {
      this.itemsToHandle = this._entity?.content?.filter(value => value.warehouse != undefined && ((!this.isSaleFailed && value.status === this.saleContentStatuses.notUpdated) || (this.isSaleFailed && value.status !== this.saleContentStatuses.notUpdated))) ?? []
      this.itemsToHandle.forEach(value => this.closingWrappers.push({
        itemId: value.itemVariant.id,
        warehouseId: value.warehouse!.id,
        selectedStatus: !this.isSaleFailed ? this.generalContentStatus : undefined,
        selectedRollback: this.isSaleFailed ? this.generalContentRollback : undefined,
        saleContentId: value.id,
        name: value.itemVariant.name,
        quantity: value.quantity,
        unit: value.itemVariant.unit
      }))
    }
  }

  hasManagedWarehouses() {
    return !(this._entity?.content?.find(value => value.warehouse?.id != null && value.status == this.saleContentStatuses.notUpdated) != undefined)
  }

  // Ritorna false se c'è un articolo gestito
  hasNotManagedWarehouses() {
    return !(this._entity?.content?.find(value => value.warehouse?.id != null && value.status != this.saleContentStatuses.notUpdated) != undefined)
  }

  updateContentStatusAndCheckErrorsOnSaleClose() {
    if (!this.isSaleFailed) {
      this.closingWrappers.forEach(value => value.selectedStatus = this.generalContentStatus)
      this.canAutoscaleOnSaleClose()
      this.setGlobalErrorOnSaleClose()
    } else {
      this.closingWrappers.forEach(value => value.selectedRollback = this.generalContentRollback)
    }
  }

  setGlobalErrorOnSaleClose() {
    this.autoscaleErrorOnSaleClose = this.closingWrappers.filter(value => value.showAutoscaleError).length > 0
  }

  setGeneralStatus(event: MatSelectChange) {
    this.generalContentStatus = event.value
    this.closingWrappers.forEach(wrapper => wrapper.selectedStatus = this.generalContentStatus)
  }

  setGeneralRollback(event: MatSelectChange) {
    this.generalContentRollback = event.value
    this.closingWrappers.forEach(wrapper => wrapper.selectedRollback = this.generalContentRollback)
  }

  setGlobalAutoscaleDropdown() {
    let matchingStatus = this.closingWrappers[0].selectedStatus
    if (this.closingWrappers.every(value => value.selectedStatus === matchingStatus))
      this.generalContentStatus = matchingStatus
    else
      this.generalContentStatus = undefined
  }

  setGlobalRollbackDropdown() {
    let matchingStatus = this.closingWrappers[0]?.selectedRollback
    if (this.closingWrappers.every(value => value.selectedRollback?.name === matchingStatus?.name))
      this.generalContentRollback = matchingStatus
    else
      this.generalContentRollback = undefined
  }

  applyStatus(saleContentClose: SaleContentClose) {


    of(true)
      .pipe(
        switchMap(() => {
          if (saleContentClose.saleContents.length <= 0)
            return of(true)


          return forkJoin(saleContentClose.saleContents.map(value =>

            this.salesService.updateSaleContentStatus(
              this.userReference.companyId,
              this.shopId!,
              this._entity!.id,
              value.saleContentId,
              value.warehouseId!,
              value.state === Constants.saleContentStatuses.updated,
              {status: value.state}
            )))
        }),
        switchMap(() => this.salesService.updateSaleStatus(this.userReference.companyId, this.shopId!, this._entity!.id, {status: saleContentClose.saleStatus as SaleStatus})),
      ).subscribe({
      next: () => {
        this._entity = {} as SaleDetail
        this.snackService.success("Stato della vendita aggiornato")
        if (this.dialogRef)
          this.dialogRef.close(true)
      },
      error: (err) => {
        this._entity = {} as SaleDetail
        this.snackService.error("Impossibile aggiornare lo stato della vendita")
      }
    })
  }

  updateSaleContent(saleContentUpdate: SaleContentUpdateWithStates) {

    of(true)
      .pipe(
        switchMap(() => {
          // Elimino gli articoli rimossi
          if (saleContentUpdate.saleContentToDelete.length <= 0)
            return of(true)

          return forkJoin(saleContentUpdate.saleContentToDelete.map((scId) => this.salesService.deleteSaleContent(this.userReference.companyId, this.shopId!, this._entity!.id, scId)))
        }),
        switchMap(() => {
          // Aggiungo gli articoli nuovi
          if (saleContentUpdate.saleContentToCreate.length > 0)
            return this.salesService.createSaleContent(this.userReference.companyId, this.shopId!, this._entity!.id, saleContentUpdate.saleContentToCreate)
          else return of([])
        }),
        // Aggiorno lo stato degli item appena inseriti
        switchMap((result) => {

          if (result?.length <= 0)
            return of(true)

          return forkJoin(result.map(saleContent => {
            let currentState: SaleContentStatus = saleContentUpdate.saleContentStates.find(it => it.itemId === saleContent.itemVariant.id && it.warehouseId === saleContent.warehouse?.id)?.state ?? this.saleContentStatuses.notUpdated as SaleContentStatus

            // Se l'utente seleziona lo stato NOT_UPDATED, allora non devo aggiornare lo stato iniziale
            if (currentState == Constants.saleContentStatuses.notUpdated)
              return of(true)

            return this.salesService.updateSaleContentStatus(this.userReference.companyId,
              this.shopId!,
              this._entity!.id,
              saleContent.id,
              saleContent.warehouse!.id!,
              currentState === Constants.saleContentStatuses.updated,
              {status: currentState},
            )
          }))
        }),

        switchMap(() => {
          // Aggiorno gli articoli modificati
          if (saleContentUpdate.saleContentToUpdate.length <= 0)
            return of(true)

          return forkJoin(saleContentUpdate.saleContentToUpdate.map(
            scu => this.salesService.updateSaleContent(this.userReference.companyId, this.shopId!, this._entity!.id, scu.saleContentId, scu))
          )
        }),
        // Aggiorno lo stato degli item aggiornati dall'utente
        switchMap(() => {

          if (saleContentUpdate.saleContentToUpdate.length <= 0)
            return of(true)

          return forkJoin(saleContentUpdate.saleContentToUpdate.map(scu => {

            let currentState: SaleContentStatus = saleContentUpdate.saleContentStates.find(it => it.itemId === scu.itemVariantId && it.warehouseId === scu.warehouseId)?.state ?? this.saleContentStatuses.notUpdated as SaleContentStatus

            // Se l'utente seleziona lo stato NOT_UPDATED, allora non devo aggiornare lo stato iniziale
            if (currentState == this.saleContentStatuses.notUpdated)
              return of(true)

            return this.salesService.updateSaleContentStatus(
              this.userReference.companyId,
              this.shopId!,
              this._entity!.id,
              scu.saleContentId,
              scu.warehouseId!,
              currentState === Constants.saleContentStatuses.updated,
              {status: currentState})
          }))
        })
      )
      .subscribe({
        next: () => {
          this.snackService.success("Contenuto della vendita aggiornato")
          this._entity = {} as SaleDetail
          if (this.dialogRef)
            this.dialogRef.close(true)
        },
        error: err => {
          if (err.status === Constants.responseStatusCode.itemWarehouseIllegalQuantityException) {
            this.snackService.success("Alcuni elementi non sono stati scalati dal magazzino")
          } else {
            this.snackService.success("Impossibile aggiornare il contenuto della vemdota")

          }
          this._entity = {} as SaleDetail
          if (this.dialogRef)
            this.dialogRef.close()
        }
      })

  }

  canEditContent() {
    return this._entity.status === Constants.saleStatuses.open
  }

  unsetSelectedWarehouse(index: number) {
    this.creationWrappers[index].selectedWarehouse = undefined
    this.creationWrappers[index].warehouseId = undefined;
    (this.saleContents().controls[index] as FormGroup).controls['warehouse'].setValue(null)
    this.unsetSelectedItem(index)
  }

  unsetSelectedItem(index: number) {
    this.creationWrappers[index].selectedItem = undefined;
    this.creationWrappers[index].itemUnitPrice = undefined;
    (this.saleContents().controls[index] as FormGroup).controls['item'].setValue(null);
    (this.saleContents().controls[index] as FormGroup).controls['itemUnitPrice'].setValue(null);
    (this.saleContents().controls[index] as FormGroup).controls['quantity'].setValue(null);
  }

  unsetGeneralContentStatus() {
    this.generalContentStatus = undefined
    this.setGlobalAutoscaleDropdown()
  }

  unsetGeneralContentRollback() {
    this.generalContentRollback = undefined
    this.setGlobalRollbackDropdown()
  }

  unsetAllContentStatus() {
    this.closingWrappers.forEach(value => value.selectedStatus = undefined)
  }

  unsetAllContentRollback() {
    this.closingWrappers.forEach(value => value.selectedRollback = undefined)
  }

  setSelectedSaleStatus($event: MatSelectChange) {
    this.selectedStatus = $event.value
  }

  setContentStatus($event: MatSelectChange, index: number) {
    this.closingWrappers[index].selectedStatus = this.updatedStates.find(state => state === $event.value) as SaleContentStatus
  }

  private getStocksForAutoscale() {

    let list = this._entity?.content?.filter(
      value => value.warehouse != undefined && value.status === this.saleContentStatuses.notUpdated) ?? []


    if (list.length != 0) {
      forkJoin(
        list.map(value => value?.warehouse?.id ?
          this.subs.push(
            this.warehouseItemsFn(value.warehouse!.id)
              .pipe(
                tap(itemWarehouses => {
                    this.currentWarehouseStocks.push({
                      warehouseId: value.warehouse!.id,
                      itemWarehouse: itemWarehouses
                    })
                    this.loadingStocks = false
                    this.updatingSaleContent = true

                  }
                )
              )
              .subscribe(() => {
              })) : NEVER))
    }

    this.updatingSaleContent = true

  }

  private manageSaleContentCreationWrapper(itemWarehouses: ItemWarehouseDetail[]) {
    this.creationWrappers[this.currentIndex].warehouseId = this.currentWarehouseId
    this.creationWrappers[this.currentIndex].warehouseDetails = itemWarehouses
    this.creationWrappers[this.currentIndex].warehouseItems = itemWarehouses.map(it => it.item)
    this.creationWrappers[this.currentIndex].filteredItems = itemWarehouses.map(it => it.item)
    this.creationWrappers[this.currentIndex].loadingItems = false
  }

  getPanelTitle(index: number, content: any) {

    let useSnapshot = this.creationWrappers[index].snapshot != undefined

    let snapshot = this.creationWrappers[index].snapshot!

    let itemName = useSnapshot ? snapshot.variantItemName : this.creationWrappers?.[index]?.selectedItem?.name ?? "";

    let itemQuantity = useSnapshot ? snapshot.quantity : !!this.creationWrappers?.[index]?.selectedItem?.name ? ' x' + (content.value.quantity != null ? content.value.quantity : '?') : ' ';

    let warehouseName = useSnapshot ? snapshot.warehouseName ?? " " : !!this.creationWrappers?.[index]?.selectedWarehouse?.name ? `da ${this.creationWrappers?.[index]?.selectedWarehouse?.name}` : ' ';

    let stockStatus = this.saleContentStatusPipe.transform(content.value.state) != undefined ? `[${this.saleContentStatusPipe.transform(content.value.state)}]` : ""

    let panelTitle = `${itemName} ${itemQuantity} ${warehouseName} ${stockStatus}`

    if (panelTitle.trim() === "")
      return "Nuovo articolo"

    return panelTitle

  }

  private manageReadonly() {
    this.readOnlySale = !hasPermission(this.userReference.user, {
      accessEntity: this.accessEntities.sellOrderShops,
      accessLevel: this.accessLevels.editor
    })

    this.readOnlyContent = this.readOnlySale && !this.canEditContent()

  }

  getSelectedItem(index: number) {
    return !!this.creationWrappers?.[index]?.selectedItem
  }
}

// Used for presentation of content creation
export interface Wrapper {
  isWarehouseManagedInCreation: boolean;
  checked?: boolean
  loadingItems?: boolean
  disabledInput?: boolean
  selectedItem?: ItemVariantDetail
  selectedWarehouse?: WarehouseData
  itemUnitPrice?: number
  warehouseId?: number
  warehouseDetails?: ItemWarehouseDetail[]
  warehouseItems?: ItemVariantDetail[]
  showAutoscaleError?: boolean
  autoscaleError?: string
  snapshot?: SaleContentSnapshotData
  filteredWarehouse: any[]
  filteredItems: any[]

}

// Used when you're transitioning to a status that requires stocks management
export interface ClosingWrapper {
  unit?: string
  quantity: number
  name: string
  autoscaleError?: string
  showAutoscaleError?: boolean
  itemId: number
  warehouseId: number
  selectedStatus?: SaleContentStatus
  saleContentId: number
  selectedRollback?: RollbackStatus
}
