import {Component, Host, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ResponsiveService} from "../../../services/responsive.service";
import {SlidingRowComponent} from "../sliding-row/sliding-row.component";

@Component({
  selector: 'td[awActionsCell]',
  templateUrl: './sliding-row-actions-cell.component.html',
  styleUrls: ['./sliding-row-actions-cell.component.scss']
})
export class SlidingRowActionsCellComponent implements OnInit {

  @ViewChild('template', {'static': true}) template!: TemplateRef<any>;


  constructor(
    public responsiveService: ResponsiveService,
    private cref: ViewContainerRef,
    @Host() public tr: SlidingRowComponent
  ) {}

  ngOnInit(): void {
    this.cref.element.nativeElement.remove();
    this.cref.createEmbeddedView(this.template);
  }

  swipingLeftId() {
    return this.tr.swipingLeftId()
  }

  swipedLeftId() {
    return this.tr.swipedLeftId()
  }

  disableClick() {
    return this.tr.disableClick()
  }
  enableClick() {
    return this.tr.enableClick()
  }
}
