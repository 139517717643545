import {Component} from '@angular/core';
import {Constants} from '../../../utils/constants';
import {EMPTY, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {WarehousesService} from "../../../services/warehouses.service";
import {ShopsService} from "../../../services/shops.service";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {catchError, map} from "rxjs/operators";
import {UsersService} from "../../../services/users.service";
import {FetchParams, UserReference} from "../../../utils/global-functions-and-types";
import {
  AccessPermissionCreate,
  AccessPermissionData,
  AccessPermissionDelete,
  UserAccessDetail
} from "../../../interfaces/permission";
import {ProfileDetail} from "../../../interfaces/profile";
import {ProfilesService} from "../../../services/profiles.service";
import {UtilsService} from "../../../services/utils.service";
import {SnackService} from "../../../services/snack.service";
import {PermissionsService} from "../../../services/permissions.service";


@Component({
  selector: 'aw-user-permissions',
  templateUrl: './user-permissions-container.component.html',
  styleUrls: ['./user-permissions-container.component.scss']
})
export class UserPermissionsContainerComponent {

  entities = Constants.entities
  roleInfoMessage = Constants.roleInfoMessage

  userReference!: UserReference

  loading = false
  loadingUser = false
  loadingAccessWarehouses = false
  loadingAccessShops = false

  observable$: Observable<void>

  saving = false
  accessWarehouses: AccessPermissionData[] = []
  accessItemWarehouses: AccessPermissionData[] = []
  accessShops: AccessPermissionData[] = []
  accessSellOrderShops: AccessPermissionData[] = []
  userAccess!: UserAccessDetail
  userId!: number

  accessWarehouses$: Observable<void>
  accessShops$: Observable<void>
  userAccess$: Observable<UserAccessDetail>
  userProfiles: ProfileDetail[] = []
  numWarehouses = 0;
  numShops = 0;
  private subs: Subscription[] = []

  saveRole$: Observable<any>
  removeRole$: Observable<any>
  setPermissions$: Observable<any>
  removePermissions$: Observable<any>


  _roleArgument: any


  permissionsToEdit: AccessPermissionCreate[] = []
  permissionsToRemove: AccessPermissionDelete[] = []

  constructor(private warehousesService: WarehousesService,
              private shopsService: ShopsService,
              private authService: AuthService,
              private snackService: SnackService,
              private userService: UsersService,
              private profilesService: ProfilesService,
              private permissionsService: PermissionsService,
              private utilsService: UtilsService,
              protected activatedRoute: ActivatedRoute,
  ) {


    let userId: number | null = null;

    activatedRoute.paramMap.subscribe(paramMap => {
      if (paramMap.has("userId"))
        userId = +paramMap.get("userId")!
    })

    this.userId = userId!

    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.utilsService.getUserReference()),
          switchMap(userReference => {
            this.userReference = userReference
            return this.userAccess$
          }),
          switchMap(() => this.accessWarehouses$),
          switchMap(() => this.accessShops$),
          tap(() => this.loading = false),
          catchError(() => {
            this.loadingUser = false
            return EMPTY;
          })
        )

    // Gets all access of target user
    this.userAccess$ =
      of(true)
        .pipe(
          tap(() => this.loadingUser = true),
          switchMap(() => this.userService.getUserAccessById(this.userId, this.userReference.companyId)),
          tap(userAccess => {
            this.userAccess = userAccess
            this.userProfiles = userAccess.profiles
            this.loadingUser = false
          }),
          catchError((err) => {
            this.loadingUser = false
            if (!err.errors.includes('403'))
              this.snackService.error("Impossibile caricare i dati dell'utente")
            return EMPTY;
          })
        )

    // Gets all warehouses permission of logged user
    this.accessWarehouses$ =
      of(true)
        .pipe(
          tap(() => this.loadingAccessWarehouses = true),
          switchMap(() => this.warehousesService.getAllCompanyWarehouses(this.userReference.companyId,
            this.utilsService.getEntityAccessIdsForUser(this.userReference.user.access.warehouses), {sort: "name asc"} as FetchParams)),
          map(warehouses => {

            this.numWarehouses = warehouses.num
            this.accessWarehouses = this.utilsService.getAccessEntities(warehouses.list, this.userAccess?.personalAccess.warehouses?.permission)
            this.accessItemWarehouses = this.utilsService.getAccessEntities(warehouses.list, this.userAccess?.personalAccess.itemWarehouses?.permission)

            this.loadingAccessWarehouses = false
          }),
          catchError((err) => {
            this.loadingAccessWarehouses = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i magazzini')
            return EMPTY;
          })
        )


    // Gets all warehouses permission of logged user
    this.accessShops$ =
      of(true)
        .pipe(
          tap(() => this.loadingAccessShops = true),
          switchMap(() => this.shopsService.getAllCompanyShops(this.userReference.companyId,
            this.utilsService.getEntityAccessIdsForUser(this.userReference.user.access.shops), {sort: "name asc"} as FetchParams)),
          map(shops => {

            this.numShops = shops.num
            this.accessShops = this.utilsService.getAccessEntities(shops.list, this.userAccess?.personalAccess.shops?.permission)
            this.accessSellOrderShops = this.utilsService.getAccessEntities(shops.list, this.userAccess?.personalAccess.sellOrderShops?.permission)

            this.loadingAccessShops = false
          }),
          catchError((err) => {
            this.loadingAccessShops = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i punti vendita')
            return EMPTY;
          })
        )

    this.saveRole$ =
      of(true)
        .pipe(
          tap(() => this.saving = true),
          switchMap(() => {
            // If it is undefined, I am removing the role. Else I'm setting it
            return this._roleArgument?.userAuthority === undefined ?
              this.permissionsService.removeRolesFromUser(this.userReference.companyId, [this._roleArgument]) :
              this.permissionsService.setRolesToUser(this.userReference.companyId, [this._roleArgument])
          }),
          switchMap(() => {
            this.saving = false
            this.snackService.success('Ruolo aggiornato')
            this._roleArgument = undefined
            return this.observable$
          }),
          catchError((err) => {
            this.saving = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile aggiornare il ruolo');
            return EMPTY;
          })
        )

    this.removeRole$ =
      of(true)
        .pipe(
          tap(() => this.saving = true),
          switchMap(() => this.permissionsService.removeRolesFromUser(this.userReference.companyId, [this._roleArgument])),
          switchMap(() => {
            this.saving = false
            this.snackService.success('Ruolo rimosso')
            this._roleArgument = undefined
            return this.observable$
          }),
          catchError((err) => {
            this.saving = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile rimuovere il ruolo');
            return EMPTY;
          }))


    this.setPermissions$ =
      of(true)
        .pipe(
          tap(() => this.saving = true),
          switchMap(() => this.permissionsService.setPermissionsToUser(this.userReference.companyId, this.permissionsToEdit)),
          switchMap(() => {
            this.snackService.success('Permessi aggiornati')
            return this.observable$
          }),
          catchError(err => {
            this.saving = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile aggiornare i permessi');
            return EMPTY;
          })
        )

    this.removePermissions$ =
      of(true)
        .pipe(
          tap(() => this.saving = true),
          switchMap(() => this.permissionsService.removePermissionsFromUser(this.userReference.companyId, this.permissionsToRemove)),
          switchMap(() => {
            this.snackService.success('Permessi rimossi');
            return this.observable$
          }),
          catchError(err => {
            this.saving = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile rimuovere i permessi');
            return EMPTY;
          }))

    this.subs.push(this.observable$.subscribe())

  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  onUserPermissionChange() {
    this.subs.push(this.observable$.subscribe())
  }

  onRemoveRole($event: any) {
    this._roleArgument = $event
    this.subs.push(this.removeRole$.subscribe())
  }

  onSaveRole($event: any) {
    this._roleArgument = $event
    this.subs.push(this.saveRole$.subscribe())
  }

  onSetPermissions($event: any) {
    this.permissionsToEdit = $event
    this.subs.push(this.setPermissions$.subscribe())
  }

  onRemovePermissions($event: any) {
    this.permissionsToRemove = $event
    this.subs.push(this.removePermissions$.subscribe())
  }
}
