<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>


<ng-container *ngIf="!loading && !loadingProfile else loadingTemplate">


  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3%">
      <form [formGroup]="formGroup">
        <mat-form-field>
          <mat-label>Nome profilo</mat-label>
          <input
            matInput
            class="w-full"
            id="name"
            formControlName="name"
            matTooltip="Nome profilo"
            matTooltipPosition="above"
            type="text"

            [awDisableElement]="{
                          selected: [],
                          accessLevels: [{accessEntity: accessEntities.profiles, accessLevel: accessLevels.editor}]}"
          >
        </mat-form-field>

        <small *ngIf="formGroup.controls['name'].dirty &&
                    formGroup.controls['name'].errors?.['required']"
               class="p-error block">È obbligatorio inserire il nome del profilo</small>
      </form>

      <!--      <button-->
      <!--        *ngIf="formGroup.dirty && !formGroup.invalid"-->
      <!--        mat-raised-button-->
      <!--        color="primary"-->
      <!--        (click)="profileId ? updateProfile() : saveProfile()"-->
      <!--      >-->
      <!--        Salva-->
      <!--      </button>-->
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="row"
         fxLayoutAlign="center center"
         fxFlex="50"
         *ngIf="!loadingProfile && !loading"
         class="border border-blue-info disclaimer-warning mat-background-light mt-2_5 mb-2_5"
    >
      <p [innerHTML]="roleInfoMessage.message">
        <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
      </p>
    </div>
  </div>


  <aw-permissions-tab-group
    [accessWarehouses]="accessWarehouses"
    [accessItemWarehouses]="accessItemWarehouses"
    [accessShops]="accessShops"
    [accessSellOrderShops]="accessSellOrderShops"
    [permissionOwnerId]="profileId"
    [accessDetail]="_profile.access"
    (onPermissionChange)="onProfilePermissionChange()"
    (onSaveRole)="manageSaveRole($event)"
    (onRemoveRole)="manageRemoveRole($event)"
    (onSetPermissions)="manageSetPermissions($event)"
    (onRemovePermissions)="manageRemovePermissions($event)"

  ></aw-permissions-tab-group>

</ng-container>

