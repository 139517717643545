<ng-template #loadingData>
  <mat-spinner class="center mb-4" color="primary"></mat-spinner>
</ng-template>

<ng-template #customerTemplate let-customer="customer">
  <div fxFlex>
    <span class="font-medium">{{customer.name + ' ' + (customer.surname ?? '')}}</span>
    <p *ngIf="customer.email">
      <span class="font-medium">Email:</span>
      {{customer.email}}
    </p>
    <p *ngIf="customer.address">
      <span class="font-medium">Indirizzo:</span>
      {{customer.address}}
    </p>
    <p *ngIf="customer.phone">
      <span class="font-medium">Telefono:</span>
      {{customer.phone}}
    </p>
    <p *ngIf="customer.vat">
      <span class="font-medium">Partita IVA</span>
      {{customer.vat}}
    </p>
  </div>
</ng-template>

<ng-template #customerInfoTemplate let-customerInfo="customerInfo">
  <p>
    <span class="font-medium">Recapito:</span>
    {{customerInfo.addressName}}
  </p>
  <p>
    <span class="font-medium">Indirizzo:</span>
    {{customerInfo.address}}, {{customerInfo.city}}, {{customerInfo.zipCode}}
  </p>
  <p>
    <span class="font-medium">Telefono:</span>
    {{customerInfo.phone}}
  </p>
</ng-template>


<!-- region Scegli se vuoi modificare l'anagrafica o il contenuto -->
<div
  *ngIf="(!readOnlyContent && !readOnlyOrder && isEditing && !(updatingOrder || updatingOrderContent || _managingSellOrderStatus || _managingSellOrderContent || showOnlyContent || _managingPaymentStatus)) || (_entity?.status === orderStatuses.completed || _entity?.status === orderStatuses.failed) || (_entity?.paymentStatus == paymentStates.paid)">

  <ng-container *ngIf="availableOrderStates.length > 1">

    <button (click)="updatingOrder = true"
            class="w-100 border border-primary mr-1"
            color="primary"
            mat-button
    >Modifica ordine
    </button>

    <mat-divider class="my-2"></mat-divider>

    <ng-container *ngIf="canEditContent()">
      <button (click)="openUpdateOrderContentSection()"
              class="w-100 border border-accent mr-1"
              color="accent"
              mat-button
      >Modifica contenuto ordine
      </button>

      <mat-divider class="my-2"></mat-divider>
    </ng-container>

    <button (click)="openManageSellOrderStatusSection()"
            class="w-100 border border-accent mr-1"
            color="accent"
            mat-button
    >Gestisci stato ordine
    </button>
  </ng-container>


</div>
<!-- endregion Scegli se vuoi modificare l'anagrafica o il contenuto -->

<!-- region payment status -->
<div [formGroup]="formGroupOrder" *ngIf="_managingPaymentStatus && !showOnlyContent && !readOnlyOrder && !readOnlyContent">

  <div fxLayout="column">
    <div fxLayout="row">
      <mat-form-field fxFlex="100">
        <mat-label>Stato del pagamento</mat-label>
        <mat-select (selectionChange)="resetPaidPrice();setSelectedPaymentStatus($event)"
                    [disabled]="readOnlyOrder || _entity.paymentStatus == paymentStates.paid"
                    [matTooltip]="readOnlyOrder ? 'Scegli lo stato del pagamento dell\'ordine' : ''"
                    formControlName="paymentStatus">

          <mat-option *ngFor="let status of paymentStatusValues" [value]="status">
            {{status | orderPaymentStatus}}
          </mat-option>
        </mat-select>

      </mat-form-field>
    </div>

      <!-- Gestione acconto -->
      <ng-container
        *ngIf="formGroupOrder.controls['paymentStatus'].value == paymentStates.advance || _entity.paidPrice">
        <mat-form-field fxFlex>
          <mat-label>Acconto ricevuto</mat-label>
          <input
            [matTooltip]="'Acconto ricevuto'"
            [min]="0"
            [options]="{ prefix: '€', thousands: '.', decimal: ',', precision: 2}"
            [readonly]="readOnlyOrder"
            currencyMask
            formControlName="paidPrice"
            id="paidPrice"
            matInput
          >
          <!--            <span matPrefix>€&nbsp;</span>-->
        </mat-form-field>
      </ng-container>


    <div fxLayout="column">

      <div fxLayout="row">

        <button (click)="updateOrder()"
                [disabled]="isApplyPaymentStatusButtonDisabled() && (!(formGroupOrder.dirty))"
                [matTooltip]="errorToShow"
                color="primary"
                fxFlex
                mat-raised-button>

          <mat-icon>check</mat-icon>
          &nbsp;<span class="hide-mobile">Applica stato pagamento</span>

        </button>
      </div>
    </div>
  </div>

</div>

<!--  region order status-->
<div *ngIf="_managingSellOrderStatus && !showOnlyContent && !readOnlyOrder && !readOnlyContent">

  <div fxLayout="column">

    <div fxLayout="row">


      <mat-form-field fxFlex>
        <mat-label>Stato dell'ordine</mat-label>

        <mat-select
          (selectionChange)="setSelectedOrderStatus($event);onStatusChange()"
          [disabled]="_entity?.status === orderStatuses.completed || _entity?.status === orderStatuses.failed"
        >
          <mat-option *ngIf="availableOrderStates.length === 0">
            Nessuno stato disponibile.
          </mat-option>
          <mat-option *ngFor="let status of availableOrderStates" [value]="status">
            {{status | orderStatus}}
          </mat-option>

        </mat-select>

      </mat-form-field>
    </div>

    <div fxLayout="row">

      <div *ngIf="showManageWarehouseButton(selectedStatus)" fxFlex>

        <div *ngIf="!isOrderFailed"
             class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
          <p fxLayoutAlign="center center">
            <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
            Devi gestire tutti gli articoli prima di continuare.
          </p>
        </div>

        <div *ngIf="autoscaleErrorOnSellOrderClose && !isOrderFailed"
             class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
          <p fxLayoutAlign="center center">
            <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
            Non tutte le giacenze possono essere scalate automaticamente.
            <br>
            Gestiscile nelle impostazioni avanzate.
          </p>
        </div>

        <div *ngIf="isOrderFailed"
             class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
          <p fxLayoutAlign="center center">
            <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
            Devi gestire il rollback delle giacenze prima di continuare.
          </p>
        </div>


        <mat-divider></mat-divider>


        <!--        <div>-->
        <mat-accordion>
          <mat-expansion-panel [expanded]="autoscaleErrorOnSellOrderClose">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Impostazioni avanzate
              </mat-panel-title>
            </mat-expansion-panel-header>


            <div *ngFor="let item of itemsToHandle; let i = index" fxLayout="column">

              <div fxFlex fxLayout="row">
                <p>{{item.item.name}} x{{item.quantity}} da {{item.warehouse?.name}}</p>
              </div>

              <mat-form-field fxFlex="100">

                <!--                  If I am completing order-->
                <mat-select
                  (selectionChange)="canAutoscaleOnOrderClose(); setGlobalErrorOnOrderClose(); setGlobalAutoscaleDropdown()"
                  *ngIf="!isOrderFailed"
                  [(ngModel)]="closingWrappers[i].selectedStatus"
                >
                  <mat-option *ngFor="let status of updatedStates" [value]="status">
                    {{status! | orderContentStatus}}
                  </mat-option>
                </mat-select>
                <button (click)="closingWrappers[i].selectedStatus = undefined;unsetGeneralContentStatus()"
                        *ngIf="closingWrappers[i].selectedStatus"
                        mat-button
                        mat-icon-button
                        matSuffix>
                  <mat-icon>close</mat-icon>
                </button>

                <!--                  If I am canceling order-->
                <mat-select
                  (selectionChange)="canAutoscaleOnOrderClose(); setGlobalErrorOnOrderClose(); setGlobalRollbackDropdown()"
                  *ngIf="isOrderFailed"
                  [(ngModel)]="closingWrappers[i].selectedRollback"
                  [matTooltip]="closingWrappers[i].selectedRollback?.tooltip ?? ''"
                >
                  <mat-option *ngFor="let status of rollbackStates" [value]="status">
                    {{status.name}}
                  </mat-option>
                </mat-select>
                <button (click)="closingWrappers[i].selectedRollback = undefined;unsetGeneralContentRollback()"
                        *ngIf="closingWrappers[i].selectedRollback"
                        mat-button
                        mat-icon-button
                        matSuffix>
                  <mat-icon>close</mat-icon>
                </button>

              </mat-form-field>
              <div>

                <div *ngIf="closingWrappers[i] && closingWrappers[i].showAutoscaleError"
                     class="border border-warn disclaimer-box mat-background-light p-2_5 mb-2_5" fxFlex>
                  <p>
                    {{closingWrappers[i].autoscaleError!}}
                  </p>
                </div>
              </div>
            </div>


          </mat-expansion-panel>
        </mat-accordion>
        <!--        </div>-->


        <div class="mt-1_5 w-100">

          <!--      in caso di completamento -->
          <mat-form-field *ngIf="!isOrderFailed" fxFlex="100">
            <mat-label>Gestione generale</mat-label>

            <mat-select
              (selectionChange)="setGeneralStatus($event);updateContentStatusAndCheckErrorsOnOrderClose()"
              *ngIf="!isOrderFailed"
              [(ngModel)]="generalContentStatus"
              [disabled]="_entity?.status === orderStatuses.completed || _entity?.status === orderStatuses.failed"

            >
              <mat-option *ngIf="updatedStates.length === 0">
                Nessuno stato disponibile.
              </mat-option>
              <mat-option *ngFor="let status of updatedStates" [value]="status">
                {{status | orderContentStatus}}
              </mat-option>
            </mat-select>

            <button (click)="unsetGeneralContentStatus();unsetAllContentStatus()"
                    *ngIf="generalContentStatus"
                    [disabled]="_entity?.status === orderStatuses.completed || _entity?.status === orderStatuses.failed"
                    mat-button
                    mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>


          <!--      in caso di annullamento -->
          <mat-form-field *ngIf="isOrderFailed" fxFlex="100">
            <mat-label>Gestione generale</mat-label>

            <mat-select
              (selectionChange)="setGeneralRollback($event);updateContentStatusAndCheckErrorsOnOrderClose();canAutoscaleOnOrderClose();setGlobalErrorOnOrderClose()"
              *ngIf="isOrderFailed"
              [(ngModel)]="generalContentRollback"
              [matTooltip]="generalContentRollback?.tooltip ?? ''"

            >
              <mat-option *ngIf="rollbackStates.length === 0">
                Nessuno stato disponibile.
              </mat-option>
              <mat-option *ngFor="let status of rollbackStates" [value]="status">
                {{status.name}}
              </mat-option>
            </mat-select>

            <button (click)="unsetGeneralContentRollback();unsetAllContentRollback()"
                    *ngIf="generalContentRollback"
                    mat-button
                    mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

      </div>
    </div>

    <div fxLayout="column">

      <div fxLayout="row" fxLayoutAlign="center center">

        <div *ngIf="!canTransition(selectedStatus) && errorToShow"
             class="border border-warn disclaimer-error mat-background-light p-2_5 mb-2_5" fxFlex="45">
          <p fxLayoutAlign="center center">
            {{errorToShow}}
          </p>
        </div>
      </div>

      <div *ngIf="_entity.customerId" class="mb-2" fxLayout="row">
        <mat-checkbox [(ngModel)]="sendEmailToClientOnStatusChange">Voglio mandare un'email al cliente per informarlo
          del cambio di
          stato
        </mat-checkbox>
      </div>


      <span *ngIf="selectedStatus == orderStatuses.completed &&_entity.paymentStatus != paymentStates.paid"
            class="my-2 color-accent">Attenzione! Il pagamento dell'ordine non è completo, puoi comunque completare l'ordine</span>

      <div fxLayout="row">

        <button (click)="applyStatusFn(selectedStatus)"
                [disabled]="isApplyStatusButtonDisabled() || (showManageWarehouseButton(selectedStatus) && autoscaleErrorOnSellOrderClose) || savingStatus"
                [matTooltip]="errorToShow"
                color="primary"
                fxFlex
                mat-raised-button>

          <mat-icon>check</mat-icon>
          &nbsp;<span class="hide-mobile">Applica stato</span>

        </button>
      </div>
    </div>
  </div>

</div>
<!--  endregion order status-->

<!--  region order creation -->
<div
  *ngIf="((!isEditing || updatingOrder || readOnlyOrder) && !showOnlyContent && !readOnlyContent) || (_entity?.status === orderStatuses.completed || _entity?.status === orderStatuses.failed)">
  <mat-accordion>

    <mat-expansion-panel [expanded]="anagraphicOpened">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Anagrafica
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div [formGroup]="formGroupOrder" fxLayout="row wrap" fxLayoutAlign="space-between">

        <div fxFlex>
          <ng-container *ngIf="!isEditing && !shopContext && showShopCheckbox">
            <mat-checkbox (change)="setMandatoryShop($event)">
              Voglio che il punto vendita sia obbligatorio
            </mat-checkbox>
          </ng-container>
        </div>

        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="3%">

          <mat-form-field fxFlex>
            <mat-label>Seleziona punto vendita</mat-label>

            <mat-select
              (selectionChange)="manageShopFlag($event)"
              [disabled]="(!!selectedShop && shopContext) || readOnlyOrder"
              formControlName="shopId"
            >

              <mat-select-trigger *ngIf="selectedShop">
                {{selectedShop.name}}
              </mat-select-trigger>

              <aw-search-option (filteredOptions)="filteredShopsOptions = $event" *ngIf="shops.length > 0"
                                [key]="'name'" [options]="shops"></aw-search-option>

              <mat-option *ngIf="shops?.length === 0">
                Nessuno punto vendita disponibile.
              </mat-option>

              <mat-option *ngIf="filteredShopsOptions.length === 0 && shops?.length !== 0">
                Nessuno punto vendita trovato.
              </mat-option>

              <mat-option *ngFor="let shop of filteredShopsOptions" [value]="shop.id">
                {{shop.name}}
              </mat-option>

            </mat-select>
            <button (click)="unsetShop();$event.stopPropagation()"
                    *ngIf="selectedShop"
                    [disabled]="(!!selectedShop && shopContext) || readOnlyOrder"
                    mat-button
                    mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>

          </mat-form-field>
        </div>

        <div fxFlex>
          <mat-checkbox (change)="setIsCustomerEqualsToRecipient($event)" [checked]="isCustomerAlsoRecipient">
            Cliente e destinatario coincidono
          </mat-checkbox>
        </div>

        <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">

          <!-- Customer-->
          <mat-form-field fxFlex>
            <mat-label>Seleziona cliente</mat-label>
            <mat-select
              (selectionChange)="setSelectedCustomer($event);getCustomerInfoFn();clearSelectedCustomerInfo();"
              [disabled]="readOnlyOrder"
              [matTooltip]="readOnlyOrder ? generateCustomerTooltip(selectedCustomer) : ''"
              formControlName="customerId"

            >
              <mat-select-trigger *ngIf="selectedCustomer">
                {{selectedCustomer.name}} {{selectedCustomer.surname ?? ""}}
              </mat-select-trigger>

              <aw-search-option (filteredOptions)="filteredCustomers = $event" *ngIf="customersAndRecipients.length > 0"
                                [options]="customersAndRecipients" [predicate]="customerPredicate"></aw-search-option>


              <mat-option *ngIf="customersAndRecipients.length === 0 || filteredCustomers.length === 0">
                Nessun cliente trovato.
              </mat-option>

              <mat-option *ngFor="let customer of filteredCustomers" [value]="customer.id" class="customer">
                <ng-container [ngTemplateOutletContext]="{customer: customer}" [ngTemplateOutlet]="customerTemplate">
                </ng-container>
              </mat-option>

              <button (click)="$event.stopPropagation();openCustomerForm('customerId')" color="primary" mat-button
                      matSuffix>
                Aggiungi cliente
              </button>

            </mat-select>


            <button (click)="unsetSelectedCustomer();$event.stopPropagation()"
                    *ngIf="selectedCustomer && !readOnlyOrder" aria-label="Clear"
                    mat-button mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>
            <span *ngIf="loadingCustomers" matPrefix><i class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

          </mat-form-field>

          <!-- Recipient-->
          <mat-form-field *ngIf="!isCustomerAlsoRecipient" fxFlex>
            <mat-label>Seleziona destinatario</mat-label>
            <mat-select
              (selectionChange)="setSelectedRecipient($event);getRecipientInfoFn();clearSelectedRecipientInfo()"
              [disabled]="readOnlyOrder"
              [matTooltip]="readOnlyOrder ? generateCustomerTooltip(selectedRecipient) : ''"
              formControlName="recipientId">

              <mat-select-trigger *ngIf="selectedRecipient">
                {{selectedRecipient.name}} {{selectedRecipient.surname ?? ""}}
              </mat-select-trigger>

              <aw-search-option (filteredOptions)="filteredRecipients = $event"
                                *ngIf="customersAndRecipients.length > 0"
                                [options]="customersAndRecipients" [predicate]="customerPredicate"></aw-search-option>


              <mat-option *ngIf="customersAndRecipients.length === 0 || filteredRecipients.length === 0">
                Nessun cliente trovato.
              </mat-option>

              <mat-option *ngFor="let customer of filteredRecipients" [value]="customer.id" class="customer">
                <ng-container [ngTemplateOutletContext]="{customer: customer}" [ngTemplateOutlet]="customerTemplate">
                </ng-container>
              </mat-option>

              <button (click)="$event.stopPropagation();openCustomerForm('recipientId')" color="primary" mat-button
                      matSuffix>
                Aggiungi cliente
              </button>

            </mat-select>


            <button (click)="unsetSelectedRecipient();$event.stopPropagation()"
                    *ngIf="selectedCustomer && !readOnlyOrder" aria-label="Clear"
                    mat-button mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>
            <span *ngIf="loadingCustomers" matPrefix><i class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

          </mat-form-field>

        </div>

        <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">
          <mat-form-field fxFlex>

            <mat-label>Informazioni fatturazione</mat-label>

            <mat-select
              (selectionChange)="setSelectedBillingInfo($event)"
              [disabled]="!selectedCustomer || loadingCustomerInfo || readOnlyOrder"
              [matTooltip]="readOnlyOrder ? generateCustomerInfoTooltip(selectedBillingInfo) : ''"
              formControlName="billingInformationId"
            >

              <mat-select-trigger *ngIf="selectedBillingInfo">
                {{selectedBillingInfo.addressName}}
              </mat-select-trigger>

              <aw-search-option (filteredOptions)="filteredBillingInformation = $event"
                                *ngIf="customerInformation.length > 0" [options]="customerInformation"
                                [predicate]="customerInfoPredicate"></aw-search-option>


              <mat-option *ngIf="customerInformation.length === 0 || filteredBillingInformation.length === 0">
                Nessuna informazione cliente trovata.
              </mat-option>


              <mat-option *ngFor="let customerInfo of filteredBillingInformation" [value]="customerInfo.id"
                          class="customer">
                <ng-container [ngTemplateOutletContext]="{customerInfo: customerInfo}"
                              [ngTemplateOutlet]="customerInfoTemplate">
                </ng-container>
              </mat-option>

              <button (click)="$event.stopPropagation();openCustomerInfoForm('billingInformationId')"
                      color="primary" mat-button
                      matSuffix>
                Aggiungi informazioni cliente
              </button>
            </mat-select>

            <button (click)="unsetSelectedCustomerBillingInfo();$event.stopPropagation()"
                    *ngIf="selectedBillingInfo && !readOnlyOrder" aria-label="Clear"
                    mat-button mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>

            <span *ngIf="loadingCustomers || loadingCustomerInfo" matPrefix><i
              class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Informazioni spedizione</mat-label>

            <mat-select
              (selectionChange)="setSelectedShippingInfo($event)"
              [disabled]="!selectedRecipient || loadingRecipientInfo || readOnlyOrder"
              [matTooltip]="readOnlyOrder ? generateCustomerInfoTooltip(selectedShippingInfo) : ''"
              formControlName="shippingInformationId"

            >
              <mat-select-trigger *ngIf="selectedShippingInfo">
                {{selectedShippingInfo.addressName}}
              </mat-select-trigger>

              <aw-search-option (filteredOptions)="filteredShippingInformation = $event"
                                *ngIf="recipientInformation.length > 0"
                                [options]="recipientInformation" [predicate]="customerInfoPredicate"></aw-search-option>


              <mat-option *ngIf="recipientInformation.length === 0 || filteredShippingInformation.length === 0">
                Nessuna informazione cliente trovata.
              </mat-option>

              <mat-option *ngFor="let customerInfo of filteredShippingInformation" [value]="customerInfo.id"
                          class="customer">
                <ng-container [ngTemplateOutletContext]="{customerInfo: customerInfo}"
                              [ngTemplateOutlet]="customerInfoTemplate">
                </ng-container>
              </mat-option>

              <button (click)="$event.stopPropagation();openCustomerInfoForm('shippingInformationId')"
                      color="primary" mat-button
                      matSuffix>
                Aggiungi informazioni cliente
              </button>
            </mat-select>

            <button (click)="unsetSelectedCustomerShippingInfo();$event.stopPropagation()"
                    *ngIf="selectedShippingInfo && !readOnlyOrder" aria-label="Clear"
                    mat-button mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>

            <span *ngIf="loadingCustomers || loadingRecipientInfo" matPrefix><i
              class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

          </mat-form-field>
        </div>


        <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-evenly" fxLayoutGap="3%">
          <mat-form-field fxFlex>
            <mat-label>Data dell'ordine</mat-label>

            <input (dateChange)="creationDate = $event.value"
                   (focusin)="orderDatePicker.open()"
                   [disabled]="readOnlyOrder"
                   [matDatepicker]="orderDatePicker"
                   [max]="formGroupOrder.controls['deadlineDate']?.value"
                   formControlName="orderDate"
                   matInput
                   name="orderDate"
            >
            <mat-datepicker-toggle [for]="orderDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker-toggle (click)="formGroupOrder.controls['orderDate'].setValue(null)" matSuffix>
              <mat-icon matDatepickerToggleIcon (click)="formGroupOrder.markAsDirty()">clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #orderDatePicker></mat-datepicker>
            <mat-error *ngIf="formGroupOrder.controls['orderDate'].errors?.['required']"
                       class="p-error block">
              La data è richiesta
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Scadenza prevista</mat-label>

            <input (dateChange)="deadlineDate = $event.value"
                   (focusin)="deadlineDatepicker.open()"
                   [disabled]="readOnlyOrder"
                   [matDatepicker]="deadlineDatepicker"
                   [min]="formGroupOrder.controls['orderDate'].value"
                   formControlName="deadlineDate"
                   matInput
                   name="deadlineDate"
            >
            <mat-datepicker-toggle [for]="deadlineDatepicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker-toggle (click)="formGroupOrder.controls['deadlineDate'].setValue(null)" matSuffix>
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #deadlineDatepicker></mat-datepicker>
            <mat-error *ngIf="formGroupOrder.controls['deadlineDate'].errors?.['required']"
                       class="p-error block">
              La data è richiesta
            </mat-error>

          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Data di consegna dell'ordine</mat-label>

            <input (dateChange)="deliveryDate = $event.value"
                   (focusin)="deliveryDatePicker.open()"
                   [disabled]="readOnlyOrder"

                   [matDatepicker]="deliveryDatePicker"
                   [min]="formGroupOrder.controls['orderDate'].value"
                   formControlName="deliveryDate"
                   matInput
                   name="deliveryDate"
            >
            <mat-datepicker-toggle [for]="deliveryDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker-toggle (click)="formGroupOrder.controls['deliveryDate'].setValue(null)" matSuffix>
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #deliveryDatePicker></mat-datepicker>
          </mat-form-field>

        </div>

        <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">

          <mat-form-field fxFlex>
            <mat-label>Seleziona tipo ritiro/consegna</mat-label>

            <mat-select
              (selectionChange)="setSelectedDeliveryOption($event.value)"
              [disabled]="readOnlyOrder"
              formControlName="deliveryOption"

            >

              <mat-option *ngFor="let deliveryOption of deliveryOptions" [value]="deliveryOption">
                {{deliveryOption | deliveryOptionsPipe}}
              </mat-option>
            </mat-select>

            <button (click)="unsetSelectedDeliveryOption();$event.stopPropagation()" *ngIf="selectedDeliveryOption"
                    [disabled]="readOnlyOrder"
                    mat-button
                    mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>

          </mat-form-field>


          <mat-form-field fxFlex *ngIf="selectedDeliveryOption !== availableDeliveryOptions.shop">
            <mat-label>Seleziona corriere</mat-label>

            <mat-select
              (selectionChange)="setSelectedDeliveryCompany($event)"
              [disabled]="readOnlyOrder"
              formControlName="deliveryCompanyId"

            >

              <aw-search-option (filteredOptions)="deliveryCompaniesFiltered = $event"
                                *ngIf="deliveryCompanies.length > 0"
                                [key]="'name'" [options]="deliveryCompanies"></aw-search-option>

              <mat-option *ngIf="deliveryCompanies.length === 0">
                Nessuno corriere disponibile.
              </mat-option>

              <mat-option *ngIf="deliveryCompaniesFiltered.length === 0 && deliveryCompanies.length !== 0">
                Nessuno corriere trovato.
              </mat-option>

              <mat-option *ngFor="let deliveryCompany of deliveryCompaniesFiltered" [value]="deliveryCompany.id">
                {{deliveryCompany.name}}
              </mat-option>
              <mat-divider></mat-divider>

              <button (click)="$event.stopPropagation();openDeliveryCompanyForm()"
                      class="align-content-center justify-content-center border border-primary m-1"
                      color="primary"
                      fxLayoutAlign="center center"
                      mat-button
                      matSuffix>
                Aggiungi corriere
              </button>

            </mat-select>

            <button (click)="unsetSelectedDeliveryCompany();$event.stopPropagation()" *ngIf="selectedDeliveryCompany"
                    [disabled]="readOnlyOrder"
                    mat-button
                    mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>
            <span *ngIf="loadingDeliveryCompanies" matPrefix><i class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

          </mat-form-field>
        </div>

        <!-- Fascia oraria e note consegna -->
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">

          <!-- Fascia oraria -->
          <mat-form-field fxFlex>
            <mat-label>Fascia oraria consegna</mat-label>

            <mat-select
              (selectionChange)="setSelectedDeliveryTimeSlot($event)"
              [disabled]="readOnlyOrder"
              formControlName="deliveryTimeSlotId"

            >

              <aw-search-option (filteredOptions)="deliveryTimeSlotsFiltered = $event"
                                *ngIf="deliveryTimeSlots.length > 0"
                                [key]="'name'" [options]="deliveryTimeSlots"></aw-search-option>

              <mat-option *ngIf="deliveryTimeSlots.length === 0">
                Nessuna fascia oraria disponibile.
              </mat-option>

              <mat-option *ngIf="deliveryTimeSlotsFiltered.length === 0 && deliveryTimeSlots.length !== 0">
                Nessuna fascia oraria trovata.
              </mat-option>

              <mat-option *ngFor="let deliveryTimeSlot of deliveryTimeSlotsFiltered" [value]="deliveryTimeSlot.id">
                {{deliveryTimeSlot.name}}
              </mat-option>
              <mat-divider></mat-divider>

              <button (click)="$event.stopPropagation();openDeliveryTimeSlotForm()"
                      class="align-content-center justify-content-center border border-primary m-1"
                      color="primary"
                      fxLayoutAlign="center center"
                      mat-button
                      matSuffix>
                Aggiungi fascia oraria
              </button>

            </mat-select>

            <button (click)="unsetSelectedDeliveryTimeSlot();$event.stopPropagation()" *ngIf="selectedDeliveryTimeSlot"
                    [disabled]="readOnlyOrder"
                    mat-button
                    mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>
            <span *ngIf="loadingDeliveryTimeSlots" matPrefix><i class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

          </mat-form-field>

          <!-- Note consegna -->
          <mat-form-field fxFlex>
            <mat-label>Note consegna</mat-label>
            <input [readonly]="readOnlyOrder"
                   formControlName="deliveryNotes"
                   id="deliveryNotes"
                   matInput>
          </mat-form-field>
        </div>


        <mat-form-field fxFlex="100">
          <mat-label>Prezzo finale (non calcolato)</mat-label>
          <input
            [matTooltip]="'Prezzo finale dell\'ordine'"
            [min]="0"
            [options]="{ prefix: '€ ', thousands: '.', decimal: ',', precision: 2 }"
            [readonly]="readOnlyOrder"
            currencyMask
            formControlName="finalPrice"
            id="finalPrice"
            matInput
            type="text"
          >
        </mat-form-field>


        <!--gestione stato del pagamento-->
        <mat-form-field fxFlex="100">
          <mat-label>Stato del pagamento</mat-label>
          <mat-select (selectionChange)="resetPaidPrice()"
                      [disabled]="readOnlyOrder || _entity.paymentStatus == paymentStates.paid"
                      [matTooltip]="readOnlyOrder ? 'Scegli lo stato del pagamento dell\'ordine' : ''"
                      formControlName="paymentStatus"
          >

            <mat-option *ngFor="let status of paymentStatusValues" [value]="status">
              {{status | orderPaymentStatus}}
            </mat-option>
          </mat-select>

        </mat-form-field>


        <!-- Gestione acconto -->
        <ng-container
          *ngIf="formGroupOrder.controls['paymentStatus'].value == paymentStates.advance || _entity.paidPrice">
          <mat-form-field fxFlex>
            <mat-label>Acconto ricevuto</mat-label>
            <input
              [matTooltip]="'Prezzo finale dell\'ordine'"
              [min]="0"
              [options]="{ prefix: '€', thousands: '.', decimal: ',', precision: 2}"
              [readonly]="readOnlyOrder"
              currencyMask
              formControlName="paidPrice"
              id="paidPrice"
              matInput
            >

            <!--            <span matPrefix>€&nbsp;</span>-->
          </mat-form-field>
        </ng-container>

        <div fxLayout="row" fxFlex="100">
          <mat-checkbox (change)="setCustomerPhoto($event.checked)" formControlName="photo">
            Il cliente ha richiesto foto
          </mat-checkbox>
        </div>

        <mat-form-field fxFlex="100">
          <mat-label>Note</mat-label>
          <textarea [readonly]="readOnlyOrder"
                    cdkAutosizeMinRows="5"
                    cdkTextareaAutosize
                    formControlName="note"
                    id="note"
                    matInput>
            </textarea>
        </mat-form-field>

      </div>

    </mat-expansion-panel>

  </mat-accordion>

  <button (click)="updateOrder()"
          *ngIf="isEditing && !readOnlyOrder"
          [disabled]="(savingOrder || saving || savingOrderAndContent || savingOrderContent || savingStatus) || (!(formGroupOrder.dirty))"
          class="w-full mt-2_5"
          color="primary"
          mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Aggiorna ordine</span>
  </button>

</div>
<!--  endregion order creation-->

<!--  region order content creation-->
<div
  *ngIf="(!isEditing || updatingOrderContent || showOnlyContent || readOnlyContent || _managingSellOrderContent) && !_managingSellOrderStatus && !readOnlyOrder"
  class="mt-4">

  <form *ngIf="!loading; else loadingData" [formGroup]="formGroupOrderContent">

    <div *ngIf="creationWrappers.length === 0"
         class="border border-yellow-warn disclaimer-box mat-background-light p-2_5 mb-2_5 mt-2_5"
         fxFlex="100">
      <p fxLayoutAlign="center center">
        <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
        Nessun articolo presente nell'ordine.
      </p>
    </div>

    <mat-accordion fxFlex>
      <div *ngFor="let content of orderContents().controls; let i=index" class="mb-4" formArrayName="orderContents">
        <mat-expansion-panel
          [expanded]="(addingOrderContent && i === (orderContents().controls.length - 1)) ||
                          (readOnlyContent && creationWrappers[i].selectedItem?.id == itemToShow &&
                          creationWrappers[i].selectedWarehouse?.id == warehouseToShow)"
        >
          <!--      todo: chissà a che cazzo serve warehouseToShow   -->
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{getPanelTitle(i, content)}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div [formGroupName]="i" fxLayout="column">
            <div>
              <mat-button-toggle-group
                (change)="getStocks($event.value, i)"
                *ngIf="!(isEditing && i < (_entity?.content?.length ?? -1))"
                [disabled]="warehouses.length < 1 || readOnlyContent"
                formControlName="checked">
                <mat-button-toggle *ngFor="let state of stateOptions"
                                   [value]="state.value">{{state.label}}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">

              <mat-form-field fxFlex>
                <mat-label>{{creationWrappers[i].snapshot ? creationWrappers[i].snapshot?.warehouseName ?? "NON ASSEGNATO" : "Magazzino"}}</mat-label>

                <mat-select
                  (selectionChange)="emitChosenWarehouse($event.value.id, i)"
                  [disabled]="creationWrappers[i].disabledInput || !creationWrappers[i].checked"
                  formControlName="warehouse"
                >
                  <!--                  if not snapshot-->
                  <ng-container *ngIf="!creationWrappers[i].snapshot">

                    <aw-search-option (filteredOptions)="creationWrappers[i].filteredWarehouse = $event"
                                      *ngIf="warehouses.length > 0"
                                      [key]="'name'" [options]="warehouses"></aw-search-option>


                    <mat-option *ngIf="warehouses.length === 0">
                      Nessuno magazzino disponibile.
                    </mat-option>

                    <mat-option *ngIf="creationWrappers[i].filteredWarehouse.length === 0 && warehouses.length !== 0">
                      Nessuno magazzino trovato.
                    </mat-option>

                    <mat-option *ngFor="let warehouse of creationWrappers[i].filteredWarehouse" [value]="warehouse">
                      {{warehouse.name}}
                    </mat-option>
                  </ng-container>


                  <!--                  if snapshot-->
                  <ng-container *ngIf="creationWrappers[i].snapshot">
                    <mat-option>
                      {{creationWrappers[i].snapshot?.warehouseName ?? "Non assegnato"}}
                    </mat-option>
                  </ng-container>


                </mat-select>
                <button (click)="unsetSelectedWarehouse(i);$event.stopPropagation()"
                        *ngIf="creationWrappers[i].selectedWarehouse"
                        [disabled]="creationWrappers[i].disabledInput || !creationWrappers[i].checked"
                        mat-button
                        mat-icon-button
                        matSuffix>
                  <mat-icon>close</mat-icon>
                </button>


              </mat-form-field>

              <mat-form-field fxFlex>
                <mat-label>{{creationWrappers[i].snapshot?.variantItemName ?? "Articolo"}}</mat-label>
                <span *ngIf="creationWrappers[i]?.loadingItems" matPrefix><i
                  class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>
                <mat-select
                  (selectionChange)="chosenItem($event.value, i)"
                  [disabled]="readOnlyContent || creationWrappers[i]?.disabledInput || !!creationWrappers[i]?.loadingItems || (!!creationWrappers[i]?.checked && !creationWrappers[i]?.selectedWarehouse)"
                  formControlName="item"
                >
                  <!--                  if not snapshot-->
                  <ng-container *ngIf="!creationWrappers[i].snapshot">

                    <aw-search-option
                      (filteredOptions)="creationWrappers[i].filteredItems = $event"
                      *ngIf="!((creationWrappers[i]?.checked && creationWrappers[i]?.warehouseItems?.length === 0) || (!creationWrappers[i]?.checked && companyItems?.length === 0))"
                      [predicate]="itemPredicate"
                      [options]="(noWarehousesAvailable ? companyItems : creationWrappers?.[i]?.warehouseItems ?? [])"></aw-search-option>


                    <mat-option
                      *ngIf="(creationWrappers[i]?.checked && creationWrappers[i]?.warehouseItems?.length === 0) || (!creationWrappers[i]?.checked && companyItems?.length === 0)">
                      Nessun articolo disponibile.
                    </mat-option>

                    <mat-option
                      *ngIf="creationWrappers[i].filteredItems.length <= 0 && !((creationWrappers[i]?.checked && creationWrappers[i]?.warehouseItems?.length === 0) || (!creationWrappers[i]?.checked && companyItems?.length === 0))">
                      Nessun articolo trovato
                    </mat-option>

                    <mat-option
                      *ngFor="let item of creationWrappers[i].filteredItems"
                      [value]="item">
                      {{item.name}}
                    </mat-option>
                  </ng-container>

                  <!--                  if snapshot-->
                  <ng-container *ngIf="creationWrappers[i].snapshot">
                    <mat-option>
                      {{creationWrappers[i].snapshot?.variantItemName}}
                    </mat-option>
                  </ng-container>
                </mat-select>
                <button (click)="unsetSelectedItem(i);$event.stopPropagation()"
                        *ngIf="creationWrappers[i].selectedItem"
                        [disabled]="readOnlyContent || creationWrappers[i].disabledInput || !!creationWrappers[i].loadingItems || (!!creationWrappers[i].checked && !creationWrappers[i].selectedWarehouse)"
                        mat-button
                        mat-icon-button
                        matSuffix>
                  <mat-icon>close</mat-icon>
                </button>

              </mat-form-field>
            </div>

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">

              <mat-form-field fxFlex>
                <mat-label>
                  Quantità{{creationWrappers?.[i]?.selectedItem?.unit ? (' (' + creationWrappers?.[i]?.selectedItem?.unit) + ')' : ('')}}
                </mat-label>
                <!--                region quantity -->
                <input #quantity
                       (keyup)="computeCalculatedPrice(i, +quantity?.value!); checkIfCanAutoscale(i, +quantity.value!)"
                       *ngIf="!creationWrappers?.[i]?.selectedItem?.unit"
                       [matTooltip]="'Quantità'"
                       [min]="1"
                       [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 0}"
                       [readonly]="readOnlyContent || savingOrderContent || !getSelectedItem(i) || (isEditing && i < (_entity?.content?.length ?? -1) && _entity?.content?.[i]?.status !== orderContentStatuses.notUpdated)"
                       currencyMask
                       formControlName="quantity"
                       matInput
                       type="text"
                >
                <!--                endregion quantity -->

                <!--                region unit_quantity -->
                <input #quantity_unit
                       (keyup)="computeCalculatedPrice(i, +quantity_unit.value!); checkIfCanAutoscale(i, +quantity_unit.value!)"
                       *ngIf="creationWrappers?.[i]?.selectedItem?.unit"
                       [matTooltip]="'Quantità (' + creationWrappers?.[i]?.selectedItem?.unit! + ')'"
                       [min]="1"
                       [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2}"
                       [readonly]="readOnlyContent || savingOrderContent || !getSelectedItem(i) || (isEditing && i < (_entity?.content?.length ?? -1) && _entity?.content?.[i]?.status !== orderContentStatuses.notUpdated)"
                       currencyMask
                       formControlName="quantity"
                       matInput
                       type="text"
                >
                <span *ngIf="creationWrappers?.[i]?.selectedItem?.unit"
                      matSuffix>{{creationWrappers?.[i]?.selectedItem?.unit!}}</span>
                <!--                endregion unit_quantity -->


                <mat-error *ngIf="orderContents().controls[i].get('quantity')?.dirty &&
                                                    orderContents().controls[i].hasError('required', 'quantity')"
                >La quantità è obbligatoria
                </mat-error>
                <mat-error *ngIf="orderContents().controls[i].get('quantity')?.dirty &&
                                                    orderContents().controls[i].hasError('min', 'quantity')"
                >La quantità non può essere 0
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex>
                <mat-label>
                  Prezzo{{creationWrappers?.[i]?.selectedItem?.unit ? ('/' + creationWrappers?.[i]?.selectedItem?.unit) : (' unitario')}}</mat-label>

                <input #unitPrice
                       (keyup)="computeCalculatedPrice(i, undefined ,+unitPrice.value);"
                       [matTooltip]="'Prezzo' + (creationWrappers?.[i]?.selectedItem?.unit ? ('/' + creationWrappers?.[i]?.selectedItem?.unit) : (' unitario'))"
                       [min]="0"
                       [options]="{ prefix: '€', thousands: '.', decimal: ',', precision: 2}"
                       [readonly]="readOnlyContent || savingOrderContent || !getSelectedItem(i) || (isEditing && i < (_entity?.content?.length ?? -1) && _entity?.content?.[i]?.status !== orderContentStatuses.notUpdated)"
                       currencyMask
                       formControlName="itemUnitPrice"
                       id="itemUnitPrice"
                       matInput
                       type="text"
                >
                <!--                <span matPrefix>€&nbsp;</span>-->

                <mat-error *ngIf="orderContents().controls[i].get('itemUnitPrice')?.dirty &&
                                                    orderContents().controls[i].hasError('required', 'itemUnitPrice')"
                >Il prezzo è obbligatorio
                </mat-error>

              </mat-form-field>
            </div>


            <div
              *ngIf="(!isEditing && creationWrappers[i].checked) || !(isEditing && creationWrappers[i].selectedWarehouse == undefined)"
              fxLayout="column">
              <div fxLayout="row">
                <div *ngIf="creationWrappers[i] && creationWrappers[i].showAutoscaleError"
                     class="border border-yellow-warn disclaimer-box mat-background-light p-2_5 mb-2_5"
                     fxFlex>
                  <p>
                    <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
                    {{creationWrappers[i].autoscaleError}}
                  </p>
                </div>
              </div>


              <div
                *ngIf="creationWrappers[i].checked"
                fxLayout="column"
                fxLayoutGap="3%"
              >
                <mat-label>Gestione giacenze del magazzino</mat-label>
                <mat-form-field fxFlex>
                  <mat-select
                    (selectionChange)="checkIfCanAutoscale(i)"
                    [disabled]="readOnlyContent || isEditing && i < (_entity?.content?.length ?? -1) && _entity?.content?.[i]?.status !== orderContentStatuses.notUpdated"
                    formControlName="state"
                  >
                    <mat-option *ngIf="orderContentStates.length === 0">
                      Nessuno stato disponibile.
                    </mat-option>
                    <mat-option *ngFor="let status of orderContentStates" [value]="status">
                      {{status | orderContentStatus}}
                    </mat-option>

                  </mat-select>
                  <button (click)="selectedStatus = ''"
                          *ngIf="selectedStatus && !creationWrappers[i]?.disabledInput"
                          [disabled]="_entity?.status === orderStatuses.completed || _entity?.status === orderStatuses.failed"
                          mat-button
                          mat-icon-button
                          matSuffix>
                    <mat-icon>close</mat-icon>
                  </button>

                </mat-form-field>

              </div>

            </div>

          </div>

          <div fxLayout="row">
            <button (click)="removeOrderContent(i); addingOrderContent = false"
                    *ngIf="!readOnlyContent && !(isEditing && i < (_entity?.content?.length ?? -1) && _entity?.content?.[i]?.status !== orderContentStatuses.notUpdated)"
                    [disabled]="savingOrderContent"
                    class="border border-warn mr-1"
                    color="warn"
                    fxFlex
                    mat-button
            >
              <mat-icon>delete</mat-icon>
              &nbsp;<span class="hide-mobile">Rimuovi articolo</span>

            </button>

          </div>


        </mat-expansion-panel>
      </div>

    </mat-accordion>
  </form>


  <div *ngIf="showDuplicateContentMessage"
       class="border border-warn disclaimer-box mat-background-light p-2_5 mb-2_5"
       fxLayoutAlign="center center"
  >
    <p>
      {{duplicateContentMessage}}
    </p>
  </div>


  <div *ngIf="showPriceChangedMessage"
       class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5"
       fxLayoutAlign="center center"
  >
    <p>
      <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
      Attenzione!
      In precedenza hai impostato un prezzo finale che potrebbe non essere più coerente con gli articoli presenti
      nell'ordine
    </p>
  </div>

  <div *ngIf="orderContents().controls.length > 0  && !readOnlyContent" class="col field">
    <p><strong>Prezzo calcolato:</strong> {{calculatedPrice | currency:'EUR'}}</p>
  </div>

  <div *ngIf="!mandatoryShop && selectedCustomer && selectedCustomer.email && !isEditing" class="col field">
    <mat-checkbox [(ngModel)]="sendEmailToClientOnCreation">Voglio mandare un'email al cliente per informarlo della
      creazione dell'ordine
    </mat-checkbox>
  </div>

  <button (click)="addOrderContent(); addingOrderContent = true; anagraphicOpened = false"
          *ngIf="(!isEditing || (isEditing && (_entity?.status === orderStatuses.toBeAssigned || _entity?.status === orderStatuses.inProgress))) && !readOnlyContent"
          [disabled]="showDuplicateContentMessage || savingOrderContent || savingOrderAndContent || formGroupOrderContent.invalid"
          class="mr-2_5"
          color="accent"
          mat-raised-button
  >
    <mat-icon>add</mat-icon>
    &nbsp;<span class="hide-mobile">Aggiungi articolo</span>
  </button>

  <button (click)="saveOrderAndContent()"
          *ngIf="!isEditing && !readOnlyContent"
          [disabled]="showDuplicateContentMessage || savingOrderAndContent || formGroupOrder.invalid || formGroupOrderContent.invalid"
          color="primary"
          mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Crea ordine</span>

  </button>

  <button (click)="updateOrderContentPre()"
          *ngIf="isEditing && !readOnlyContent"
          [disabled]="(showDuplicateContentMessage || savingOrder || saving || savingOrderAndContent || savingOrderContent || savingStatus) ||
                          (formGroupOrder.invalid || formGroupOrderContent.invalid) ||
                        (_entity?.status !== orderStatuses.toBeAssigned && _entity?.status !== orderStatuses.inProgress)"
          [matTooltip]="_entity?.status !== orderStatuses.toBeAssigned && _entity?.status !== orderStatuses.inProgress ? 'Non è possibile modificare il contenuto': ''"
          color="primary"
          mat-raised-button

  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Aggiorna contenuto ordine</span>

  </button>

</div>
<!--  endregion order content creation-->

