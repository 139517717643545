import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {
  AwAccessType,
  hasPermission,
  hasPermissionOnEntity,
  partialAccessSanitize
} from "../utils/global-functions-and-types";
import {AuthService} from "../services/auth.service";
import {Subscription} from "rxjs";
import {RequiredAccessLevel} from "../classes/required-access-level";


@Directive({
  selector: '[awAccess]'
})
export class AccessDirective implements OnInit {
  private subscription: Subscription = new Subscription();
  private requiredAccess: RequiredAccessLevel = new RequiredAccessLevel();
  private selected?: any

  constructor(private authService: AuthService,
              private viewContainer: ViewContainerRef,
              private templateRef: TemplateRef<any>,) {
  }

  @Input() set awAccess(value: AwAccessType | undefined) {
    if (value) {
      this.requiredAccess = partialAccessSanitize(value.accessLevel)
      this.selected = value.selected
    }
  }

  ngOnInit() {
    this.viewContainer.clear();
    this.subscription = this.authService
      .onUserChange()
      .subscribe((user) => {
          const isAuthenticated = !!user;
          this.viewContainer.clear()
          if (isAuthenticated) {
            user = user!

            let userHasPermission: boolean
            if (this.selected !== undefined)
              userHasPermission = hasPermissionOnEntity(user, this.requiredAccess, this.selected)
            else
              userHasPermission = hasPermission(user, this.requiredAccess)

            if (userHasPermission)
              this.viewContainer.createEmbeddedView(this.templateRef)
          }
        }
      )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
