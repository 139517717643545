import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'aw-removable-column',
  templateUrl: './removable-column.component.html',
  styleUrls: ['./removable-column.component.scss']
})
export class RemovableColumnComponent implements OnInit {

  // Name displayed on the header column
  @Input() headerName = ''

  @Output() removeColumn = new EventEmitter<void>()

  constructor() {
  }

  ngOnInit(): void {
  }

}
