import {Component, Input} from '@angular/core';
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {Constants} from "../../../utils/constants";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {ResponsiveService} from "../../../services/responsive.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {SalesService} from "../../../services/sales.service";
import {SaleDetail} from "../../../interfaces/sale";
import {SaleContentSnapshotData} from "../../../interfaces/sale-content-snapshot";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {SalesFormComponent} from "../sales-form/sales-form.component";


@Component({
  selector: 'aw-sale-content-table',
  templateUrl: './sale-content-table.component.html',
  styleUrls: ['./sale-content-table.component.scss']
})
export class SaleContentTableComponent extends BaseTableComponent<SaleContentSnapshotData> {


  saleStates  = Constants.saleStatuses

  @Input() shopId: number | null = null;
  @Input() defaultWarehouseId?: number;

  constructor(private salesService: SalesService,
              public responsiveService: ResponsiveService,
              public activatedRoute: ActivatedRoute,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
  ) {

    let shopId: number | null = null;

    activatedRoute.paramMap.subscribe(paramMap => {
      if (paramMap.has("shopId"))
        shopId = +paramMap.get("shopId")!
    })

    let deleteFunction =
      (companyId: number, shopId: number, saleId: number, contentId: number): Observable<void> =>
        this.salesService.deleteSaleContent(companyId, shopId, saleId, contentId)

    super('', Constants.localStorageKeys.saleContentTable, deleteFunction, utilsService, snackService, dialogService)

    this.shopId = shopId


    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(() => this.salesService.getShopSaleById(this.userReference.companyId, this.shopId!, this._sale?.id!)),
          switchMap(sale => {
            this._sale = sale
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<SaleContentSnapshotData>(sale.contentSnapshot)
            if (sale.content?.length != 0) {
              this.entityList.push(...sale.contentSnapshot!)
              this.numEntities = sale.content?.length ?? 0
            }
            return of([] as SaleContentSnapshotData[])
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error("Impossibile caricare la vendita");
            return EMPTY;
          })
        )


    this.displayedColumns = [
      "warehouse",
      "item",
      "barcode",
      "sku",
      "quantity",
      "itemUnitPrice",
      "total",
      "warehouseStocks",
      "action",
    ]

  }

  _sale: SaleDetail | null = null

  @Input() set sale(sale: SaleDetail) {
    if (sale) {
      this._sale = sale
      this.subs.push(this.observable$.subscribe(value => this.loading = false))

    }
  }

  isDeleteButtonDisabled(element: SaleContentSnapshotData) {

    return (this._sale?.status !== Constants.saleStatuses.open) ||
      (element.status !== Constants.saleContentStatuses.notUpdated && !!element.warehouseName)
  }

  addContent() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'AGGIORNA - Vendita',
        componentData: {
          companyId: this.userReference.companyId,
          entity: this._sale,
          shopId: this.shopId,
          managingSaleContent: true,
          defaultWarehouseId: this.defaultWarehouseId
        },
        component: SalesFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: (value) => this.snackService.success('Vendita aggiornata'),
        error: (error) => this.snackService.error(error.message),
      })

  }
}
