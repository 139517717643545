import {Component} from '@angular/core';

@Component({
  selector: 'aw-option-values',
  templateUrl: './option-values-container.component.html',
  styleUrls: ['./option-values-container.component.scss']
})
export class OptionValuesContainerComponent {


  constructor() {
  }

}
