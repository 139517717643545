import {Pipe, PipeTransform} from '@angular/core';
import {Constants} from "../utils/constants";

@Pipe({
  name: 'orderPaymentStatus'
})
export class OrderPaymentStatusPipe implements PipeTransform {

  transform(status: string) {
    return (Constants.orderPaymentStatusesPipe as any)[Constants.orderPaymentStatusKeys()
      .find(k => (Constants.orderPaymentStatuses as any)[k] === status) as any];
  }
}
