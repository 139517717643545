import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomerCreate, CustomerData} from "../../../interfaces/customer";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {WarehouseDetail} from "../../../interfaces/warehouse";
import {UtilsService} from "../../../services/utils.service";
import {delay, Observable, of, Subscription, switchMap} from "rxjs";
import {CustomersService} from "../../../services/customers.service";
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'aw-customers-form',
  templateUrl: './customers-form.component.html',
  styleUrls: ['./customers-form.component.scss']
})
export class CustomersFormComponent extends BaseFormComponent<CustomerCreate, CustomerData> {

  override _entity: CustomerData = {} as CustomerData

  @Input() companyId = -1
  errorMessage: string = ""
  checkingPhone: boolean = false;
  checkingFullName: boolean = false;
  checkingVat: boolean = false;
  timeout?: Subscription;
  phoneAlreadyInUse = false;
  nameAlreadyInUse = false;
  vatAlreadyInUse = false;

 usedPhoneNames: CustomerData[] = [];
 usedVatNames: CustomerData[] = [];

  @Input() set entity(value: CustomerData) {
    this._entity = value
    this.formGroup.patchValue(this._entity)
  }

  constructor(
    public customersService: CustomersService,
    public override utilsService: UtilsService) {

    let createFunction = (companyId: number, customer: CustomerCreate): Observable<CustomerData> => customersService.createCompanyCustomer(companyId, customer)
    let updateFunction = (companyId: number, customerId: number, customer: CustomerCreate): Observable<CustomerData> => customersService.updateCompanyCustomer(companyId, customerId, customer)

    super(createFunction, updateFunction, utilsService)

    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      name: new FormControl('', [Validators.required]),
      surname: new FormControl(undefined, [Validators.required]),
      email: new FormControl('', [
        // Validators.required,
        Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"),

      ]),
      phone: new FormControl('', [Validators.required]),
      vat: new FormControl(undefined),
      isCompany: new FormControl(false)
    })


    this.formGroup.get('isCompany')?.valueChanges.subscribe((isCompany) => {
      const surnameControl = this.formGroup.get('surname');

      if (surnameControl) {
        if (!isCompany) {
          surnameControl.setValidators([Validators.required]);
        } else {
          surnameControl.clearValidators();
        }
        surnameControl.updateValueAndValidity();
      }
    });

  }

  cleanSurnameVATFields() {
    this.nameAlreadyInUse = false
    this.formGroup.controls['name'].setValue('')
    this.formGroup.controls['surname'].setValue('')
    this.formGroup.controls['vat'].setValue('')
  }

  getUsedPhonesNames(){
     return this.usedPhoneNames.map(customer => customer.name + " " + customer.surname)
  }

  getUsedVatsNames(){
    return this.usedVatNames.map(customer => customer.name)
  }


  checkCustomer(event: KeyboardEvent){

    if(this.formGroup.value.phone){
      this.checkingPhone = true;
    }
    if((this.formGroup.value.name && this.formGroup?.value.surname) || this.formGroup.value.isCompany){
      this.checkingFullName = true;
    }

    if(this.formGroup.value.vat){
      this.checkingVat = true;
    }

    if (this.timeout !== undefined && !this.timeout.closed)
      this.timeout.unsubscribe()
      this.timeout = of(event)

      .pipe(
        delay(1000),
        switchMap((ev: KeyboardEvent) => {
          if ((this.formGroup.value.phone) || (this.formGroup.value.name) || (this.formGroup.value.vat)) {

            return this.customersService
              .getCustomerCheck(this.userReference?.companyId!, this.userReference?.user.id!, this.formGroup.value.phone, this.formGroup.value.name, this.formGroup?.value.surname, this.formGroup?.value.isCompany, this.formGroup?.value.vat)
          }

          return of({phoneUsedBy: [], nameUsedBy: [], vatUsedBy: []})
        })
      ).subscribe(value => {

        this.checkingPhone = false
        this.checkingFullName = false
        this.checkingVat = false

        this.nameAlreadyInUse = value.nameUsedBy.length > 0 && !value.nameUsedBy.find(customer => customer.id === this._entity.id)
        this.phoneAlreadyInUse = value.phoneUsedBy.length > 0 && !value.phoneUsedBy.find(customer => customer.id === this._entity.id)
        this.vatAlreadyInUse = value.vatUsedBy.length > 0 && !value.vatUsedBy.find(customer => customer.id === this._entity.id)
        this.usedPhoneNames = value.phoneUsedBy
        this.usedVatNames = value.vatUsedBy
        })


  }
}
