<ng-template #loadingGeneral>

  <mat-spinner class="align-self-center mt-8"></mat-spinner>

</ng-template>


<div fxLayout="column">

  <ng-container *ngIf="dataSource && !loading else loadingGeneral">

    <aw-actions-bar
      [requiredCreateAccessLevels]="requiredCreateAccessLevels"
      [requiredDeleteAccessLevels]="requiredDeleteAccessLevels"
      [parentEntity]="{id: _warehouseId}"
      (deleteObjects)="deleteEntities(getSelectedRowIds($event), userReference.companyId, _warehouseId)"
      (openCreateForm)="createItemWarehouse()"
      (load)="loadResults($event, observable$)"
      [displayedColumns]="displayedColumns"
      [isAllSelected]="isAllSelected()"
      [selectVisible]="selectVisible"
      [selection]="selection"
    >

    </aw-actions-bar>

    <table
      (matSortChange)="loadResults($event, observable$)"
      [dataSource]="dataSource"
      class="w-100 mat-elevation-z2"
      mat-table
      matSort
      matSortActive="name"
      matSortDirection="asc"
      multiTemplateDataRows>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                        (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="name">
          Nome
        </th>

        <td *matCellDef="let element" mat-cell> {{element.item.name}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="description">Descrizione</th>
        <td *matCellDef="let element" mat-cell> {{element.item.description}} </td>
      </ng-container>

      <ng-container matColumnDef="minimumSellNetPrice">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell mat-sort-header="minimumSellNetPrice">Prezzo di
          vendita minimo (€)
        </th>
        <td *matCellDef="let element" class="hide-mobile"
            mat-cell> {{element.item.minimumSellNetPrice | currency:'EUR'}} </td>
      </ng-container>


      <ng-container matColumnDef="maximumBuyNetPrice">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell mat-sort-header="maximumBuyNetPrice">Prezzo di
          acquisto massimo (€)
        </th>
        <td *matCellDef="let element" class="hide-mobile"
            mat-cell> {{element.item.maximumBuyNetPrice | currency:'EUR'}} </td>
      </ng-container>


      <ng-container matColumnDef="quantity">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell mat-sort-header="quantity">Quantità</th>
        <td *matCellDef="let element" [ngClass]=" {'py-4': isEditActive && updatingItemWarehouse !== element}"
            class="hide-mobile" mat-cell>

          <ng-container>

            <div *ngIf="!canEdit">
              {{element.quantity}} {{element.item.unit}}
            </div>

            <div
              *ngIf="(updatingItemWarehouse !== element || !isEditActive) && canEdit"
              (click)="!isEditActive ? onRowEditInit(element) : null"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="4%"
              [ngClass]="{'cursor-pointer': !isEditActive}"
            >
              <span>{{element.quantity}} {{element.item.unit}}</span>
              <mat-icon>edit</mat-icon>
            </div>

            <div *ngIf="isEditActive && updatingItemWarehouse === element">
              <div [formGroup]="formGroup" fxLayout="row" fxLayoutAlign="center">

                <button (click)="decreaseQty(element)" mat-icon-button>
                  <mat-icon>remove</mat-icon>
                </button>
                <mat-form-field>

                  <input
                    *ngIf="hasUnit(formGroup, element.item.id)"
                    [(ngModel)]="element.quantity"
                    [min]="0"
                    [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2}"
                    currencyMask
                    formControlName="quantity"
                    matInput
                    name="quantity"
                    placeholder="Quantità articolo"
                    type="text">

                  <input
                    *ngIf="!hasUnit(formGroup, element.item.id)"
                    [(ngModel)]="element.quantity"
                    [min]="0"
                    [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 0}"
                    currencyMask
                    formControlName="quantity"
                    matInput
                    name="quantity"
                    placeholder="Quantità articolo"
                    type="text">

                  <span *ngIf="hasUnit(formGroup, element.item.id)"
                        matSuffix>&nbsp;{{getMeasureUnit(element.item.id)}}</span>


                </mat-form-field>

                <button (click)="increaseQty()" mat-icon-button>
                  <mat-icon>add</mat-icon>
                </button>
              </div>

              <div *ngIf="element.item.itemComposers.length > 0">
                <div *ngIf="allComposersAlreadyInWarehouse">
                  <div
                    *ngIf="element.item.itemComposers?.length ?? -1 > 0 && checked && !canAutoscale(element.item, element.quantity) && element.quantity != 0"
                    fxFlex
                    class="mb-1">


                    <div class="border border-warn disclaimer-error mat-background-light p-2_5 mb-2_5">
                      <span [innerHTML]="getMissingQuantitiesText"></span>
                    </div>
                  </div>

                  <div class="border border-warn disclaimer-warning mat-background-light p-2_5">
                    <span [innerHTML]="getAutoScaleText(element.item)"></span>
                  </div>


                  <mat-button-toggle-group [(ngModel)]="checked">

                    <ng-container *ngFor="let state of stateOptions">
                      <mat-button-toggle [value]="state.value">{{state.label}}</mat-button-toggle>
                    </ng-container>
                  </mat-button-toggle-group>
                </div>

                <div *ngIf="!allComposersAlreadyInWarehouse">
                  <div class="border border-warn disclaimer-warning inline-block mat-background-light p-2_5 mb-2_5">
                    <span
                      [innerHTML]="'Le giacenze dei seguenti articoli che compongono articolo scelto non esistono in questo magazzino: <br>' + missingItemsNames() + '<br> Lo scalo automatico non è disponibile'"></span>
                  </div>

                </div>


                <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="3%" class="mt-2">
                  <button (click)="addItemWarehouseFn(element)"
                          [disabled]="(checked && !canAutoscale(element.item, element.quantity)) || formGroup.invalid"
                          class="border border-primary"
                          color="primary" fxFlex="30"
                          mat-button>
                    Salva
                  </button>

                  <button (click)="onRowEditCancel(element)" class="border border-warn" color="warn" fxFlex="30"
                          mat-button>Annulla
                  </button>
                </div>


              </div>


              <div *ngIf="(element.item.itemComposers?.length ?? -1) <= 0">
                <div class="mt-2" fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="3%">
                  <button (click)="addItemWarehouseFn(element)" [disabled]="element.quantity < 0  || formGroup.invalid"
                          class="border border-primary" color="primary" fxFlex="30" mat-button>Salva
                  </button>
                  <button (click)="onRowEditCancel(element)" class="border border-warn" color="warn" fxFlex="30"
                          mat-button>Annulla
                  </button>
                </div>
              </div>

            </div>
          </ng-container>

        </td>
      </ng-container>


      <!-- Action -->
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Azioni</th>
        <td *matCellDef="let element" class="hide-mobile" fxLayoutGap="8" mat-cell>

          <button #delete
                  (click)="deleteEntities([element.item.id], userReference.companyId, _warehouseId);$event.preventDefault();$event.stopPropagation();"
                  *awAccess="{accessLevel:{accessEntity: accessEntities.itemWarehouses, accessLevel: accessLevels.admin}}"
                  [awDisableElement]="{
                        selected: [{id: _warehouseId}],
                        logicalAnd: true,
                        accessLevels: requiredDeleteAccessLevels,
                        component: delete}"
                  color="warn"
                  mat-icon-button
                  matTooltip="Elimina"
                  matTooltipPosition="above">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>


      <tr *matNoDataRow class="mat-row">
        <td [colSpan]="displayedColumns.length" class="mat-cell text-center">
          Nessuna giacenza trovata
        </td>
      </tr>


    </table>

    <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </mat-card>
  </ng-container>


  <mat-paginator #paginator
                 (page)="loadResults($event, observable$)"
                 (selectionchange)="logMe($event)"
                 *ngIf="dataSource && numEntities > 0"
                 [length]="numEntities"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 class="mat-elevation-z1"
                 showFirstLastButtons>
  </mat-paginator>
</div>
