<div [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="space-between">

  <mat-form-field fxFlex="45">

    <mat-label>Nome</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="name"
      id="name"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['name'].errors?.['required']"
    >
      È obbligatorio inserire il nome
    </mat-error>
  </mat-form-field>


  <mat-form-field fxFlex="45">

    <mat-label>Indirizzo</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="address"
      id="address"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['address'].errors?.['required']"
    >È obbligatorio inserire l'indirizzo
    </mat-error>
  </mat-form-field>


  <mat-form-field fxFlex="45">
    <mat-label>Telefono</mat-label>
    <input [dropSpecialCharacters]="false"
           [mask]="'+00 000 0*'" [readonly]="readOnly" formControlName="phone" matInput name="phone">
  </mat-form-field>


  <button
    (click)="_entity?.id ? updateEntity(companyId, _entity.id) : createEntity(companyId)"
    [disabled]="formGroup.invalid || saving || readOnly || !formGroup.dirty"
    color="primary"
    mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Salva</span>
  </button>
</div>






