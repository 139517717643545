import {Component, OnDestroy} from '@angular/core';
import {BaseTableComponent} from "./base-table.component";
import {SellOrderFilter} from "../../../interfaces/sell-order";
import {Observable} from "rxjs";

@Component({
  selector: 'aw-base-order-sale-table',
  template: '',
})
export abstract class BaseOrderSaleTableComponent<T> extends BaseTableComponent<T> implements OnDestroy {

  loadOrderSaleResults($event: any, obs$: Observable<any>) {

    let keys = Object.keys($event)

    keys.forEach(key => {
      if (($event as any)[key] != undefined) {
        // If provided key matches a key of SellOrderFilter, i populate that field of SellOrderFilter with that value
        if (Object.getOwnPropertyNames(new SellOrderFilter()).find(property => property == key)) {
          (this.filterObject as any)[key] = $event[key]
        }
      }
    })

    // Otherwise, it is probably a fetch param
    this.loadResults($event, obs$)
  }

  /**
   * Expands or contracts note column
   */
  manageExpandedNote(rowId: number) {
    if (this.expandedNotes.includes(rowId)) {
      this.expandedNotes.splice(this.expandedEntities.indexOf(rowId), 1)
    } else {
      this.expandedNotes.push(rowId)
    }
  }


  /**
   * Tells if note column is expanded
   */
  isNoteExpanded(elementId: number) {
    return this.expandedNotes.includes(elementId)
  }

  /**
   * Tells if delivery notes column is expanded
   */
  isDeliveryNotesExpanded(elementId: number) {
    return this.expandedDeliveryNotes.includes(elementId)
  }

  /**
   * Tells if row is expanded
   */
  isContentExpanded(rowId: number) {
    return this.expandedEntities.includes(rowId)
  }


}
