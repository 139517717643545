import {Pipe, PipeTransform} from '@angular/core';
import {Constants} from "../utils/constants";

@Pipe({
  name: 'orderContentStatus'
})
export class OrderContentStatusPipe implements PipeTransform {

  transform(status: string) {
    return (Constants.orderContentStatusesPipe as any)[Constants.orderContentStatusKeys()
      .find(k => (Constants.orderContentStatuses as any)[k] === status) as any]
  }

}
