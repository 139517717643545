import {Component, NgZone, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {Constants} from "../../utils/constants";
import {ResponsiveService} from "../../services/responsive.service";
import {SnackService} from "../../services/snack.service";


@Component({
  selector: 'aw-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup
  formGroupReset: FormGroup
  logged = false
  resetPwd = false
  loading = false
  loadingReset = false
  dialogSizes = Constants.dialogSizes

  constructor(public authService: AuthService,
              private ngZone: NgZone,
              private router: Router,
              public responsiveService: ResponsiveService,
              private snackService: SnackService) {
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });

    this.formGroupReset = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }

  ngOnInit(): void {
    if (!this.authService.redirectUrl)
      this.authService.redirectUrl = 'reserved'
  }


  login(): void {
    this.loading = true
    this.authService.login(this.formGroup.value.email, this.formGroup.value.password)
      .subscribe({
        next: success => {
          this.snackService.success('Accesso effettuato')
          this.loading = false
          this.router.navigate(['/reserved'])
        },
        error: err => {
          this.loading = false
          this.snackService.error('Impossibile fare login, riprova fra poco.')
        },
      })
  }

  showResetModal(): void {
    this.resetPwd = true
  }

  resetPassword() {
    this.loadingReset = true
    this.authService.forgotPassword(this.formGroupReset.value.email)
      .subscribe({
        next: success => {
          this.snackService.success('Email inviata')
          this.loadingReset = false
          this.resetPwd = false
        },
        error: err => {
          this.snackService.error('Non è stato possibile mandare l\'email! Riprova fra poco')
          this.loadingReset = false
          this.resetPwd = false
        },
      })
  }
}
