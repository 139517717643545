import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResponse} from '../classes/api-response';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Constants} from '../utils/constants';
import {BaseItemCreate, BaseItemData, BaseItemDetail, BaseItemFullDetail} from "../interfaces/base-item";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";
import {UtilsService} from "./utils.service";
import {CheckResponse} from "../interfaces/check-response";


@Injectable({
  providedIn: 'root'
})
export class BaseItemsService {

  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.companies}`;


  constructor(private http: HttpClient) {
  }


  public checkBaseItemName(companyId: number, tentativeName: string): Observable<boolean> {

    let params: HttpParams = new HttpParams().set('tentativeName', tentativeName)

    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/base-items/name-check`, {params})
      .pipe(map(resp => resp.data as boolean));
  }

  public checkBaseItemBarcode(companyId: number, barcode: string, variantId?: number): Observable<CheckResponse> {
    let params: HttpParams = new HttpParams().set('barcode', barcode)

    if (variantId)
      params = params.set('variantId', variantId)

    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/base-items/barcode-check`, {params})
      .pipe(map(resp => resp.data as CheckResponse));
  }

  public checkBaseItemSku(companyId: number, sku: string, variantId?: number): Observable<CheckResponse> {
    let params: HttpParams = new HttpParams().set('sku', sku)
    if (variantId)
      params = params.set('variantId', variantId)

    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/base-items/sku-check`, {params})
      .pipe(map(resp => resp.data as CheckResponse));
  }

  public getAllCompanyBaseItems(companyId: number): Observable<BaseItemDetail[]> {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.baseItems}`)
      .pipe(map(value => value.data as BaseItemDetail[]))

  }

  public getAllCompanyBaseItemsFull(companyId: number, paginationParams?: FetchParams): Observable<PageConverter<BaseItemFullDetail>> {
    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})
    let queryParams = UtilsService.buildParams([], sortFilterParams)


    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.baseItems}/full`, {params: queryParams})
      .pipe(map(value => value.data as PageConverter<BaseItemFullDetail>))
  }

  public getCompanyBaseItem(companyId: number, baseItemId: number): Observable<BaseItemFullDetail> {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.baseItems}/${baseItemId}`)
      .pipe(map(value => value.data as BaseItemFullDetail))

  }

  public createCompanyBaseItem(companyId: number, baseItem: BaseItemCreate): Observable<BaseItemData> {
    return this.http
      .post<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.baseItems}`, baseItem)
      .pipe(map(value => value.data as BaseItemData));
  }

  public deleteCompanyBaseItem(companyId: number, baseItemId: number): Observable<void> {
    return this.http
      .delete<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.baseItems}/${baseItemId}`)
      .pipe(map(() => {
      }))
  }

  public updateCompanyBaseItem(companyId: number, baseItemId: number, baseItem: BaseItemCreate): Observable<BaseItemFullDetail> {
    return this.http
      .patch<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.baseItems}/${baseItemId}`, baseItem)
      .pipe(map(value => value.data as BaseItemFullDetail))
  }

  public replaceCompanyBaseItem(companyId: number, baseItemId: number, baseItem: BaseItemCreate): Observable<BaseItemFullDetail> {
    return this.http
      .put<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.baseItems}/${baseItemId}`, baseItem)
      .pipe(map(value => value.data as BaseItemFullDetail))
  }


}
