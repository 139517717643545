<ng-template #loadingOptionsTemplate>
  <mat-spinner *ngIf="isEdit && !defaultVariant"></mat-spinner>
</ng-template>

<ng-template #noOptionsTemplate>
  <div *ngIf="isEdit" class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
    <p fxLayout="column">
      <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
      Non esiste alcuna opzione.
      Creane una e valorizzala per aggiungere varianti dei tuoi articoli.
    </p>

  </div>
</ng-template>


<p class="text-3xl">

  <ng-container *ngIf="!isEdit">CREAZIONE – Articolo</ng-container>
  <ng-container *ngIf="isEdit && !readonly">MODIFICA – {{_baseItemToEdit?.name ?? 'Articolo'}}</ng-container>
  <ng-container *ngIf="isEdit && readonly">DETTAGLIO – {{_baseItemToEdit?.name ?? 'Articolo'}}</ng-container>

</p>

<form [formGroup]="formGroup" fxLayout="column">
  <p class="text-light">Dati generali</p>
  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="3%">
    <mat-form-field fxFlex>
      <mat-label>Nome</mat-label>
      <input
        [readonly]="readonly" formControlName="name" id="name" matInput matTooltip="Inserisci il nome" type="text">
      <mat-error *ngIf="formGroup.controls['name'].errors?.['required']"> È obbligatorio inserire il nome</mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Unità di misura</mat-label>

      <input
        [readonly]="readonly" formControlName="unit" id="unit" matInput matTooltip="Ad esempio chilogrammo, litro..."
        type="text">
    </mat-form-field>
  </div>

  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="3%">
    <mat-form-field fxFlex>
      <mat-label>Descrizione</mat-label>

      <input
        [readonly]="readonly" formControlName="description" id="description" matInput
        matTooltip="Descrizione aggiuntiva per l'articolo"
        type="text">
    </mat-form-field>

    <div fxFlex>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="3%">
        <mat-form-field fxFlex>
          <mat-label>Prezzo Minimo Di Vendita (netto)</mat-label>
          <input
            [min]="0"
            [options]="{ prefix: '€ ', thousands: '.', decimal: ',', precision: 2 }"
            [readonly]="readonly"
            currencyMask
            formControlName="minimumSellNetPrice"
            id="minimumSellNetPrice"
            matInput
            matTooltip="Prezzo Minimo Di Vendita"
            type="text">
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>IVA di Vendita (%)</mat-label>
          <mat-select formControlName="defaultSellVAT" id="defaultSellVAT" matTooltip="IVA Di Vendita">
            <mat-option [value]="4">4%</mat-option>
            <mat-option [value]="5">5%</mat-option>
            <mat-option [value]="10">10%</mat-option>
            <mat-option [value]="22">22%</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
  </div>

  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="3%">
    <mat-form-field fxFlex>
      <mat-label>Codice a barre</mat-label>

      <input
        (keyup)="readonly ? undefined : checkBarcodeAvailability($event)"
        [readonly]="readonly" formControlName="barcode" id="barcode" matInput matTooltip="Codice a barre"
        type="text">
      <span *ngIf="checkingBarcode && formGroup.value.barcode" matSuffix><i
        class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

      <mat-icon *ngIf="!checkingBarcode && barcodeAlreadyInUse"
                [matTooltip]="'Attenzione, questo codice a barre è stato già utilizzato in: ' + itemNamesUsingSameBarcode.join(', ')"
                color="warn" matSuffix>
        warning_amber
      </mat-icon>
      <mat-icon *ngIf="!checkingBarcode && !barcodeAlreadyInUse && formGroup.controls['barcode'].dirty"
                matSuffix>done
      </mat-icon>
    </mat-form-field>


    <div fxFlex>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="3%">
        <mat-form-field fxFlex>
          <mat-label>Prezzo Massimo Di Acquisto (netto)</mat-label>
          <input
            [min]="0"
            [options]="{ prefix: '€', thousands: '.', decimal: ',', precision: 2}"
            [readonly]="readonly"
            currencyMask
            formControlName="maximumBuyNetPrice"
            id="maximumBuyNetPrice"
            matInput
            matTooltip="Prezzo Massimo Di Acquisto"
            type="text"
          >
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>IVA di Acquisto (%)</mat-label>
          <mat-select formControlName="defaultBuyVAT" id="defaultBuyVAT" matTooltip="IVA Di Acquisto">
            <mat-option [value]="4">4%</mat-option>
            <mat-option [value]="5">5%</mat-option>
            <mat-option [value]="10">10%</mat-option>
            <mat-option [value]="22">22%</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </div>

  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="3%">
    <mat-form-field fxFlex>
      <mat-label>Codice identificativo articolo</mat-label>

      <input
        (keyup)="readonly ? undefined : checkSkuAvailability($event)"
        [readonly]="readonly" formControlName="sku" id="sku" matInput matTooltip="Codice identificativo articolo"
        type="text">

      <span *ngIf="checkingSku && formGroup.value.sku" matSuffix><i
        class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

      <mat-icon *ngIf="!checkingSku && skuAlreadyInUse"
                [matTooltip]="'Attenzione, questo codice articolo è stato già utilizzato in: ' + itemNamesUsingSameSku.join(', ')"
                color="warn" matSuffix>
        warning_amber
      </mat-icon>
      <mat-icon *ngIf="!checkingSku && !skuAlreadyInUse && formGroup.controls['sku'].dirty"
                matSuffix>done
      </mat-icon>
    </mat-form-field>

    <!--    for asymmetric layout-->
    <div fxFlex></div>
  </div>

  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="3%">
    <!--    todo: inserire le chip-->
    <mat-form-field fxFlex>
      <mat-label>Tipo di articolo</mat-label>
      <mat-select [disabled]="readonly" formControlName="itemTypesIds" id="typesIds" multiple>

        <!--        todo: fix this-->
        <!--        <aw-search-option (filteredOptions)="filteredItemTypes = $event" *ngIf="itemTypes.length > 0"-->
        <!--                          [key]="'name'" [options]="itemTypes"></aw-search-option>-->


        <mat-option *ngFor="let itemType of itemTypes" [matTooltip]="itemType.description??''" [value]="itemType.id">
          {{itemType.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--    for asymmetric layout-->
    <div fxFlex></div>
  </div>
</form>


<!--Stocks accordion -->

<ng-container
  *awAccess="{accessLevel:{accessEntity: accessEntities.itemWarehouses, accessLevel: accessLevels.reader}}"
>
  <aw-item-stocks-table
    *ngIf="!computingVariants && !isNew && !isDuplicate"
    [unit]="_baseItemToEdit?.unit"
    [variants]="existingVariants"
  >
  </aw-item-stocks-table>
</ng-container>


<div *ngIf="!loadingOptions else loadingOptionsTemplate" fxLayout="row" fxLayoutGap="3%">
  <ng-container *ngIf="options.length > 0 else noOptionsTemplate">
    <ng-container *ngIf="showVariantsForm">

      <div fxFlex="22%">
        <p class="text-xl">Opzioni varianti</p>

        <div *ngIf="disableIfAnyVariantNotDeletable() && isForceVariantsRecompute"
             class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
          <p fxLayout="column">
            <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
            Non puoi aggiungere opzioni perché le seguenti varianti sono coinvolte in un ordine o una vendita non
            conclusi: <br>
            <span [innerHTML]="getNotDeletableVariants()"></span>
          </p>

        </div>

        <table
          [dataSource]="dataSourceOptions"
          class="w-100 mat-elevation-z2"
          mat-table
          multiTemplateDataRows>

          <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell>Nome</th>
            <td *matCellDef="let element" mat-cell>
              {{element.displayName}}
              <small *ngIf="element.values.length == 0"><br>Questa opzione non ha valori, pertanto è
                disabilitata</small>
            </td>
          </ng-container>


          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let element" mat-cell>
              <ng-container *ngIf="(!isEdit || isForceVariantsRecompute) && !disableIfAnyVariantNotDeletable()">
                <ng-container *ngIf="!isOptionSelected(element)">
                  <div fxLayout="row" fxLayoutAlign="center center">
                    AGGIUNGI&nbsp;<mat-icon>chevron_right</mat-icon>
                  </div>
                </ng-container>
                <ng-container *ngIf="isOptionSelected(element)">
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon>chevron_left</mat-icon>&nbsp;RIMUOVI
                  </div>
                </ng-container>
              </ng-container>

            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumnsOptions" mat-header-row></tr>
          <tr
            (click)="!isEdit || (isForceVariantsRecompute && !disableIfAnyVariantNotDeletable() && row.values.length != 0) ? manageRowClick(row): null"
            *matRowDef="let row; columns:  displayedColumnsOptions"
            [ngClass]="{'bg-black-alpha-10': isOptionSelected(row) }"
            class="cursor-pointer hover:bg-black-alpha-10" mat-row></tr>
        </table>

      </div>

      <div fxFlex>
        <p class="text-xl">
          Varianti ({{selection?.selected?.length ?? 0}} attiv{{selection?.selected?.length == 1 ? "a" : "e"}}
          / {{variants?.length ?? 0}} possibili)
        </p>

        <table
          [dataSource]="dataSourceVariants"
          class="w-100 mat-elevation-z2"
          mat-table
          multiTemplateDataRows
        >


          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell>
              <mat-checkbox (change)="$event ? toggleAllRows() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [disabled]="disableIfAnyVariantNotDeletable() || readonly"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </th>
            <td *matCellDef="let row" mat-cell>
              <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                            (click)="$event.stopPropagation()"
                            [checked]="selection.isSelected(row)"
                            [disabled]="disableIfThisVariantNotDeletable(row.variant) || readonly"
              >
              </mat-checkbox>
            </td>
          </ng-container>


          <ng-container *ngFor="let opt of selectedOptions" [matColumnDef]="opt.name">
            <th *matHeaderCellDef mat-header-cell>{{opt.displayName}}</th>
            <td *matCellDef="let element" mat-cell>
              {{getOptionValue(element.variant, opt).displayName}}
            </td>
          </ng-container>


          <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell>Stato</th>
            <td *matCellDef="let element;" mat-cell>
              <div [ngClass]="selection.isSelected(element) ? 'border-primary bg-primary': 'bg-accent border-accent'"
                   class="border border-round-md inline-flex p-2">

                {{selection.isSelected(element) ? 'ATTIVA' : 'NON ATTIVA'}}


              </div>
            </td>
          </ng-container>


          <ng-container matColumnDef="expand">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let element" mat-cell>
              <button (click)="manageExpandedItemVariants(element.key)" color="accent" mat-button>Dettagli
                <mat-icon *ngIf="!expandedItemVariants.includes(element.key)">chevron_right</mat-icon>
                <mat-icon *ngIf="expandedItemVariants.includes(element.key)">expand_more</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedOrder">
            <td *matCellDef="let element; let i=dataIndex" [attr.colspan]="displayedColumnsVariants.length"
                class="p-1 bg-gray-100"
                mat-cell>
              <div *ngIf="expandedItemVariants.includes(element.key)" class="mx-8 my-2">
                <div fxLayout="row" fxLayoutGap="3%">
                  <mat-form-field fxFlex>
                    <mat-label>Nome</mat-label>
                    <input
                      [(ngModel)]="element.variant.name"
                      [readonly]="readonly"
                      matInput
                      type="text">
                  </mat-form-field>

                  <mat-form-field fxFlex>
                    <mat-label>Descrizione</mat-label>
                    <input
                      [(ngModel)]="element.variant.description"
                      [readonly]="readonly"
                      matInput
                      type="text">
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="3%">

                  <mat-form-field fxFlex>
                    <mat-label>Codice a barre</mat-label>

                    <input
                      (keyup)="readonly ? undefined : checkBarcodeAvailabilityForItemVariant($event, i, element.variant.barcode, element.variant.id)"
                      [(ngModel)]="element.variant.barcode"
                      [readonly]="readonly"
                      matInput
                      type="text">
                    <span *ngIf="itemVariantWrappers[i].checkingBarcode && element.variant.barcode"
                          matSuffix><i
                      class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

                    <mat-icon
                      *ngIf="itemVariantWrappers[i].barcodeDirty && !itemVariantWrappers[i].checkingBarcode && itemVariantWrappers[i].barcodeAlreadyInUse && element.variant.barcode"
                      [matTooltip]="'Attenzione, questo codice a barre è stato già utilizzato in: ' + itemVariantWrappers[i].itemNamesUsingSameBarcode.join(', ')"
                      color="warn"
                      matSuffix>warning_amber
                    </mat-icon>
                    <mat-icon
                      *ngIf="itemVariantWrappers[i].barcodeDirty && !itemVariantWrappers[i].checkingBarcode && !itemVariantWrappers[i].barcodeAlreadyInUse && element.variant.barcode"
                      matSuffix>done
                    </mat-icon>
                  </mat-form-field>

                  <mat-form-field fxFlex>
                    <mat-label>Codice identificativo articolo</mat-label>
                    <input
                      (keyup)="readonly ? undefined : checkSkuAvailabilityForItemVariant($event, i, element.variant.sku, element.variant.id)"
                      [(ngModel)]="element.variant.sku"
                      [readonly]="readonly"
                      matInput
                      type="text">
                    <span *ngIf="itemVariantWrappers[i].checkingSku && element.variant.sku"
                          matSuffix><i
                      class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>

                    <mat-icon
                      *ngIf="itemVariantWrappers[i].skuDirty && !itemVariantWrappers[i].checkingSku && itemVariantWrappers[i].skuAlreadyInUse && element.variant.sku"
                      [matTooltip]="'Attenzione, questo codice articolo è stato già utilizzato in: ' + itemVariantWrappers[i].itemNamesUsingSameSku.join(', ')"
                      color="warn"
                      matSuffix>warning_amber
                    </mat-icon>
                    <mat-icon
                      *ngIf="itemVariantWrappers[i].skuDirty && !itemVariantWrappers[i].checkingSku && !itemVariantWrappers[i].skuAlreadyInUse && element.variant.sku"
                      matSuffix>done
                    </mat-icon>
                  </mat-form-field>

                  <mat-form-field fxFlex>
                    <mat-label>Prezzo Minimo Di Vendita (netto)</mat-label>
                    <input
                      [(ngModel)]="element.variant.minimumSellNetPrice"
                      [min]="0"
                      [options]="{ prefix: '€', thousands: '.', decimal: ',', precision: 2}"
                      [readonly]="readonly"
                      currencyMask
                      matInput
                      type="text"
                    >
                  </mat-form-field>

                  <mat-form-field fxFlex>
                    <mat-label>Prezzo Massimo Di Acquisto (netto)</mat-label>
                    <input
                      [(ngModel)]="element.variant.maximumBuyNetPrice"
                      [min]="0"
                      [options]="{ prefix: '€', thousands: '.', decimal: ',', precision: 2}"
                      [readonly]="readonly"
                      currencyMask
                      matInput
                      type="text">
                  </mat-form-field>
                </div>


                <div class="mt-2" fxLayout="row" fxLayoutAlign="center center">
                  <button (click)="openCompositionForm(element.variant)" *ngIf="element.variant.id && !readonly"
                          [disabled]="formGroup.invalid || saving"
                          color="accent" fxFlex="23.5%" mat-raised-button>Composizione
                  </button>

                  <div fxFlex></div>


                  <div class="color-primary" fxFlex>
                    I prezzi lasciati in bianco verranno valorizzati con i prezzi dei dati generali
                  </div>

                </div>


              </div>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumnsVariants" mat-header-row></tr>
          <tr *matRowDef="let row; columns:  displayedColumnsVariants" mat-row></tr>
          <tr *matRowDef="let row; columns: ['expandedOrder']"
              [fxShow]="expandedItemVariants.includes(row.key)" mat-row></tr>

          <tr *matNoDataRow class="mat-row">
            <td [colSpan]="displayedColumnsVariants.length" class="mat-cell text-center">
              Nessuna opzione selezionata!
            </td>
          </tr>
        </table>

      </div>
    </ng-container>

  </ng-container>
</div>

<!-- region buttons-->
<div class="mt-2" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="0.5%">


  <button (click)="deleteOptionsAndVariants()"
          *ngIf="isEdit && isForceVariantsRecompute && options.length > 0  && !readonly"
          color="accent"
          mat-button>
    Cancella varianti e rimuovi opzioni
  </button>

  <button (click)="loadOptions()"
          *ngIf="!_baseItemToEdit?.id && !readonly && !showVariantsForm"
          [disabled]="formGroup.invalid || saving || options.length == 0"
          class="inline-block" color="accent" mat-raised-button>
    Aggiungi varianti
  </button>

  <button (click)="forceVariantsRecompute()"
          *ngIf="isEdit && !isForceVariantsRecompute && !readonly && options.length > 0"
          color="accent"
          mat-button>
    Abilita modifiche strutturali
  </button>

  <button
    (click)="openCompositionForm(defaultVariant)"
    *ngIf="defaultVariant && !isForceVariantsRecompute && !readonly"
    [disabled]="formGroup.invalid || saving"
    color="accent" mat-raised-button>
    Composizione base
  </button>

  <button (click)="completed.emit()" color="warn" mat-raised-button>
    {{readonly ? 'Chiudi' : 'Annulla'}}
  </button>

  <button
    (click)="saveBaseItem()"
    *ngIf="!readonly"
    [disabled]="formGroup.invalid || saving || ((selectedOptions?.length ?? 0) > 0 && (selection.selected?.length ?? 0)==0)"
    color="primary"
    mat-raised-button>
    Salva
  </button>
</div>
<!-- endregion buttons-->
