<div *ngIf="!showBaseItemModal"
     class="mx-2"
>
  <!--  Questa tabella ospiterà BaseItems che una volta espansi mostreranno gli ItemVariants-->
  <aw-base-items-table
    (addBaseItem)="addBaseItem()"
    (editBaseItem)="updateBaseItem($event)"
    (duplicateItem)="duplicateItem($event)"
    (showBaseItemDetails)="openFormInReadonly($event)"
    >
  </aw-base-items-table>
</div>

<div *ngIf="showBaseItemModal" class="mx-2">
  <aw-base-items-form
    (completed)="editCompleted($event)"
    [baseItemToEdit]="baseItemToEdit"
    [baseItemToDuplicate]="baseItemToDuplicate"
    [readonly]="readOnly"
  >
  </aw-base-items-form>

</div>


