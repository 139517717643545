import {Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {Constants} from "../../constants";
import {MatTableDataSource} from "@angular/material/table";
import {FetchParams, UserReference} from "../../global-functions-and-types";
import {EMPTY, forkJoin, map, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {UtilsService} from "../../../services/utils.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {RequiredAccessLevel} from "../../../classes/required-access-level";
import {SellOrderFilter} from "../../../interfaces/sell-order";

@Component({
  selector: 'aw-base-table',
  template: '',
})
export abstract class BaseTableComponent<T> implements OnDestroy {


  requiredCreateAccessLevels: RequiredAccessLevel[] = []

  requiredDeleteAccessLevels: RequiredAccessLevel[] = []

  accessEntities = Constants.accessEntities

  accessLevels = Constants.accessLevels

  // Selected entities through checkbox column
  selection = new SelectionModel<T>(true, []);

  // All available columns for the current table, sorted by user
  availableColumns: string[] = []

  // Columns to display
  displayedColumns: string[] = []

  // List of columns retrieved from Local Storage
  localStorageColumns: string[] = []

  entities = Constants.entities

  // Searched keyword
  keyword = ""

  // Date ranges for filters in orders
  deliveryFrom: string = ""
  deliveryTo: string = ""

  // Date ranges for filters in orders
  deadlineFrom: string = ""
  deadlineTo: string = ""

  // Date ranges for filters in sales
  saleFrom: string = ""
  saleTo: string = ""

  // List of status keys for filter in orders
  statuses: string[] = [];

  // List of payment status keys for filter in orders
  paymentStatuses: string[] = [];

  // List of item type id for filter in items table
  itemTypeIds: number[] = [];

  // Sorting direction
  direction = Constants.pagination.defaultSortDirection;

  // Total number of entities in repository
  numEntities = 0;

  // List of entities, probably useless.
  entityList: T[] = [];

  // Batch size of results to fetch in each request
  pageSize = Constants.pagination.defaultPageSize;

  pageSizeOptions = Constants.pagination.defaultPageSizeOptions;

  // Manages disabled fields, spinners etc
  disabled = false;

  // Manages component rendering
  loading = false

  // Manages spinners etc
  paramLoading = false

  userReferenceLoading = false

  // Manages visibility of checkbox column
  selectVisible = false

  // Table datasource
  dataSource!: MatTableDataSource<T>

  // User for HTTP Params
  fetchParams: FetchParams = {} as FetchParams

  // User to create HTTP Params from filters
  filterObject: SellOrderFilter = {} as SellOrderFilter

  // IDs of expanded rows
  expandedEntities: number[] = [];

  // IDs of expanded note column rows
  expandedNotes: number[] = [];

  // IDs of expanded delivery note column rows
  expandedDeliveryNotes: number[] = [];

  // Gets the UserReference object
  userReference$: Observable<UserReference> = new Observable<UserReference>()

  // Read UserReference interface documentation
  userReference!: UserReference

  // Table paginator reference
  @ViewChild('paginator') paginator: any

  @ViewChild('actionRow') actionRow: any

  // Usually, the main observable used
  observable$!: Observable<T[]>

  // Subscription array
  protected subs: Subscription[] = []


  /**
   * @param defaultSortColumn
   * @param localStorageKey
   * @param deleteFunction
   * @param utilsService
   * @param snackService
   * @param dialogService
   */
  protected constructor(
    @Inject(String) protected defaultSortColumn: string,
    @Inject(String) protected localStorageKey: string,
    // todo find a way to always infer types
    // @Inject(Function) protected getFunction: (...args: any[]) => Observable<PageConverter<T>>,
    @Inject(Function) protected deleteFunction: (...args: any[]) => Observable<void>,
    protected utilsService: UtilsService,
    protected snackService: SnackService,
    protected dialogService: DialogsService,
  ) {

    this.userReference$ =
      of(true)
        .pipe(
          tap(() => this.userReferenceLoading = true),
          switchMap(() => this.utilsService.getUserReference()),
          map(userReference => this.userReference = userReference),
          tap(() => this.userReferenceLoading = false)
        )

    this.fetchParams = {
      page: Constants.pagination.defaultPage,
      num: Constants.pagination.defaultPageSize,
      sort: `${this.defaultSortColumn} ${this.direction}`,
      keyword: ""
    }


    // this.observable$ =
    //   of(true)
    //     .pipe(
    //       tap(() => this.paramLoading = true),
    //       switchMap(() => this.userReference$),
    //       switchMap(userReference => {
    //         this.userReference = userReference
    //
    //         let entitiesIds = []
    //
    //         if (this.entityName !== '*') {
    //           let access = userReference.user.access[this.entityName]
    //           if (typeof access === "object")
    //             entitiesIds = access.userAuthority != undefined ? [] :
    //               access.permission.map((value: any) => value.entityId)
    //         }
    //
    //         return this.getFunction(...this.args, this.fetchParams)
    //       }),
    //       switchMap(entities => {
    //         this.paramLoading = false
    //         this.dataSource = new MatTableDataSource<T>(entities.list)
    //         this.numEntities = entities.num
    //         return of(entities.list)
    //       }),
    //       catchError((err, caught) => {
    //         this.paramLoading = false
    //         if (!err.errors.includes('403'))
    //           this.snackService.error('Impossibile caricare i dati');
    //         return EMPTY;
    //       })
    //     )
    //
    // this.subs.push(this.observable$.subscribe())


  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  /**
   * Expands or contracts selected row
   */
  manageExpandedRows(rowId: number) {
    if (this.expandedEntities.includes(rowId)) {
      this.expandedEntities.splice(this.expandedEntities.indexOf(rowId), 1)
    } else {
      this.expandedEntities.push(rowId)
    }
  }


  /**
   * Expands or contracts delivery notes column
   */
  manageExpandedDeliveryNotes(rowId: number) {
    if (this.expandedDeliveryNotes.includes(rowId)) {
      this.expandedDeliveryNotes.splice(this.expandedEntities.indexOf(rowId), 1)
    } else {
      this.expandedDeliveryNotes.push(rowId)
    }
  }


  /**
   * Tells if the number of selected elements matches the total number of rows
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected, otherwise clear selection
   */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /**
   *  Updates table based on provided event and observable
   * @param $event Can be a Sort Event, a Change page size Event, a Keyword Search event, a Filter Event
   * @param obs$ Manages chain of data fetching of provided type
   */
  loadResults($event: any, obs$: Observable<T[]>) {

    this.paramLoading = true

    if ($event?.keyword != undefined)
      this.keyword = $event?.keyword

    let active = $event?.active ? $event?.active : this.defaultSortColumn
    let direction = $event?.direction ? $event?.direction : Constants.pagination.defaultSortDirection

    // todo: this is for BaseItemTable
    if ($event?.itemTypeIds != undefined)
      this.itemTypeIds = $event.itemTypeIds

    this.fetchParams = {
      page: $event?.pageIndex ? $event?.pageIndex : Constants.pagination.defaultPage,
      num: this.paginator?.pageSize !== undefined ? this.paginator?.pageSize : Constants.pagination.defaultPageSize,
      keyword: $event?.keyword ? $event.keyword : this.keyword,
      itemTypeIds: $event?.itemTypeIds ? $event.itemTypeIds : this.itemTypeIds,
      sort: `${active} ${direction}`,
    }

    this.subs.push(obs$.subscribe(() => this.paramLoading = false))

  }


  logMe(...a: any[]) {
    console.log(...a)
  }

  /**
   * Generates path for parent entity
   */
  generateParentPath(entity: string): any[] {
    return this.userReference.isAWUser ?
      ['/reserved', 'companies', this.userReference.companyId, entity] :
      ['/reserved', entity];
  }


  /**
   * Generates path for entity detail
   */
  generatePath(entityId: number | string, childPath: string | undefined, entity: string) {
    if (childPath)
      return [...this.generateParentPath(entity), entityId, childPath]
    return [...this.generateParentPath(entity), entityId]
  }


  /**
   * Manages entities deletion
   */
  deleteEntities(entitiesIds: number[], ...args: any[]) {


    this.dialogService.confirm("Conferma eliminazione", "Sei sicuro di voler eliminare le risorse selezionate?")
      .pipe(
        switchMap((value) => value ?
          forkJoin(entitiesIds.map(entityId => this.deleteFunction(...args, entityId))) : EMPTY),
        switchMap(() => this.observable$)
      )

      .subscribe({
          next: () => {
            this.selection.clear(true)
            this.snackService.success("Risorse selezionate eliminate con successo")
          },
          error: () => this.snackService.error("Impossibile eliminare le risorse selezionate")
        }
      )
  }

  /**
   * Inserts a column based on the order of the availableColumns
   *
   * @param columnName name of the column to be inserted
   * @param saveToLocalStorage if false don't save to localStorage. Default is true
   */
  insertColumn(columnName: string, saveToLocalStorage = true) {
    if (this.displayedColumns.includes(columnName))
      return

    let columnOriginalIndex = this.availableColumns.indexOf(columnName)

    // Reversing the list because find() searches from 0 to length, and
    // I need to find the first preceding element contained in displayedColumns starting from the end
    let previousColumns = this.availableColumns.slice(0, columnOriginalIndex).reverse()

    let indexToInsert = this.displayedColumns.indexOf(previousColumns.find((value) => this.displayedColumns.includes(value)) ?? '') + 1

    this.displayedColumns.splice(indexToInsert, 0, columnName)

    if (saveToLocalStorage)
      this.saveDisplayedColumnsToLocalStorage()
  }


  /**
   * Inserts a list of columns based on the order of the availableColumns
   * Internally, use {@link insertColumn}
   * @param columnNames list of column names to be inserted
   */
  insertColumns(columnNames: string[]) {
    columnNames.forEach(col => this.insertColumn(col))
  }

  /**
   * Removes a column based on the order of the availableColumns
   *
   * @param columnName name of the column to remove
   * @param saveToLocalStorage if false don't save to localStorage. Default is true
   */
  removeColumn(columnName: string, saveToLocalStorage = true) {
    if (!this.displayedColumns.includes(columnName))
      return

    let columnIndex = this.displayedColumns.indexOf(columnName)

    this.displayedColumns.splice(columnIndex, 1)

    if (saveToLocalStorage)
      this.saveDisplayedColumnsToLocalStorage()
  }


  /**
   * Returns the list of columns present in the availableColumns list but not in the
   * displayedColumns list
   */
  getHiddenColumns() {
    return this.availableColumns.filter(col => this.displayedColumns.indexOf(col) < 0)
  }


  /**
   * Retrieves and returns displayedColumns from the local storage value if any, otherwise returns availableColumns
   */
  getDisplayedColumnsFromLocalStorage(): string[] {
    let result = localStorage.getItem(this.localStorageKey)

    if (result == null)
      return [...this.availableColumns]

    return JSON.parse(result)
  }


  saveDisplayedColumnsToLocalStorage() {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.displayedColumns))
    this.localStorageColumns = this.getDisplayedColumnsFromLocalStorage()
  }


}
