import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApiResponse} from '../classes/api-response';
import {cloneDeep} from 'lodash-es';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GeneralInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((err: HttpErrorResponse) => {

          if (err.status == 401) {
            this.authService.logout();
            return EMPTY;
          }

          //todo:

          if (err.status === 403) {
            const ret = new ApiResponse();
            ret.errors = ['403'];
            return throwError(ret);
            // this.dialogService.closeAll();
            // return from(
            //   this.router.navigate(['/reserved']
            //   ))
            //   .pipe(switchMap(() => EMPTY));
          }


          if (err.error) {
            return throwError(cloneDeep<ApiResponse>(err.error));
          } else {
            const ret = new ApiResponse();
            ret.errors = ['aw.api.errors.generic'];
            return throwError(ret);
          }
        }));
  }
}
