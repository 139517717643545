import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResponse} from '../classes/api-response';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Constants} from '../utils/constants';
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";
import {ItemVariantCreate, ItemVariantDetail} from "../interfaces/item-variant";

@Injectable({
  providedIn: 'root'
})
export class ItemVariantsService {

    private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.companies}`;


  constructor(private http: HttpClient) { }


  public checkName(companyId: number, tentativeName: string): Observable<boolean> {

    let params: HttpParams = new HttpParams().set('tentativeName' , tentativeName)

    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/items/name-check`, {params})
      .pipe(map(resp => resp.data as boolean));
  }

  public getAllCompanyItems(companyId: number, paginationParams?: FetchParams): Observable<PageConverter<ItemVariantDetail>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.items}`, {params : sortFilterParams})
      .pipe(map(value => value.data as PageConverter<ItemVariantDetail>))

  }

  public getItemVariant(companyId: number, baseItemId: number, itemId: number): Observable<ItemVariantDetail> {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/base-items/${baseItemId}/${Constants.entities.items}/${itemId}`)
      .pipe(map(value => value.data as ItemVariantDetail))

  }

  public createCompanyItem(companyId: number, item: ItemVariantCreate): Observable<ItemVariantDetail> {
    return this.http
      .post<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.items}`, item)
      .pipe(map(value => value.data as ItemVariantDetail));
  }

  public deleteCompanyItem(companyId: number, itemId: number): Observable<void> {
    return this.http
      .delete<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.items}/${itemId}`)
      .pipe(map(() => {
      }))
  }

  public patchCompanyItem(companyId: number, item: ItemVariantCreate, itemId : number): Observable<ItemVariantDetail> {
    return this.http
      .patch<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.items}/${itemId}`, item)
      .pipe(map(value => value.data as ItemVariantDetail))
  }


}
