import {Component} from '@angular/core';
import {ResponsiveService} from "./services/responsive.service";

@Component({
  selector: 'aw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'floweer-fe';
  activated = false;

  constructor(private rs: ResponsiveService) {
  }

}
