<ng-template #loadingGeneral>

  <mat-spinner class="align-self-center mt-8"></mat-spinner>

</ng-template>


<div fxLayout="column">
  <h1 class="font-light">Articoli</h1>

  <ng-container *ngIf="dataSource && !loading else loadingGeneral">


    <table
      (matSortChange)="loadResults($event, observable$)"
      [dataSource]="dataSource"
      class="w-100 mat-elevation-z2"
      mat-table
      matSort
      matSortActive="name"
      matSortDirection="asc"
      multiTemplateDataRows>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                        (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <!-- Warehouse Column -->
      <ng-container matColumnDef="warehouse">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell mat-sort-header="warehouse">Magazzino</th>
        <td *matCellDef="let element" mat-cell>{{element?.warehouseName ?? "Magazzino non assegnato"}}</td>
      </ng-container>

      <!-- Item Column -->
      <ng-container matColumnDef="item">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="item">Articolo</th>
        <td *matCellDef="let element" mat-cell>
          <div fxLayout="column">
            <strong>{{element.variantItemName}}</strong>

            <small *ngIf="element.message.name">
              ===> {{element.message.name}}
            </small>
          </div>
        </td>
      </ng-container>

      <!-- Barcode Column -->
      <ng-container matColumnDef="barcode">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="barcode">Codice a barre</th>
        <td *matCellDef="let element" mat-cell>
          <div fxLayout="column">
            <strong>{{element.barcode ?? "NON PRESENTE"}}</strong>
            <small *ngIf="element.message.barcode">
              ===> {{element.message.barcode}}
            </small>
          </div>
        </td>
      </ng-container>

      <!-- SKU Column -->
      <ng-container matColumnDef="sku">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="sku">Codice identificativo articolo</th>
        <td *matCellDef="let element" mat-cell>
          <div fxLayout="column">
            <strong>{{element.sku ?? "NON PRESENTE"}}</strong>
            <small *ngIf="element.message.sku">
              ===> {{element.message.sku}}
            </small>
          </div>
        </td>
      </ng-container>

      <!-- Quantity Column -->
      <ng-container matColumnDef="quantity">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="quantity">Quantità</th>
        <td *matCellDef="let element"
            mat-cell>{{element.unit ? (element.quantity + ' ' + element.unit) : (element.quantity)}}</td>
      </ng-container>

      <!-- Item Unit Price Column -->
      <ng-container matColumnDef="itemUnitPrice">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell mat-sort-header="itemUnitPrice">Prezzo unitario</th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell>
          {{element.unit ? ((element.itemUnitPrice | currency:'EUR') + '/' + element.unit) : (element.itemUnitPrice | currency:'EUR')}}
        </td>
      </ng-container>

      <!-- Total Column -->
      <ng-container matColumnDef="total">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell mat-sort-header="total">Totale</th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell>
          {{(element.itemUnitPrice * element.quantity) | currency:'EUR'}}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="warehouseStocks">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell mat-sort-header="warehouseStocks">Giacenze</th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell>
          {{element?.warehouseName ? (element.status | orderContentStatus) : ''}}
        </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Azioni</th>
        <td *matCellDef="let element" class="hide-mobile" fxLayoutGap="8" mat-cell>


          <button #delete
                  (click)="deleteEntities([element.sellOrderContentId], userReference.companyId, shopId ?? null, _sellOrder?.id);$event.preventDefault();$event.stopPropagation();"

                  [disabled]="isDeleteButtonDisabled(element)"
                  *awAccess="{selected: this.shopId ? {id: this.shopId!} : undefined, accessLevel: {accessEntity: accessEntities.sellOrderShops, accessLevel: accessLevels.admin}}"
                  [awDisableElement]="{
                    selected: [this.shopId ? {id: this.shopId!} : undefined],
                    condition: isDeleteButtonDisabled(element),
                    accessLevels: requiredDeleteAccessLevels,
                  component: delete}"
                  color="warn"
                  mat-icon-button
                  matTooltip="Elimina"
                  matTooltipPosition="above"
          >
            <mat-icon>delete</mat-icon>
          </button>

        </td>

      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      <!--      todo add awSlidingRow-->


      <tr *matNoDataRow class="mat-row">
        <td [colSpan]="displayedColumns.length" class="mat-cell text-center">
          Nessun articolo trovato
        </td>
      </tr>

    </table>

    <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </mat-card>

  </ng-container>

  <mat-paginator #paginator
                 (page)="loadResults($event, observable$)"
                 (selectionchange)="logMe($event)"
                 *ngIf="dataSource && numEntities > 0"
                 [length]="numEntities"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 class="mat-elevation-z1"
                 showFirstLastButtons
  >
  </mat-paginator>

  <div *ngIf="_sellOrder?.status == sellOrderStates.toBeAssigned || _sellOrder?.status == sellOrderStates.inProgress" class="mt-2" fxLayout="row"
       fxLayoutAlign="center center">
    <button (click)="addContent()" class="border border-primary" color="primary" fxFlex="100" mat-button>
      <mat-icon matPrefix>add</mat-icon>
      Aggiungi articolo
    </button>
  </div>
</div>
