import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {ProfileDetail,} from "../../../interfaces/profile";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {PermissionsService} from "../../../services/permissions.service";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {ProfilesService} from "../../../services/profiles.service";
import { getBackgroundColor } from 'src/app/utils/global-functions-and-types';
import {Constants} from "../../../utils/constants";


@Component({
  selector: 'aw-profiles-table',
  templateUrl: './profiles-table.component.html',
  styleUrls: ['./profiles-table.component.scss']
})
export class ProfilesTableComponent extends BaseTableComponent<ProfileDetail> {


  getBackgroundColor = getBackgroundColor


  constructor(
    public router: Router,
    private permissionsService: PermissionsService,
    private profilesService: ProfilesService,
    public override snackService: SnackService,
    protected override dialogService: DialogsService,
    protected override utilsService: UtilsService) {

    let deleteFunction =
      (companyId: number, profileId: number): Observable<void> =>
        profilesService.deleteProfile(companyId, profileId)

    super('name', Constants.localStorageKeys.profilesTable, deleteFunction, utilsService, snackService, dialogService)

    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(() => this.profilesService.getAllCompanyProfiles(this.userReference.companyId, this.fetchParams)),
          switchMap(profiles => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<ProfileDetail>(profiles.list)
            this.entityList.push(...profiles.list)
            this.numEntities = profiles.num
            return of(profiles.list)
          }),
          catchError((err) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i profili');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())

    this.displayedColumns = [
      "name",
      "roles",
      "action",
    ]

  }

  createProfile() {
    this.router.navigate(this.generatePath('new', undefined, this.entities.profiles))
  }
}
