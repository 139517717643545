import {Component} from '@angular/core';

@Component({
  selector: 'aw-customers',
  templateUrl: './customers-container.component.html',
  styleUrls: ['./customers-container.component.scss']
})
export class CustomersContainerComponent {
  constructor() { }
}
