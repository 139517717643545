<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>

<h1 class="font-light">Dettagli dell'ordine</h1>


<mat-card>


  <mat-card-content>

    <div *ngIf="!loading else loadingTemplate" class="grid">

      <div class="col-4 bottom-position-1">
        <p *ngIf="order?.customerName"><strong>Cliente Mittente:</strong> {{order?.customerName}} {{orderCustomer?.phone ? ", " +  orderCustomer!.phone : ""}} {{orderCustomer?.email ? ", " +  orderCustomer!.email : ""}}</p>
        <p><strong>Ordine effettuato il:</strong> {{order?.orderDate | date: 'EEEE, dd/MM/yyyy'}}</p>
        <p *ngIf="order?.shopName"><strong>Punto vendita:</strong> {{order?.shopName}}</p>
        <p *ngIf="order?.deadlineDate"><strong>Da evadere entro:</strong> {{order?.deadlineDate | date: 'EEEE, dd/MM/yyyy'}}</p>
        <p *ngIf="order?.calculatedPrice"><strong>Prezzo calcolato:</strong> {{order?.calculatedPrice | currency:'EUR'}}</p>
        <p *ngIf="order?.paymentStatus"><strong>Stato pagamento:</strong>
          {{order?.paymentStatus == 'TO_BE_PAID' ? 'Da Pagare' :
            order?.paymentStatus == 'PAID' ? 'Pagato' :
            order?.paymentStatus == 'ADVANCE' ? 'Acconto' :
            order?.paymentStatus
          }}
        </p>

        <p *ngIf="order?.paymentStatus == 'ADVANCE'">
          <strong> Pagato: </strong> {{order?.paidPrice | currency: 'EUR'}}
        </p>

        <p *ngIf="order?.trackingNumber">
          <strong> Codice tracciamento: </strong> {{order?.trackingNumber}}
        </p>

        <div class="bottom-position-price">
        <p *ngIf="order?.finalPrice"><strong>Prezzo finale:</strong> {{order?.finalPrice ? (order?.finalPrice | currency:'EUR') : (order?.calculatedPrice | currency:'EUR')}}</p>
        </div>

      </div>


      <div class="col-4 bottom-position-1">

        <p *ngIf="order?.customerName"><strong>Cliente Destinatario:</strong> {{order?.recipientName}} {{order?.shippingInformation?.phone ? ", " +  orderCustomer!.phone : ""}}</p>
        <p *ngIf="order?.shippingInformation?.addressName"><strong>Indirizzo di spedizione:</strong> ({{order?.shippingInformation?.addressName}}): {{order?.shippingInformation?.phone}}, {{order?.shippingInformation?.address}}, {{order?.shippingInformation?.city}}</p>
        <p *ngIf="order?.billingInformation?.addressName"><strong>Indirizzo di fatturazione:</strong> {{order?.billingInformation?.addressName}}: {{order?.billingInformation?.phone}}, {{order?.billingInformation?.address}}, {{order?.billingInformation?.city}}</p>
        <p *ngIf="order?.deliveryTimeSlot?.id"><strong>Fascia oraria di consegna:</strong> {{order?.deliveryTimeSlot?.name}}</p>

        <p *ngIf="order?.deliveryOption"><strong>Opzioni Consegna:</strong>
          {{order?.deliveryOption == 'SHOP' ? 'Ritiro in negozio' :
            order?.deliveryOption == 'DELIVERY_COMPANY' ? 'Consegna con corriere' :
            order?.deliveryOption == 'RIDER' ? 'Consegna con rider' :
            order?.deliveryOption
          }}
        </p>

        <p *ngIf="order?.deliveryCompany?.id"><strong>Corriere:</strong> {{order?.deliveryCompany?.name}}</p>


        <p *ngIf="order?.deliveryNotes"><strong>Note consegna:</strong> {{order?.deliveryNotes}}</p>

        <p *ngIf="order?.photo == true"> <strong> Il Cliente ha richiesto delle foto </strong> <mat-icon *ngIf="order.photo">photo_camera</mat-icon> </p>

        <p *ngIf="order?.trackingNumber"> <strong> Codice tracciamento:</strong> {{order?.trackingNumber}} </p>


        <div class="bottom-position-delivery">
          <p *ngIf="order?.deliveryDate"><strong>Consegna effettuata il:</strong> {{order?.deliveryDate | date: 'EEEE, dd/MM/yyyy'}}</p>
        </div>

      </div>


      <div class="col-4">
        <p>
        <button (click)="openTimeline()"
                class="align-content-end justify-content-end mt-3"
                color="accent"
                mat-raised-button>
          Storico eventi
        </button>
       </p>

        <div>
          <mat-form-field fxFlex>
            <mat-label>Stato dell'ordine</mat-label>

            <mat-select
              (selectionChange)="onStatusChange()"
              [disabled]="!showButton"
              [(ngModel)]="selectedStatus"
            >
              <mat-option *ngIf="availableStates.length === 0">
                Nessuno stato disponibile.
              </mat-option>
              <mat-option *ngFor="let status of availableStates" [value]="status">
                {{status | orderStatus}}
              </mat-option>

            </mat-select>

          </mat-form-field>

        </div>


        <div *ngIf="showManageWarehouseButton(selectedStatus)" class="col-12">


          <div *ngIf="selectedStatus !== 'FAILED'"
               class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
            <p fxLayoutAlign="center center">
              <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
              Devi gestire tutti gli articoli prima di continuare.
            </p>
          </div>

          <div *ngIf="selectedStatus === 'FAILED'"
               class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
            <p fxLayoutAlign="center center">
              <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
              Devi gestire il rollback delle giacenze prima di continuare.
            </p>
          </div>



          <div class="mt-1_5 w-100">
            <!--      in caso di completamento -->
            <mat-form-field *ngIf="!isOrderFailed">
              <mat-label>Gestione generale</mat-label>

              <mat-select
                (selectionChange)="updateOrderContentStatusAndCheckErrorsOnCloseSale"
                [(ngModel)]="generalSelectedSellOrderContentStatus"
                *ngIf="!isOrderFailed"
              >
                <mat-option *ngIf="updatedStates.length === 0">
                  Nessuno stato disponibile.
                </mat-option>
                <mat-option *ngFor="let status of updatedStates" [value]="status">
                  {{status | orderContentStatus}}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <!--      in caso di annullamento -->
            <mat-form-field *ngIf="isOrderFailed">
              <mat-label>Gestione generale</mat-label>

              <mat-select
                (selectionChange)="updateOrderContentStatusAndCheckErrorsOnCloseSale(); checkIfCanAutoscaleOnOrderClose();  setGlobalErrorMessageOnSellOrderClose()"
                *ngIf="isOrderFailed"
                [(ngModel)]="generalSelectedRollbackStatus"
                [matTooltip]="generalSelectedRollbackStatus?.tooltip ?? ''"

              >
                <mat-option *ngIf="rollbackStates.length === 0">
                  Nessuno stato disponibile.
                </mat-option>
                <mat-option *ngFor="let status of rollbackStates" [value]="status">
                  {{status.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <div fxLayoutAlign="center center" fxLayout="row">

            <div *ngIf="autoscaleErrorOnSellOrderClose"
                 class="border border-warn disclaimer-error mat-background-light mt-4">
              <p fxLayoutAlign="center center">
                Attenzione, non tutti gli articoli possono essere scalati automaticamente! Entra nelle impostazioni
                avanzate e gestiscili singolarmente
              </p>
            </div>
          </div>


          <div class="mt-4">

            <mat-accordion>
              <mat-expansion-panel [expanded]="autoscaleErrorOnSellOrderClose">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Impostazioni avanzate
                  </mat-panel-title>
                </mat-expansion-panel-header>


                <div *ngFor="let item of itemToHandle; let i = index" fxLayout="column">

                  <div fxLayout="row" fxFlex>
                    <p>{{item.item.name}} x{{item.quantity}} da {{item.warehouse?.name}}</p>
                  </div>

                  <mat-form-field fxLayout="row" fxFlex="100">

                    <!--                  If I am completing order-->
                    <mat-select
                      *ngIf="!isOrderFailed"
                      [(ngModel)]="closeOrderContentWrapper[i].selectedStatus"
                      (selectionChange)="checkIfCanAutoscaleOnOrderClose(); setGlobalErrorMessageOnSellOrderClose()"
                    >
                      <mat-option *ngFor="let status of updatedStates" [value]="status">
                        {{status! | orderContentStatus}}
                      </mat-option>
                    </mat-select>

                    <!--                  If I am canceling order-->
                    <mat-select
                      *ngIf="isOrderFailed"
                      [(ngModel)]="closeOrderContentWrapper[i].selectedRollback"
                      [matTooltip]="closeOrderContentWrapper[i].selectedRollback?.tooltip ?? ''"
                    >
                      <mat-option *ngFor="let status of rollbackStates" [value]="status">
                        {{status.name}}
                      </mat-option>
                    </mat-select>

                  </mat-form-field>
                  <div>

                    <div *ngIf="closeOrderContentWrapper[i] && closeOrderContentWrapper[i].showAutoscaleError"
                         class="border border-warn disclaimer-box mat-background-light p-2_5 mb-2_5" fxFlex>
                      <p>
                        {{closeOrderContentWrapper[i].autoscaleError!}}
                      </p>
                    </div>
                  </div>
                </div>

              </mat-expansion-panel>
            </mat-accordion>

          </div>
        </div>

        <div
          *ngIf="!canTransition(selectedStatus) && errorToShow"
          class="border border-warn disclaimer-error mat-background-light p-2_5 mb-2_5 mt-4">
          <p fxLayoutAlign="center center">
            {{errorToShow}}
          </p>
        </div>


        <!--        todo: align better-->
        <div class="flex flex-row justify-content-between">
          <button *ngIf="showButton"
                  (click)="applyStatus(selectedStatus)"
                  [disabled]="isApplyStatusButtonDisabled() || (showManageWarehouseButton(selectedStatus) && autoscaleErrorOnSellOrderClose) || savingStatus"
                  [matTooltip]="errorToShow"
                  class="align-content-end justify-content-end mt-3 col-5"
                  mat-raised-button
                  color="primary"
          >
            Applica stato
          </button>

          <button (click)="selectedStatus = order.status!"
                  *ngIf="showButton"
                  [disabled]="isApplyStatusButtonDisabled()"
                  class="align-content-end justify-content-end mt-3 col-5"
                  color="warn"
                  mat-raised-button
          >
            Annulla modifiche
          </button>
        </div>

      </div>

    </div>


    <div *ngIf="order?.note" class="border border-yellow-warn disclaimer-warning my-4 p-4 text-center">
      <div class="mb-4"><strong>NOTE</strong></div>
      {{order?.note}}
    </div>

    <div class="grid">
      <div class="col-12">
        <aw-order-content-table
          *ngIf="!loadingOrder else loadingTemplate"
          [shopId]="shopId"
          [sellOrder]="order"
        >
        </aw-order-content-table>
      </div>

    </div>
  </mat-card-content>


</mat-card>

