<ng-template #loadingGeneral>

  <mat-spinner class="align-self-center mt-8"></mat-spinner>

</ng-template>


<div fxLayout="column">
  <h1 class="font-light">Punti vendita</h1>

  <ng-container *ngIf="dataSource && !loading else loadingGeneral">


    <aw-actions-bar
      (deleteElements)="deleteEntities($event, userReference.companyId)"
      (load)="loadResults($event, observable$)"
      (openCreateForm)="addShop()"
      [accessEntity]="accessEntities.shops"
      [displayedColumns]="displayedColumns"
      [isAllSelected]="isAllSelected()"
      [selectVisible]="selectVisible"
      [selection]="selection"
    >

    </aw-actions-bar>


    <table
      (matSortChange)="loadResults($event, observable$)"
      [dataSource]="dataSource"
      class="w-100 mat-elevation-z2"
      mat-table
      matSort
      matSortActive="name"
      matSortDirection="asc"
      multiTemplateDataRows>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                        (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="name">
          Nome
        </th>
        <td *matCellDef="let element" mat-cell> {{element.name}} </td>
      </ng-container>

      <!-- Address Column -->
      <ng-container matColumnDef="address">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="address">
          Indirizzo
        </th>
        <td *matCellDef="let element" mat-cell>
          {{element.address}}
        </td>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell mat-sort-header="phone">
          Telefono
        </th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell> {{element.phone}} </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>
          Azioni
        </th>
        <td *matCellDef="let element" class="hide-mobile" fxLayoutGap="8" mat-cell>

          <button (click)="$event.preventDefault();$event.stopPropagation();"
                  [awDisableElement]="{
                        selected: [element],
                        accessLevels: [{accessEntity: accessEntities.shops, accessLevel: accessLevels.editor}, {accessEntity: accessEntities.sellOrderShops, accessLevel: accessLevels.reader}]}"
                  [routerLink]="generatePath(element.id, undefined, entities.shops)"
                  color="accent"
                  mat-icon-button
                  matTooltip="Vai al dettaglio"
                  matTooltipPosition="above"
          >

            <mat-icon>info</mat-icon>

          </button>

          <button #share
                  (click)="$event.preventDefault();$event.stopPropagation();"
                  [awDisableElement]="{
                        selected: [element],
                        logicalAnd: true,
                        accessLevels: [{accessEntity: accessEntities.shops, accessLevel: accessLevels.owner}, {accessEntity: accessEntities.users, accessLevel: accessLevels.reader}],
                        component: share}"
                  [routerLink]="generatePath(element.id, entities.permissions, entities.shops)"
                  color="accent"
                  mat-icon-button
                  matTooltip="Condividi"
                  matTooltipPosition="above"
          >

            <mat-icon>share</mat-icon>

          </button>

          <button #edit
                  (click)="editShop(element);$event.preventDefault();$event.stopPropagation();"
                  *awAccess="{accessLevel:{accessEntity: accessEntities.shops, accessLevel: accessLevels.editor}}"

                  [awDisableElement]="{
                        selected: [element],
                        logicalAnd: true,
                        accessLevels: [{accessEntity: accessEntities.shops, accessLevel: accessLevels.editor}],
                        component: edit}"

                  color="accent"
                  mat-icon-button
                  matTooltip="Modifica"
                  matTooltipPosition="above"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button #delete
                  (click)="deleteEntities([element.id], userReference.companyId);$event.preventDefault();$event.stopPropagation();"
                  *awAccess="{accessLevel:{accessEntity: accessEntities.shops, accessLevel: accessLevels.admin}}"

                  [awDisableElement]="{
                        selected: [element],
                        logicalAnd: true,
                        accessLevels: [{accessEntity: accessEntities.shops, accessLevel: accessLevels.admin}],
                        component: delete}"
                  color="warn"
                  mat-icon-button
                  matTooltip="Elimina"
                  matTooltipPosition="above"
          >
            <mat-icon>delete</mat-icon>
          </button>

        </td>

      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr (click)="editShop(row)" *matRowDef="let row; columns:  displayedColumns" mat-row></tr>
      <!--      todo add awSlidingRow-->


      <tr *matNoDataRow class="mat-row">
        <td [colSpan]="displayedColumns.length" class="mat-cell text-center">
          Nessun magazzino trovato
        </td>
      </tr>

    </table>

    <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </mat-card>

  </ng-container>

  <mat-paginator #paginator
                 (page)="loadResults($event, observable$)"
                 (selectionchange)="logMe($event)"
                 *ngIf="dataSource && numEntities > 0"
                 [length]="numEntities"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 class="mat-elevation-z1"
                 showFirstLastButtons
  >
  </mat-paginator>
</div>
