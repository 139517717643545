import {Component, OnDestroy, OnInit} from '@angular/core';
import {EMPTY, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {ProfileDetail,} from "../../../interfaces/profile";
import {AuthService} from "../../../services/auth.service";
import {ItemTypesService} from "../../../services/item-types.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ProfilesService} from "../../../services/profiles.service";
import {catchError} from "rxjs/operators";
import {WarehousesService} from "../../../services/warehouses.service";
import {ShopsService} from "../../../services/shops.service";
import {UtilsService} from "../../../services/utils.service";
import {UserReference} from "../../../utils/global-functions-and-types";
import {SnackService} from "../../../services/snack.service";

@Component({
  selector: 'aw-profile-details',
  templateUrl: './profile-details-container.component.html',
  styleUrls: ['./profile-details-container.component.scss']
})
export class ProfileDetailsContainerComponent implements OnInit, OnDestroy {

  loading = false
  saving = false
  profile$: Observable<any>
  userReference!: UserReference;
  subs: Subscription[] = []
  profile!: ProfileDetail
  profileId: number | string | null = null;


  constructor(private warehousesService: WarehousesService,
              private shopsService: ShopsService,
              private profilesService: ProfilesService,
              private authService: AuthService,
              private itemTypeService: ItemTypesService,
              private activatedRoute: ActivatedRoute,
              private utilsService: UtilsService,
              private snackService: SnackService,
              public router: Router) {


    this.profile$ =
      of(true)
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => activatedRoute.paramMap),
          switchMap(paramMap => {
            if (paramMap.has("profileId"))
              this.profileId = paramMap.get("profileId")!
            return this.utilsService.getUserReference()
          }),
          switchMap(userReference => {
            this.userReference = userReference
            return (this.profileId === "new") ? of(undefined) :
              this.profilesService.getProfileById(this.userReference.companyId, +this.profileId!);
          }),
          tap(profile => {
            this.loading = false;
            if (profile)
              this.profile = profile
            return profile
          }),
          catchError((err) => {
            this.loading = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare il profilo')
            return EMPTY;
          })
        )


  }

  ngOnInit(): void {
    this.loadProfile()
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  loadProfile() {
    this.subs.push(this.profile$.subscribe())
  }

}
