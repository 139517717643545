import {Injectable} from '@angular/core';

import {ApiResponse} from "../classes/api-response";
import {map} from "rxjs/operators";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Constants} from "../utils/constants";
import {
  AccessDataUser,
  AccessPermissionCreate,
  AccessPermissionDelete,
  AccessProfileCreate,
  AccessRoleCreate,
  AccessRoleDelete
} from "../interfaces/permission";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.access}`;

  constructor(private http: HttpClient) {
  }

  getEntityUsers(companyId: number, entity: string, entityId: number, paginationParams: FetchParams): Observable<PageConverter<AccessDataUser>> {
    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.access}/${Constants.entities.users}/${entity}/${entityId}`, {params: sortFilterParams})
      .pipe(map(value => {
        return value.data as PageConverter<AccessDataUser>
      }))
  }

  setProfilesToUser(companyId: number, profiles: AccessProfileCreate[]) {

    return this.http
      .put<ApiResponse>(
        `${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.access}/${Constants.entities.profiles}`, profiles)
      .pipe(map(value => value.data as {}));
  }

  setRolesToUser(companyId: number, roles: AccessRoleCreate[]) {
    return this.http
      .put<ApiResponse>(
        `${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.access}/${Constants.entities.roles}`, roles)
      .pipe(map(value => value.data as {}));
  }

  setPermissionsToUser(companyId: number, permissions: AccessPermissionCreate[]) {
    return this.http
      .put<ApiResponse>(
        `${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.access}/${Constants.entities.permissions}`, permissions)
      .pipe(map(value => value.data as {}));
  }

  removeProfilesFromUser(companyId: number, profiles: AccessProfileCreate[]) {
    return this.http
      .delete<ApiResponse>(
        `${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.access}/${Constants.entities.profiles}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          body: profiles
        })
      .pipe(map(() => {
      }))
  }

  removeRolesFromUser(companyId: number, roles: AccessRoleDelete[]) {
    return this.http
      .delete<ApiResponse>(
        `${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.access}/${Constants.entities.roles}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          body: roles
        })
      .pipe(map(() => {
      }))
  }

  removePermissionsFromUser(companyId: number, permissions: AccessPermissionDelete[]) {
    return this.http
      .delete<ApiResponse>(
        `${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.access}/${Constants.entities.permissions}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          body: permissions
        })
      .pipe(map(() => {
      }))
  }

}
