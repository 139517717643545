import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColumnNameConversion} from "../../column-name-conversion";

@Component({
  selector: 'aw-insert-columns-selector',
  templateUrl: './insert-columns-selector.component.html',
  styleUrls: ['./insert-columns-selector.component.scss']
})
export class InsertColumnsSelectorComponent implements OnInit {

  // Name displayed on the header column
  @Input() headerName = 'Azioni'

  // Columns to display in mat select
  @Input() hiddenColumns: string[] = [];
  selectedColumns: string[] = [];

  @Output() insertColumns = new EventEmitter<string[]>();

  constructor() {
  }

  ngOnInit(): void {
  }

  convertColumnName(columnKey: string): string {


    if (ColumnNameConversion.conversionMapKeys().includes(columnKey)) {
      return (ColumnNameConversion.conversionMap as any)[columnKey]
    }

    return ''
  }

}
