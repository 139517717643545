import {Injectable} from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, of, switchMap} from "rxjs";
import {AuthService} from "./auth.service";
import {UserReference} from "../utils/global-functions-and-types";
import {AccessData, AccessPermissionData} from "../interfaces/permission";


@Injectable({
  providedIn: 'root'
})
export class UtilsService {


  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  static buildParams(list: number[], params: HttpParams | undefined): HttpParams {
    let obj: any = {}

    params?.keys().filter(value => params.get(value) != undefined && params.get(value) != "")
      .map(value => obj[value] = params.getAll(value))

    if (list.length > 0)
      obj['ids'] = list.toString()

    return new HttpParams({fromObject: obj})
  }

  /**
   * Gets user, company ID (from user or from path if AW user) and
   */
  getUserReference(): Observable<UserReference> {
    let userReference: UserReference = {} as UserReference

    return (
      of(true)
        .pipe(
          switchMap(() => this.authService.onUserChange()),
          switchMap(user => {

            if (user) {
              userReference.user = user
              if (user?.companyId)
                userReference.companyId = user?.companyId
            }

            let companyIdInUrl: number | null = null

            // todo questa roba fa schifo, ma non sembra esserci modo di ottenerlo dalla paramMap di ActivatedRoute (snapshot o meno)
            //  probabilmente per ordine di istanziazione. Magari passandola o facendo subscribe al momento giusto può funzionare, ma per ora è sempre stata vuota
            if (this.router.url.includes('companies/')) {
              companyIdInUrl = +this.router.url.split('companies/')[1].split('/')[0]
            }

            userReference.isAWUser = !!companyIdInUrl

            if (userReference.isAWUser)
              userReference.companyId = +companyIdInUrl!

            return of(userReference)
          }),
        )
    )
  }

  getDifference<T>(a: T[], b: T[]): T[] {
    return a.filter((element) => !b.includes(element));
  }

  getEntityAccessIdsForUser(entity: AccessData) {

    return entity?.userAuthority != undefined ?
      [] :
      entity!.permission.map(value => value.entityId)
  }

  getAccessEntities(entities: any[], accessPermissionData?: AccessPermissionData[]) {

    return entities.map(entity => {
      return {
        entityId: entity.id,
        name: entity.name,
        userAuthority: accessPermissionData?.find(userEntity => userEntity.entityId === entity.id)?.userAuthority
      }
    });
  }

}
