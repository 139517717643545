import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {from, Observable} from "rxjs";
import {switchMap} from 'rxjs/operators';
import {environment} from "../../environments/environment";
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {


  constructor(public afAuth: AngularFireAuth) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.afAuth.currentUser)
      .pipe(
        switchMap((auth) => {

          if (request.url.includes(environment.apiUrl) && auth) {

            return from(auth.getIdToken())
              .pipe(
                switchMap((jwt) => {
                  request = request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${jwt}`
                    }
                  });
                  return next.handle(request);

                })
              )
          }
          return next.handle(request)
        }),
      );

  }

}





