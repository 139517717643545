<div fxLayout="row" fxLayoutAlign="start center">


    <ng-container *ngIf="removable">
        <button (click)="$event.preventDefault(); $event.stopPropagation(); openRemoveColumnDialog()" mat-icon-button>
            <mat-icon class="close-icon" color="warn" inline="true">
                cancel
            </mat-icon>
        </button>
    </ng-container>

    <div>{{headerName}}</div>

    <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="appMenu" mat-icon-button>
        <mat-icon [ngStyle]="{'color': getColor() }" class="icon-small" inline="true">filter_alt</mat-icon>
    </button>

    <mat-menu #appMenu="matMenu">
        <div class="my-2 mx-4">
            <div>
                <p class="font-normal"> Filtra per <span class="font-light">{{headerName.toLowerCase()}}</span></p>
                <mat-form-field (click)="$event.stopPropagation()">
                    <mat-select (click)="$event.stopPropagation()"
                                [(ngModel)]="selectedOptions"
                                (ngModelChange)="manageSelectedOptions($event); this.isFilterChanged = true; this.isFiltered = false"
                                multiple [placeholder]="placeholder">
                        <mat-option (click)="$event.stopPropagation()" *ngFor="let status of options"
                                    [value]="status.key">{{status.value}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div fxLayout="row">
                <button (click)="emitOnFilter()" class="border border-primary mr-1" color="primary" fxFlex mat-button>
                    Filtra
                </button>
                <button (click)="resetFilter()" class="border border-accent ml-1" color="accent" fxFlex mat-button>Reset
                </button>
            </div>
        </div>
    </mat-menu>

</div>
