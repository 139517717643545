import {Component} from "@angular/core";

@Component({
  selector: 'aw-warehouses',
  templateUrl: './warehouses-container.component.html',
  styleUrls: ['./warehouses-container.component.scss']
})
export class WarehousesContainerComponent {


  constructor() {


  }


}
