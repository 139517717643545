
<div [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="space-between">

  <mat-form-field fxFlex="45">

    <mat-label>Nome</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="name"
      id="name"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['name'].errors?.['required']"
    >
      È obbligatorio inserire il nome
    </mat-error>
  </mat-form-field>


  <button
    (click)="_entity?.id ? updateEntity(companyId, _entity.id) : createEntity(companyId)"
    [disabled]="formGroup.invalid || saving || readOnly || !formGroup.dirty"
    color="primary"
    mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Salva</span>
  </button>
</div>






