<mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="matTabChange($event)">

  <mat-tab label="Aziende">
    <aw-permissions-table
      [permissionOwner]="{entityName: accessEntityToEntityIdentifierMap[entities.companies], permissionOwnerId: permissionOwnerId}"
      [role]="_accessDetail?.companies"
      (onSaveRole)="onSaveRole.emit($event)"
      (onRemoveRole)="onRemoveRole.emit($event)"
    ></aw-permissions-table>
  </mat-tab>

  <mat-tab label="Utenti">
    <aw-permissions-table
      [permissionOwner]="{entityName: accessEntityToEntityIdentifierMap[entities.users], permissionOwnerId: permissionOwnerId}"
      [role]="_accessDetail?.users"
      (onSaveRole)="onSaveRole.emit($event)"
      (onRemoveRole)="onRemoveRole.emit($event)"
    ></aw-permissions-table>
  </mat-tab>

  <mat-tab label="Magazzini">
    <aw-permissions-table
      [accessPermissions]="accessWarehouses"
      [permissionOwner]="{entityName: accessEntityToEntityIdentifierMap[entities.warehouses], permissionOwnerId: permissionOwnerId}"
      [role]="_accessDetail?.warehouses?.userAuthority"
      (onSaveRole)="onSaveRole.emit($event)"
      (onRemoveRole)="onRemoveRole.emit($event)"
      (onUserPermissionChange)="onPermissionChange.emit()"
      (onSetPermissions)="onSetPermissions.emit($event)"
      (onRemovePermissions)="onRemovePermissions.emit($event)"
    ></aw-permissions-table>
  </mat-tab>

  <mat-tab label="Articoli">
    <aw-permissions-table
      [permissionOwner]="{entityName: accessEntityToEntityIdentifierMap[entities.items], permissionOwnerId: permissionOwnerId}"
      [role]="_accessDetail?.items"
      (onSaveRole)="onSaveRole.emit($event)"
      (onRemoveRole)="onRemoveRole.emit($event)"
    ></aw-permissions-table>
  </mat-tab>

  <mat-tab label="Giacenze">
    <aw-permissions-table
      [accessPermissions]="accessItemWarehouses"
      [permissionOwner]="{entityName: accessEntityToEntityIdentifierMap[entities.itemWarehouses], permissionOwnerId: permissionOwnerId}"
      [role]="_accessDetail?.itemWarehouses?.userAuthority"
      (onSaveRole)="onSaveRole.emit($event)"
      (onRemoveRole)="onRemoveRole.emit($event)"
      (onUserPermissionChange)="onPermissionChange.emit()"
      (onSetPermissions)="onSetPermissions.emit($event)"
      (onRemovePermissions)="onRemovePermissions.emit($event)"
    ></aw-permissions-table>
  </mat-tab>

  <mat-tab label="Punti vendita">
    <aw-permissions-table
      [accessPermissions]="accessShops"
      [permissionOwner]="{entityName: accessEntityToEntityIdentifierMap[entities.shops], permissionOwnerId: permissionOwnerId}"
      [role]="_accessDetail?.shops?.userAuthority"
      (onSaveRole)="onSaveRole.emit($event)"
      (onRemoveRole)="onRemoveRole.emit($event)"
      (onUserPermissionChange)="onPermissionChange.emit()"
      (onSetPermissions)="onSetPermissions.emit($event)"
      (onRemovePermissions)="onRemovePermissions.emit($event)"
    ></aw-permissions-table>
  </mat-tab>

  <mat-tab label="Ordini dei Punti vendita">
    <aw-permissions-table
      [accessPermissions]="accessSellOrderShops"
      [permissionOwner]="{entityName: accessEntityToEntityIdentifierMap[entities.sellOrderShops], permissionOwnerId: permissionOwnerId}"
      [role]="_accessDetail?.sellOrderShops?.userAuthority"
      (onSaveRole)="onSaveRole.emit($event)"
      (onRemoveRole)="onRemoveRole.emit($event)"
      (onUserPermissionChange)="onPermissionChange.emit()"
      (onSetPermissions)="onSetPermissions.emit($event)"
      (onRemovePermissions)="onRemovePermissions.emit($event)"
    ></aw-permissions-table>
  </mat-tab>

  <mat-tab label="Clienti">
    <aw-permissions-table
      [permissionOwner]="{entityName: accessEntityToEntityIdentifierMap[entities.customers], permissionOwnerId: permissionOwnerId}"
      [role]="_accessDetail?.customers"
      (onSaveRole)="onSaveRole.emit($event)"
      (onRemoveRole)="onRemoveRole.emit($event)"
    ></aw-permissions-table>
  </mat-tab>

  <mat-tab label="Corrieri">
    <aw-permissions-table
      [permissionOwner]="{entityName: accessEntityToEntityIdentifierMap[entities.deliveryDashCompanies], permissionOwnerId: permissionOwnerId}"
      [role]="_accessDetail?.deliveryCompanies"
      (onSaveRole)="onSaveRole.emit($event)"
      (onRemoveRole)="onRemoveRole.emit($event)"
    ></aw-permissions-table>
  </mat-tab>

  <mat-tab label="Profili">
    <aw-permissions-table
      [permissionOwner]="{entityName: accessEntityToEntityIdentifierMap[entities.profiles], permissionOwnerId: permissionOwnerId}"
      [role]="_accessDetail?.profiles"
      (onSaveRole)="onSaveRole.emit($event)"
      (onRemoveRole)="onRemoveRole.emit($event)"
    ></aw-permissions-table>
  </mat-tab>

</mat-tab-group>
