import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomerInformationCreate, CustomerInformationDetail} from "../../../interfaces/customer-information";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {UtilsService} from "../../../services/utils.service";
import {Observable} from "rxjs";
import {CustomersService} from "../../../services/customers.service";
import {CustomerInformationTableComponent} from "../customer-information-table/customer-information-table.component";

@Component({
  selector: 'aw-customers-info-form',
  templateUrl: './customers-info-form.component.html',
  styleUrls: ['./customers-info-form.component.scss']
})
export class CustomersInfoFormComponent extends BaseFormComponent<CustomerInformationCreate, CustomerInformationDetail> implements OnInit {

  @Input() companyId = -1
  @Input() customerId = -1
  @Input() initialName: string = '';
  @Input() initialSurname: string = '';
  @Input() initialPhone: string = '';

  constructor(
    public customersService: CustomersService,
    public override utilsService: UtilsService) {

    let createFunction = (companyId: number, customerId: number, customerInfo: CustomerInformationCreate):
      Observable<CustomerInformationDetail> =>
      customersService.createCustomerInformation(companyId, customerId, customerInfo)

    let updateFunction =
      (companyId: number, customerId: number, customerInfoId: number, customerInfo: CustomerInformationCreate):
        Observable<CustomerInformationDetail> =>
        customersService.updateCompanyCustomerInformation(companyId, customerId, customerInfoId, customerInfo)

    super(createFunction, updateFunction, utilsService)


    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      addressName: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', []),
      phone: new FormControl(this.initialPhone, [Validators.required]),
      email: new FormControl('', [])
    })

  }

  override _entity: CustomerInformationDetail = {} as CustomerInformationDetail

  @Input() set entity(value: CustomerInformationDetail) {
    this._entity = value
    this.formGroup.patchValue(this._entity)
  }

  ngOnInit() {
    if (!this._entity.id) {
      this.formGroup.controls["addressName"].setValue(this.initialName + ' ' + this.initialSurname)
      this.formGroup.controls["phone"].setValue(this.initialPhone)
    }
  }
}
