import {Component} from '@angular/core';
import {CustomerInformationDetail} from "../../../interfaces/customer-information";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {catchError} from "rxjs/operators";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {SnackService} from "../../../services/snack.service";
import {CustomersService} from "../../../services/customers.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {MatTableDataSource} from "@angular/material/table";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {hasPermissionOnEntity} from "../../../utils/global-functions-and-types";
import {CustomersInfoFormComponent} from "../customers-info-form/customers-info-form.component";
import {ActivatedRoute} from "@angular/router";
import {CustomerData} from "../../../interfaces/customer";
import {Constants} from "../../../utils/constants";

@Component({
  selector: 'aw-customer-information-table',
  templateUrl: './customer-information-table.component.html',
  styleUrls: ['./customer-information-table.component.scss']
})
export class CustomerInformationTableComponent extends BaseTableComponent<any> {

  customerId = -1

  customer$: Observable<CustomerData>

  customer!: CustomerData

  constructor(
    public customersService: CustomersService,
    private activatedRoute: ActivatedRoute,
    protected override snackService: SnackService,
    protected override dialogService: DialogsService,
    protected override utilsService: UtilsService) {

    let deleteFunction =
      (companyId: number, customerId: number, customerInfoId: number):
        Observable<void> =>
        customersService.deleteCompanyCustomerInformation(companyId, customerId, customerInfoId)


    super('addressName', Constants.localStorageKeys.customerInformationTable, deleteFunction, utilsService, snackService, dialogService)

    this.subs.push(
      this.activatedRoute.paramMap
        .subscribe(paraMap => {
          this.customerId = +paraMap.get("customerId")!
        })
    )

    this.customer$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(userReference => {
            this.userReference = userReference
            return this.customersService.getCompanyCustomer(this.userReference.companyId, this.customerId)
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare il cliente');
            return EMPTY;
          })
        )

      this.observable$ =
        of(true)
          .pipe(
            tap(() => this.paramLoading = true),
            switchMap(() => this.customer$),
            switchMap(customer => {
              this.customer = customer
              return this.customersService.getCustomerInformationList(this.userReference.companyId, this.customerId, this.fetchParams)
            }),
            switchMap(customerInfo => {
              this.paramLoading = false
              this.dataSource = new MatTableDataSource<CustomerInformationDetail>(customerInfo.list)
              this.entityList.push(...customerInfo.list)
              this.numEntities = customerInfo.num
              return of(customerInfo.list)
            }),
            catchError((err, caught) => {
              this.paramLoading = false
              if (!err.errors.includes('403'))
                this.snackService.error('Impossibile caricare le informazioni del cliente');
              return EMPTY;
            })
          )

    this.subs.push(this.observable$.subscribe())

    this.displayedColumns = [
      "addressName",
      "address",
      "city",
      "zipCode",
      "phone",
      "action",
    ]






  }

  addCustomerInfo() {

    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Informazioni Cliente',
        componentData: {
          companyId: this.userReference.companyId,
          customerId: this.customerId,
          initialName: this.customer.name,
          initialSurname: this.customer.surname,
          initialPhone: this.customer.phone

        },
        component: CustomersInfoFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: () => this.snackService.success('Informazioni cliente create'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  editCustomerInfo(customerId: number, customerInfoId: number) {

    this.customersService.getCustomerInformation(this.userReference.companyId, this.customerId, customerInfoId)
      .subscribe(customerInfo => {

        let readonly = !hasPermissionOnEntity(this.userReference.user, {
          accessEntity: this.accessEntities.customers,
          accessLevel: this.accessLevels.editor
        }, customerInfo)

        this.dialogService.open(GeneralDialogComponent, {
          data: {
            dialogTitle: readonly ? `DETTAGLIO – ${customerInfo.addressName}` : `MODIFICA – ${customerInfo.addressName}`,
            componentData: {entity: customerInfo, companyId: this.userReference.companyId, customerId: this.customerId, readOnly: readonly},
            component: CustomersInfoFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Informazioni cliente aggiornate');
            },
            error: () => {
              this.snackService.success('Impossibile aggiornare le informazioni del cliente');
            },
          })
      })

  }

}
