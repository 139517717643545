<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>


<ng-container *ngIf="!loading && !loadingUser else loadingTemplate">


  <div fxLayout="column">
    <div fxLayout="row" fxFlex>
      <h1 class="font-light"
      >Gestione Accessi "{{userAccess?.name}} {{userAccess?.surname}}"
      </h1>
    </div>

    <mat-divider></mat-divider>

    <aw-profiles-user-dropdown
      [userId]="userId"
      [initialProfiles]="userProfiles"
      (onUserProfileChange)="onUserPermissionChange()"
    ></aw-profiles-user-dropdown>

    <mat-divider></mat-divider>

    <div fxLayout="row"
         fxLayoutAlign="center center"
         fxFlex="50"
         *ngIf="!loadingUser && !loading"
         class="border border-blue-info disclaimer-warning mat-background-light mt-2_5 mb-2_5"
    >
      <p [innerHTML]="roleInfoMessage.message">
        <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
      </p>
    </div>
  </div>

  <div>

    <aw-permissions-tab-group
      [accessWarehouses]="accessWarehouses"
      [accessItemWarehouses]="accessItemWarehouses"
      [accessShops]="accessShops"
      [accessSellOrderShops]="accessSellOrderShops"
      [permissionOwnerId]="userId"
      [accessDetail]="userAccess.personalAccess"
      (onPermissionChange)="onUserPermissionChange()"
      (onSaveRole)="onSaveRole($event)"
      (onRemoveRole)="onRemoveRole($event)"
      (onSetPermissions)="onSetPermissions($event)"
      (onRemovePermissions)="onRemovePermissions($event)"
    ></aw-permissions-tab-group>
  </div>

</ng-container>
