<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>

<mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="matTabChange($event)" *ngIf="!loading; else loadingTemplate">
  <mat-tab *awAccess="{selected: shop, accessLevel: {accessEntity: accessEntities.shops, accessLevel: accessLevels.editor}}" label="Anagrafica">
    <h1 class="font-light">MODIFICA – {{shop?.name}}</h1>

    <aw-shops-form
      [entity]="shop"
      [companyId]="referenceCompanyId"
      *ngIf="!loading; else loadingTemplate"
    ></aw-shops-form>

  </mat-tab>


  <mat-tab *awAccess="{selected: shop, accessLevel: {accessEntity: accessEntities.sellOrderShops, accessLevel: accessLevels.reader}}" label="Vendite">
    <h1 class="font-light">{{shop?.name}} – VENDITE</h1>

    <aw-sales-table [shopId]="referenceShopId"></aw-sales-table>

  </mat-tab>



  <mat-tab *awAccess="{selected: shop, accessLevel: {accessEntity: accessEntities.sellOrderShops, accessLevel: accessLevels.reader}}" label="Ordini">
    <aw-orders-table [shopId]="referenceShopId"></aw-orders-table>
  </mat-tab>

</mat-tab-group>
