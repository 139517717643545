import {NativeDateAdapter} from "@angular/material/core";
import {Injectable} from "@angular/core";
import {getDateDay} from "./global-functions-and-types";

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  override parse(value: any): Date | null {

    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  override format(date: Date, displayFormat: Object): string {
    date = new Date(Date.UTC(
      date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
      date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    displayFormat = Object.assign({}, displayFormat, { timeZone: 'utc' });

    const dtf = new Intl.DateTimeFormat(this.locale, displayFormat);
    // todo: per scrivere il giorno avrei voluto usare la pipe date ma non ci sono riuscito :)
    return getDateDay(date.toISOString()) + ' ' + dtf.format(date).replace(/[\u200e\u200f]/g, '');
  }


  override getFirstDayOfWeek(): number {
    return 1;
  }
}
