import {Component} from '@angular/core';

@Component({
  selector: 'aw-orders',
  templateUrl: './orders-container.component.html',
  styleUrls: ['./orders-container.component.scss']
})
export class OrdersContainerComponent{

}
