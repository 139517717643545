import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {delay, of, Subscription, switchMap} from "rxjs";
import {AccessEntities, GenericFilter} from "../../global-functions-and-types";
import {Constants} from "../../constants";
import {RequiredAccessLevel} from "../../../classes/required-access-level";
import {FilterOptionNames} from "../../../interfaces/sell-order";

@Component({
  selector: 'aw-actions-bar',
  templateUrl: './actions-bar.component.html',
  styleUrls: ['./actions-bar.component.scss']
})
export class ActionsBarComponent {

  chipList: { fieldDisplayName: string, value: string, fieldName: string }[] = [];

  accessLevels = Constants.accessLevels

  // List of conditions required for Create Button
  @Input() requiredCreateAccessLevels: RequiredAccessLevel[] = []

  // List of conditions required for Delete Button
  @Input() requiredDeleteAccessLevels: RequiredAccessLevel[] = []

  @Input() searchableFields: FilterOptionNames[] = []

  // Timeout for search onKeySearch
  timeout?: Subscription;

  // e.g. Warehouse for ItemWarehouses, Shop for SellOrderShops
  @Input() parentEntity: any

  // Searched keyword (if there is no filter)
  @Input() keyword = ''

  // Last pressed key
  @Input() lastKey = ''

  // AccessEntities (authorizes buttons)
  @Input() accessEntity!: AccessEntities | null

  // Tells if all checkboxes are selected
  @Input() isAllSelected = false

  // Tells if checkbox column is visible
  @Input() selectVisible = false

  // Shows/Hides delete button
  @Input() isDeletable = true;

  // Shows/Hides create button
  @Input() isCreatable = true;

  // Shows/Hides selection button
  @Input() isSelectable = true;

  // Shows/Hides export button
  @Input() isExportable = false;

  // Shows/Hides search bar
  @Input() isSearchable = true;

  // Needed to add and remove checkbox column
  @Input() displayedColumns: string[] = []

  // Represents rows with checked checkbox
  @Input() selection: SelectionModel<any> = new SelectionModel<any>(true, []);

  // Delete checked rows emitter
  @Output() deleteElements: EventEmitter<number[]> = new EventEmitter<number[]>();

  // Emitting entire objects (in case id is not at the first level, eg. itemWarehouse)
  @Output() deleteObjects: EventEmitter<any[]> = new EventEmitter<any[]>();

  // Open creation form emitter
  @Output() openCreateForm: EventEmitter<void> = new EventEmitter<void>();

  // Search emitter
  @Output() load: EventEmitter<any> = new EventEmitter<any>();

  @Input() filterObject: GenericFilter = {};

  // filter emitter
  @Output() filterObjectChange: EventEmitter<GenericFilter> = new EventEmitter<GenericFilter>();

  // Emits selectVisible changes
  @Output() selectVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Emits export
  @Output() export = new EventEmitter<void>()


  constructor() {
  }

  // Search function
  onKeySearch(event: { value: string, key: string }) {

    if (this.timeout !== undefined && !this.timeout.closed)
      this.timeout.unsubscribe()

    // Search one second after last pressed key has been pressed
    if (event.key.toLowerCase() !== 'enter')
      this.timeout = of(event)
        .pipe(
          delay(1000),
          switchMap(() => {
            this.keyword = event.value
            this.load.emit({keyword: event.value})
            return of(false)
          })
        ).subscribe(() => {
        })

    // If "Enter" key is pressed, search immediately
    else {
      this.keyword = event.value
      this.load.emit({keyword: event.value})
    }

  }

  // Manages checkbox column visibility
  manageCheckboxColumn() {
    this.selectVisible = !this.selectVisible
    this.selectVisibleChange.emit(this.selectVisible)

    this.selection.clear()

    if (this.selectVisible) {
      this.displayedColumns.splice(0, 0, 'select')
      this.displayedColumns.splice(this.displayedColumns.indexOf("action"), 1)

    } else {
      this.displayedColumns.splice(this.displayedColumns.indexOf("select"), 1)
      this.displayedColumns.splice(this.displayedColumns.length, 0, 'action')
    }

  }


  // Emits selected rows IDs
  deleteSelected() {
    this.deleteElements.emit(this.selection.selected.map(select => select.id))
    this.deleteObjects.emit(this.selection.selected)
  }


  // Emits creation form opening event
  openForm() {
    this.openCreateForm.emit()
  }

  removeChip(index: number, fieldName: string) {
    this.chipList.splice(index, 1);
    (this.filterObject as any)[fieldName] = "";

    this.load.emit(this.filterObject)
  }


  // This is invoked only if there are no searchable fields
  clearAndEmitLoad() {
    this.keyword = '';
    this.load.emit({keyword: ''})
  }

}
