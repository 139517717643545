import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  AccessDetail,
  AccessPermissionCreate,
  AccessPermissionData,
  AccessPermissionDelete,
  AccessRoleCreate,
  AccessRoleDelete,
} from "../../../../interfaces/permission";
import {BaseTabGroupComponent} from "../../../../utils/shared-components/base-container/base-tab-group.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Constants} from "../../../../utils/constants";

@Component({
  selector: 'aw-permissions-tab-group',
  templateUrl: './permissions-tab-group.component.html',
  styleUrls: ['./permissions-tab-group.component.scss']
})
export class PermissionsTabGroupComponent extends BaseTabGroupComponent {

  entities = Constants.entities

  accessEntityToEntityIdentifierMap = Constants.accessEntityToEntityIdentifierMap

  @Input() permissionOwnerId: number | null = null
  @Output() onPermissionChange: EventEmitter<void> = new EventEmitter<void>()
  @Output() onSaveRole: EventEmitter<AccessRoleCreate> = new EventEmitter<AccessRoleCreate>()
  @Output() onRemoveRole: EventEmitter<AccessRoleDelete> = new EventEmitter<AccessRoleDelete>()
  @Output() onSetPermissions: EventEmitter<AccessPermissionCreate[]> = new EventEmitter<AccessPermissionCreate[]>()
  @Output() onRemovePermissions: EventEmitter<AccessPermissionDelete[]> = new EventEmitter<AccessPermissionDelete[]>()
  @Input() accessWarehouses: AccessPermissionData[] = []
  @Input() accessItemWarehouses: AccessPermissionData[] = []
  @Input() accessShops: AccessPermissionData[] = []
  @Input() accessSellOrderShops: AccessPermissionData[] = []

  constructor(
    protected override activatedRoute: ActivatedRoute,
    protected override router: Router,
  ) {
    super(activatedRoute, router)
  }

  _accessDetail: AccessDetail | undefined = undefined

  @Input() set accessDetail(accessDetail: AccessDetail | undefined) {
    this._accessDetail = accessDetail
  }

}
