<div [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="space-between">

  <mat-form-field fxFlex="45">

    <mat-label>Nome recapito</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="addressName"
      id="addressName"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['addressName'].errors?.['required']">
      È obbligatorio inserire il nome del recapito
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45">

    <mat-label>Indirizzo</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="address"
      id="address"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['address'].errors?.['required']">
      È obbligatorio inserire l'indirizzo
    </mat-error>
  </mat-form-field>


  <mat-form-field fxFlex="45">

    <mat-label>Città</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="city"
      id="city"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['city'].errors?.['required']">
      È obbligatorio inserire la città
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45">

    <mat-label>CAP</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="zipCode"
      id="zipCode"
      matInput
      type="text">
  </mat-form-field>



  <mat-form-field fxFlex="45">
    <mat-label>Telefono</mat-label>
    <input       [readonly]="readOnly"
                 [dropSpecialCharacters]="false" [mask]="'000 0*'" formControlName="phone" matInput name="phone">
    <mat-error *ngIf="formGroup.controls['phone'].errors?.['required']">
      È obbligatorio inserire il numero di telefono
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="45">

  <mat-label>Email</mat-label>
  <input
    [readonly]="readOnly"
    formControlName="email"
    id="email"
    matInput
    type="text">
  </mat-form-field>

  <button
    (click)="_entity?.id ? updateEntity(companyId, customerId ,_entity.id) : createEntity(companyId, customerId)"
    color="primary"
    mat-raised-button
    [disabled]="formGroup.invalid || saving || readOnly || !formGroup.dirty"
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Salva</span>
  </button>
</div>






