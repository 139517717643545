import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {


  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot,
              ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let {blockOn, redirectOnBlock}  = route.data as {
      blockOn: 'anonymous' | 'authenticated', redirectOnBlock?: string
    };

    return this.authService
      .onUserChange()
      .pipe(
        map((user: any) => {
        if (!user) {
          if(blockOn == 'anonymous') {
            this.authService.redirectUrl = state.url;
            return this.router.createUrlTree([redirectOnBlock || '/login']);
          }
        }else{
          if(blockOn == 'authenticated') {
            this.authService.redirectUrl = state.url;
            return this.router.createUrlTree([redirectOnBlock || '/reserved']);
          }
        }
        return true;
      }));

  }
}
