import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import {Constants} from "../utils/constants";
import {environment} from "../../environments/environment";
import {ApiResponse} from "../classes/api-response";
import {HttpClient, HttpParams} from "@angular/common/http";
import {UserCreate, UserData, UserDetail, UserMeDetail} from "../interfaces/user";
import {UserAccessDetail} from "../interfaces/permission";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private readonly entities = Constants.entities;
  private readonly crtlPathFragment = this.entities.users;
  private readonly crtlFullPath = `${environment.apiUrl}/${this.crtlPathFragment}`;

  constructor(private http: HttpClient) {
  }

  public getMyself(): Observable<UserMeDetail> {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/me`)
      .pipe(map(value => value.data as UserMeDetail));
  }

  public getAllUsers(params : HttpParams | undefined): Observable<PageConverter<UserData>> {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}`)
      .pipe(map(value => value.data as PageConverter<UserData>),)
  }

  public getAllCompanyUsers(companyId: number, paginationParams?: FetchParams): Observable<PageConverter<UserData>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.users}`, {params: sortFilterParams})
      .pipe(map(value => value.data as PageConverter<UserData>))
  }

  public getAllCompanyUsersTmp(companyId: number) {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.entities.users}`)
      .pipe(map(value => value.data as UserData[]),)
  }

  public getUserById(companyId: number, id: number): Observable<UserDetail> {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.crtlPathFragment}/${id}`)
      .pipe(
        map(value => value.data as UserDetail)
      )
  }

  public getUserAccessById(id: number, companyId: number): Observable<UserAccessDetail> {
    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.crtlPathFragment}/${id}/${this.entities.access}`)
      .pipe(
        map(value => value.data as UserAccessDetail)
      )
  }

  public createUser(companyId: number, user: UserCreate): Observable<UserDetail> {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.crtlPathFragment}`, user)
      .pipe(
        map(value => value.data as UserDetail)
      )
  }

  public updateUser(companyId: number, userId: number, user: UserCreate): Observable<UserDetail> {
    return this.http
      .patch<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.crtlPathFragment}/${userId}`, user)
      .pipe(map(value => value.data as UserDetail))
  }

  public deleteUser(companyId: number, userId: number): Observable<void> {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${this.entities.companies}/${companyId}/${this.crtlPathFragment}/${userId}`)
      .pipe(map(() => {
      }))
  }

}
