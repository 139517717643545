import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {FilterOptionNames, FilterOptionValues} from "../../../interfaces/sell-order";
import {MatSelectChange} from "@angular/material/select";
import {GenericFilter} from "../../global-functions-and-types";

@Component({
  selector: 'aw-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @Input() chipList: { fieldDisplayName: string, value: string, fieldName: string }[] = []
  @Output() chipListChange = new EventEmitter<{ fieldDisplayName: string, value: string, fieldName: string }[]>()


  // prende in input la lista di parametri di ricerca
  @Input() searchableFields: FilterOptionNames[] = []
  searchText: string = '';
  selectedSearchableField: FilterOptionNames = {} as FilterOptionNames;

  filterObject: GenericFilter = {};
  // filter emitter ritorna l'array con i parametri di ricerca + chiavi
  @Output() emitFilterObject: EventEmitter<GenericFilter> = new EventEmitter<GenericFilter>();

  @Output() value = new EventEmitter<{ value: string, key: string }>()
  @Output() clear = new EventEmitter<void>()
  @ViewChild('template', {'static': true}) template!: TemplateRef<any>;
  classList = ['search-box'].join(' ');

  constructor(private contRef: ViewContainerRef) {
  }


  ngOnInit(): void {
    this.classList = [this.classList, ...this.contRef.element.nativeElement.classList].join(' ');
    this.contRef.element.nativeElement.remove();
    this.contRef.createEmbeddedView(this.template);
  }

  emitValue(value: string, $event: KeyboardEvent) {
    console.log($event.key)
    this.value.emit({value: value, key: $event.key})
  }

  populateFilterObject(optionSearch: FilterOptionValues) {
    (this.filterObject as any)[(optionSearch.field)] = optionSearch.value;
  }

  generateChip(fieldDisplayName: string, value: string, fieldName: string) {
    console.log("Genero la chip")

    let i = this.chipList.findIndex(chip => chip.fieldName === fieldName)

    if (i != -1) {
      // If this is not undefined then I need to upload this chip's value
      console.log("This is to upload")
      this.chipList[i].value = value
    } else {
      // If find() is undefined, than I need to create a new chip
      console.log("This is new")
      this.chipList.push({fieldDisplayName, value, fieldName})
    }

    let optionSearch: FilterOptionValues = {
      field: this.selectedSearchableField.fieldName,
      value: value
    }

    this.populateFilterObject(optionSearch)

    this.emitFilterObject.emit(this.filterObject)
    this.searchText = ""
    this.selectedSearchableField = {} as FilterOptionNames;

  }


  setSelectedSearchableField($event: MatSelectChange) {
    this.selectedSearchableField = $event.value
  }
}
