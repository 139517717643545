import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OptionValueCreate, OptionValueData} from "../../../../interfaces/option-value";
import {UtilsService} from "../../../../services/utils.service";
import {Observable} from "rxjs";
import {BaseFormComponent} from "../../../../utils/shared-components/base-form/base-form.component";
import {OptionValuesService} from "../../../../services/option-values.service";

@Component({
  selector: 'aw-option-values-form',
  templateUrl: './option-values-form.component.html',
  styleUrls: ['./option-values-form.component.scss']
})
export class OptionValuesFormComponent extends BaseFormComponent<OptionValueCreate, OptionValueData> {

  @Input() companyId = -1
  @Input() optionId = -1


  constructor(
    public optionValuesService: OptionValuesService,
    public override utilsService: UtilsService) {

    let createFunction = (companyId: number, optionId: number, optionValue: OptionValueCreate):
      Observable<OptionValueData> =>
      optionValuesService.createOptionValue(companyId, optionId, optionValue)

    let updateFunction =
      (companyId: number, optionId: number, optionValueId: number, optionValue: OptionValueCreate):
        Observable<OptionValueData> =>
        optionValuesService.updateOptionValue(companyId, optionId, optionValueId, optionValue)

    super(createFunction, updateFunction, utilsService)

    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      name: new FormControl('', [Validators.required]),
      displayName: new FormControl('', [Validators.required]),
    })

  }

  override _entity: OptionValueData = {} as OptionValueData

  @Input() set entity(value: OptionValueData) {
    this._entity = value
    this.formGroup.patchValue(this._entity)
  }

}
