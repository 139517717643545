<div [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="space-between">

  <mat-form-field fxFlex="45">

    <mat-label>Nome</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="name"
      id="name"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['name'].errors?.['required']"
    >
      È obbligatorio inserire il nome
    </mat-error>
  </mat-form-field>


  <mat-form-field fxFlex="45">

    <mat-label>Indirizzo</mat-label>
    <input
      [readonly]="readOnly"
      formControlName="address"
      id="address"
      matInput
      type="text">
    <mat-error *ngIf="formGroup.controls['address'].errors?.['required']"
    >È obbligatorio inserire l'indirizzo
    </mat-error>
  </mat-form-field>


  <mat-form-field fxFlex="45">
    <mat-label>Telefono</mat-label>
    <input [dropSpecialCharacters]="false"
           [mask]="'+00 000 0*'" [readonly]="readOnly" formControlName="phone" matInput name="phone">
  </mat-form-field>

  <ng-container *ngIf="!hasPermissionOnDefaultWarehouse">
    <div class="border border-warn disclaimer-box mat-background-light p-2_5 mb-2_5" fxFlex="45">
      <p>
        Attualmente è selezionato un magazzino di default che non si è autorizzati a visualizzare:
        <strong>{{_entity?.defaultWarehouseName}}</strong>.<br>
        Avendo accesso ad altri magazzini puoi sceglierne un altro, ma non sarai in grado di reimpostare quello attuale!<br>
      </p>
      <button (click)="warehouseFieldDisabled = false" mat-button>Scegli un altro magazzino</button>
    </div>
  </ng-container>
  <mat-form-field *ngIf="_warehouses.length > 0" fxFlex="45">

    <mat-label>Magazzino di default</mat-label>
    <mat-select [(value)]="_selectedWarehouse" [disabled]="readOnly || warehouseFieldDisabled"
                (selectionChange)="checkWarehouseChange($event)"
                formControlName="defaultWarehouseId"
                name="defaultWarehouseId">


      <aw-search-option *ngIf="_warehouses.length > 0" (filteredOptions)="this.filteredWarehouses = $event" [options]="_warehouses" [key]="'name'"></aw-search-option>


      <mat-option *ngIf="!loadingWarehouses && _warehouses.length === 0">
        Nessun magazzino.
      </mat-option>

      <mat-option *ngIf="!loadingWarehouses && filteredWarehouses.length <= 0 && _warehouses.length !== 0">
        Nessun magazzino trovato
      </mat-option>


      <mat-option *ngFor="let warehouse of filteredWarehouses" [value]="warehouse.id">
        {{warehouse.name}}
      </mat-option>
    </mat-select>
    <span *ngIf="loadingWarehouses" matPrefix><i class="fas fa-spinner fa-spin color-primary"></i>&nbsp;</span>
    <mat-hint *ngIf="!loadingWarehouses && _warehouses.length === 0" class="color-warn">
      Nessun magazzino.
    </mat-hint>
  </mat-form-field>


  <button
    (click)="_entity?.id ? updateEntity(companyId, _entity!.id) : createEntity(companyId)"
    [disabled]="formGroup.invalid || saving || readOnly || !formGroup.dirty"
    color="primary"
    mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Salva</span>
  </button>
</div>






