import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../classes/api-response';
import {Constants} from '../utils/constants';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ItemTypeCreate, ItemTypeData, ItemTypeDetail} from "../interfaces/item-type";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";


@Injectable({
  providedIn: 'root'
})
export class ItemTypesService {

  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.companies}`;

  constructor(private http: HttpClient) {
  }


  public getAllCompanyItemTypes(companyId: number, paginationParams?: FetchParams): Observable<PageConverter<ItemTypeData>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.itemTypes}`, {params: sortFilterParams})
      .pipe(map(value => value.data as PageConverter<ItemTypeData>))

  }

  public getCompanyItemType(companyId: number, itemTypeId: number): Observable<ItemTypeDetail> {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.itemTypes}/${itemTypeId}`)
      .pipe(map(value => value.data as ItemTypeDetail))

  }

  public createCompanyItemType(companyId: number, itemType: ItemTypeCreate): Observable<ItemTypeDetail> {
    return this.http
      .post<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.itemTypes}`, itemType)
      .pipe(map(value => value.data as ItemTypeDetail));
  }

  public updateCompanyItemType(companyId: number, itemTypeId: number, itemType: ItemTypeCreate): Observable<ItemTypeDetail> {
    return this.http
      .patch<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.itemTypes}/${itemTypeId}`, itemType)
      .pipe(map(value => value.data as ItemTypeDetail))
  }

  public deleteCompanyItemType(companyId: number, itemTypeId: number): Observable<void> {
    return this.http
      .delete<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.itemTypes}/${itemTypeId}`)
      .pipe(map(() => {
      }))
  }
}
