import {Component} from '@angular/core';
import {WarehouseData} from "../../../interfaces/warehouse";
import {hasPermissionOnEntity,} from "../../../utils/global-functions-and-types";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {WarehousesService} from "../../../services/warehouses.service";
import {ResponsiveService} from "../../../services/responsive.service";
import {DialogsService} from "../../../services/dialogs.service";
import {WarehousesFormComponent} from "../warehouses-form/warehouses-form.component";
import {SnackService} from "../../../services/snack.service";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {UtilsService} from "../../../services/utils.service";
import {catchError} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material/table";
import {Constants} from "../../../utils/constants";
import {
  StocksMigrationFormComponent
} from "../../warehouses-items/stocks-migration-form/stocks-migration-form.component";

@Component({
  selector: 'aw-warehouses-table',
  templateUrl: './warehouses-table.component.html',
  styleUrls: ['./warehouses-table.component.scss']
})
export class WarehousesTableComponent extends BaseTableComponent<WarehouseData> {

  constructor(private warehousesService: WarehousesService,
              public responsiveService: ResponsiveService,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
  ) {

    let deleteFunction =
      (companyId: number, warehouseId: number): Observable<void> =>
        warehousesService.deleteWarehouse(companyId, warehouseId)

    super('name', Constants.localStorageKeys.warehousesTable, deleteFunction, utilsService, snackService, dialogService)

    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(userReference => this.warehousesService.getAllCompanyWarehouses(this.userReference.companyId, this.utilsService.getEntityAccessIdsForUser(userReference.user.access.warehouses), this.fetchParams)),
          switchMap(warehouses => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<WarehouseData>(warehouses.list)
            this.entityList.push(...warehouses.list)
            this.numEntities = warehouses.num
            return of(warehouses.list)
          }),
          catchError((err) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i magazzini');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())

    this.displayedColumns = [
      "name",
      "address",
      "phone",
      "action",
    ]

  }

  addWarehouse() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Magazzino',
        componentData: {companyId: this.userReference.companyId},
        component: WarehousesFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: () => this.snackService.success('Magazzino creato'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  editWarehouse(warehouseId: number) {
    this.warehousesService.getWarehouseById(this.userReference.companyId, warehouseId)
      .subscribe(warehouse => {
        let readonly = !hasPermissionOnEntity(this.userReference.user, {
          accessEntity: this.accessEntities.warehouses,
          accessLevel: this.accessLevels.editor
        }, warehouse)
        this.dialogService.open(GeneralDialogComponent, {
          data: {
            dialogTitle: readonly ? `DETTAGLIO – ${warehouse.name}` : `MODIFICA – ${warehouse.name}`,
            componentData: {entity: warehouse, companyId: this.userReference.companyId, readOnly: readonly},
            component: WarehousesFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Magazzino aggiornato');
            },
            error: () => {
              this.snackService.success('Impossibile aggiornare il magazzino');
            },
          })
      })
  }

  migrateFromWarehouse(warehouseFromId: number) {
    this.warehousesService.getWarehouseById(this.userReference.companyId, warehouseFromId)
      .subscribe(warehouseFrom => {
        this.dialogService.open(GeneralDialogComponent, {
          data: {
            dialogTitle: `MIGRAZIONE DA – ${warehouseFrom.name}`,
            componentData: {entity: warehouseFrom, companyId: this.userReference.companyId},
            component: StocksMigrationFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Migrazione completata');
            },
            error: () => {
              this.snackService.success('Impossibile migrare le giacenze');
            },
          })
      })

  }
}
