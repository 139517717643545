import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ItemCompositionData} from "../../../interfaces/item-composition";
import {ItemVariantDetail} from "../../../interfaces/item-variant";
import {ItemWarehouseCreateRequest, ItemWarehouseDetail} from "../../../interfaces/item-warehouse";
import {ActivatedRoute} from "@angular/router";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {WarehousesService} from "../../../services/warehouses.service";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {WarehousesItemsFormComponent} from "../warehouses-items-form/warehouses-items-form.component";
import {Constants} from "../../../utils/constants";
import {hasPermission, hasPermissionOnEntity} from "../../../utils/global-functions-and-types";

@Component({
  selector: 'aw-warehouses-items-table',
  templateUrl: './warehouses-items-table.component.html',
  styleUrls: ['./warehouses-items-table.component.scss']
})
export class WarehousesItemsTableComponent extends BaseTableComponent<ItemWarehouseDetail> {

  formGroup: FormGroup
  isEditActive = false;
  checked = false;

  // Reference to the line being updated
  updatingItemWarehouse?: ItemWarehouseDetail = undefined

  stateOptions = [{label: 'Si', value: true}, {label: 'No', value: false}];
  initialItemQty = 0

  stocks: ItemWarehouseDetail[] = [];

  allComposersAlreadyInWarehouse = false;
  notYetList: ItemCompositionData[] = [];
  _warehouseId!: number

  @Input() set warehouseId(warehouseId: number) {
    this._warehouseId = warehouseId
    this.subs.push(this.observable$.subscribe(() => this.computePermissions()))
  }

  measureUnit = ''

  canEdit = false
  canDelete = false


  constructor(private activatedRoute: ActivatedRoute,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
              private warehousesService: WarehousesService) {


    let deleteFunction =
      (companyId: number, warehouseId: number, itemWarehouseId: number): Observable<void> => this.warehousesService.deleteItemWarehouse(companyId, warehouseId, itemWarehouseId)

    super('name', Constants.localStorageKeys.warehousesItemsTable, deleteFunction, utilsService, snackService, dialogService);

    this.formGroup = new FormGroup({
      id: new FormControl(null),
      quantity: new FormControl('', [Validators.required, Validators.min(0)]),
    })

    this.observable$ =
      of(true)
      .pipe(
        tap(() => this.paramLoading = true),
        switchMap(() => this.userReference$),
        switchMap(() => this.warehousesService.getWarehouseItems(this.userReference.companyId, this._warehouseId, this.fetchParams)),
        switchMap(stocks => {
          this.paramLoading = false
          this.stocks = stocks.list
          this.dataSource = new MatTableDataSource<ItemWarehouseDetail>(stocks.list)
          this.entityList.push(...stocks.list)
          this.numEntities = stocks.num
          return of(stocks.list)
        }),
        catchError((err, caught) => {
          this.paramLoading = false
          if (!err.errors.includes('403'))
            this.snackService.error('Impossibile caricare le giacenze');
          return EMPTY;
        })
      )

    this.requiredCreateAccessLevels = [
      {accessEntity: Constants.accessEntities.warehouses, accessLevel: Constants.accessLevels.reader},
      {accessEntity: Constants.accessEntities.items, accessLevel: Constants.accessLevels.reader},
      {accessEntity: Constants.accessEntities.itemWarehouses, accessLevel: Constants.accessLevels.editor},
    ]

    this.requiredDeleteAccessLevels = [
      {accessEntity: Constants.accessEntities.warehouses, accessLevel: Constants.accessLevels.reader},
      {accessEntity: Constants.accessEntities.items, accessLevel: Constants.accessLevels.reader},
      {accessEntity: Constants.accessEntities.itemWarehouses, accessLevel: Constants.accessLevels.admin},
    ]


    this.displayedColumns = [
      "name",
      "description",
      "minimumSellNetPrice",
      "maximumBuyNetPrice",
      "quantity",
      "action",
    ]

  }

  private _getMissingQuantitiesText: string = ''

  get getMissingQuantitiesText(): string {
    return "Al momento mancano:<br>" + this._getMissingQuantitiesText;
  }

  onRowEditInit(itemWarehouse: ItemWarehouseDetail) {
    this.allComposersAlreadyInWarehouse = true
    this.notYetList = []

    this.updatingItemWarehouse = itemWarehouse

    this.initialItemQty = itemWarehouse.quantity

    this.formGroup.controls['quantity'].patchValue(this.initialItemQty)

    this.isEditActive = true
    if (itemWarehouse.item.itemComposers && itemWarehouse.item.itemComposers.length > 0) {


      if (itemWarehouse.item && itemWarehouse.item.itemComposers && itemWarehouse.item.itemComposers.length > 0) {

        // se il componente è nella lista di items inseribili allora non è censito, dunque disabilito l'opzione di scalo automatico
        // Lo aggiungo a una lista che uso per comunicare quali items non sono censiti nelle giacenze

        itemWarehouse.item.itemComposers.forEach(composer => {
          let found = this.stocks.find(iw => iw.item.id === composer.itemComposer.id)
          if (!found) {
            this.notYetList.push(composer)
            this.allComposersAlreadyInWarehouse = false
          }

        })

      }
    }
  }

  onRowEditCancel(itemWarehouse: ItemWarehouseDetail) {
    itemWarehouse.quantity = this.initialItemQty
    this.isEditActive = false
    this.updatingItemWarehouse = undefined
  }

  decreaseQty(item: ItemWarehouseDetail) {
    if (item.quantity >= 1)
      this.formGroup.controls['quantity'].patchValue(this.formGroup.controls['quantity'].value - 1)
  }

  increaseQty() {
    this.formGroup.controls['quantity'].patchValue(this.formGroup.controls['quantity'].value + 1)
  }


  addItemWarehouseFn(item: ItemWarehouseDetail) {
    if (item.quantity != this.initialItemQty) {

      let createRequest: ItemWarehouseCreateRequest = {
        item: {
          item: item.item,
          quantity: item.item!.itemComposers!.length > 0 && this.checked ? item.quantity - this.initialItemQty : item.quantity,
          autoscaling: item.item!.itemComposers!.length > 0 ? this.checked : false,

        },
        createMissingComposers: false
      }

      // this.addItemWarehouse.emit(createRequest)
      this.saveItemWarehouse(createRequest)
    }
    this.isEditActive = false
  }

  saveItemWarehouse(itemWarehouseCreateRequest: ItemWarehouseCreateRequest) {
    of(true)
      .pipe(
        switchMap(() => {
            if (itemWarehouseCreateRequest.item?.autoscaling)
              return this.warehousesService.postItemWarehouse(this.userReference.companyId, this._warehouseId, itemWarehouseCreateRequest.item)

            return this.warehousesService.putItemWarehouse(this.userReference.companyId, this._warehouseId, itemWarehouseCreateRequest.item, itemWarehouseCreateRequest.createMissingComposers)
          }
        ),
        switchMap(() => this.observable$)
      ).subscribe({
      next: () => {
        this.snackService.success('Giacenze aggiornate.')
      },
      error: err => {
        this.snackService.error('Impossibile aggiornare le giacenze')
        // this.subs.push(this.itemsWarehouse$.subscribe())
      }
    })
  }


  getAutoScaleText(item: ItemVariantDetail) {
    let headerText = "L'articolo che stai aggiungendo è composto da:<br>"
    let footerText = "Vuoi scalare in automatico gli articoli dal magazzino corrente?"
    let str = ""
    item.itemComposers!.forEach(composer => str = str + `&#8226; ${composer.itemComposer.name + ' x' + composer.quantity} <br>`);

    return headerText + str + footerText
  }


  canAutoscale(item: ItemVariantDetail, currentQuantity: number) {

    let itemsIdQty = this.stocks.map(iw => {
      return {
        id: iw.item.id,
        qty: iw.quantity
      }
    })

    let x = item.itemComposers?.map((composed) => {
      return {
        itemId: composed.itemComposer.id,
        itemName: composed.itemComposer.name,
        qtyRequired: composed.quantity * (currentQuantity - this.initialItemQty),
        qtyAvailable: itemsIdQty.find((value => value.id == composed.itemComposer.id))?.qty ?? 0
      }
    }) ?? []


    let missingItems = x.filter(value => value.qtyRequired > value.qtyAvailable).map(value =>
      "&#8226; " + value.itemName + " x" + (value.qtyRequired - value.qtyAvailable))

    this._getMissingQuantitiesText = missingItems.join('<br>')

    return x.every((d) => d.qtyRequired <= d.qtyAvailable)

  }


  missingItemsNames(): string {
    return this.notYetList.map(value => "&#8226; " + value.itemComposer.name).join('<br>')
  }

  createItemWarehouse() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE - Giacenza',
        componentData: {warehouseId: this._warehouseId},
        component: WarehousesItemsFormComponent
      }
    }).afterClosed()
      .pipe(switchMap(() => this.observable$))
      .subscribe()
  }

  getSelectedRowIds(itemsWarehouseDetails: ItemWarehouseDetail[]) {
    return itemsWarehouseDetails.map(iw => iw.item.id);
  }

  hasUnit(formGroup: FormGroup, itemId?: number) {
    let item =
      itemId ?
        this.stocks.find(stock => stock.item.id === itemId)!.item :
        this.stocks.find(stock => stock.item.id === formGroup.value.id)!.item

    if (item && item.unit)
      this.measureUnit = item.unit

    return !!item?.unit
  }

  getMeasureUnit(itemId: number) {
    return this.stocks.find(stock => stock.item.id === itemId)!.item.unit
  }


  private computePermissions() {
    this.canEdit = hasPermissionOnEntity(this.userReference.user,
        {
          accessEntity: Constants.accessEntities.itemWarehouses,
          accessLevel: Constants.accessLevels.editor
        }, {id: this._warehouseId}) &&

      hasPermissionOnEntity(this.userReference.user,
        {
          accessEntity: Constants.accessEntities.warehouses,
          accessLevel: Constants.accessLevels.reader
        }, {id: this._warehouseId})
      && hasPermission(this.userReference.user,
        {
          accessEntity: Constants.accessEntities.items,
          accessLevel: Constants.accessLevels.reader
        })

    this.canDelete = hasPermissionOnEntity(this.userReference.user,
        {
          accessEntity: Constants.accessEntities.itemWarehouses,
          accessLevel: Constants.accessLevels.admin
        }, {id: this._warehouseId}) &&

      hasPermissionOnEntity(this.userReference.user,
        {
          accessEntity: Constants.accessEntities.warehouses,
          accessLevel: Constants.accessLevels.reader
        }, {id: this._warehouseId})
      && hasPermission(this.userReference.user,
        {
          accessEntity: Constants.accessEntities.items,
          accessLevel: Constants.accessLevels.reader
        })
  }
}
