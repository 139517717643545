import {Directive, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AuthService} from "../services/auth.service";
import {DisableComponent, hasPermissionOnEntity, partialAccessSanitize} from "../utils/global-functions-and-types";
import {Subscription} from "rxjs";
import {RequiredAccessLevel} from "../classes/required-access-level";

@Directive({
  selector: '[awDisableElement]'
})
  /**
   * @param selected: list of entities for which permissions will be checked
   * @param condition: the normal condition for which the component will be enabled/disabled
   * @param accessLevels: permissions conditions
   * @param logicalAnd: choose if access levels shall be in AND or in OR
   * @param component: the component (mandatory for mat-button)
   */
export class DisableElementDirective implements OnChanges {
  @HostBinding('disabled') disabled = false;
  private subscription: Subscription = new Subscription();
  private requiredAccesses: RequiredAccessLevel[] = [];
  private selected?: any[];
  private condition?: boolean;
  private logicalAnd?: boolean;
  private component?: any;

  constructor(private authService: AuthService) {
  }

  @Input() set awDisableElement(value: DisableComponent | undefined) {
    if (value) {
      this.selected = value.selected
      this.requiredAccesses = value.accessLevels.map(accessLevel => partialAccessSanitize(accessLevel))
      this.condition = value.condition
      this.logicalAnd = value.logicalAnd
      this.component = value.component
    }
  }

  // todo: document this code
  ngOnChanges(changes: SimpleChanges): void {
    this.subscription = this.authService
      .onUserChange()
      .subscribe((user) => {
          const isAuthenticated = !!user

          if (isAuthenticated) {
            if (!this.selected) {
              if (this.logicalAnd) {
                this.disabled = this.requiredAccesses.every(requiredAccess => requiredAccess.checkRolesOnly && hasPermissionOnEntity(user!, requiredAccess, undefined))
              } else {
                this.disabled = this.requiredAccesses.some(requiredAccess => requiredAccess.checkRolesOnly && hasPermissionOnEntity(user!, requiredAccess, undefined))
              }
            } else {
              user = user!

              this.disabled = this.condition || !this.selected!.reduce((previousValue, currentValue) => {

                if (this.logicalAnd) {
                  return previousValue && this.requiredAccesses.every(requiredAccess => hasPermissionOnEntity(user!, requiredAccess, currentValue))
                } else {
                  return previousValue && this.requiredAccesses.some(requiredAccess => hasPermissionOnEntity(user!, requiredAccess, currentValue))
                }
              }, true)
            }
          }

        if (this.component)
          this.component._disabled = this.disabled

        }
      )
  }

}
