import {Component} from '@angular/core';

@Component({
  selector: 'aw-companies',
  templateUrl: './companies-container.component.html',
  styleUrls: ['./companies-container.component.scss']
})

export class CompaniesContainerComponent {

  constructor() {

  }

}
