import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResponse} from '../classes/api-response';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Constants} from '../utils/constants';
import {
  ItemCompositionCreate,
  ItemCompositionCreateList,
  ItemCompositionData,
  ItemCompositionDetail
} from "../interfaces/item-composition";


@Injectable({
  providedIn: 'root'
})
export class ItemCompositionsService {

  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.companies}`;


  constructor(private http: HttpClient) { }


  public getCompanyItemComposition(companyId: number, itemId: number): Observable<ItemCompositionDetail> {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.itemCompositions}/${itemId}`)
      .pipe(map(value => value.data as ItemCompositionDetail))

  }

  public createCompanyItemComposition(companyId: number, itemComposition: ItemCompositionCreateList): Observable<ItemCompositionDetail> {
    return this.http
      .post<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.itemCompositions}/${itemComposition.itemComposedId}`, itemComposition.itemsComposers)
      .pipe(map(value => value.data as ItemCompositionDetail));
  }


  public patchCompanyItemComposition(companyId: number, itemId: number, itemComposition: ItemCompositionCreate[]): Observable<ItemCompositionData> {
    return this.http
      .patch<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.itemCompositions}/${itemId}`, itemComposition)
      .pipe(map(value => value.data as ItemCompositionData))
  }

  public deleteCompanyItemComposition(companyId: number, itemId: number): Observable<void> {
    return this.http
      .delete<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.itemCompositions}/${itemId}`)
      .pipe(map(() => {
      }))
  }

  public removeComposerFromItemComposition(companyId: number, composedId: number, composerId: number): Observable<void> {
    return this.http
      .delete<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.items}/${composedId}/${Constants.entities.itemCompositions}/${composerId}`)
      .pipe(map(() => {
      }))
  }

}
