import {Component, Input} from '@angular/core';
import {ShopDetail} from "../../../interfaces/shop";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {Constants} from "../../../utils/constants";
import {OptionFilterItem} from "../../../utils/shared-components/status-filter/column-filter.component";
import {getSaleStatusColor, hasPermission, hasPermissionOnEntity,} from "../../../utils/global-functions-and-types";
import {ShopsService} from "../../../services/shops.service";
import {ResponsiveService} from "../../../services/responsive.service";
import {ActivatedRoute} from "@angular/router";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {MatTableDataSource} from "@angular/material/table";
import {catchError, map} from "rxjs/operators";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {SaleDetail} from "../../../interfaces/sale";
import {SalesService} from "../../../services/sales.service";
import {SalesFormComponent} from "../sales-form/sales-form.component";
import {DateFilterOutput} from "../../../utils/shared-components/date-filter/date-filter.component";
import {BaseOrderSaleTableComponent} from "../../../utils/shared-components/base-table/base-order-sale-table.component";


@Component({
    selector: 'aw-sales-table',
    templateUrl: './sales-table.component.html',
    styleUrls: ['./sales-table.component.scss']
})
export class SalesTableComponent extends BaseOrderSaleTableComponent<SaleDetail> {

    @Input() shopId: number | null = null;

    shop$: Observable<ShopDetail | undefined> = new Observable<ShopDetail>()
    sales$: Observable<SaleDetail[]> = new Observable<SaleDetail[]>()
    shop!: ShopDetail;

    path: any[] = []

    options = Constants.saleStatusKeys().map(k => {
        return {
            value: (Constants.saleStatuses as any)[k],
            name: (Constants.saleStatusesPipe as any)[k]
        }
    })


    availableStatuses: OptionFilterItem[] = Constants.saleStatusKeys().map(k => {
        return {
            key: (Constants.saleStatuses as any)[k],
            value: (Constants.saleStatusesPipe as any)[k]
        }
    })

    getColor = getSaleStatusColor
    readonly = false


    constructor(
        private salesService: SalesService,
        private shopsService: ShopsService,
        public responsiveService: ResponsiveService,
        public activatedRoute: ActivatedRoute,
        public override snackService: SnackService,
        protected override dialogService: DialogsService,
        protected override utilsService: UtilsService
    ) {

        let shopId: number | null = null;

        activatedRoute.paramMap.subscribe(paramMap => {
            if (paramMap.has("shopId"))
                shopId = +paramMap.get("shopId")!
        })

        let deleteFunction =
            (companyId: number, shopId: number, saleId: number): Observable<void> =>
                this.salesService.deleteSale(companyId, shopId, saleId)


        super("saleDate", Constants.localStorageKeys.salesTable, deleteFunction, utilsService, snackService, dialogService)

        this.shopId = shopId

        this.shop$ =
            of(true)
                .pipe(
                    switchMap(() => this.shopsService.getShop(this.userReference.companyId, shopId!)),
                    map(shop => this.shop = shop),
                    catchError((err, caught) => {
                        if (!err.errors.includes('403'))
                            this.snackService.error('Impossibile caricare i dati del punto vendita')
                        return EMPTY;
                    })
                )

        this.sales$ =
            of(true)
                .pipe(
                    switchMap(() => this.salesService.getAllShopSales(this.userReference.companyId, this.shop.id, this.fetchParams, this.filterObject)),
                    switchMap(sales => {
                        this.paramLoading = false
                        this.dataSource = new MatTableDataSource<SaleDetail>(sales.list)
                        this.entityList.push(...sales.list)
                        //todo per avere tutte le righe già espanse, scommentare
                        // la lascio commentata perché secondo me viene una merda
                        // this.expandedOrders.push(...orders.list.map(order => order.id))
                        this.numEntities = sales.num
                        return of(sales.list)
                    })
                )

        this.observable$ =
            of(true)
                .pipe(
                    tap(() => this.paramLoading = true),
                    switchMap(() => this.userReference$),
                    switchMap(() => this.shop$),
                    switchMap(() => this.sales$),
                    catchError((err, caught) => {
                        this.paramLoading = false
                        if (!err.errors.includes('403'))
                            this.snackService.error('Impossibile caricare le vendite');
                        return EMPTY;
                    })
                )


        this.subs.push(this.observable$.subscribe())


        // todo: get from localstorage
        this.availableColumns = [
            "saleDate",
            "note",
            "finalPrice",
            "itemNumber",
            "status",
            "action",
        ]

        // todo: get from localstorage
        this.displayedColumns = [
            "saleDate",
            "note",
            "finalPrice",
            "itemNumber",
            "status",
            "action",
        ]

        this.requiredCreateAccessLevels = [
            {accessEntity: Constants.accessEntities.sellOrderShops, accessLevel: Constants.accessLevels.editor},
            {accessEntity: Constants.accessEntities.shops, accessLevel: Constants.accessLevels.reader},
        ]

        this.requiredDeleteAccessLevels = [
            {accessEntity: Constants.accessEntities.sellOrderShops, accessLevel: Constants.accessLevels.admin},
            {accessEntity: Constants.accessEntities.shops, accessLevel: Constants.accessLevels.reader},
        ]

        this.subs.push(
            this.shopsService.getShop(this.userReference.companyId, this.shopId!, false)
                .subscribe(shop => this.shop = shop)
        )


    }


    addSale() {
        this.dialogService.open(GeneralDialogComponent, {
            data: {
                dialogTitle: 'CREAZIONE – Vendita',
                componentData: {companyId: this.userReference.companyId, entity: {}, shopId: this.shopId, defaultWarehouseId: this.shop.defaultWarehouseId},
                component: SalesFormComponent
            }
        })
            .afterClosed()
            .pipe(switchMap(value => value ? this.sales$ : EMPTY))
            .subscribe({
                next: () => this.snackService.success('Vendita creata'),
                error: (error) => this.snackService.error(error.message),
            })

    }


    openEditDialog(sale: SaleDetail, openStatusDialog: boolean) {

        this.readonly = !hasPermission(this.userReference.user, {
            accessEntity: this.accessEntities.sellOrderShops,
            accessLevel: this.accessLevels.editor
        })

        return this.dialogService.open(GeneralDialogComponent, {
            data: {
                dialogTitle: this.readonly ? `DETTAGLIO – Vendita` : `MODIFICA – Vendita`,
                componentData: this.getComponentData(sale, openStatusDialog),
                component: SalesFormComponent
            }
        })
    }


    editSale(saleId: number, openStatusDialog: boolean) {


        this.salesService.getShopSaleById(this.userReference.companyId, this.shopId!, saleId).subscribe(sale => {

            this.openEditDialog(sale, openStatusDialog)
                .afterClosed()
                .pipe(switchMap(value => value ? this.sales$ : EMPTY))
                .subscribe({
                    next: () => this.snackService.success('Vendita aggiornata'),
                    error: (error) => this.snackService.error(error.message),
                })
        })
    }


    filterBySaleDate(dateRange: DateFilterOutput) {
        this.loadOrderSaleResults({
            saleFrom: dateRange.startDate != undefined ? dateRange.startDate.toISOString() : undefined,
            saleTo: dateRange.endDate != undefined ? dateRange.endDate.toISOString() : undefined
        }, this.sales$)
    }


    filterBySaleStatus(statuses: string[]) {
        console.log(statuses)
        this.loadOrderSaleResults({statuses}, this.sales$)
    }

    resetSaleStatusFilter() {
        this.loadOrderSaleResults({statuses: []}, this.sales$)
    }


    getTodaySales() {
        let today = new Date()
        let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

        let todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0)
        let todayEnd = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 0, 0)

        this.loadOrderSaleResults({
            saleFrom: todayStart.toISOString(),
            saleTo: todayEnd.toISOString(),
            statuses: []
        }, this.sales$)

    }

    getDateDay(deliveryDate: string) {
        return Constants.daysTranslation[new Date(deliveryDate).getDay()]
    }

    getConditionalStatus(status: string) {
        return (status !== Constants.saleStatuses.closed && status !== Constants.saleStatuses.canceled)
    }

    disableDeletionIfContentNotUpdated(sale: SaleDetail) {
        return !!sale.contentSnapshot?.find(content => content.status !== Constants.saleContentStatuses.notUpdated)
    }

    isDeleteActionButtonVisibile() {
        return hasPermissionOnEntity(
            this.userReference.user,
            {
                accessEntity: this.accessEntities.sellOrderShops,
                accessLevel: this.accessLevels.admin
            },
            {id: this.shopId}
        )
    }

    resetSaleDateFilter() {
        this.loadOrderSaleResults({saleFrom: "", saleTo: "", statuses: []}, this.sales$)
    }

    override toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSource.data.filter(element => !this.disableDeletionIfContentNotUpdated(element)))
    }

    private getComponentData(sale: SaleDetail, openStatusDialog: boolean) {
        return {
            companyId: this.userReference.companyId,
            entity: sale,
            managingSaleStatus: openStatusDialog,
            shopId: this.shopId,
            readonly: this.readonly,
            defaultWarehouseId: this.shop.defaultWarehouseId
        }
    }


}
