import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserCreate, UserDetail} from "../../../interfaces/user";
import {UsersService} from "../../../services/users.service";
import {UtilsService} from "../../../services/utils.service";
import {Observable} from "rxjs";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";

@Component({
  selector: 'aw-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss']
})
export class UsersFormComponent extends BaseFormComponent<UserCreate, UserDetail> {


  @Input() companyId = -1

  constructor(
    public usersService: UsersService,
    public override utilsService: UtilsService) {

    let createFunction = (companyId: number, user: UserCreate): Observable<UserDetail> => usersService.createUser(companyId, user)
    let updateFunction = (companyId: number, userId: number, user: UserCreate): Observable<UserDetail> => usersService.updateUser(companyId, userId, user)

    super(createFunction, updateFunction, utilsService)

    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required]),
      phone: new FormControl('',),
      address: new FormControl('',),
      companyId: new FormControl(undefined)
    })

  }

  override _entity: UserDetail = {} as UserDetail

  @Input() set entity(value: UserDetail) {
    this._entity = value
    this.formGroup.patchValue(this._entity)
  }

}
