<ng-template #loadingGeneral>

  <mat-spinner class="align-self-center mt-8"></mat-spinner>

</ng-template>


<div fxLayout="column">
  <h1 class="font-light">Clienti</h1>

  <ng-container *ngIf="dataSource && !loading else loadingGeneral">


    <aw-actions-bar
      [isExportable]="true"
      [accessEntity]="accessEntities.customers"
      [selectVisible]="selectVisible"
      [displayedColumns]="displayedColumns"
      [selection]="selection"
      (deleteElements)="deleteEntities($event, userReference.companyId)"
      (openCreateForm)="addCustomer()"
      (load)="loadResults($event, observable$)"
      (export)="exportCustomers()"
      [isAllSelected]="isAllSelected()"
    >

    </aw-actions-bar>

    <table
      (matSortChange)="loadResults($event, observable$)"
      [dataSource]="dataSource"
      matSortActive="name"
      matSortDirection="asc"
      class="w-100 mat-elevation-z2"
      mat-table
      matSort
      multiTemplateDataRows>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                        (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="name">Cliente</th>
        <td *matCellDef="let element" mat-cell> {{element.name + ' ' + (element.surname ?? '')}} </td>
      </ng-container>


      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="email">Email</th>
        <td *matCellDef="let element" mat-cell> {{element.email}} </td>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell mat-sort-header="phone">Telefono</th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell> {{element.phone}} </td>
      </ng-container>


      <!-- VAT Column -->
      <ng-container matColumnDef="vat">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Partita IVA</th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell> {{element.vat ?? 'Non disponibile'}} </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Azioni</th>
        <td *matCellDef="let element" class="hide-mobile" fxLayoutGap="8" mat-cell>

          <button [awDisableElement]="{
                        accessLevels: [{accessEntity: accessEntities.customers, accessLevel: accessLevels.editor}]}"
                  [routerLink]="generatePath(element.id, undefined, entities.customers)"
                  (click)="$event.preventDefault();$event.stopPropagation();"
                  color="accent"
                  mat-icon-button
                  matTooltip="Vai al dettaglio"
                  matTooltipPosition="above"
          >

            <mat-icon>info</mat-icon>

          </button>

          <button #edit
                  (click)="editCustomer(element.id);$event.preventDefault();$event.stopPropagation();"
                  *awAccess="{accessLevel:{accessEntity: accessEntities.customers, accessLevel: accessLevels.editor}}"
                  [awDisableElement]="{
                        logicalAnd: true,
                        accessLevels: [{accessEntity: accessEntities.customers, accessLevel: accessLevels.editor}],
                        component: edit}"

                  color="accent"
                  mat-icon-button
                  matTooltip="Modifica"
                  matTooltipPosition="above"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button #delete
                  (click)="deleteEntities([element.id], userReference.companyId);$event.preventDefault();$event.stopPropagation();"
                  *awAccess="{accessLevel:{accessEntity: accessEntities.customers, accessLevel: accessLevels.admin}}"
                  [awDisableElement]="{
                        logicalAnd: true,
                        accessLevels: [{accessEntity: accessEntities.customers, accessLevel: accessLevels.admin}],
                        component: delete}"
                  color="warn"
                  mat-icon-button
                  matTooltip="Elimina"
                  matTooltipPosition="above"
          >
            <mat-icon>delete</mat-icon>
          </button>

        </td>

      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns:  displayedColumns" (click)="editCustomer(row.id)" mat-row></tr>
      <!--      todo add awSlidingRow-->


      <tr *matNoDataRow class="mat-row">
        <td [colSpan]="displayedColumns.length" class="mat-cell text-center">
          Nessun cliente trovato
        </td>
      </tr>

    </table>

    <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </mat-card>

  </ng-container>

  <mat-paginator #paginator
                 (page)="loadResults($event, observable$)"
                 (selectionchange)="logMe($event)"
                 *ngIf="dataSource && numEntities > 0"
                 [length]="numEntities"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 class="mat-elevation-z1"
                 showFirstLastButtons
  >
  </mat-paginator>
</div>
