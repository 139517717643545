import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {tap} from "rxjs";
import {DialogsService} from "../../../services/dialogs.service";

@Component({
  selector: 'aw-base-filter',
  template: ''
})
export abstract class BaseFilterComponent<InitialFilterStateType, OnFilterType> implements OnInit {

  getColor() {
    if (this.isFiltered)
      return '#49a342'
    else if (this.isFilterChanged)
      return '#ebad73'
    else
      return '#645ad0'
  }


  // Text displayed in the column header
  @Input() headerName: string = ''

  // If True, a button is shown which when clicked emits `removeColumn` event
  @Input() removable: boolean = false


  // Emits the selected options list
  @Output() onFilter = new EventEmitter<OnFilterType>()

  // Emits when the reset button is clicked
  @Output() onResetFilter = new EventEmitter<void>()

  // Emits when the remove button is clicked
  @Output() removeColumn = new EventEmitter<void>()


  // True if there are some filters active on the column
  isFiltered = false;
  // If filter has changed from last search
  isFilterChanged = false;


  protected constructor(protected dialogsService: DialogsService) {
  }


  ngOnInit(): void {
  }


  resetFilter() {
    this.isFiltered = false
    this.isFilterChanged = false
    this.onResetFilter.emit()
  }

  abstract emitOnFilter(): void

  openRemoveColumnDialog() {
    if (this.isFiltered) {
      this.dialogsService.confirm('Filtro sulla colonna attivo', 'Vuoi effettuare il reset del filtro?', false, 'Si', 'No').pipe(
        tap((value) => {
          if (value) {
            this.resetFilter()
          }
          this.removeColumn.emit()
        })
      ).subscribe()
    } else {
      this.removeColumn.emit()
    }

  }


}
