import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'aw-base-tab-group',
  template: '',
})
export class BaseTabGroupComponent {

  selectedTab = 0

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router
  ) {
    this.selectedTab = +this.activatedRoute.snapshot.queryParamMap.get('tab')! ?? 0
  }


  matTabChange(tabIndex: number) {
    this.selectedTab = tabIndex
    this.router.navigate([], {
      queryParams: {tab: tabIndex},
      relativeTo: this.activatedRoute
    }).then(() => {});
  }


}
