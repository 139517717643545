import * as Hammer from 'hammerjs';
import {Injectable, LOCALE_ID, NgModule} from "@angular/core";
import {WarehousesFormComponent} from "./components/warehouses/warehouses-form/warehouses-form.component";
import {
  OptionValuesFormComponent
} from "./components/options/option-values/option-values-form/option-values-form.component";
import {CompaniesTableComponent} from './components/companies/companies-table/companies-table.component';
import {CompaniesFormComponent} from './components/companies/companies-form/companies-form.component';
import {CompaniesContainerComponent} from './pages/companies/companies-container/companies-container.component';
import {ItemsContainerComponent} from './pages/items/items-container.component';
import {UsersContainerComponent} from './pages/users/users-container.component';
import {UsersFormComponent} from './components/users/users-form/users-form.component';
import {UserPermissionsContainerComponent} from './pages/permissions/permissions/user-permissions-container.component';
import {AccessDirective} from './directives/access.directive';
import {WarehousesContainerComponent} from './pages/warehouses/warehouses-container.component';
import {WarehousesTableComponent} from './components/warehouses/warehouses-table/warehouses-table.component';
import {CompanyDetailsContainer} from './pages/companies/company-details-container/company-details-container.component';
import {ItemTypesFormComponent} from './components/item-types/item-types-form/item-types-form.component';
import {WarehouseItemsPageComponent} from './pages/warehouses-items/warehouse-items-page.component';
import {AngularFireAuth, USE_DEVICE_LANGUAGE} from "@angular/fire/compat/auth";
import {OrdersTableComponent} from "./components/orders/orders-table/orders-table.component";
import {ProfilesTableComponent} from "./components/profiles/profiles-table/profiles-table.component";
import {OrderContentStatusPipe} from "./pipes/order-content-status.pipe";
import {
  CustomerInformationTableComponent
} from "./components/customers/customer-information-table/customer-information-table.component";
import {
  WarehousesItemsFormComponent
} from "./components/warehouses-items/warehouses-items-form/warehouses-items-form.component";
import {ShopsTableComponent} from "./components/shops/shops-table/shops-table.component";
import {DisableElementDirective} from "./directives/disable-element.directive";
import {CustomersFormComponent} from "./components/customers/customers-form/customers-form.component";
import {SalesContainerComponent} from "./pages/sales/sales/sales-container.component";
import {OptionsTableComponent} from "./components/options/options/options-table/options-table.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {
  ItemsCompositionFormComponent
} from "./components/items/items-composition-form/items-composition-form.component";
import {
  ProfilePermissionsTableContainerComponent
} from './components/profiles/profiles-form/profile-permissions-table-container.component';
import {ProfilesContainerComponent} from './pages/profiles/profiles/profiles-container.component';
import {
  ProfilePermissionsTableComponent
} from './components/profiles/profiles-form-tab-panel/profile-permissions-table.component';
import {ProfileDetailsContainerComponent} from './pages/profiles/profile-details/profile-details-container.component';

import {AppRoutingModule} from "./app-routing.module";

import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from "@angular/platform-browser";
import {SalesFormComponent} from "./components/sales/sales-form/sales-form.component";
import {OrdersFormComponent} from "./components/orders/orders-form/orders-form.component";
import {GeneralInterceptorService} from "./services/general.interceptor.service";
import {
  DeliveryCompaniesTableComponent
} from "./components/delivery-companies/delivery-companies-table/delivery-companies-table.component";
import {ShopsFormComponent} from "./components/shops/shops-form/shops-form.component";
import {
  ShopsPermissionsContainerComponent
} from "./pages/permissions/shops-permissions/shops-permissions-container.component";
import {UsersTableComponent} from "./components/users/users-table/users-table.component";
import {CustomerInfoContainerComponent} from "./pages/customers/customer-info/customer-info-container.component";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {SaleContentStatusPipe} from "./pipes/sale-content-status.pipe";
import {PermissionsTableComponent} from "./components/permissions-form/permissions-table.component";
import {ShopsContainerComponent} from "./pages/shops/shops/shops-container.component";
import {ImageCropperModule} from "ngx-image-cropper";
import {AngularFireModule} from "@angular/fire/compat";
import {PrivateContainerComponent} from "./containers/private-container/private-container.component";
import {
  DeliveryCompaniesFormComponent
} from "./components/delivery-companies/delivery-companies-form/delivery-companies-form.component";
import {
  ProfilesUserDropdownComponent
} from "./components/profiles/profiles-user-dropdown/profiles-user-dropdown.component";
import {EntityPermissionTableComponent} from "./components/entity-permission-table/entity-permission-table.component";
import {
  SlidingRowActionsCellComponent
} from "./utils/shared-components/sliding-row-actions-cell/sliding-row-actions-cell.component";
import {OrderStatusPipe} from "./pipes/order-status";
import {
  WarehousePermissionsContainerComponent
} from "./pages/permissions/warehouse-permissions/warehouse-permissions-container.component";
import {DashboardContainerComponent} from "./pages/dashboard/dashboard-container.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxImageCompressService} from "ngx-image-compress";
import {OrderDetailsContainerComponent} from "./pages/orders/order-details/order-details-container.component";
import {AppComponent} from "./app.component";
import {OptionValuesContainerComponent} from "./pages/option-values/option-values-container.component";
import {CustomersContainerComponent} from "./pages/customers/customers/customers-container.component";
import {ShopDetailsContainerComponent} from "./pages/shops/shop-details/shop-details-container.component";
import {PublicContainerComponent} from "./containers/public-container/public-container.component";
import {
  OptionValuesTableComponent
} from "./components/options/option-values/option-values-table/option-values-table.component";
import {OrderContentTableComponent} from "./components/orders/order-content-table/order-content-table.component";
import {LoginComponent} from "./pages/login/login.component";
import {
  WarehousesItemsTableComponent
} from "./components/warehouses-items/warehouses-items-table/warehouses-items-table.component";
import {SaleStatusPipe} from "./pipes/sale-status.pipe";
import {SlidingRowComponent} from "./utils/shared-components/sliding-row/sliding-row.component";
import {OptionsFormComponent} from "./components/options/options/options-form/options-form.component";
import {OrdersContainerComponent} from "./pages/orders/orders/orders-container.component";
import {AwImageCompressComponent} from "./common/image-compress/aw-image-compress.component";
import {CustomersTableComponent} from "./components/customers/customers-table/customers-table.component";
import {SalesTableComponent} from "./components/sales/sales-table/sales-table.component";
import {BaseItemsFormComponent} from "./components/base-items/base-items-form/base-items-form.component";
import {BaseItemsTableComponent} from "./components/base-items/base-items-table/base-items-table.component";
import {JwtInterceptorService} from "./services/jwt.interceptor.service";
import {CustomersInfoFormComponent} from "./components/customers/customers-info-form/customers-info-form.component";
import {environment} from "../environments/environment";
import {SearchBoxComponent} from './utils/shared-components/search-box/search-box.component';
import {ItemVariantsTableComponent} from './components/items/item-variants-table/item-variants-table.component';
import {ItemTypesTableComponent} from "./components/item-types/item-types-table/item-types-table.component";
import {
  ItemCompositionTableComponent
} from "./components/items/item-composition-table/item-composition-table.component";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDialogModule} from "@angular/material/dialog";
import {ActionsBarComponent} from './utils/shared-components/actions-row/actions-bar.component';
import {ConfirmDialogComponent} from "./utils/shared-components/confirm-dialog/confirm-dialog.component";
import {AlertDialogComponent} from "./utils/shared-components/alert-dialog/alert-dialog.component";
import {GeneralDialogComponent} from "./utils/shared-components/general-dialog/general-dialog.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgxMaskModule} from "ngx-mask";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatListModule} from "@angular/material/list";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSelectModule} from "@angular/material/select";
import {MatChipsModule} from "@angular/material/chips";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatMenuModule} from "@angular/material/menu";
import {CustomDateAdapter} from "./utils/custom-date-adapter";
import {DateFilterComponent} from './utils/shared-components/date-filter/date-filter.component';
import {ColumnFilterComponent} from './utils/shared-components/status-filter/column-filter.component';
import {LegendStatusesComponent} from './components/legend-statuses/legend-statuses.component';
import {SaleContentTableComponent} from "./components/sales/sale-content-table/sale-content-table.component";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import '@angular/common/locales/global/it';
import {
  PermissionsTabGroupComponent
} from './pages/permissions/permissions/permissions-tab-group/permissions-tab-group.component'
import {OrderPaymentStatusPipe} from "./pipes/order-payment-status.pipe";
import {TimelineComponent} from './utils/shared-components/timeline/timeline.component';
import {SearchOptionComponent} from './utils/shared-components/search-option/search-option.component';
import {NgxCurrencyModule} from "ngx-currency";
import {ItemStocksTableComponent} from './components/items/item-stocks-table/item-stocks-table.component';
import {RemovableColumnComponent} from './utils/shared-components/removable-column/removable-column.component';
import {
  InsertColumnsSelectorComponent
} from './utils/shared-components/insert-columns-selector/insert-columns-selector.component';
import {
  DeliveryTimeSlotsFormComponent
} from "./components/delivery-time-slots/delivery-time-slots-form/delivery-time-slots-form.component";
import {
  DeliveryTimeSlotsTableComponent
} from "./components/delivery-time-slots/delivery-time-slots-table/delivery-time-slots-table.component";
import {StocksMigrationFormComponent} from "./components/warehouses-items/stocks-migration-form/stocks-migration-form.component";
import {MigrationChoicesPipe} from "./pipes/migration-choice.pipe";
import {DeliveryOptionsPipe} from "./pipes/delivery-option.pipe";


@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    press: {time: 500},
    pan: {direction: Hammer.DIRECTION_HORIZONTAL, threshold: 100}
  };

  override buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-y',
    });
    return mc;
  }
}


@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AccessDirective,
    AppComponent,
    PublicContainerComponent,
    LoginComponent,
    PrivateContainerComponent,
    CompaniesTableComponent,
    CompaniesFormComponent,
    CompaniesContainerComponent,
    BaseItemsFormComponent,
    BaseItemsTableComponent,
    ItemsContainerComponent,
    UsersContainerComponent,
    UsersFormComponent,
    UsersTableComponent,
    CompanyDetailsContainer,

    ItemTypesTableComponent,
    ItemTypesFormComponent,
    ItemsCompositionFormComponent,
    ItemCompositionTableComponent,

    WarehousesContainerComponent,
    WarehousesTableComponent,
    WarehousesFormComponent,
    WarehouseItemsPageComponent,
    WarehousesItemsTableComponent,
    WarehousesItemsFormComponent,
    StocksMigrationFormComponent,

    OrdersTableComponent,
    OrdersContainerComponent,
    OrdersFormComponent,
    CustomersContainerComponent,
    CustomersFormComponent,
    CustomersTableComponent,
    CustomerInfoContainerComponent,
    CustomersInfoFormComponent,
    CustomerInformationTableComponent,

    ShopDetailsContainerComponent,
    ShopsContainerComponent,
    ShopsFormComponent,
    ShopsTableComponent,
    DeliveryCompaniesFormComponent,
    DeliveryCompaniesTableComponent,
    DeliveryTimeSlotsFormComponent,
    DeliveryTimeSlotsTableComponent,
    OptionsTableComponent,
    OptionsFormComponent,
    OptionValuesTableComponent,
    OptionValuesFormComponent,
    OptionValuesContainerComponent,
    OrdersFormComponent,
    OrdersContainerComponent,
    OrdersTableComponent,
    OrderDetailsContainerComponent,
    OrderContentTableComponent,
    UserPermissionsContainerComponent,
    PermissionsTableComponent,
    UserPermissionsContainerComponent,
    ProfilesUserDropdownComponent,
    ProfilesTableComponent,
    ProfilePermissionsTableContainerComponent,
    ProfilesContainerComponent,
    ProfilePermissionsTableComponent,
    ProfileDetailsContainerComponent,
    MigrationChoicesPipe,
    DeliveryOptionsPipe,
    OrderStatusPipe,
    OrderContentStatusPipe,
    EntityPermissionTableComponent,
    WarehousePermissionsContainerComponent,
    ShopsPermissionsContainerComponent,
    DashboardContainerComponent,
    DisableElementDirective,
    SalesContainerComponent,
    SalesTableComponent,
    SalesFormComponent,
    SaleContentTableComponent,
    SaleContentStatusPipe,
    SaleStatusPipe,
    OrderPaymentStatusPipe,
    AwImageCompressComponent,
    OrdersFormComponent,
    SlidingRowComponent,
    SlidingRowActionsCellComponent,
    SearchBoxComponent,
    ItemVariantsTableComponent,
    ActionsBarComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    GeneralDialogComponent,
    DateFilterComponent,
    ColumnFilterComponent,
    LegendStatusesComponent,
    PermissionsTabGroupComponent,
    TimelineComponent,
    SearchOptionComponent,
    ItemStocksTableComponent,
    RemovableColumnComponent,
    InsertColumnsSelectorComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FlexModule,
    FormsModule,
    HammerModule,
    HttpClientModule,
    ImageCropperModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxCurrencyModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: GeneralInterceptorService, multi: true},
    {provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig},
    {provide: USE_DEVICE_LANGUAGE, useValue: true},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: LOCALE_ID, useValue: "it-IT"},
    AngularFireAuth,
    MigrationChoicesPipe,
    DeliveryOptionsPipe,
    OrderStatusPipe,
    OrderContentStatusPipe,
    SaleContentStatusPipe,
    SaleStatusPipe,
    OrderPaymentStatusPipe,
    NgxImageCompressService,
    MatDatepickerModule
  ],
})
export class AppModule {

}
