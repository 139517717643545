import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ResponsiveService} from "../../../services/responsive.service";
import {DialogsService} from "../../../services/dialogs.service";
import {BaseFilterComponent} from "../base-filter/base-filter.component";

@Component({
    selector: 'aw-column-filter',
    templateUrl: './column-filter.component.html',
    styleUrls: ['./column-filter.component.scss']
})
export class ColumnFilterComponent extends BaseFilterComponent<string[], any[]> {

    // Contains the key of the selected options
    @Input() placeholder = '';

    // All available options to display
    @Input() options: OptionFilterItem[] = []
    @Output() filterFieldChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    selectedOptions: string[] = []
    _defaultOptionsList: OptionFilterItem[] = []

    constructor(public responsiveService: ResponsiveService, protected override dialogsService: DialogsService) {
        super(dialogsService);
    }

    // used for Status and PaymentStatus
    @Input() set filterField(initialOptions: string[] | undefined) {

        this.manageSelectedOptions(initialOptions)
    }

    // Options to be applied at the beginning
    @Input()
    set initialOptions(optionFilterItems: OptionFilterItem[]) {
        this._defaultOptionsList = optionFilterItems
        this.selectedOptions = this._defaultOptionsList.map(s => s.key)
    }

    manageSelectedOptions(options: string[] | undefined) {
        if (options && options.length > 0) {
            this.selectedOptions = [...options]
            this.isFiltered = true;
        } else {
            this.selectedOptions = []
            this.isFiltered = false
        }
    }

    emitOnFilter() {
        this.isFilterChanged = false
        this.isFiltered = true
        this.isFiltered = this.selectedOptions.length > 0
        this.onFilter.emit(this.selectedOptions)
    }

    override resetFilter() {
        // reset to initial options
        this.selectedOptions = this._defaultOptionsList.map(s => s.key)
        super.resetFilter()
    }
}

/**
 * Example of item:
 *  ```json
 *    {
 *      value: "IN LAVORAZIONE',
 *      key: 'IN_PROGESS'
 *    }
 *  ```
 * @property value the value to show in the UI
 * @property key the internal unique key
 * */
export interface OptionFilterItem {
    value: string,
    key: any
}
