import {Component} from '@angular/core';
import {CustomerData} from "../../../interfaces/customer";
import {hasPermission} from "../../../utils/global-functions-and-types";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {SnackService} from "../../../services/snack.service";
import {UtilsService} from "../../../services/utils.service";
import {CustomersService} from "../../../services/customers.service";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {DialogsService} from "../../../services/dialogs.service";
import {CustomersFormComponent} from "../customers-form/customers-form.component";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {Constants} from "../../../utils/constants";
import {saveAs} from "file-saver";

@Component({
  selector: 'aw-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss']
})
export class CustomersTableComponent extends BaseTableComponent<CustomerData> {


  constructor(
    public customersService: CustomersService,
    protected override  dialogService: DialogsService,
    protected override snackService: SnackService,
    protected override utilsService: UtilsService) {


    let deleteFunction =
      (companyId: number, customerId: number): Observable<void> =>
        customersService.deleteCompanyCustomer(companyId, customerId)

    super('name', Constants.localStorageKeys.customersTable, deleteFunction, utilsService, snackService, dialogService)

    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(userReference => {
            this.userReference = userReference
            return this.customersService.getCompanyCustomers(this.userReference.companyId, this.fetchParams)
          }),
          switchMap(customers => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<CustomerData>(customers.list)
            this.entityList.push(...customers.list)
            this.numEntities = customers.num
            return of(customers.list)
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i clienti');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())

    this.displayedColumns = [
      "name",
      "email",
      "phone",
      "vat",
      "action",
    ]

  }

  addCustomer() {

    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Cliente',
        componentData: {companyId: this.userReference.companyId},
        component: CustomersFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: () => this.snackService.success('Cliente creato'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  editCustomer(customerId: number) {

    this.customersService.getCompanyCustomer(this.userReference.companyId, customerId)
      .subscribe(customer => {
        let readonly = !hasPermission(this.userReference.user, {
          accessEntity: this.accessEntities.customers,
          accessLevel: this.accessLevels.editor
        })
        this.dialogService.open(GeneralDialogComponent, {
          data: {
            dialogTitle: readonly ? `DETTAGLIO – ${customer.name}` : `MODIFICA – ${customer.name}`,
            componentData: {entity: customer, companyId: this.userReference.companyId, readOnly: readonly},
            component: CustomersFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Cliente aggiornato');
            },
            error: () => {
              this.snackService.success('Impossibile aggiornare il cliente');
            },
          })
      })

  }

  exportCustomers() {
    this.customersService.exportCompanyCustomers(this.userReference.companyId, this.fetchParams)
      .subscribe( data => saveAs(data))
  }
}
