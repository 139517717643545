import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DeliveryCompanyCreate, DeliveryCompanyData} from "../../../interfaces/delivery-company";

import {UtilsService} from "../../../services/utils.service";
import {Observable} from "rxjs";
import {DeliveryCompaniesService} from "../../../services/delivery-companies.service";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";

@Component({
  selector: 'aw-delivery-companies-form',
  templateUrl: './delivery-companies-form.component.html',
  styleUrls: ['./delivery-companies-form.component.scss']
})
export class DeliveryCompaniesFormComponent extends BaseFormComponent<DeliveryCompanyCreate, DeliveryCompanyData> {

  override _entity: DeliveryCompanyData = {} as DeliveryCompanyData

  @Input() companyId = -1

  @Input() set entity(value: DeliveryCompanyData) {
    this._entity = value
    this.formGroup.patchValue(this._entity)
  }

  constructor(
    public deliveryCompaniesService: DeliveryCompaniesService,
    public override utilsService: UtilsService) {

    let createFunction = (companyId: number, deliveryCompany: DeliveryCompanyCreate):
      Observable<DeliveryCompanyData> =>
      deliveryCompaniesService.createDeliveryCompany(companyId, deliveryCompany)

    let updateFunction = (companyId: number, deliveryCompanyId: number, deliveryCompany: DeliveryCompanyCreate):
      Observable<DeliveryCompanyData> =>
      deliveryCompaniesService.updateDeliveryCompany(companyId, deliveryCompanyId, deliveryCompany)

    super(createFunction, updateFunction, utilsService)

    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      name: new FormControl('', [Validators.required]),
    })

  }
}
