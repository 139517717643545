<ng-template #loadingGeneral>

  <mat-spinner class="align-self-center mt-8"></mat-spinner>

</ng-template>

<div fxLayout="column">

  <ng-container *ngIf="dataSource && !loading else loadingGeneral">

    <table
      [dataSource]="dataSource"
      class="w-100 mat-elevation-z2"
      mat-table
      matSort
      matSortActive="name"
      matSortDirection="asc"
      multiTemplateDataRows>


      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="name">
          Nome
        </th>
        <td *matCellDef="let element" mat-cell> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell>Descrizione</th>
        <td *matCellDef="let element" mat-cell> {{element.description}} </td>
      </ng-container>

      <ng-container matColumnDef="minimumSellNetPrice">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Vendita <!--(IVA)--></th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell>{{element.minimumSellNetPrice | currency:'EUR' }} <!--( {{element.defaultSellVAT | currency:'':'':'0.0-2'}}% )--></td>
      </ng-container>

      <ng-container matColumnDef="maximumBuyNetPrice">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Acquisto <!--(IVA)--></th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell>{{element.maximumBuyNetPrice | currency:'EUR' }} <!--({{element.defaultBuyVAT  | currency:'':'':'0.0-2'}}%)--></td>
      </ng-container>

<!--      <ng-container matColumnDef="unit">-->
<!--        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Unità di misura</th>-->
<!--        <td *matCellDef="let element" class="hide-mobile" mat-cell>{{element.unit}}</td>-->
<!--      </ng-container>-->

      <ng-container matColumnDef="barcode">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Codice a barre</th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell> {{element.barcode}} </td>
      </ng-container>

      <ng-container matColumnDef="sku">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Codice identificativo articolo</th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell> {{element.sku}} </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell></th>
        <td *matCellDef="let element" class="hide-mobile" fxLayoutGap="8" mat-cell>
          <button
            (click)="manageExpandedComposerItemRows(element.id)"
            *ngIf="element.itemComposers.length !== 0"
                  color="accent"
                  mat-icon-button
                  matTooltip="Mostra composizione"
                  matTooltipPosition="above"
          >
            <mat-icon>inventory</mat-icon>
          </button>
        </td>

      </ng-container>



      <ng-container matColumnDef="expandedComposerItem">
        <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="pr-1 pl-8 py-1" mat-cell>
          <aw-item-composition-table *ngIf="expandedComposerItems.includes(element.id)" [itemComposers]="element.itemComposers"></aw-item-composition-table>
        </td>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

      <tr *matRowDef="let row; columns: ['expandedComposerItem']"
          [fxShow]="expandedComposerItems.includes(row.id)" mat-row class="bg-gray-300"></tr>
    </table>

    <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </mat-card>

  </ng-container>

  <mat-paginator #paginator
                 (page)="loadResults($event, observable$)"
                 (selectionchange)="logMe($event)"
                 *ngIf="dataSource && numEntities > 0"
                 [length]="numEntities"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 class="mat-elevation-z1"
                 showFirstLastButtons
  >
  </mat-paginator>
</div>




