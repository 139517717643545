<ng-template #template>

  <tr
    (click)="rowClick()"
    (panend)="panEnd($event)"
    (panleft)="move($event)"
    (panstart)="panStart($event);"
    (panright)="unSwipe()"
    (press)="press();"
    #row
    class="cursor-pointer border-none " style="position: relative"
    [ngClass]="{'swiped': swipedLeftId() == element.id}"
  >
    <ng-content></ng-content>
  </tr>

</ng-template>
