import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../classes/api-response';
import {Constants} from '../utils/constants';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {OptionCreate, OptionData} from "../interfaces/option";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";


@Injectable({
  providedIn: 'root'
})
export class OptionsService {

  constructor(private http: HttpClient) { }

  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.companies}`;

  public getAllCompanyOptions(companyId: number, paginationParams?: FetchParams): Observable<PageConverter<OptionData>> {
    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.options}`, {params : sortFilterParams})
      .pipe(map(value => value.data as PageConverter<OptionData>))

  }
  public getCompanyOption(companyId: number, optionId: number): Observable<OptionData> {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.options}/${optionId}`)
      .pipe(map(value => value.data as OptionData))

  }

  public createCompanyOption(companyId: number, option: OptionCreate): Observable<OptionData> {
    return this.http
      .post<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.options}`, option)
      .pipe(map(value => value.data as OptionData));
  }

  public deleteCompanyOption(companyId: number, optionId: number): Observable<void> {
    return this.http
      .delete<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.options}/${optionId}`)
      .pipe(map(() => {
      }))
  }

  public updateCompanyOption(companyId: number, optionId: number, option: OptionCreate): Observable<OptionData> {
    return this.http
      .patch<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.options}/${optionId}`, option)
      .pipe(map(value => value.data as OptionData))
  }
}
