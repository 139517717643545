import {Component, OnDestroy, OnInit} from '@angular/core';
import {EMPTY, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {ShopsService} from "../../../services/shops.service";
import {OrdersService} from "../../../services/orders.service";
import {CustomersService} from "../../../services/customers.service";

import {ShopData, ShopDetail} from "../../../interfaces/shop";
import {CustomerData,} from "../../../interfaces/customer";
import {AccessData} from "../../../interfaces/permission";
import {WarehouseData} from "../../../interfaces/warehouse";
import {WarehousesService} from "../../../services/warehouses.service";
import {FetchParams, UserReference} from "../../../utils/global-functions-and-types";
import {UtilsService} from "../../../services/utils.service";
import {Constants} from "../../../utils/constants";
import {SnackService} from "../../../services/snack.service";
import {BaseTabGroupComponent} from "../../../utils/shared-components/base-container/base-tab-group.component";


@Component({
  selector: 'aw-shop-details',
  templateUrl: './shop-details-container.component.html',
  styleUrls: ['./shop-details-container.component.scss']
})
export class ShopDetailsContainerComponent extends BaseTabGroupComponent implements OnInit, OnDestroy {
  loading = false
  loadingShop = false
  loadingCustomers = false
  loadingWarehouses = false
  saving = false
  referenceCompanyId!: number;
  referenceShopId!: number;
  shop$: Observable<ShopDetail>
  shop!: ShopDetail

  customers$: Observable<CustomerData[]>
  customers: CustomerData[] = []

  warehouses$: Observable<WarehouseData[]>
  warehouses: WarehouseData[] = []

  accessWarehouses: AccessData | undefined

  accessEntities = Constants.accessEntities

  accessLevels = Constants.accessLevels

  selectedShop?: ShopData;

  userReference$: Observable<UserReference> = new Observable<UserReference>()

  userReference!: UserReference

  accessShops: AccessData | undefined

  private subs: Subscription[] = []

  constructor(private authService: AuthService,
              private shopsService: ShopsService,
              private ordersService: OrdersService,
              private customersService: CustomersService,
              private snackService: SnackService,
              private warehousesService: WarehousesService,
              private utilsService: UtilsService,
              protected override activatedRoute: ActivatedRoute,
              protected override router: Router) {

    super(activatedRoute, router)

    this.subs.push(this.utilsService.getUserReference().subscribe(userReference => this.userReference = userReference))

    this.activatedRoute.paramMap.subscribe(paramsMap => this.referenceShopId = +paramsMap.get('shopId')!)


    //todo metti apposto sta roba per dio, throw Error()???
    //  usa userReference e altre cose per sta schifezza
    let ids$ = activatedRoute
      .paramMap
      .pipe(
        switchMap(paramsMap => {
          if (!paramsMap.has('shopId'))
            throw Error()

          if (paramsMap.has('companyId')) {
            return of(
              {
                cid: +paramsMap.get('companyId')!,
                sid: +paramsMap.get('shopId')!,
                accessShops: undefined,
                accessWarehouses: undefined});
          } else {
            return this.authService.onUserChange().pipe(map(u => {
                return {
                  cid: u?.companyId!,
                  sid: +paramsMap.get('shopId')!,
                  accessShops: u?.access.shops,
                  accessWarehouses: u?.access.warehouses
                }
              }
            ));
          }
        }),
        tap((ids) => {
          this.referenceCompanyId = ids.cid
          this.referenceShopId = ids.sid
          this.accessShops = ids.accessShops
          this.accessWarehouses = ids.accessWarehouses
        }),
        catchError((err, caught) => {
          router.navigate(['/error']).then()
          return EMPTY
        }))


    this.shop$ =
      ids$
        .pipe(
          tap(() => {
            this.loading = true
            this.loadingShop = true
          }),
          switchMap(() => this.shopsService.getShop(this.referenceCompanyId, this.referenceShopId, true)),
          tap((shop) => {
            this.shop = shop
            this.selectedShop = {...shop}
            this.loadingShop = false
          }),
          catchError((err, caught) => {
            if (err.errors.includes('403')) {
              this.shopsService.getShop(this.referenceCompanyId, this.referenceShopId)
                .subscribe(
                {
                  next: (shop) => {
                    this.shop = shop
                    this.selectedShop = {...shop}
                    this.loadingShop = false
                  },
                  error: (err) => {
                    if (!err.errors.includes('403')) {
                    this.snackService.error('Impossibile caricare i dati del punto vendita')
                    }
                  }
                }
              )
            }
            this.loading = false;
            this.loadingShop = true
            return EMPTY;
          })
        )

    this.warehouses$ =
      ids$
        .pipe(
          tap(() => this.loadingWarehouses = true),
          switchMap(() => {
            return this.warehousesService.getAllCompanyWarehouses(this.referenceCompanyId,
              this.accessWarehouses?.userAuthority != undefined? [] : this.accessWarehouses!.permission.map((value: { entityId: any; }) => value.entityId), undefined)
          }),
          switchMap(warehouses => {
            this.warehouses = warehouses.list;
            this.loadingWarehouses = false;
            this.loading = false
            return of(warehouses.list)

          }),
          catchError((err, caught) => {
            this.loadingWarehouses = false;
            this.loading = false;
            if (!err.errors.includes('403')) {
              this.snackService.error('Impossibile caricare i magazzini')
            }
            return EMPTY;
          })
        )


    this.customers$ =
      ids$
        .pipe(
          tap(() => this.loadingCustomers = true),
          switchMap(() => this.customersService.getCompanyCustomers(this.referenceCompanyId, {} as FetchParams)),
          switchMap((customers) => {
            this.loadingCustomers = false;
            this.customers = customers.list
            return of(customers.list)
          }),
          catchError((err, caught) => {
            this.loadingCustomers = false
            this.loading = false
            if (!err.errors.includes('403')) {
              this.snackService.error('Impossibile caricare i clienti')
            }
            return EMPTY;
          })
        )

  }

  ngOnInit(): void {
    this.subs.push(this.shop$.subscribe())
    this.subs.push(this.warehouses$.subscribe())
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

}
