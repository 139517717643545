import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ItemTypeCreate, ItemTypeData} from "../../../interfaces/item-type";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {UtilsService} from "../../../services/utils.service";
import {OptionData} from "../../../interfaces/option";
import {Observable} from "rxjs";
import {ItemTypesService} from "../../../services/item-types.service";

@Component({
  selector: 'aw-item-types-form',
  templateUrl: './item-types-form.component.html',
  styleUrls: ['./item-types-form.component.scss']
})
export class ItemTypesFormComponent extends BaseFormComponent<ItemTypeCreate, ItemTypeData> {

  @Input() companyId = -1

  constructor(
    public itemTypesService: ItemTypesService,
    public override utilsService: UtilsService) {

    let createFunction = (companyId: number, itemType: ItemTypeCreate):
      Observable<ItemTypeData> =>
      itemTypesService.createCompanyItemType(companyId, itemType)

    let updateFunction = (companyId: number, itemTypeId: number, itemType: ItemTypeCreate):
      Observable<ItemTypeData> =>
      itemTypesService.updateCompanyItemType(companyId, itemTypeId, itemType)

    super(createFunction, updateFunction, utilsService)

    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', []),
    })

  }

  override _entity: OptionData = {} as OptionData

  @Input() set entity(value: OptionData) {
    this._entity = value
    this.formGroup.patchValue(this._entity)
  }
}
