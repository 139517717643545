import {Component} from '@angular/core';
import {ItemTypeData} from "../../../interfaces/item-type";
import {hasPermissionOnEntity} from "../../../utils/global-functions-and-types";
import {ResponsiveService} from "../../../services/responsive.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {ItemTypesService} from "../../../services/item-types.service";
import {ItemTypesFormComponent} from "../item-types-form/item-types-form.component";
import {Constants} from "../../../utils/constants";

@Component({
  selector: 'aw-item-types-table',
  templateUrl: './item-types-table.component.html',
  styleUrls: ['./item-types-table.component.scss']
})
export class ItemTypesTableComponent extends BaseTableComponent<ItemTypeData> {

  constructor(private itemTypesService: ItemTypesService,
              public responsiveService: ResponsiveService,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
  ) {

    let deleteFunction =
      (companyId: number, itemTypeId: number): Observable<void> =>
        itemTypesService.deleteCompanyItemType(companyId, itemTypeId)

    super('name', Constants.localStorageKeys.itemTypesTable, deleteFunction, utilsService, snackService, dialogService)


    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(userReference => {
            this.userReference = userReference
            return this.itemTypesService.getAllCompanyItemTypes(this.userReference.companyId, this.fetchParams)
          }),
          switchMap(itemTypes => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<ItemTypeData>(itemTypes.list)
            this.entityList.push(...itemTypes.list)
            this.numEntities = itemTypes.num
            return of(itemTypes.list)
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i tipi articolo');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())

    this.displayedColumns = [
      "name",
      "description",
      "action",
    ]

  }


  addOption() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Tipo articolo',
        componentData: {companyId: this.userReference.companyId},
        component: ItemTypesFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: () => this.snackService.success('Tipo articolo creato'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  editOption(itemTypeId: number) {
    this.itemTypesService.getCompanyItemType(this.userReference.companyId, itemTypeId)
      .subscribe(itemType => {
        let readonly = !hasPermissionOnEntity(this.userReference.user, {
          accessEntity: this.accessEntities.items,
          accessLevel: this.accessLevels.editor
        }, itemType)
        this.dialogService.open(GeneralDialogComponent, {
          data: {
            dialogTitle: readonly ? `DETTAGLIO – ${itemType.name}` : `MODIFICA – ${itemType.name}`,
            componentData: {entity: itemType, companyId: this.userReference.companyId, readOnly: readonly},
            component: ItemTypesFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Tipo articolo aggiornato');
            },
            error: () => {
              this.snackService.success('Impossibile aggiornare il tipo articolo');
            },
          })
      })
  }
}
