<ng-template #loadingGeneral>
    <mat-spinner class="center"></mat-spinner>
</ng-template>


<div fxLayout="column">
    <h1 class="font-light">{{shop?.id ? (shop.name + ' – ORDINI') : ("Ordini dell'azienda")}}</h1>
    <ng-container *ngIf="dataSource && !loading else loadingGeneral">

        <aw-actions-bar
                (deleteElements)="deleteEntities($event, userReference.companyId, shopId ?? shopId)"
                (load)="loadOrderSaleResults($event, observable$)"
                (openCreateForm)="addOrder()"
                [accessEntity]="shopId ? null : accessEntities.sellOrderShops"
                [displayedColumns]="displayedColumns"
                [isAllSelected]="isAllSelected()"
                [isDeletable]="isDeleteActionButtonVisibile()"
                [parentEntity]="shopId ? {id: shopId} : null"
                [requiredCreateAccessLevels]="requiredCreateAccessLevels"
                [requiredDeleteAccessLevels]="requiredDeleteAccessLevels"
                [selectVisible]="selectVisible"
                [selection]="selection"
                [searchableFields]="searchableFields"
        >
        </aw-actions-bar>


        <div class="my-2" fxLayout="row">


            <mat-button-toggle-group aria-label="Filtri per data" name="deliveryFilter" value="all">

                <mat-button-toggle (click)="getOverdueOrders()"
                                   *ngIf="overdueOrdersCount > 0"
                                   [matTooltipPosition]="'above'"
                                   [matTooltip]="'Alcuni ordini non sono stati gestiti prima della data di consegna'"
                                   class="overdue-toggle"
                                   value="tomorrow">
                    <mat-icon color="warn">warning</mat-icon>
                    Ordini non consegnati
                </mat-button-toggle>

                <mat-button-toggle (click)="resetDisplayedColumnsToLocalStorage();getAllButCompletedAndCanceled()"
                                   value="all"
                >
                    Aperti
                </mat-button-toggle>

                <mat-button-toggle (click)="resetDisplayedColumnsToLocalStorage();getOrdersToDeliverToday()"
                                   value="today">In
                    consegna OGGI
                </mat-button-toggle>

                <mat-button-toggle (click)="resetDisplayedColumnsToLocalStorage();getOrdersToDeliverTomorrow()"
                                   value="tomorrow">In consegna DOMANI
                </mat-button-toggle>

                <mat-button-toggle
                        (click)="resetDisplayedColumnsToLocalStorage();getStatusBasedOrders(orderStates.completed)"
                        value="completed">COMPLETATI
                </mat-button-toggle>

                <mat-button-toggle
                        (click)="resetDisplayedColumnsToLocalStorage();getStatusBasedOrders(orderStates.failed)"
                        value="cancelled">ANNULLATI
                </mat-button-toggle>

                <mat-button-toggle
                        (click)="resetDisplayedColumnsToLocalStorage();getPaymentStatusBasedOrders([paymentStates.paid])"
                        value="completed">PAGATI
                </mat-button-toggle>

                <mat-button-toggle
                        (click)="resetDisplayedColumnsToLocalStorage();getPaymentStatusBasedOrders([paymentStates.advance, paymentStates.toBePaid])"
                        value="cancelled">NON PAGATI
                </mat-button-toggle>


            </mat-button-toggle-group>

            <div fxFlex></div>

            <button (click)="openLegendStatusesDialog()" class="border border-accent mr-1" color="accent" mat-button>
                Legenda
                stati
            </button>

            <!--      todo: finisci questa roba-->
                  <button (click)="clearFilters()" class="border border-warn mr-1" color="warn" mat-button>Pulisci filtri
                  </button>

        </div>


        <table
                (matSortChange)="loadResults($event, observable$)"
                [dataSource]="dataSource"
                class="w-100 mat-elevation-z2"
                mat-table
                matSort
                matSortDirection="asc"
                multiTemplateDataRows>

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th *matHeaderCellDef mat-header-cell>
                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td *matCellDef="let row" mat-cell>
                    <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                                  (click)="$event.stopPropagation()"
                                  *ngIf="!disableDeletionIfContentNotUpdated(row)"
                                  [checked]="selection.isSelected(row)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Shop Name Column -->
            <ng-container *ngIf="!shop?.id" matColumnDef="shopName">
                <th *matHeaderCellDef mat-header-cell>
                    <aw-removable-column (removeColumn)="removeColumn('shopName')"
                                         headerName="Punto vendita"></aw-removable-column>
                </th>
                <td *matCellDef="let element" mat-cell>{{element.shopName ? element.shopName : "NON ASSEGNATO"}} </td>
            </ng-container>

            <!-- DELIVERY Note Column -->
            <ng-container matColumnDef="deliveryNotes">
                <th *matHeaderCellDef class="hide-mobile" mat-header-cell>
                    <aw-removable-column (removeColumn)="removeColumn('deliveryNotes')"
                                         headerName="Note Consegna"></aw-removable-column>

                </th>
                <!--        todo: inline style to get the highest priority. class "normal-wrap" is overridden by angular classes-->


                <td *matCellDef="let element"
                    [style]="{'white-space': 'normal!important', 'overflow-wrap': 'break-word'}"
                    class="hide-mobile p-2"
                    mat-cell>

                    <ng-container
                            *ngIf="element.deliveryNotes != null && element.deliveryNotes.length > 10 && !isDeliveryNotesExpanded(element.id)">
                        {{element.deliveryNotes.substring(0, 10).trimEnd() + '...'}}
                        <div (click)="$event.stopPropagation(); manageExpandedDeliveryNotes(element.id)"
                             class="color-accent underline cursor-pointer">Mostra tutto
                        </div>
                    </ng-container>

                    <ng-container
                            *ngIf="element.deliveryNotes != null && element.deliveryNotes.length > 10 && isDeliveryNotesExpanded(element.id)">
                        {{element.deliveryNotes}}
                        <div (click)="$event.stopPropagation(); manageExpandedDeliveryNotes(element.id)"
                             class="color-accent underline cursor-pointer">Nascondi
                        </div>
                    </ng-container>

                    <ng-container *ngIf="element.deliveryNotes != null && element.deliveryNotes.length <= 10">
                        {{element.deliveryNotes}}
                    </ng-container>

                </td>
            </ng-container>

            <!-- Delivery Company Column  -->
            <ng-container matColumnDef="deliveryCompanyId">
                <th *matHeaderCellDef mat-header-cell>
                    <aw-removable-column (removeColumn)="removeColumn('deliveryCompanyId')"
                                         headerName="Corriere"></aw-removable-column>
                </th>
                <td *matCellDef="let element"
                    mat-cell>{{element.deliveryCompany ? element.deliveryCompany.name : "Non specificato"}} </td>
            </ng-container>

            <!-- Delivery Time  Column  -->
            <ng-container matColumnDef="deliveryTimeSlotId">
                <th *matHeaderCellDef mat-header-cell>
                    <aw-removable-column (removeColumn)="removeColumn('deliveryTimeSlotId')"
                                         headerName="Fascia Oraria"></aw-removable-column>
                </th>
                <td *matCellDef="let element"
                    mat-cell>{{element.deliveryTimeSlot ? element.deliveryTimeSlot.name : "Non specificato"}} </td>
            </ng-container>

            <!-- Customer Name Column -->
            <ng-container matColumnDef="customerName">
                <th *matHeaderCellDef mat-header-cell>Cliente Mittente</th>
                <td *matCellDef="let element" mat-cell>

                    <p>{{element.customerName ? element.customerName : "NON ASSEGNATO"}}</p>
                    <p>{{element.customerPhone && element.customerPhone ?? element?.customerPhone}}</p>
                    <p>{{element.customerEmail && element.customerEmail ?? element?.customerEmail}}</p>
                </td>


            </ng-container>


            <!-- Final price Column -->
            <ng-container matColumnDef="finalPrice">
                <th *matHeaderCellDef mat-header-cell>
                    <aw-removable-column (removeColumn)="removeColumn('finalPrice')"
                                         headerName="Prezzo"></aw-removable-column>
                </th>
                <td *matCellDef="let element"
                    mat-cell> {{element.finalPrice ? (element.finalPrice | currency:'EUR') : (element.calculatedPrice | currency:'EUR')}} </td>
            </ng-container>

            <!-- Shipping address Column -->
            <ng-container matColumnDef="shippingAddress">
                <th *matHeaderCellDef class="hide-mobile" mat-header-cell>
                    <aw-removable-column (removeColumn)="removeColumn('shippingAddress')"
                                         headerName="Indirizzo consegna"></aw-removable-column>
                </th>
                <td *matCellDef="let element" class="hide-mobile" mat-cell>
                    <div *ngIf="element.recipientName || element?.recipientSurname">
                        {{element.recipientName}} {{element?.recipientSurname ?? ""}}
                    </div>
                    <div>
                        <!-- todo Mao: prima di tutto, l'indentazione. Seconda cosa, non era meglio fare una funzione che costruisce l'indirizzo e la invochi?  tipo "getShippingAddress: () => string"           -->
                        <p>{{element.shippingInformation && element.shippingInformation.address ? element?.shippingInformation?.address + ', ' + element?.shippingInformation?.city + ', ' + element?.shippingInformation?.zipCode : 'In negozio'}}</p>
                        <p>{{element.shippingInformation && element.shippingInformation.phone ?? element?.shippingInformation?.phone}}</p>
                    </div>
                </td>
            </ng-container>

            <!-- Delivery Date Column -->
            <ng-container matColumnDef="deliveryDate">
                <th *matHeaderCellDef class="hide-mobile" mat-header-cell>
                    <aw-date-filter (onFilter)="filterByDeliveryDate($event)"
                                    (onResetFilter)="resetDeliveryDateFilter()"
                                    [initialState]="{startDate: filterObject.deliveryFrom, endDate: filterObject.deliveryTo}"
                                    (startDateChange)="filterObject.deliveryFrom = $event"
                                    (endDateChange)="filterObject.deliveryTo = $event"
                                    headerName="Data consegna" [removable]="true"
                                    (removeColumn)="removeColumn('deliveryDate')"></aw-date-filter>

                    <!--          Data consegna-->
                </th>
                <td *matCellDef="let element" class="hide-mobile" mat-cell>
                    {{element.deliveryDate !== null ? (element.deliveryDate | date: 'EEEE, dd/MM/yyyy') : 'Data non specificata'}}
                </td>
            </ng-container>

            <!-- Day of delivery date Column -->
            <ng-container matColumnDef="deliveryDay">
                <th *matHeaderCellDef mat-header-cell>
                    <aw-removable-column (removeColumn)="removeColumn('deliveryDay')"
                                         headerName="Giorno di consegna"></aw-removable-column>
                </th>
                <td *matCellDef="let element" mat-cell>
                    <mat-chip-list>
                        <mat-chip [ngStyle]="{'background-color': getColorForDay(element.deliveryDate)}">
                            {{element.deliveryDate !== null ? ((element.deliveryDate | date: 'EEEE') | titlecase) : 'Non disponibile'}}
                        </mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <!-- Deadline Date Column -->
            <ng-container matColumnDef="deadlineDate">
                <th *matHeaderCellDef class="hide-mobile" mat-header-cell>
                    <aw-date-filter (onFilter)="filterByDeadlineDate($event)"
                                    (onResetFilter)="resetDeadlineDateFilter()"
                                    [initialState]="{startDate: filterObject.deadlineFrom, endDate: filterObject.deadlineTo}"
                                    (startDateChange)="filterObject.deadlineFrom = $event"
                                    (endDateChange)="filterObject.deadlineTo = $event"
                                    headerName="Scadenza prevista" [removable]="true"
                                    (removeColumn)="removeColumn('deadlineDate')"></aw-date-filter>
                </th>
                <td *matCellDef="let element" class="hide-mobile" mat-cell>
                    {{element.deadlineDate !== null ? (element.deadlineDate | date: 'EEEE, dd/MM/yyyy') : 'Data non specificata'}}        </td>
            </ng-container>

            <!-- Note Column -->
            <ng-container matColumnDef="note">
                <th *matHeaderCellDef class="hide-mobile" mat-header-cell>
                    <aw-removable-column (removeColumn)="removeColumn('note')"
                                         headerName="Note"></aw-removable-column>

                </th>
                <!--        todo: inline style to get the highest priority. class "normal-wrap" is overridden by angular classes-->


                <td *matCellDef="let element"
                    [style]="{'white-space': 'normal!important', 'overflow-wrap': 'break-word'}"
                    class="hide-mobile p-2"
                    mat-cell>

                    <ng-container *ngIf="element.note.length > 20 && !isNoteExpanded(element.id)">
                        {{element.note.substring(0, 20).trimEnd() + '...'}}
                        <div (click)="$event.stopPropagation(); manageExpandedNote(element.id)"
                             class="color-accent underline cursor-pointer">Mostra tutto
                        </div>
                    </ng-container>

                    <ng-container *ngIf="element.note.length > 20 && isNoteExpanded(element.id)">
                        {{element.note}}
                        <div (click)="$event.stopPropagation(); manageExpandedNote(element.id)"
                             class="color-accent underline cursor-pointer">Nascondi
                        </div>
                    </ng-container>

                    <ng-container *ngIf="element.note.length <= 20">
                        {{element.note}}
                    </ng-container>

                </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th *matHeaderCellDef mat-header-cell>

                    <aw-column-filter #columnFilter
                                      (onFilter)="filterByOrderStatus($event)"
                                      (onResetFilter)="resetOrderStatusFilter()"
                                      [options]="availableOrderStatuses"
                                      [(filterField)]="this.filterObject.statuses"
                                      [placeholder]="'Stati ordine'"
                                      [removable]="true" (removeColumn)="removeColumn('status')"
                                      headerName="Stato"></aw-column-filter>
                </th>
                <td *matCellDef="let element" mat-cell>

                    <mat-chip-list>
                        <mat-chip
                                (click)="$event.stopPropagation();editOrder(element.id, getConditionalStatus(element.status))"
                                [ngStyle]="{'background-color': getOrderStatusColor(element.status)}">
                            {{element.status | orderStatus}}
                        </mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <!-- Payment Status Column -->
            <ng-container matColumnDef="paymentStatus">
                <th *matHeaderCellDef mat-header-cell>
                    <aw-column-filter
                            (onFilter)="filterByPaymentStatus($event)" (onResetFilter)="resetOrderPaymentStatusFilter()"
                            [options]="availablePaymentStatuses"
                            [(filterField)]="this.filterObject.paymentStatuses"
                            [placeholder]="'Saldo'"
                            [removable]="true" (removeColumn)="removeColumn('paymentStatus')"
                            headerName="Saldo"></aw-column-filter>
                </th>
                <td *matCellDef="let element" mat-cell>

                    <div fxLayout="column">
                        <mat-chip-list>
                            <mat-chip

                                    (click)="$event.stopPropagation();editOrderPaymentStatus(element.id, getConditionalPaymentStatus(element.paymentStatus))"
                                    [ngStyle]="{'background-color': getPaymentStatusColor(element.paymentStatus)}">
                                {{element.paymentStatus ? (element.paymentStatus | orderPaymentStatus) : "NON SELEZIONATO"}}
                            </mat-chip>
                        </mat-chip-list>
                        <small *ngIf="element.paidPrice"
                               class="mt-1"><strong>Acconto</strong>: {{element.paidPrice | currency:'EUR'}}</small>
                    </div>
                </td>
            </ng-container>


            <!-- Action -->
            <ng-container matColumnDef="action">
                <th *matHeaderCellDef class="hide-mobile" mat-header-cell>
                    <aw-insert-columns-selector (insertColumns)="insertColumns($event)"
                                                [hiddenColumns]="getHiddenColumns()"></aw-insert-columns-selector>
                </th>
                <td *matCellDef="let element" class="hide-mobile" fxLayoutGap="8" mat-cell>

                    <button (click)="manageExpandedRows(element.id);$event.stopPropagation()"
                            [disabled]="disabled || element.content?.length == 0" mat-icon-button
                            matTooltip="Mostra articoli">

                        <mat-icon *ngIf="!isContentExpanded(element.id)">navigate_next</mat-icon>
                        <mat-icon *ngIf="isContentExpanded(element.id)">expand_more</mat-icon>

                    </button>

                    <button
                            (click)="navigateToOrder(element.id);$event.preventDefault();$event.stopPropagation();"
                            [awDisableElement]="{
              selected: shopId ? [{id: shopId!}] : undefined,
              accessLevels: [
                {
                  accessEntity: accessEntities.sellOrderShops, accessLevel: accessLevels.reader
                }
              ]}"
                            color="accent"
                            mat-icon-button
                            matTooltip="Vai al dettaglio"
                            matTooltipPosition="above"
                    >

                        <mat-icon>info</mat-icon>

                    </button>

                    <button #edit
                            (click)="editOrder(element.id, false);$event.preventDefault();$event.stopPropagation();"
                            *awAccess="{selected: this.shopId ? {id: this.shopId!} : undefined, accessLevel:{accessEntity: accessEntities.sellOrderShops, accessLevel: accessLevels.editor}}"
                            color="accent"
                            mat-icon-button
                            matTooltip="Modifica"
                            matTooltipPosition="above"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>

                    <button #delete
                            (click)="deleteEntities([element.id], userReference.companyId, shopId ?? null);$event.preventDefault();$event.stopPropagation();"
                            *awAccess="{selected: this.shopId ? {id: this.shopId!} : undefined, accessLevel:{accessEntity: accessEntities.sellOrderShops, accessLevel: accessLevels.admin}}"
                            [awDisableElement]="{
                    selected: [this.shopId ? {id: this.shopId!} : undefined],
                    condition: disableDeletionIfContentNotUpdated(element),
                    accessLevels: requiredDeleteAccessLevels,
                  component: delete}"
                            color="warn"
                            mat-icon-button
                            matTooltip="Elimina"
                            matTooltipPosition="above"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>

                </td>

            </ng-container>


            <ng-container matColumnDef="expandedOrder">
                <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="p-1" mat-cell>
                    <aw-order-content-table *ngIf="expandedEntities.includes(element.id)"
                                            [sellOrder]="element"
                                            [shopId]="shopId"
                    >

                    </aw-order-content-table>
                </td>
            </ng-container>


            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr (click)="editOrder(row.id, false)" *matRowDef="let row; columns:  displayedColumns" mat-row></tr>
            <tr *matRowDef="let row; columns: ['expandedOrder']"
                [fxShow]="expandedEntities.includes(row.id)" mat-row></tr>


            <tr *matNoDataRow class="mat-row">
                <td [colSpan]="displayedColumns.length" class="mat-cell text-center">
                    Nessun ordine trovato
                </td>
            </tr>


        </table>

        <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
            <mat-progress-spinner
                    color="primary"
                    mode="indeterminate"
            >
            </mat-progress-spinner>
        </mat-card>

    </ng-container>

    <mat-paginator #paginator
                   (page)="loadResults($event, observable$)"
                   (selectionchange)="logMe($event)"
                   *ngIf="dataSource && numEntities > 0"
                   [length]="numEntities"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="pageSize"
                   class="mat-elevation-z1"
                   showFirstLastButtons
    >
    </mat-paginator>
</div>
