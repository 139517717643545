import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Constants} from "../../../utils/constants";
import {AccessPermissionData} from "../../../interfaces/permission";
import {AccessLevel, EntityIdentifier} from "../../../utils/global-functions-and-types";
import {EMPTY, NEVER, Observable, of, switchMap, tap} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {WarehousesService} from "../../../services/warehouses.service";
import {ShopsService} from "../../../services/shops.service";
import {PermissionsService} from "../../../services/permissions.service";
import {DialogsService} from "../../../services/dialogs.service";
import {SnackService} from "../../../services/snack.service";
import {UtilsService} from "../../../services/utils.service";
import {catchError} from "rxjs/operators";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";

@Component({
  selector: ' aw-profile-permissions-table',
  templateUrl: './profile-permissions-table.component.html',
  styleUrls: ['./profile-permissions-table.component.scss']
})
export class ProfilePermissionsTableComponent extends BaseTableComponent<AccessPermissionData> implements OnInit {

  @Input() globalUserAuthority: AccessLevel
  @Output() onUserPermissionChange = new EventEmitter<void>();
  _entityName!: EntityIdentifier
  _userId = 0
  initialEntities = new Map<number, AccessLevel>()
  permissionsToEdit: AccessPermissionData[] = []
  initialRole: AccessLevel = undefined
  multiplePermissionValue: undefined
  showSaveButton = false
  saving = false
  saveRole$: Observable<any>
  removeRole$: Observable<any>
  setPermissions$: Observable<any>

  _accessEntities: AccessPermissionData[] = []

  accessLevelOrder = Constants.accessLevelOrder;



  @Input() set accessPermissions(accessEntities: AccessPermissionData[]) {
    this._accessEntities = accessEntities
    this.dataSource = new MatTableDataSource<AccessPermissionData>(accessEntities)
  }

  constructor(
    public warehousesService: WarehousesService,
    public shopsService: ShopsService,
    private permissionService: PermissionsService,
    protected override dialogService: DialogsService,
    public override snackService: SnackService,
    protected override utilsService: UtilsService,
  ) {
    super('name', Constants.localStorageKeys.profilePermissionsTable, () => new Observable<any>(), utilsService, snackService, dialogService)

    this.observable$ =
      of(true)
        .pipe(
          switchMap(() => this.userReference$),
          switchMap(() => of([] as AccessPermissionData[]))
        )

    this.saveRole$ =
      of(true)
        .pipe(
          tap(() => this.saving = true),
          switchMap(() => {
            return this._role === undefined ?
              this.permissionService.removeRolesFromUser(this.userReference.companyId, [{
                userId: this._userId,
                entity: this._entityName
              }]) :
              this.permissionService.setRolesToUser(this.userReference.companyId, [{
                userId: this._userId,
                entity: this._entityName,
                userAuthority: this._role
              }])
          }),
          switchMap(() => this.observable$),
          catchError((err) => {
            this.saving = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile aggiornare il ruolo');
            return EMPTY;
          })
        )

    this.removeRole$ =
      of(true)
        .pipe(
          tap(() => this.saving = true),
          switchMap(() => this.permissionService.removeRolesFromUser(this.userReference.companyId, [{
            userId: this._userId,
            entity: this._entityName
          }])),
          switchMap(() => this.observable$),
          catchError((err) => {
            this.saving = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile rimuovere il ruolo');
            return EMPTY;
          }))


    this.setPermissions$ =
      of(true)
        .pipe(
          tap(() => this.saving = true),
          switchMap(() => {
            return this.permissionService.setPermissionsToUser(this.userReference.companyId, this.permissionsToEdit.map(value => {
              return {
                userId: this._userId,
                entity: this._entityName,
                userAuthority: value.userAuthority!,
                entityId: value.entityId
              }
            }))
          }),
          switchMap(() => this.observable$),
        )

    this.dataSource = new MatTableDataSource<AccessPermissionData>()

    this.displayedColumns = [
      'name',
      'permission',
      'action',
    ]

    this.subs.push(this.observable$.subscribe())


  }

  @Input() _role: AccessLevel = undefined

  @Input() set role(role: AccessLevel) {
    if (role) {
      this._role = role
      this.initialRole = role
    }
  }

  @Input() set user(entity: { entityName: EntityIdentifier, userId: number }) {
    this._entityName = entity.entityName
    this._userId = entity.userId
  }



  ngOnInit(): void {
    this.setInitialEntities()
  }

  reset() {
    this._role = this.initialRole
    this.globalUserAuthority = undefined
    this.multiplePermissionValue = undefined
    this._accessEntities?.forEach(value => value.userAuthority = this.initialEntities.get(value.entityId))
    this.showSaveButton = false
  }


  isDeletable(accessPermission: AccessPermissionData) {
    return this.initialEntities.get(accessPermission.entityId) != undefined
  }

  isSelectable() {
    return !this.showSaveButton && !!this._accessEntities.find(accessEntity => accessEntity.userAuthority != undefined)
  }

  shouldISave() {
    this.permissionsToEdit = this._accessEntities?.filter(value => value.userAuthority != this.initialEntities.get(value.entityId))
    this.showSaveButton = this.permissionsToEdit.length > 0 || this._role !== this.initialRole
  }

  setMultiplePermission($event: any) {
    this._accessEntities?.forEach(value => {
      value.userAuthority = $event.value
    })
    this.dataSource.data.forEach(value => {
      value.userAuthority = $event.value
    })

    this.shouldISave()
  }


  saveRoleAndPermissions() {

    if (this.initialRole != this._role)
      this.saveRole$
        .subscribe({
          next: () => {
            this.initialRole = this._role
            this.saving = false
            this.showSaveButton = false
            this.snackService.success('Ruolo aggiornato')
          },
        })

    if (this.permissionsToEdit)
      this.setPermissions$
        .subscribe({
          next: () => {
            this.saving = false
            this.showSaveButton = false
            this.globalUserAuthority = undefined
            this.setInitialEntities()
            this.snackService.success('Permessi aggiornati')

          },
          error: err => {
            this.saving = false
            this.snackService.error('Impossibile aggiornare i permessi')
          },
        })

  }

  removeRole() {
    this.dialogService.confirm('Conferma rimozione', "Sei sicuro di voler rimuovere questo ruolo all'utente?")
      .pipe(
        switchMap(value => value ? this.removeRole$ : NEVER),
        tap(() => {
          this.saving = false
          this.onUserPermissionChange.emit()
          this.snackService.success('Ruolo rimosso')
        }),
        catchError((err) => {
          this.saving = false
          this.snackService.error('Impossibile rimuovere il ruolo')
          return EMPTY
        })
      )
      .subscribe()
  }


  deleteEntity(accessPermissionData?: AccessPermissionData) {
    this.dialogService.confirm('Conferma rimozione', 'Sei sicuro di voler rimuovere i permessi selezionati?')
      .pipe(
        tap(value => {
          if (value) {
            if (!accessPermissionData)
              this.deletePermissions(this.selection.selected.filter(value => value.userAuthority))
            else if (accessPermissionData.userAuthority)
              this.deletePermissions([accessPermissionData].filter(value => value.userAuthority))
          }
        })).subscribe()
  }

  deletePermissions(accessPermissionData: AccessPermissionData[]) {

    of(true)
      .pipe(
        tap(() => this.saving = true),
        switchMap(() => {
          return this.permissionService.removePermissionsFromUser(this.userReference.companyId, accessPermissionData.map(value => {
            return {
              userId: this._userId,
              entity: this._entityName,
              entityId: value.entityId
            }
          }))
        }),
        switchMap(() => {
          this.saving = false
          this.onUserPermissionChange.emit()
          this.snackService.success('Permessi selezionati rimossi')
          return this.observable$
        }),
        catchError((err) => {
          this.saving = false
          this.snackService.error('Impossibile rimuovere i permessi selezionati')
          return EMPTY
        }))
      .subscribe()
  }


  private setInitialEntities() {
    this.initialEntities = new Map<number, AccessLevel>()
    this._accessEntities?.forEach(entity => this.initialEntities.set(entity.entityId, entity.userAuthority))
  }
}
