import {Pipe, PipeTransform} from '@angular/core';
import {Constants} from "../utils/constants";

@Pipe({
  name: 'saleStatus'
})
export class SaleStatusPipe implements PipeTransform {

  transform(status: string) {
    return (Constants.saleStatusesPipe as any)[Constants.saleStatusKeys()
      .find(k => (Constants.saleStatuses as any)[k] === status) as any];
  }
}
