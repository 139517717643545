import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./pages/login/login.component";
import {PublicContainerComponent} from "./containers/public-container/public-container.component";
import {PrivateContainerComponent} from "./containers/private-container/private-container.component";
import {AuthenticationGuard} from "./guards/authentication.guard";
import {CompaniesContainerComponent} from "./pages/companies/companies-container/companies-container.component";
import {ItemsContainerComponent} from './pages/items/items-container.component';
import {AccessGuard} from "./guards/access.guard";
import {ProfilesContainerComponent} from "./pages/profiles/profiles/profiles-container.component";
import {ProfileDetailsContainerComponent} from "./pages/profiles/profile-details/profile-details-container.component";
import {WarehousesContainerComponent} from "./pages/warehouses/warehouses-container.component";
import {WarehouseItemsPageComponent} from "./pages/warehouses-items/warehouse-items-page.component";
import {ShopDetailsContainerComponent} from "./pages/shops/shop-details/shop-details-container.component";
import {OrdersContainerComponent} from "./pages/orders/orders/orders-container.component";
import {UsersContainerComponent} from "./pages/users/users-container.component";
import {CompanyDetailsContainer} from "./pages/companies/company-details-container/company-details-container.component";
import {UserPermissionsContainerComponent} from "./pages/permissions/permissions/user-permissions-container.component";
import {ShopsContainerComponent} from "./pages/shops/shops/shops-container.component";
import {CustomerInfoContainerComponent} from "./pages/customers/customer-info/customer-info-container.component";
import {OrderDetailsContainerComponent} from "./pages/orders/order-details/order-details-container.component";
import {DashboardContainerComponent} from "./pages/dashboard/dashboard-container.component";
import {
  WarehousePermissionsContainerComponent
} from "./pages/permissions/warehouse-permissions/warehouse-permissions-container.component";
import {
  ShopsPermissionsContainerComponent
} from "./pages/permissions/shops-permissions/shops-permissions-container.component";
import {CustomersContainerComponent} from "./pages/customers/customers/customers-container.component";
import {OptionValuesContainerComponent} from "./pages/option-values/option-values-container.component";
import {Constants} from "./utils/constants";
import {RequiredAccessLevel} from "./classes/required-access-level";


const routes: Routes = [
  {
    path: '',
    component: PublicContainerComponent,
    canActivate: [AuthenticationGuard], data: {blockOn: 'authenticated', redirectOnBlock: '/reserved'},
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent
      },
    ]
  },
  {
    path: 'reserved',
    // canActivate: [AngularFireAuthGuard], data: {authGuardPipe: () => redirectUnauthorizedTo(['login'])},
    canActivate: [AuthenticationGuard], data: {blockOn: 'anonymous', redirectOnBlock: '/login'},
    component: PrivateContainerComponent,
    children: [
      {
        path: '',
        component: DashboardContainerComponent
      },
      // region utenti AW
      // User must be 'companies_owner' to access AW paths
      {
        canActivate: [AccessGuard],
        data: {
          requiredAccess: [{
            accessEntity: Constants.accessEntities.companies,
            accessLevel: Constants.accessLevels.owner,
            needsCompany: false,
            checkCompany: true
          } as RequiredAccessLevel]
        },
        path: 'companies',
        children: [
          {
            canActivate: [AccessGuard],
            data: {
              requiredAccess: [{
                accessEntity: Constants.accessEntities.companies,
                accessLevel: Constants.accessLevels.admin,
                needsCompany: false,
                checkCompany: true
              } as RequiredAccessLevel]
            },
            path: '',
            component: CompaniesContainerComponent
          },
          {
            path: ':companyId',
            children: [
              {
                path: 'users',
                children: [
                  {
                    canActivate: [AccessGuard],
                    data: {
                      requiredAccess: [{
                        accessEntity: Constants.accessEntities.users,
                        accessLevel: Constants.accessLevels.reader,
                        needsCompany: false,
                        checkCompany: true
                      } as RequiredAccessLevel]
                    },
                    path: '', component: UsersContainerComponent
                  },
                  {
                    canActivate: [AccessGuard],
                    data: {
                      requiredAccess: [{
                        accessEntity: Constants.accessEntities.users,
                        accessLevel: Constants.accessLevels.editor,
                        needsCompany: false,
                        checkCompany: true
                      } as RequiredAccessLevel]
                    }, path: ':userId/permissions', component: UserPermissionsContainerComponent
                  }
                ]
              },
              {
                canActivate: [AccessGuard],
                data: {
                  requiredAccess: [{
                    accessEntity: Constants.accessEntities.warehouses,
                    accessLevel: Constants.accessLevels.reader,
                    needsCompany: false,
                    checkCompany: true
                  } as RequiredAccessLevel]
                },
                path: 'warehouses',
                children: [
                  {
                    path: '',
                    component: WarehousesContainerComponent,
                  },
                  {
                    path: ':warehouseId',
                    children: [
                      {
                        path: '',
                        component: WarehouseItemsPageComponent,
                      },
                      {
                        path: 'permissions',
                        component: WarehousePermissionsContainerComponent,
                      }
                    ]
                  },

                ]
              },
              {
                canActivate: [AccessGuard],
                data: {
                  requiredAccess: [{
                    accessEntity: Constants.accessEntities.customers,
                    accessLevel: Constants.accessLevels.reader,
                    needsCompany: false,
                    checkCompany: true
                  } as RequiredAccessLevel]
                },
                path: 'customers',
                children: [
                  {
                    path: '',
                    component: CustomersContainerComponent
                  },
                  {
                    path: ':customerId',
                    component: CustomerInfoContainerComponent
                  }
                ]
              },
              {
                path: 'details',
                children: [
                  {
                    path: '',
                    component: CompanyDetailsContainer,

                  },
                ]
              },
              {
                path: 'shops',
                children: [
                  {
                    path: '',
                    component: ShopsContainerComponent
                  },
                  {
                    path: ':shopId',
                    children: [
                      {
                        path: '',
                        component: ShopDetailsContainerComponent,
                      },
                      {
                        path: 'permissions',
                        component: ShopsPermissionsContainerComponent,
                      },
                      {
                        path: 'orders',
                        children: [
                          {
                            path: ':orderId',
                            component: OrderDetailsContainerComponent
                          }
                        ]
                      },
                    ]
                  }
                ]
              },
              {
                path: 'orders',
                children: [
                  {
                    path: '',
                    component: OrdersContainerComponent
                  },
                  {
                    path: ':orderId',
                    component: OrderDetailsContainerComponent
                  }
                ]
              },
              {
                path: 'profiles',
                children: [
                  {
                    path: '',
                    component: ProfilesContainerComponent
                  },
                  {
                    path: ':profileId',
                    component: ProfileDetailsContainerComponent
                  },
                  {
                    path: 'new',
                    component: ProfileDetailsContainerComponent
                  }
                ]
              },
              {
                path: 'options',
                children: [
                  {
                    path: ':optionId',
                    component: OptionValuesContainerComponent
                  }
                ]
              },
              {
                path: 'items',
                component: ItemsContainerComponent
              },
            ]
          }
        ]
      },
      //endregion utenti AW

      // region utenti normali
      {
        canActivate: [AccessGuard],
        data: {
          requiredAccess: [
            {
              accessEntity: Constants.accessEntities.users,
              accessLevel: Constants.accessLevels.reader
            },
          ]
        },
        path: 'users',
        children: [
          {
            path: '', component: UsersContainerComponent
          },
          {
            path: ':userId/permissions', component: UserPermissionsContainerComponent
          }
        ]
      },


      {
        canActivate: [AccessGuard],
        data: {
          requiredAccess: [{
            accessEntity: Constants.accessEntities.warehouses,
            accessLevel: Constants.accessLevels.reader,
            checkCompany: true
          } as RequiredAccessLevel]
        },
        path: 'warehouses',
        children: [
          {
            path: '',
            component: WarehousesContainerComponent,
          },
          {
            path: ':warehouseId',
            children: [
              {
                path: '',
                component: WarehouseItemsPageComponent,
              },
              {
                path: 'permissions',
                component: WarehousePermissionsContainerComponent,
              }
            ]
          },

        ]
      },
      {
        canActivate: [AccessGuard],
        data: {
          requiredAccess: [{
            accessEntity: Constants.accessEntities.customers,
            accessLevel: Constants.accessLevels.reader,
            checkCompany: true
          } as RequiredAccessLevel]
        },
        path: 'customers',
        children: [
          {
            path: '',
            component: CustomersContainerComponent
          },
          {
            path: ':customerId',
            component: CustomerInfoContainerComponent
          }
        ]
      },
      {
        canActivate: [AccessGuard],
        data: {
          requiredAccess: [{
            accessEntity: Constants.accessEntities.companies,
            accessLevel: Constants.accessLevels.reader,
            checkCompany: true
          } as RequiredAccessLevel,
            {
              accessEntity: Constants.accessEntities.items,
              accessLevel: Constants.accessLevels.reader,
              checkCompany: true
            },
            {
              accessEntity: Constants.accessEntities.deliveryCompanies,
              accessLevel: Constants.accessLevels.reader,
              checkCompany: true
            },
            {
              accessEntity: Constants.accessEntities.customers,
              accessLevel: Constants.accessLevels.reader,
              checkCompany: true
            }
          ]
        },
        path: 'details',
        children: [
          {
            path: '',
            component: CompanyDetailsContainer

          },

          {
            canActivate: [AccessGuard],
            data: {
              requiredAccess: [
                {
                  accessEntity: Constants.accessEntities.customers,
                  accessLevel: Constants.accessLevels.reader,
                  checkCompany: true
                }
              ]
            },
            path: 'customer-detail',
            children: [
              {
                path: ':customerId',
                component: CustomerInfoContainerComponent,
              }
            ]
          }
          // {
          //   path: 'customer-detail',
          //   component: CustomerInfoPageComponent
          // },

        ]
      },
      {
        canActivate: [AccessGuard],
        data: {
          requiredAccess: [
            {
              accessEntity: Constants.accessEntities.items,
              accessLevel: Constants.accessLevels.reader,
              checkCompany: true
            },
          ]
        },
        path: 'items',
        component: ItemsContainerComponent
      },
      {
        canActivate: [AccessGuard],
        data: {
          requiredAccess: [
            {
              accessEntity: Constants.accessEntities.shops,
              accessLevel: Constants.accessLevels.reader,
              checkCompany: true
            },
          ]
        },
        path: 'shops',
        // component: ShopsPageComponent,
        children: [
          {
            path: '',
            component: ShopsContainerComponent
          },
          {
            path: ':shopId',
            children: [
              {
                path: '',
                component: ShopDetailsContainerComponent
              },
              {
                path: 'permissions',
                component: ShopsPermissionsContainerComponent,
              },
              {
                path: 'orders',
                children: [
                  {
                    path: ':orderId',
                    component: OrderDetailsContainerComponent
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        canActivate: [AccessGuard],
        data: {
          requiredAccess: [
            {
              accessEntity: Constants.accessEntities.sellOrderShops,
              accessLevel: Constants.accessLevels.reader,
              checkCompany: true
            },
          ]
        },
        path: 'orders',
        children: [
          {
            path: '',
            component: OrdersContainerComponent
          },
          {
            path: ':orderId',
            component: OrderDetailsContainerComponent
          }
        ]
      },
      {
        path: 'options',
        children: [
          {
            canActivate: [AccessGuard],
            data: {
              requiredAccess: [
                {
                  accessEntity: Constants.accessEntities.companies,
                  accessLevel: Constants.accessLevels.reader,
                  checkCompany: true
                },
              ]
            },
            path: ':optionId',
            component: OptionValuesContainerComponent
          }
        ]
      },
      {
        canActivate: [AccessGuard],
        data: {
          requiredAccess: [
            {
              accessEntity: Constants.accessEntities.profiles,
              accessLevel: Constants.accessLevels.reader,
              checkCompany: true
            },
          ]
        },
        path: 'profiles',
        children: [
          {
            path: '',
            component: ProfilesContainerComponent
          },
          {
            path: ':profileId',
            component: ProfileDetailsContainerComponent
          },
          {
            path: 'new',
            component: ProfileDetailsContainerComponent
          }
        ]
      },
      // endregion utenti normali

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
