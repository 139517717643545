import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {EventManager} from "@angular/platform-browser";
import {ResponsiveService} from "../../../services/responsive.service";


@Component({
  selector: 'tr[awSlidingRow][element][selectedElements]',
  templateUrl: './sliding-row.component.html',
  styleUrls: ['./sliding-row.component.scss'],
})
export class SlidingRowComponent implements OnInit {

  static swipedLeftId = -1;
  static swipingLeftId = -1;
  static disableClick = -1;
  static swipedElement: HTMLElement | undefined = undefined;
  @Input() element!: any;

  // TODO move outside. Here, only emit element selected (or delesected)
  @Input() selectedElements: any[] = [];
  @Output() selectedElementsChange = new EventEmitter<any[]>();

  @Output() click = new EventEmitter<void>();

  @ViewChild('template', {'static': true}) template!: TemplateRef<any>;
  @ViewChild('row', {'static': true}) rowEl!: ElementRef;

  currentMove = 0;

  constructor(
    private el: ElementRef,
    private hgp: EventManager,
    public responsiveService: ResponsiveService,
    private cref: ViewContainerRef,
  ) {
  }

  @HostListener('window:scroll', ['$event'])
  scrool(event: Event) {
    if (SlidingRowComponent.swipingLeftId != -1) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  ngOnInit(): void {
    this.cref.element.nativeElement.remove();
    this.cref.createEmbeddedView(this.template);
  }

  move($event: any) {
    if (this.responsiveService.isDesktop)
      return

    if (!this.selectedElements || this.selectedElements.length > 0 || SlidingRowComponent.swipedLeftId == this.element.id)
      return

    // const maxMove = 0.5 * window.innerWidth
    const delta = $event.deltaX < -this.responsiveService.maxSlideMove ? -this.responsiveService.maxSlideMove : $event.deltaX
    this.currentMove = -delta;
    if ($event.target.localName != 'tr') {
      $event.target.closest('tr')!.style.transform = `translateX(${delta}px)`;
    } else {
      $event.target.style.transform = `translateX(${delta}px)`;
    }
  }

  panStart(event: any) {

    if (this.responsiveService.isDesktop)
      return

    if (this.element.id == SlidingRowComponent.swipedLeftId || this.selectedElements.length > 0)
      return;

    this.unSwipe()

    SlidingRowComponent.swipingLeftId = this.element.id
    SlidingRowComponent.disableClick = this.element.id;

    if (event.target.localName != 'tr')
      SlidingRowComponent.swipedElement = event.target.closest('tr');
    else
      SlidingRowComponent.swipedElement = event.target

  }

  panEnd(event: any) {

    if (this.responsiveService.isDesktop)
      return

    if (this.selectedElements.length > 0)
      return;

    if (event.deltaX > 0)
      return;

    if (event.target.localName != 'tr') {
      SlidingRowComponent.swipedElement = event.target.closest('tr');
    } else {
      SlidingRowComponent.swipedElement = event.target
    }

    this.currentMove = this.responsiveService.maxSlideMove;

    SlidingRowComponent.swipedElement!.style.transform = `translateX(${-this.responsiveService.maxSlideMove}px)`;
    SlidingRowComponent.swipedLeftId = this.element.id;
    SlidingRowComponent.swipingLeftId = -1;
  }

  unSwipe() {
    if (SlidingRowComponent.swipedElement !== undefined) {
      SlidingRowComponent.swipedElement!.style.transform = `translateX(0)`;
      SlidingRowComponent.swipedElement = undefined;
      SlidingRowComponent.swipedLeftId = -1;
    }

  }


  rowClick() {


    if (SlidingRowComponent.disableClick == this.element.id) {
      SlidingRowComponent.disableClick = -1;
      return
    }

    if (SlidingRowComponent.swipedLeftId != -1) {
      this.unSwipe();
      return;
    }

    if (this.selectedElements.length > 0) {
      this.selectElement();
    } else {
      this.click.emit();
    }


  }

  press() {

    this.selectElement()
    SlidingRowComponent.disableClick = this.element.id
  }

  selectElement() {
    if (this.selectedElements.map(u => u.id).includes(this.element.id))
      this.selectedElementsChange.emit([...this.selectedElements.filter(u => u.id != this.element.id)])
    else
      this.selectedElementsChange.emit([...this.selectedElements, this.element])

  }

  public swipedLeftId() {
    return SlidingRowComponent.swipedLeftId;
  }

  public swipingLeftId() {
    return SlidingRowComponent.swipingLeftId;
  }

  public disableClick() {
    SlidingRowComponent.disableClick = this.element.id
  }

  public enableClick() {
    SlidingRowComponent.disableClick = -1
  }
}
