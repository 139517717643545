import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

import {UtilsService} from "../../../services/utils.service";
import {Observable} from "rxjs";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {DeliveryTimeSlotCreate, DeliveryTimeSlotData} from "../../../interfaces/delivery-time-slot";
import {DeliveryTimeSlotsService} from "../../../services/delivery-time-slots.service";

@Component({
  selector: 'aw-delivery-time-slots-form',
  templateUrl: './delivery-time-slots-form.component.html',
  styleUrls: ['./delivery-time-slots-form.component.scss']
})
export class DeliveryTimeSlotsFormComponent extends BaseFormComponent<DeliveryTimeSlotCreate, DeliveryTimeSlotData> {

  override _entity: DeliveryTimeSlotData = {} as DeliveryTimeSlotData

  @Input() companyId = -1

  @Input() set entity(value: DeliveryTimeSlotData) {
    this._entity = value
    this.formGroup.patchValue(this._entity)
  }

  constructor(
    public deliveryTimeSlotsService: DeliveryTimeSlotsService,
    public override utilsService: UtilsService) {

    let createFunction = (companyId: number, deliveryTimeSlot: DeliveryTimeSlotCreate):
      Observable<DeliveryTimeSlotData> =>
      deliveryTimeSlotsService.createDeliveryTimeSlot(companyId, deliveryTimeSlot)

    let updateFunction = (companyId: number, deliveryTimeSlotId: number, deliveryTimeSlot: DeliveryTimeSlotCreate):
      Observable<DeliveryTimeSlotData> =>
      deliveryTimeSlotsService.updateDeliveryTimeSlot(companyId, deliveryTimeSlotId, deliveryTimeSlot)

    super(createFunction, updateFunction, utilsService)

    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      name: new FormControl('', [Validators.required]),
    })

  }
}
