import {Component, Input} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Constants} from "../../../utils/constants";
import {ItemVariantDetail} from "../../../interfaces/item-variant";
import {ItemWarehouseDetail, ItemWarehouseMigrate, MigrationItemChoice} from "../../../interfaces/item-warehouse";
import {WarehouseData, WarehouseDetail} from "../../../interfaces/warehouse";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, of, switchMap, tap} from "rxjs";
import {UtilsService} from "../../../services/utils.service";
import {catchError} from "rxjs/operators";
import {FetchParams} from "../../../utils/global-functions-and-types";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {WarehousesService} from "../../../services/warehouses.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {MigrationChoicesPipe} from "../../../pipes/migration-choice.pipe";

@Component({
  selector: 'aw-stocks-migration-form',
  templateUrl: './stocks-migration-form.component.html',
  styleUrls: ['./stocks-migration-form.component.scss']
})
export class StocksMigrationFormComponent extends BaseFormComponent<MigrationItemChoice, { id: number }> {

  formGroupMigrationStocks: FormGroup
  isEditing = false
  loadingWarehouses = false
  loadingStocks = false
  loadingItems = false
  globalMaxQuantities = false
  generalDeletionFromOldWarehouse = false
  globalWarehouseTo?: WarehouseData
  migrationChoicesValues = Object.values(Constants.migrationChoices)
  warehouses: WarehouseData[] = []
  filteredWarehouses: WarehouseData[] = []
  warehouseItemsFrom: ItemWarehouseDetail[] = [];
  warehouseItemsFromChipList: ItemWarehouseDetail[] = [];
  warehouseFrom?: WarehouseDetail
  addingMigrationStock = false
  migrationWrappers: Wrapper[] = []
  items: ItemVariantDetail[] = []
  warehouses$: Observable<WarehouseData[]> = new Observable<WarehouseData[]>()
  // questa funzione ritorna un observable delle giacenze in base al warehouseId passato come parametro
  warehouseItemsFn: (warehouseId: number) => Observable<ItemWarehouseDetail[]>
  generalMigrationChoice?: MigrationItemChoice

  constructor(public migrationChoicesPipe: MigrationChoicesPipe, public formBuilder: FormBuilder, public warehousesService: WarehousesService, public snackService: SnackService, public dialogService: DialogsService, public router: Router, public activatedRoute: ActivatedRoute, protected override utilsService: UtilsService,) {
    super(() => new Observable<any>(), () => new Observable<any>(), utilsService)

    this.warehouseItemsFn = (warehouseId: number) => of(true)
      .pipe(tap(() => this.loadingStocks = true), switchMap(() => this.warehousesService.getWarehouseItems(this.userReference.companyId, warehouseId, {} as FetchParams)), switchMap((res) => {
        this.loadingStocks = false
        return of(res.list)
      }), catchError((err, caught) => {
        this.loadingStocks = false;
        if (!err.errors.includes('403')) this.snackService.error('Impossibile caricare le giacenze')
        return of([] as ItemWarehouseDetail[]);
      }))

    this.warehouses$ = of(true)
      .pipe(tap(() => this.loadingWarehouses = true), switchMap(() => {
        return this.warehousesService.getAllCompanyWarehouses(this.userReference.companyId, this.utilsService.getEntityAccessIdsForUser(this.userReference.user.access.warehouses), {sort: "name asc"} as FetchParams)
      }), switchMap(warehouses => {
        this.warehouses = warehouses.list
        this.filteredWarehouses = warehouses.list
        this.warehouses.splice(this.warehouses.indexOf(this.warehouses.find(w => w.id === this.warehouseFrom!.id)!), 1)
        this.loadingWarehouses = false
        return of(this.warehouses)
      }), catchError((err, caught) => {
        this.loadingWarehouses = false
        if (!err.errors.includes('403')) this.snackService.error('Impossibile caricare i magazzini')
        return of([] as WarehouseData[]);
      }))

    this.formGroupMigrationStocks = this.formBuilder.group({migrationStocks: this.formBuilder.array([])});
  }

  override _entity: WarehouseDetail = {} as WarehouseDetail

  @Input() set entity(value: WarehouseDetail) {
    this._entity = value
    this.warehouseFrom = value

    this.subs.push(this.warehouses$.subscribe())

    this.subs.push(this.warehouseItemsFn(value.id).subscribe(stocks => {
      this.warehouseItemsFrom.push(...stocks)
      this.warehouseItemsFromChipList.push(...stocks)
      this.loading = false
    }))
  }

  migrationStocks(): FormArray {
    return this.formGroupMigrationStocks.get("migrationStocks") as FormArray
  }

  addMigrationStock(stock?: ItemWarehouseDetail) {
    this.migrationStocks().push(this.newMigrationStock(stock))
    this.warehouseItemsFromChipList.splice(this.warehouseItemsFromChipList.indexOf(this.warehouseItemsFromChipList.find(stockChip => stockChip.item.id === stock?.item.id)!), 1)
  }

  newMigrationStock(stock?: ItemWarehouseDetail): FormGroup {

    let wrapper: Wrapper = {
      stock: stock,
      selectedItem: stock ? this.warehouseItemsFrom.find(stockFrom => stockFrom.item.id == stock.item.id)!.item : undefined,
      migrationChoice: this.generalMigrationChoice,
      warehouseFrom: this.warehouseFrom,
      warehouseFromId: this.warehouseFrom?.id,
      warehouseTo: this.globalWarehouseTo,
      warehouseToId: this.globalWarehouseTo?.id,
      deleteFromOldWarehouse: this.generalDeletionFromOldWarehouse,
      filteredWarehouse: this.warehouses,
      filteredItems: [],
      loadingItems: false,
      warehouseToItems: [],
      message: ""
    }

    this.migrationWrappers.push(wrapper)

    return this.formBuilder.group({
      warehouseTo: new FormControl(this.globalWarehouseTo, [Validators.required]),
      warehouseFrom: new FormControl(this._entity, [Validators.required]),
      item: new FormControl(wrapper?.selectedItem, [Validators.required]),
      quantity: new FormControl<number | undefined>(stock?.quantity, {validators: [Validators.required, Validators.max(stock!.quantity), Validators.min(Constants.numerics.minForDecimalQuantities)]}),
      deleteFromOldWarehouse: new FormControl<boolean | undefined>(this.generalDeletionFromOldWarehouse, [Validators.required]),
      migrationChoice: new FormControl<MigrationItemChoice | undefined>(this.generalMigrationChoice, [Validators.required])
    })
  }

  removeMigrationStock(i: number) {
    this.migrationStocks().removeAt(i)
    this.warehouseItemsFromChipList.push(this.migrationWrappers[i].stock!)
    this.migrationWrappers.splice(i, 1)
  }


  emitChosenWarehouseTo(warehouseToId: number, index: number) {

    let warehouseTo = this.warehouses.find(warehouse => warehouse.id === warehouseToId)!
    let selectedItem = this.migrationWrappers[index].selectedItem!
    let hasUnti = !!selectedItem.unit

    this.migrationWrappers[index].warehouseTo = warehouseTo
    this.migrationWrappers[index].warehouseToId = warehouseToId

    this.migrationWrappers[index].loadingItems = true


    this.warehouseItemsFn(warehouseToId)
      .subscribe(itemWarehousesTo => {
        this.migrationWrappers[index].warehouseToItems = itemWarehousesTo
        this.migrationWrappers[index].filteredItems = itemWarehousesTo.map(it => it.item)
        this.migrationWrappers[index].loadingItems = false
        let message: string
        let stock = this.migrationWrappers[index].warehouseToItems!.find(stock => stock.item.id === selectedItem!.id)

        if (stock) {
          if (hasUnti)
            message = `Ci sono già ${stock.quantity} ${selectedItem.unit} di ${selectedItem.name} nel magazzino "${warehouseTo.name}"`
          else
            message = `Ci sono già ${stock.quantity} unità di ${selectedItem.name} nel magazzino "${warehouseTo.name}"`
        } else
          message = `Non esistono ancora giacenze di ${selectedItem.name} nel magazzino "${warehouseTo.name}". Verranno create insieme alla migrazione.`

        this.migrationWrappers[index].message = message

      })
  }

  getSelectedItem(index: number) {
    return !!this.migrationWrappers?.[index]?.selectedItem
  }

  setGlobalWarehouse(warehouse: WarehouseData) {
    this.globalWarehouseTo = warehouse
    this.migrationWrappers.forEach((a, index) => {
      this.emitChosenWarehouseTo(warehouse.id, index);
      (this.migrationStocks().controls[index] as FormGroup).controls['warehouseTo'].setValue(warehouse);
    });
  }

  setGlobalDeletionFromOldWarehouse(deleteMe: boolean) {
    this.generalDeletionFromOldWarehouse = deleteMe
    this.migrationWrappers.forEach((wrapper, index) => this.emitDeletionFromOldWarehouse(deleteMe, index))
  };

  setGlobalMigrationChoiceDropdown(choice: MigrationItemChoice) {
    this.generalMigrationChoice = choice
    this.migrationWrappers.forEach((wrapper, index) => this.emitChosenChoice(choice, index))
  }

  setGlobalMaxQuantity(mode: boolean) {
    this.globalMaxQuantities = mode
    if (mode)
      this.migrationWrappers.forEach((a, index) => this.setMaxQuantity(index))
  }

  emitDeletionFromOldWarehouse(deleteMe: boolean, index: number) {
    this.migrationWrappers[index].deleteFromOldWarehouse = deleteMe;
    (this.migrationStocks().controls[index] as FormGroup).controls['deleteFromOldWarehouse'].setValue(deleteMe);
  }

  emitChosenChoice(choice: MigrationItemChoice, index: number) {
    this.migrationWrappers[index].migrationChoice = choice;
    (this.migrationStocks().controls[index] as FormGroup).controls['migrationChoice'].setValue(choice);
  }

  checkGlobalDeleteFromOldWarehouse() {
    this.generalDeletionFromOldWarehouse = this.migrationWrappers.every(value => value?.deleteFromOldWarehouse === true)
  }

  checkGlobalMigrationChoice() {
    let matchingChoice = this.migrationWrappers[0].migrationChoice
    if (this.migrationWrappers.every(value => value?.migrationChoice === matchingChoice))
      this.generalMigrationChoice = matchingChoice
    else
      this.generalMigrationChoice = undefined
  }

  checkGlobalWarehouseTo() {
    let warehouseTo = this.migrationWrappers[0].warehouseTo
    if (this.migrationWrappers.every(value => value.warehouseTo === warehouseTo))
      this.globalWarehouseTo = warehouseTo
    else
      this.globalWarehouseTo = undefined
  }


  setMaxQuantity(index: number) {
    let quantity = this.migrationWrappers[index].stock?.quantity;
    (this.migrationStocks().controls[index] as FormGroup).controls['quantity'].setValue(quantity);
  }


  unsetSelectedWarehouseTo(index: number) {
    this.migrationWrappers[index].warehouseTo = undefined;
    this.migrationWrappers[index].warehouseToId = undefined;
    this.migrationWrappers[index].warehouseToItems = [];
    (this.migrationStocks().controls[index] as FormGroup).controls['warehouseTo'].setValue(undefined);
  }


  getPanelTitle(index: number, content: any) {

    let itemName = this.migrationWrappers?.[index]?.selectedItem?.name

    let itemQuantity = !!this.migrationWrappers?.[index]?.selectedItem?.name ? (content.value.quantity != null ? ' x' + content.value.quantity : ' x?') : ' ';

    let warehouseName = !!this.migrationWrappers?.[index]?.warehouseTo?.name ? `verso ${this.migrationWrappers?.[index]?.warehouseTo?.name}` : ' ';

    let stockChoice = this.migrationChoicesPipe.transform(content.value.state) != undefined ? `[${this.migrationChoicesPipe.transform(content.value.state)}]` : ""

    let panelTitle = `${itemName} ${itemQuantity} ${warehouseName} ${stockChoice}`

    if (panelTitle.trim() === "") return "Nuovo articolo"

    return panelTitle

  }


  saveMigration() {

    let currentStocksMigration = this.migrationStocks().controls.map(it => it.value)
    let migrationStocksList: ItemWarehouseMigrate[] = []

    currentStocksMigration.forEach(stock => {
      console.log(stock)
      migrationStocksList.push({
        migrationChoice: stock.migrationChoice,
        deleteFromOldWarehouse: stock.deleteFromOldWarehouse,
        itemId: stock.item.id,
        quantity: stock.quantity,
        warehouseFromId: stock.warehouseFrom.id,
        warehouseToId: stock.warehouseTo.id
      })
    })

    of(true)
      .pipe(switchMap(() => this.warehousesService.migrateStocks(this.userReference.companyId, migrationStocksList)))
      .subscribe({
        next: () => {
          if (this.dialogRef)
            this.dialogRef.close(true)
        },
        error: err => {
          this.snackService.error("Impossibile migrare le giacenze")
          if (this.dialogRef)
            this.dialogRef.close()
        }
      })
  }
}

// Used for presentation of content creation
export interface Wrapper {
  stock?: ItemWarehouseDetail
  selectedItem?: ItemVariantDetail
  migrationChoice?: MigrationItemChoice
  warehouseFrom?: WarehouseData
  warehouseTo?: WarehouseData
  warehouseFromId?: number
  warehouseToId?: number
  deleteFromOldWarehouse?: boolean
  filteredWarehouse: WarehouseData[]
  filteredItems: any[]
  loadingItems?: boolean
  warehouseId?: number
  warehouseToItems?: ItemWarehouseDetail[]
  warehouseFromItems?: ItemWarehouseDetail[]
  message: string
}
