import {Component, Input} from '@angular/core';
import {ItemCompositionData} from "../../../interfaces/item-composition";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {of} from "rxjs";
import {UtilsService} from "../../../services/utils.service";
import {DialogsService} from "../../../services/dialogs.service";
import {SnackService} from "../../../services/snack.service";
import {MatTableDataSource} from "@angular/material/table";
import {Constants} from "../../../utils/constants";

@Component({
  selector: 'aw-item-composition-table',
  templateUrl: './item-composition-table.component.html',
  styleUrls: ['./item-composition-table.component.scss']
})
export class ItemCompositionTableComponent extends BaseTableComponent<ItemCompositionData>{

  @Input() set itemComposers(itemComposers: ItemCompositionData[]) {
    this.dataSource = new MatTableDataSource<ItemCompositionData>(itemComposers)
  }

  constructor(protected override utilsService: UtilsService,
              protected override dialogService: DialogsService,
              public override snackService: SnackService) {
    let deleteFunction = (() => of())
    super('name', Constants.localStorageKeys.itemCompositionTable, deleteFunction, utilsService, snackService, dialogService);


    this.displayedColumns = [
      'name',
      'quantity',
    ]

  }
}
