import {Pipe, PipeTransform} from '@angular/core';
import {Constants} from "../utils/constants";

@Pipe({
  name: 'saleContentStatus'
})
export class SaleContentStatusPipe implements PipeTransform {

  transform(status: string) {
    return (Constants.saleContentStatusesPipe as any)[Constants.orderContentStatusKeys()
      .find(k => (Constants.saleContentStatuses as any)[k] === status) as any];
  }
}
