import {Component, Input, OnInit} from '@angular/core';
import {SellOrderEventData} from "../../../interfaces/sell-order-event";
import {Constants} from "../../constants";

@Component({
  selector: 'aw-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {


  @Input() events: SellOrderEventData[] = []

  constructor() { }

  ngOnInit(): void {
  }

  getColor(event: SellOrderEventData) {
    switch (event.appliedStatus) {

      case Constants.orderStatuses.toBeAssigned:
        return 'goldenrod'
      case Constants.orderStatuses.inProgress:
        return 'darkslateblue'
      case Constants.orderStatuses.readyForPickupShop:
      case Constants.orderStatuses.readyForPickupRider:
      case Constants.orderStatuses.readyForPickupCourier:
        return 'orange'
      case Constants.orderStatuses.deliveringRider:
      case Constants.orderStatuses.deliveringCourier:
        return 'green'
      case Constants.orderStatuses.deliveryFailedRider:
      case Constants.orderStatuses.deliveryFailedCourier:
      case Constants.orderStatuses.failed:
        return 'crimson'
      case Constants.orderStatuses.deliveredRider:
      case Constants.orderStatuses.deliveredCourier:
      case Constants.orderStatuses.pickedUp:
      case Constants.orderStatuses.completed:
        return 'cornflowerblue'

    }
    return event.appliedStatus.includes(Constants.orderStatuses.failed.toUpperCase()) ? 'crimson' : 'cornflowerblue'
  }


}
