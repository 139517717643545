<ng-template #loadingData>
  <mat-spinner class="center mb-4" color="primary"></mat-spinner>
</ng-template>

<div fxLayout="column" fxLayoutGap="2%">


  <mat-accordion *ngIf="warehouseItemsFromChipList.length > 0">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Scegli gli articoli da migrare
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row">
        <mat-chip-list fxLayout="row">
          <mat-chip *ngFor="let stock of warehouseItemsFromChipList" [ngStyle]="{'background-color': 'goldenrod'}"
                    (click)="addMigrationStock(stock); addingMigrationStock = true">
            {{stock.item.name}}
          </mat-chip>
        </mat-chip-list>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion *ngIf="warehouseItemsFrom.length !== 0">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Comandi globali
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="2%">
        <mat-label fxLayout="row" class="mt-3"></mat-label>
        <div fxLayout="row">

          <mat-checkbox
            (change)="setGlobalMaxQuantity($event.checked)"
          >
            Imposta massime quantità
          </mat-checkbox>
        </div>
        <div fxLayout="row">

          <mat-checkbox
            (change)="setGlobalDeletionFromOldWarehouse($event.checked)"
            [checked]="generalDeletionFromOldWarehouse"
          >
            Rimuovi dal magazzino di provenienza
          </mat-checkbox>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex="50">
            <mat-label>Modalità migrazione globale</mat-label>
            <mat-select
              (selectionChange)="setGlobalMigrationChoiceDropdown($event.value)"
              [(ngModel)]="generalMigrationChoice"
            >
              <mat-option *ngFor="let choice of migrationChoicesValues" [value]="choice">
                {{choice | migrationChoicesPipe}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field fxFlex="50">
            <mat-label>Migra tutto in questo magazzino</mat-label>
            <mat-select [value]="globalWarehouseTo" (selectionChange)="setGlobalWarehouse($event.value)">
                <aw-search-option (filteredOptions)="filteredWarehouses = $event"
                                  *ngIf="warehouses.length > 0"
                                  [key]="'name'" [options]="warehouses"></aw-search-option>

                <mat-option *ngIf="warehouses.length === 0">
                  Nessuno magazzino disponibile.
                </mat-option>

                <mat-option *ngIf="filteredWarehouses.length === 0 && warehouses.length !== 0">
                  Nessuno magazzino trovato.
                </mat-option>

                <mat-option *ngFor="let warehouse of filteredWarehouses" [value]="warehouse">
                  {{warehouse.name}}
                </mat-option>

            </mat-select>

            <button (click)="globalWarehouseTo = undefined;$event.stopPropagation()"
                    *ngIf="globalWarehouseTo"
                    mat-button
                    mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>


</div>

<div class="mt-4">

  <form *ngIf="!loading; else loadingData" [formGroup]="formGroupMigrationStocks">

    <div *ngIf="migrationWrappers.length === 0"
         class="border border-yellow-warn disclaimer-box mat-background-light p-2_5 mb-2_5 mt-2_5"
         fxFlex="100">
      <p fxLayoutAlign="center center">
        <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
        Nessun articolo presente nella migrazione.
      </p>
    </div>

    <mat-accordion fxFlex>
      <div *ngFor="let content of migrationStocks().controls; let i=index" class="mb-4" formArrayName="migrationStocks">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{getPanelTitle(i, content)}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div [formGroupName]="i" fxLayout="column">

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">

              <mat-form-field fxFlex="43">
                <mat-label>{{migrationWrappers[i]?.selectedItem?.name ?? "Articolo"}}</mat-label>
                <mat-select formControlName="item" [disabled]="true">
                  <mat-option
                    *ngFor="let item of migrationWrappers[i].filteredItems"
                    [value]="item">
                    {{item.name}}
                  </mat-option>


                </mat-select>

              </mat-form-field>

              <mat-form-field fxFlex="42">
                <mat-label>Magazzino di destinazione</mat-label>

                <mat-select
                  (selectionChange)="emitChosenWarehouseTo($event.value.id, i);checkGlobalWarehouseTo()"
                  formControlName="warehouseTo"
                >
                  <ng-container>
                    <aw-search-option (filteredOptions)="migrationWrappers[i].filteredWarehouse = $event"
                                      *ngIf="warehouses.length > 0"
                                      [key]="'name'" [options]="warehouses"></aw-search-option>

                    <mat-option *ngIf="warehouses.length === 0">
                      Nessuno magazzino disponibile.
                    </mat-option>

                    <mat-option *ngIf="migrationWrappers[i].filteredWarehouse.length === 0 && warehouses.length !== 0">
                      Nessuno magazzino trovato.
                    </mat-option>

                    <mat-option *ngFor="let warehouse of migrationWrappers[i].filteredWarehouse" [value]="warehouse">
                      {{warehouse.name}}
                    </mat-option>
                  </ng-container>

                </mat-select>

                <button (click)="unsetSelectedWarehouseTo(i);globalWarehouseTo = undefined;$event.stopPropagation()"
                        *ngIf="migrationWrappers[i].warehouseTo"
                        mat-button
                        mat-icon-button
                        matSuffix>
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

            </div>

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">

              <mat-form-field fxFlex="15">
                <mat-label>
                  Quantità{{migrationWrappers?.[i]?.selectedItem?.unit ? (' (' + migrationWrappers?.[i]?.selectedItem?.unit) + ')' : ('')}}
                </mat-label>
                <!--                region quantity -->
                <input
                  *ngIf="!migrationWrappers?.[i]?.selectedItem?.unit"
                  [matTooltip]="'Quantità'"
                  [min]="1"
                  [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 0}"
                  [readonly]="!getSelectedItem(i) || loadingItems "
                  currencyMask
                  formControlName="quantity"
                  matInput
                  type="text"
                >
                <!--                endregion quantity -->

                <!--                region unit_quantity -->
                <input
                  *ngIf="migrationWrappers?.[i]?.selectedItem?.unit"
                  [matTooltip]="'Quantità (' + migrationWrappers?.[i]?.selectedItem?.unit! + ')'"
                  [min]="1"
                  [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2}"
                  [readonly]="!getSelectedItem(i) || loadingItems "
                  currencyMask
                  formControlName="quantity"
                  matInput
                  type="text"
                >
                <span *ngIf="migrationWrappers?.[i]?.selectedItem?.unit"
                      matSuffix>{{migrationWrappers?.[i]?.selectedItem?.unit!}}</span>
                <!--                endregion unit_quantity -->

                <mat-error *ngIf="migrationStocks().controls[i].get('quantity')?.dirty &&
                                                    migrationStocks().controls[i].hasError('required', 'quantity')"
                >La quantità è obbligatoria
                </mat-error>
                <mat-error *ngIf="migrationStocks().controls[i].get('quantity')?.dirty &&
                                                    migrationStocks().controls[i].hasError('min', 'quantity')"
                >La quantità non può essere 0
                </mat-error>
                <mat-error *ngIf="migrationStocks().controls[i].get('quantity')?.dirty &&
                                                    migrationStocks().controls[i].hasError('max', 'quantity')"
                >La quantità non può essere superiore a {{migrationWrappers?.[i]?.stock?.quantity}}
                </mat-error>

                <mat-chip (click)="setMaxQuantity(i);$event.stopPropagation()" class="ml-2" matSuffix
                          [ngStyle]="{'background-color': 'white', 'color': '#49a342', 'border-color': '#49a342'}">
                  MAX
                </mat-chip>

              </mat-form-field>


              <mat-form-field fxFlex>
                <mat-label>Modalità migrazione</mat-label>
                <mat-select
                  (selectionChange)="emitChosenChoice($event.value, i);checkGlobalMigrationChoice()"
                  formControlName="migrationChoice">
                  <mat-select-trigger *ngIf="migrationWrappers[i].migrationChoice">
                    {{migrationWrappers[i].migrationChoice! | migrationChoicesPipe}}
                  </mat-select-trigger>

                  <mat-option *ngFor="let choice of migrationChoicesValues" [value]="choice">
                    {{choice | migrationChoicesPipe}}
                  </mat-option>
                </mat-select>

              </mat-form-field>

              <mat-checkbox (change)="emitDeletionFromOldWarehouse($event.checked, i);checkGlobalDeleteFromOldWarehouse()"
                            [checked]="migrationWrappers[i].deleteFromOldWarehouse"
                            formControlName="deleteFromOldWarehouse"
                            class="mt-3">
                Rimuovi da "{{warehouseFrom?.name}}"
              </mat-checkbox>

            </div>

          </div>


          <div fxLayout="column">
            <div *ngIf="migrationWrappers?.[i]?.message"
                 class="border border-blue-info disclaimer-box mat-background-light p-2_5 mb-2_5 mt-2_5"
                 fxFlex>
              <p fxLayoutAlign="center center">
                <mat-icon color="accent">info</mat-icon> &nbsp;
                {{migrationWrappers?.[i]?.message}}
              </p>
            </div>

            <button (click)="removeMigrationStock(i); addingMigrationStock = false"
                    [disabled]="saving"
                    class="border border-warn mr-1"
                    color="warn"
                    fxFlex
                    mat-button
            >
              <mat-icon>delete</mat-icon>
              &nbsp;<span class="hide-mobile">Rimuovi articolo da migrazione</span>
            </button>
          </div>

        </mat-expansion-panel>


      </div>


    </mat-accordion>
  </form>

  <div fxLayout="row">

    <button (click)="saveMigration()"
            *ngIf="!isEditing"
            [disabled]="saving || formGroupMigrationStocks.invalid || migrationStocks().length === 0"
            color="primary"
            class="mt-5"
            fxLayoutAlign="end"
            mat-raised-button
    >
      <mat-icon>check</mat-icon>
      &nbsp;<span class="hide-mobile">Migra</span>

    </button>
  </div>

</div>
