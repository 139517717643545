import {Component, Input, OnInit} from '@angular/core';
import {AccessDataUser} from "../../interfaces/permission";
import {Constants} from "../../utils/constants";
import {AccessLevel, EntityIdentifier} from "../../utils/global-functions-and-types";
import {DialogsService} from "../../services/dialogs.service";
import {SnackService} from "../../services/snack.service";
import {BaseTableComponent} from "../../utils/shared-components/base-table/base-table.component";
import {UtilsService} from "../../services/utils.service";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {PermissionsService} from "../../services/permissions.service";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {WarehousesService} from "../../services/warehouses.service";
import {ShopsService} from "../../services/shops.service";

@Component({
  selector: 'aw-entity-permission-table',
  templateUrl: './entity-permission-table.component.html',
  styleUrls: ['./entity-permission-table.component.scss']
})
export class EntityPermissionTableComponent extends BaseTableComponent<AccessDataUser> implements OnInit {

  accessDataUsers: AccessDataUser[] = []
  showSaveButton = false
  loadingEntity = false
  loadingUsersPermissions = false
  numUsersEntity = 0
  _entityId = 0
  _entityName!: EntityIdentifier
  globalUserAuthority: AccessLevel
  initialUsers = new Map<number, AccessLevel>()
  permissionsToEdit: AccessDataUser[] = []
  accessLevelOrder = Constants.accessLevelOrder

  constructor(public permissionsService: PermissionsService, public warehousesService: WarehousesService, public shopsService: ShopsService, protected override dialogService: DialogsService, public override snackService: SnackService, protected override utilsService: UtilsService,) {

    super('name', Constants.localStorageKeys.entityPermissionTable,() => new Observable<any>(), utilsService, snackService, dialogService)


    this.observable$ = of(true)
      .pipe(tap(() => {
        this.loading = true
        this.loadingEntity = true
        this.loadingUsersPermissions = true
      }), switchMap(() => this.userReference$), switchMap(() => this.permissionsService.getEntityUsers(this.userReference.companyId, this._entityName, this._entityId, this.fetchParams)), switchMap(userEntity => {
        this.accessDataUsers = userEntity.list
        this.dataSource = new MatTableDataSource<AccessDataUser>(userEntity.list)
        this.entityList.push(...userEntity.list)
        this.numUsersEntity = userEntity.num
        this.accessDataUsers.forEach(user => this.initialUsers.set(user.user.id, user.userAuthority))
        return of(userEntity.list)
      }), tap(() => {
        this.loading = false
        this.loadingEntity = false
        this.loadingUsersPermissions = false
      }), catchError((err) => {
        this.loading = false
        this.loadingEntity = false
        this.loadingUsersPermissions = false
        if (!err.errors.includes('403')) this.snackService.error('Impossibile caricare le informazioni')
        return EMPTY;
      }))

    this.displayedColumns = ["name", "permission", "action",]

  }

  @Input() set entity(entity: { entityName: EntityIdentifier, entityId: number }) {
    this._entityName = entity.entityName
    this._entityId = entity.entityId
  }

  ngOnInit() {
    this.subs.push(this.observable$.subscribe())
  }

  reset() {
    this.accessDataUsers?.forEach(value => value.userAuthority = this.initialUsers.get(value.user.id))
    this.globalUserAuthority = undefined
    this.showSaveButton = false
  }

  deleteEntity(accessData?: AccessDataUser) {

    this.dialogService.confirm('Conferma rimozione', 'Sei sicuro di voler rimuovere i permessi selezionati?')
      .pipe(tap(value => {
        if (value) {
          if (!accessData)
            this.deletePermissions(this.selection.selected.filter(value => value.userAuthority), this._entityName)
          else if (accessData.userAuthority)
            this.deletePermissions([accessData].filter(value => value.userAuthority), this._entityName)
        }
      }))
      .subscribe()
  }

  setMultiplePermission($event: any) {

    this.accessDataUsers?.forEach(value => {
      value.userAuthority = $event.value
    })
    this.dataSource.data.forEach(value => {
      value.userAuthority = $event.value
    })

    this.shouldISave()
  }

  shouldISave() {
    this.permissionsToEdit = this.accessDataUsers?.filter(value => value.userAuthority != this.initialUsers.get(value.user.id))
    this.showSaveButton = this.permissionsToEdit.length > 0
  }

  isDeletable(accessData: AccessDataUser) {
    return this.initialUsers.get(accessData.user.id) != undefined
  }

  isSelectable() {
    return !this.showSaveButton && !!this.accessDataUsers.find(accessData => accessData.userAuthority != undefined)
  }

  setPermissions(permissions: AccessDataUser[], entity: EntityIdentifier) {
    of(true)
      .pipe(
        tap(() => this.loading = true),
        switchMap(() => {
        return this.permissionsService.setPermissionsToUser(this.userReference.companyId, permissions.map(value => {
          return {
            userId: value.user.id, entity: entity, userAuthority: value.userAuthority!, entityId: this._entityId
          }
        }))
      }),
        switchMap(() => {
          this.loading = false
          this.showSaveButton = false
          this.globalUserAuthority = undefined
          this.snackService.success('Permessi aggiornati correttamente')
          return this.observable$
        }),
        catchError((err) => {
          this.loading = false
          if (!err.errors.includes('403')) this.snackService.error('Impossibile aggiornare i permessi')
          return EMPTY;
      })).subscribe()
  }

  deletePermissions(permissions: AccessDataUser[], entity: EntityIdentifier) {
    of(true)
      .pipe(
          tap(() => this.loading = true),
          switchMap(() => {
          return this.permissionsService.removePermissionsFromUser(this.userReference.companyId, permissions.map(value => {
            return {
              entity: entity, userId: value.user.id, entityId: this._entityId
            }
          }))
          }),
          switchMap(() => {
            this.accessDataUsers = []
            this.initialUsers = new Map<number, AccessLevel>()
            this.selectVisible = false
            this.displayedColumns = ["name", "permission", "action"]
            this.selection.clear(true)
            this.loading = false
            this.showSaveButton = false
            this.globalUserAuthority = undefined
            this.snackService.success('Permessi selezionati eliminati correttamente')
            return this.observable$
          }),
        catchError((err) => {
          this.loading = false
          if (!err.errors.includes('403'))
            this.snackService.error('Impossibile eliminare i permessi selezionati')
          return EMPTY;
        })).subscribe()
  }

}
