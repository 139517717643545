import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ShopCreate, ShopDetail} from "../../../interfaces/shop";
import {BaseFormComponent} from "../../../utils/shared-components/base-form/base-form.component";
import {WarehouseData} from "../../../interfaces/warehouse";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {ShopsService} from "../../../services/shops.service";
import {catchError} from "rxjs/operators";
import {UtilsService} from "../../../services/utils.service";
import {WarehousesService} from "../../../services/warehouses.service";
import {FetchParams, hasPermissionOnEntity} from "../../../utils/global-functions-and-types";
import {SnackService} from "../../../services/snack.service";
import {merge} from "lodash-es";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'aw-shops-form',
  templateUrl: './shops-form.component.html',
  styleUrls: ['./shops-form.component.scss']
})
export class ShopsFormComponent extends BaseFormComponent<ShopCreate, ShopDetail> implements OnInit {

  override _entity: ShopDetail = {} as ShopDetail
  _warehouses: WarehouseData[] = []
  filteredWarehouses: any[] = [];

  _selectedWarehouse: WarehouseData = {} as WarehouseData

  loadingWarehouses = false
  hasPermissionOnDefaultWarehouse = false
  warehouseHasChanged = false
  warehouseFieldDisabled = false

  initialDefaultWarehouseId?: number = undefined

  warehouses$: Observable<WarehouseData[]> = new Observable<WarehouseData[]>()

  @Input() companyId = -1

  @Input() set entity(value: ShopDetail) {
    this._entity = value
    this.formGroup.patchValue(this._entity)
    this.hasPermissionOnDefaultWarehouse = hasPermissionOnEntity(this.userReference.user, {accessEntity: this.accessEntities.warehouses, accessLevel: this.accessLevels.reader}, {id: this._entity?.defaultWarehouseId})
    if (value.defaultWarehouseId)
      this.initialDefaultWarehouseId = value.defaultWarehouseId
    this.formGroup.controls["defaultWarehouseId"].setValue(value?.defaultWarehouseId)
  }

  constructor(
    public shopsService: ShopsService,
    public warehousesService: WarehousesService,
    public snackService: SnackService,
    protected override utilsService: UtilsService
  ) {

    let createFunction = (companyId: number, shop: ShopCreate): Observable<ShopDetail> => shopsService.createShop(companyId, shop)
    let updateFunction = (companyId: number, shopId: number, shop: ShopCreate): Observable<ShopDetail> => shopsService.updateShop(companyId, shopId, shop)

    super(createFunction, updateFunction, utilsService)

    this.formGroup = new FormGroup({
      id: new FormControl(undefined),
      name: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      phone: new FormControl('',),
      defaultWarehouseId: new FormControl<number | undefined>(this._selectedWarehouse?.id ?? undefined)
    })

    this.warehouses$ =
      of(true)
        .pipe(
          tap(() => this.loadingWarehouses = true),
          switchMap(() => this.userReference$),
          switchMap(userReference => this.warehousesService
            .getAllCompanyWarehouses(this.userReference.companyId, this.utilsService.getEntityAccessIdsForUser(userReference.user.access.warehouses), {sort: 'name asc'} as FetchParams)),
          switchMap(warehouses => {
            this._warehouses = warehouses.list
            this.filteredWarehouses = warehouses.list

            if (!this.hasPermissionOnDefaultWarehouse)
              this._warehouses.push({id: this._entity.defaultWarehouseId, name: this._entity.defaultWarehouseName} as WarehouseData)
            this.loadingWarehouses = false
            return of(warehouses.list)
          }),
          catchError((err) => {
            this.loadingWarehouses = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i magazzini');
            return EMPTY;
          })
        )


  }

  ngOnInit() {
    if (this.userReference.user.access.warehouses.userAuthority != undefined || this.userReference.user.access.warehouses.permission.length !== 0)
      this.subs.push(this.warehouses$.subscribe())

    this.warehouseFieldDisabled = !this.hasPermissionOnDefaultWarehouse

  }

  override updateEntity(companyId: number, entityId: number) {
    this.manageForm()
    super.updateEntity(companyId, entityId)
  }

  manageForm() {
    let formValue = this.formGroup.value

    if (!formValue.id)
      delete formValue.id

    this.formEntity = merge({} as ShopDetail, this._entity, formValue)

    if (!this.warehouseHasChanged) {
      delete this.formEntity.defaultWarehouseId
      this.formGroup.controls['defaultWarehouseId'].setValue(undefined)
    }

  }

  checkWarehouseChange($event: MatSelectChange) {
    this.warehouseHasChanged = this.initialDefaultWarehouseId != $event.value
  }
}
