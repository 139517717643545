<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>

<ng-container *ngIf="!loading else loadingTemplate">
  <h1 class="font-light">
    {{profile?.id ? ('MODIFICA – ' + profile?.name) : ('CREAZIONE – PROFILO')}}
  </h1>
  <aw-profiles-permissions-table-container
    [profile]="profile"
    (loadProfile)="loadProfile()"
  ></aw-profiles-permissions-table-container>
</ng-container>


