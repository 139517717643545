<div fxLayout="row" fxLayoutAlign="center center">


  <ng-container *ngIf="!resetPwd">
    <mat-card class="shadow" fxFlex="33%" fxFlex.lt-md="66%">


      <div [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="space-around">

        <div class="text-center mb-5">
          <img
            alt="Image"
            class="w-25"
            src="assets/svg/flower.svg">
          <h1 class="font-normal">Floweers</h1>
          <h2 class="font-light">Login</h2>
        </div>

        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            formControlName="email"
            id="email"
            matInput
            type="email">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input
            formControlName="password"
            id="password"
            matInput
            type="password">
        </mat-form-field>

        <button
          (click)="login()"
          [disabled]="formGroup.invalid || loading"
          color="primary"
          mat-raised-button
        >
          <mat-icon>person_outline</mat-icon>
          &nbsp;<span class="hide-mobile">Login</span>
        </button>

        <div class="my-4" fxLayoutAlign="start">
          <a (click)="showResetModal()" class="font-normal cursor-pointer facebook-color">
            Password dimenticata?
          </a>
        </div>

      </div>
      <mat-card-footer>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="resetPwd">
    <mat-card class="shadow" fxFlex="33%" fxFlex.lt-md="66%">

      <div class="text-center mb-5">
        <img
          alt="Image"
          class="w-25"
          src="assets/svg/flower.svg">
        <h1 class="font-normal">Floweers</h1>
        <h1 class="font-light">Reset password</h1>
      </div>

      <div [formGroup]="formGroupReset" fxLayout="column" fxLayoutAlign="space-around">


        <mat-form-field>
          <mat-label>Email di recupero</mat-label>
          <input
            formControlName="email"
            id="email"
            matInput
            type="email">
        </mat-form-field>

        <button
          (click)="resetPassword()"
          [disabled]="formGroupReset.invalid || loadingReset"
          color="primary"
          mat-raised-button
        >
          <mat-icon class="left-0">mail_outline</mat-icon>
          &nbsp;<span class="hide-mobile">Invia</span>
        </button>

        <div class="my-4" fxLayoutAlign="start">
          <a (click)="resetPwd = false" class="font-normal cursor-pointer facebook-color">
            &#8249; Torna indietro
          </a>
        </div>

      </div>

      <mat-card-footer>
        <mat-progress-bar *ngIf="loadingReset" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </ng-container>

</div>



