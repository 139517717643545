import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ItemTypeData} from 'src/app/interfaces/item-type';
import {delay, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {map} from "rxjs/operators";
import {BaseItemData, BaseItemDetail, BaseItemFullDetail} from "../../../interfaces/base-item";
import {OptionData} from "../../../interfaces/option";
import {OptionsService} from "../../../services/options.service";
import {AuthService} from "../../../services/auth.service";
import {ItemVariantCreate, ItemVariantData, ItemVariantDetail} from "../../../interfaces/item-variant";
import {OptionValueData} from "../../../interfaces/option-value";
import {combine, FetchParams, UserReference} from "../../../utils/global-functions-and-types";
import {BaseItemsService} from "../../../services/base-items.service";
import {ItemTypesService} from "../../../services/item-types.service";
import {ItemVariantValueCreate} from "../../../interfaces/item-variant-value";
import {DialogsService} from "../../../services/dialogs.service";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {SnackService} from "../../../services/snack.service";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {ItemsCompositionFormComponent} from "../../items/items-composition-form/items-composition-form.component";
import {ItemCompositionsService} from "../../../services/item-compositions.service";
import {UtilsService} from "../../../services/utils.service";
import {WarehousesService} from "../../../services/warehouses.service";
import {Constants} from "../../../utils/constants";


@Component({
  selector: 'aw-base-items-form',
  templateUrl: './base-items-form.component.html',
  styleUrls: ['./base-items-form.component.scss']
})
export class BaseItemsFormComponent implements OnInit {

  formGroup: FormGroup

  accessEntities = Constants.accessEntities
  accessLevels = Constants.accessLevels

  itemTypes: ItemTypeData[] = []
  filteredItemTypes: ItemTypeData[] = []
  @Output() onClose = new EventEmitter<void>()
  @Output() completed = new EventEmitter<BaseItemData>()
  @Input() readonly = false

  selectedItemTypes: ItemTypeData[] = [];
  selectedOptions: OptionData[] = [];

  saving = false
  showVariantsForm = false;

  computingVariants = true;

  timeout?: Subscription;
  options: OptionData[] = [];

  // This is a cast from ItemVariant Detail to ItemVariantCreate.
  // For this reason, it is possible to check if the variant has an ID
  variants?: { key: number, variant: ItemVariantCreate }[]

  existingVariants: ItemVariantDetail[] = []
  selectedVariants?: { key: number, variant: ItemVariantCreate }[];

  isEdit = false;
  isForceVariantsRecompute = false;
  wasVariantsRecomputed = false;
  loadingOptions = false;

  lastBarcodeChecked = ""
  checkingBarcode = false;
  barcodeAlreadyInUse = false;
  itemNamesUsingSameBarcode: string[] = []

  lastSkuChecked = "";
  checkingSku = false;
  skuAlreadyInUse = false;
  itemNamesUsingSameSku: string[] = []

  itemVariantWrappers: ItemVariantWrapper[] = []

  defaultVariant?: ItemVariantDetail;

  dataSourceOptions!: MatTableDataSource<OptionData>;
  displayedColumnsOptions = [
    'name',
    'actions'
  ]

  selection = new SelectionModel<{ key: number, variant: ItemVariantCreate }>(true, []);
  dataSourceVariants!: MatTableDataSource<{ key: number, variant: ItemVariantCreate }>;

  defaultDisplayedColumnsVariants = [
    'select',
    'status',
    'expand'
  ]

  displayedColumnsVariants = [
    'select',
    'status',
    'expand'
  ]


  // Total number of stocks in repository
  numEntities = 0;
  stocksParams: FetchParams = {} as FetchParams

  expandedItemVariants: number[] = [];
  userReference?: UserReference
  options$: Observable<OptionData[]>;
  userReference$: Observable<UserReference>
  isNew = false;
  isDuplicate = false;

  constructor(private optionsService: OptionsService,
              private authService: AuthService,
              private baseItemService: BaseItemsService,
              private itemTypeService: ItemTypesService,
              private dialogsService: DialogsService,
              private warehousesService: WarehousesService,
              private snackService: SnackService,
              private itemCompositionsService: ItemCompositionsService,
              private utilsService: UtilsService,
  ) {

    this.loadingOptions = true

    this.formGroup = new FormGroup({
      id: new FormControl<number | null>(0, []),
      name: new FormControl<string>('', [Validators.required]),
      description: new FormControl<string | null>(null, []),
      barcode: new FormControl<string | null>(null, []),
      sku: new FormControl<string | null>(null, []),
      minimumSellNetPrice: new FormControl<number | null>(null, [Validators.min(0)]),
      maximumBuyNetPrice: new FormControl<number | null>(null, [Validators.min(0)]),
      defaultBuyVAT: new FormControl<number | null>(null, [Validators.min(0), Validators.max(100)]),
      defaultSellVAT: new FormControl<number | null>(null, [Validators.min(0), Validators.max(100)]),
      unit: new FormControl<string | null>(null, []),
      itemTypesIds: new FormControl<number[] | null>(null, []),
      options: new FormControl<number[] | null>(null, []),
    })

    this.stocksParams = {
      page: Constants.pagination.defaultPage,
      num: Constants.pagination.defaultPageSize,
      sort: `name asc`,
      keyword: ""
    }

    this.userReference$ =
      this.utilsService.getUserReference()
        .pipe(tap(userReference => this.userReference = userReference))

    this.options$ =
      this.userReference$.pipe(
        tap(() => this.loadingOptions = true),
        switchMap(() => this.optionsService.getAllCompanyOptions(this.userReference?.companyId!, {} as FetchParams)),
        map(value => value.list),
        tap(options => this.options = options),
        tap(options => this.dataSourceOptions = new MatTableDataSource<OptionData>(options)),
        tap(() => this.loadingOptions = false)
      )


    this.options$.subscribe()
  }

  _baseItemToEdit: BaseItemFullDetail | BaseItemDetail | undefined = undefined

  @Input()
  set baseItemToDuplicate(baseItem: BaseItemFullDetail | BaseItemDetail | undefined) {

    if (baseItem) {

      this.isDuplicate = true

      this._baseItemToEdit = baseItem

      this.selectedItemTypes = baseItem.itemTypes ?? []

      // base item has options --> variate item
      if ((baseItem.options?.length ?? 0) > 0) {
        this.options$.subscribe(() => {
          this.selectedOptions = [...baseItem.options ?? []]
          this.addColumnToVariantsTable(this.selectedOptions.map(s => s.name))
          this.computeVariants()
          if (baseItem.variants.length > 0) {

            const selectedVariants = (baseItem as BaseItemFullDetail).variants
              .map((variant) => {
                this.existingVariants.push(variant)
                return {
                  key: variant.variantValues!.map(value => value.optionValue!.id)
                    .reduce((previousValue, currentValue) => previousValue * currentValue),
                  variant: {
                    ...variant,
                    variantValues: variant.variantValues?.map(value => {
                      return {
                        baseItemId: value.baseItem.id,
                        itemVariantId: variant.id,
                        optionId: value.option.id,
                        optionValueId: value.optionValue!.id
                      }
                    })
                  }
                }
              });

            this.variants!
              .forEach((possibleVariant, index) => {
                  const varToReplace = selectedVariants.find(value => value.key === possibleVariant.key)
                  if (varToReplace !== undefined)
                    this.variants![index] = varToReplace
                }
              )

            this.selectedVariants = selectedVariants;

            let selectedVariantKeys = selectedVariants?.map(t => t.key)
            this.selection.select(...this.dataSourceVariants.data.filter(dsv => selectedVariantKeys.includes(dsv.key)))
            this.computingVariants = false
            this.showVariantsForm = true
          }
        });
      } else {
        this.defaultVariant = baseItem.variants[0]
        this.showVariantsForm = false
        this.existingVariants = [this.defaultVariant]
        this.computingVariants = false
        // base item does not have variants -> base item
        // this.isForceVariantsRecompute =  true
      }


      this.formGroup.patchValue(baseItem)

      if (this.defaultVariant) {
        this.formGroup.controls["barcode"].setValue(this.defaultVariant.barcode)
        this.formGroup.controls["sku"].setValue(this.defaultVariant.sku)
      }
    }
  }

  @Input()
  set baseItemToEdit(baseItem: BaseItemFullDetail | BaseItemDetail | undefined) {

    if (baseItem) {

      if (baseItem?.id) {

        this._baseItemToEdit = baseItem

        this.isEdit = true;

        this.selectedItemTypes = baseItem.itemTypes ?? []

        // base item has options --> variate item
        if ((baseItem.options?.length ?? 0) > 0) {
          this.options$.subscribe(() => {
            this.selectedOptions = [...baseItem.options ?? []]
            this.addColumnToVariantsTable(this.selectedOptions.map(s => s.name))
            this.computeVariants()
            if (baseItem.variants.length > 0) {

              const selectedVariants = (baseItem as BaseItemFullDetail).variants
                .map((variant) => {
                  this.existingVariants.push(variant)
                  return {
                    key: variant.variantValues!.map(value => value.optionValue!.id)
                      .reduce((previousValue, currentValue) => previousValue * currentValue),
                    variant: {
                      ...variant,
                      variantValues: variant.variantValues?.map(value => {
                        return {
                          baseItemId: value.baseItem.id,
                          itemVariantId: variant.id,
                          optionId: value.option.id,
                          optionValueId: value.optionValue!.id
                        }
                      })
                    }
                  }
                });

              this.variants!
                .forEach((possibleVariant, index) => {
                    const varToReplace = selectedVariants.find(value => value.key === possibleVariant.key)
                    if (varToReplace !== undefined)
                      this.variants![index] = varToReplace
                  }
                )

              this.selectedVariants = selectedVariants;

              let selectedVariantKeys = selectedVariants?.map(t => t.key)
              this.selection.select(...this.dataSourceVariants.data.filter(dsv => selectedVariantKeys.includes(dsv.key)))
              this.computingVariants = false
              this.showVariantsForm = true
            }
          });
        } else {
          this.defaultVariant = baseItem.variants[0]
          this.showVariantsForm = false
          this.existingVariants = [this.defaultVariant]
          this.computingVariants = false
          // base item does not have variants -> base item
          // this.isForceVariantsRecompute =  true
        }

      } else {
        this.isNew = true
        this.computingVariants = false
      }

      this.formGroup.patchValue(baseItem)

      if (this.defaultVariant) {
        this.formGroup.controls["barcode"].setValue(this.defaultVariant.barcode)
        this.formGroup.controls["sku"].setValue(this.defaultVariant.sku)
      }
    }
  }

  @Input()
  set onHideFn(condition: boolean) {
    if (condition) this.formGroup.reset()
  }

  ngOnInit(): void {
    this.userReference$.pipe(
      switchMap(() => this.itemTypeService
        .getAllCompanyItemTypes(this.userReference?.companyId!)))
      .subscribe(value => {
        this.itemTypes = value.list
        this.filteredItemTypes = value.list
        if (this.isEdit || this.isDuplicate) {
          // patch item types if editing
          let ids = this._baseItemToEdit?.itemTypes?.map(it => it.id) ?? []
          this.formGroup.controls["itemTypesIds"].setValue(this.itemTypes.filter(it => ids.includes(it.id)).map(it => it.id))
        }
      })

  }

  saveBaseItem() {

    this.saving = true;
    let p = this.formGroup.value

    if (!p.id)
      delete p.id

    let baseItem = {...p}

    baseItem.optionsIds = this.selectedOptions.map(value => value.id)

    if (!this.selection.isEmpty())
      baseItem.variants = this.selection.selected.map(s => s.variant)

    if (!this.isEdit) {
      this.baseItemService
        .createCompanyBaseItem(this.userReference?.companyId!, baseItem)
        .subscribe({
          next: (value) => {
            this.completed.emit(value)
            this.snackService.success('Articolo creato')
          }, error: () => {
            this.snackService.error('Impossibile creare l\'articolo')
          }
        })
    } else {
      this.baseItemService
        .updateCompanyBaseItem(this.userReference?.companyId!, baseItem.id, baseItem)
        .subscribe({
          next: (value) => {
            this.completed.emit(value)
            this.snackService.success('Articolo aggiornato')
          }, error: () => {
            this.snackService.error('Impossibile aggiornare l\'articolo')
          }
        })
    }
  }

  loadOptions() {

    this.displayedColumnsVariants = [...this.defaultDisplayedColumnsVariants]
    this.selectedOptions = []
    this.dataSourceVariants = new MatTableDataSource<{ key: number, variant: ItemVariantCreate }>()
    this.showVariantsForm = true
  }

  computeVariants() {

    this.selectedVariants = []
    this.selection.clear()

    const values = this.selectedOptions.map(o => o.values!);

    const combinations = combine<OptionValueData[]>(...values)

    this.variants = combinations.map(variantValues => {
        return {
          key: variantValues.map(value => value.id).reduce((previousValue, currentValue) => previousValue * currentValue),
          variant:
            {
              name: this.formGroup.value.name + " " + variantValues.map(value => value.displayName).join("-"),
              maximumBuyNetPrice: this.formGroup.value.maximumBuyNetPrice,
              minimumSellNetPrice: this.formGroup.value.minimumSellNetPrice,
              variantValues: variantValues.map(value => {
                return {
                  optionId: value.optionId,
                  optionValueId: value.id
                }
              })

            } as ItemVariantCreate
        };
      }
    );
    this.wasVariantsRecomputed = true;

    this.dataSourceVariants = new MatTableDataSource<{ key: number, variant: ItemVariantCreate }>(this.variants)

    // init itemVariantWrapper with default values
    this.variants?.forEach(value => this.itemVariantWrappers.push({
      itemNamesUsingSameBarcode: [],
      itemNamesUsingSameSku: [],
      lastSkuChecked: "",
      lastBarcodeChecked: "",
      skuDirty: false,
      barcodeDirty: false,
      barcodeAlreadyInUse: false,
      checkingBarcode: false,
      skuAlreadyInUse: false,
      checkingSku: false,
    }))

  }


  getOptionValue(variant: ItemVariantCreate | ItemVariantDetail, option: OptionData): OptionValueData {

    const vVal: ItemVariantValueCreate = (variant as ItemVariantCreate).variantValues!.find((optV) => optV.optionId == option.id)!;
    return option.values!.find((optionValue) => optionValue.id == vVal.optionValueId)!

  }


  isOptionSelected(option: OptionData) {
    return !!this.selectedOptions.find(so => so.id === option.id)
  }

  /**
   * Enables structural edit, if confirmed.
   */
  forceVariantsRecompute() {

    this.dialogsService.confirm('ATTENZIONE - MODIFICA STRUTTURALE', `
            Per modifiche strutturali si intendono modifiche che cambiano la natura dell'articolo:
            come aggiungere opzioni e varianti ad articoli di base o cambiare le opzioni di
            variazione ad articoli con varianti.

            Se cambi, aggiungi o rimuovi opzioni, tutte le varianti esistenti
            verranno rigenerate e i dati attuali verranno eliminati.

            Qualunque modifica strutturale eliminerà anche tutti i riferimenti
            esistenti, come le giacenze di magazzino, che dovranno essere ricreati manualmente.


            Sei sicuro di voler procedere?`, true).pipe(
      tap(value => {
        if (value) {
          this.isForceVariantsRecompute = true
          if (this._baseItemToEdit?.options?.length == 0)
            this.loadOptions()
        }
      })
    ).subscribe()
  }

  deleteOptionsAndVariants() {
    this.dialogsService.confirm('Elimina varianti', `Sei sicuro di voler eliminare tutte le varianti?`, true).pipe(
      tap(value => {
        if (value) {
          this.variants = this.selectedVariants = this.options = this.selectedOptions = []
          this.selection.clear()
          this.displayedColumnsVariants = [...this.defaultDisplayedColumnsVariants]
        }
      })
    ).subscribe()
  }

  /**
   * Adds or removes option, recalculating variants table
   */
  manageRowClick(option: OptionData) {
    // removing option
    if (this.isOptionSelected(option)) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1)
      this.displayedColumnsVariants.splice(this.displayedColumnsVariants.indexOf(option.name), 1)
    } else {
      // adding option
      this.selectedOptions.push(option)
      this.addColumnToVariantsTable([option.name])
    }

    this.computeVariants()
  }

  /**
   * Whether the number of selected elements matches the total number of rows
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceVariants.data.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected, otherwise clear selection
   */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceVariants.data);
  }

  manageExpandedItemVariants(rowId: number) {
    if (this.expandedItemVariants.includes(rowId)) {
      this.expandedItemVariants.splice(this.expandedItemVariants.indexOf(rowId), 1)
    } else {
      this.expandedItemVariants.push(rowId)
    }
  }

  openCompositionForm(itemVariantToEdit: ItemVariantDetail) {
    this.dialogsService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'Composizione di ' + itemVariantToEdit.name,
        componentData: {itemVariantToEdit: itemVariantToEdit},
        component: ItemsCompositionFormComponent
      }
    }).afterClosed().pipe(switchMap(() => this.itemCompositionsService.getCompanyItemComposition(this.userReference?.companyId!, itemVariantToEdit.id)))
      .subscribe(value => {
        // updating composers
        itemVariantToEdit.itemComposers = value.itemsComposers
      })
  }

  /**
   * Adds column to displayedColumnsVariants only if it is not yet present
   */
  private addColumnToVariantsTable(columns: string[]) {
    columns.forEach((value => this.displayedColumnsVariants.splice(this.displayedColumnsVariants.length - 2, 0, value)))
  }

  logMe(row: any) {
    console.log(row)
  }


  /**
   * Returns 'true' if one of the variants is not deletable.
   * If empty list, it is not disabled
   */
  disableIfAnyVariantNotDeletable() {
    let variants: any[] = this._baseItemToEdit?.variants ?? []

    return !!variants.find(variant => this.disableIfThisVariantNotDeletable(variant))
  }

  /**
   * Returns 'true' if the variant is not deletable
   * For turned off variants, they must not be disabled (otherwise you could never turn them on).
   * Turned off variants doesn't have this field in their dataset
   */
  disableIfThisVariantNotDeletable(variant: ItemVariantData) {

    if (variant?.isDeletable == undefined)
      return false

    return !variant?.isDeletable
  }


  /**
   * Builds message for variants that can't be turned off
   */
  getNotDeletableVariants() {
    return this._baseItemToEdit?.variants.filter(variant => this.disableIfThisVariantNotDeletable(variant))
      .map(value => "&#8226; " + value.name).join('<br>')
  }


  checkBarcodeAvailability(event: KeyboardEvent) {
    this.checkingBarcode = true

    if (this.timeout !== undefined && !this.timeout.closed)
      this.timeout.unsubscribe()

    this.timeout = of(event)
      .pipe(
        delay(1000),
        switchMap((ev: KeyboardEvent) => {
          if (this.formGroup.value.barcode && this.formGroup.value.barcode !== this.lastBarcodeChecked) {
            this.barcodeAlreadyInUse = false

            this.lastBarcodeChecked = this.formGroup.value.barcode
            return this.baseItemService
              .checkBaseItemBarcode(this.userReference?.companyId!, this.formGroup.value.barcode, this.defaultVariant?.id)
          }
          return of({isUsed: false, itemNames: []})
        })
      ).subscribe(value => {
        this.barcodeAlreadyInUse = value.isUsed
        this.itemNamesUsingSameBarcode = value.itemNames
        this.checkingBarcode = false

      })
  }

  checkSkuAvailability(event: KeyboardEvent) {
    this.checkingSku = true
    if (this.timeout !== undefined && !this.timeout.closed)
      this.timeout.unsubscribe()

    this.timeout = of(event)
      .pipe(
        delay(1000),
        switchMap((ev: KeyboardEvent) => {
          if (this.formGroup.value.sku && this.formGroup.value.sku !== this.lastSkuChecked) {
            this.skuAlreadyInUse = false
            this.lastBarcodeChecked = this.formGroup.value.barcode

            return this.baseItemService
              .checkBaseItemSku(this.userReference?.companyId!, this.formGroup.value.sku, this.defaultVariant?.id)
          }
          return of({isUsed: false, itemNames: []})
        })
      ).subscribe(value => {
        this.skuAlreadyInUse = value.isUsed
        this.itemNamesUsingSameSku = value.itemNames
        this.checkingSku = false
      })

  }

  checkBarcodeAvailabilityForItemVariant(event: KeyboardEvent, index: number, value: string, variantId: number) {
    this.itemVariantWrappers[index].barcodeDirty = true
    this.itemVariantWrappers[index].checkingBarcode = true

    if (this.timeout !== undefined && !this.timeout.closed)
      this.timeout.unsubscribe()

    this.timeout = of(event)
      .pipe(
        delay(1000),
        switchMap((ev: KeyboardEvent) => {
          if (value && this.itemVariantWrappers[index].lastBarcodeChecked) {
            this.itemVariantWrappers[index].barcodeAlreadyInUse = false
            this.itemVariantWrappers[index].lastBarcodeChecked = value
            return this.baseItemService
              .checkBaseItemBarcode(this.userReference?.companyId!, value, variantId)
          }
          return of({isUsed: false, itemNames: []})
        })
      ).subscribe(value => {
        this.itemVariantWrappers[index].barcodeAlreadyInUse = value.isUsed
        this.itemVariantWrappers[index].itemNamesUsingSameBarcode = value.itemNames
        this.itemVariantWrappers[index].checkingBarcode = false
      })
  }


  checkSkuAvailabilityForItemVariant(event: KeyboardEvent, index: number, value: string, variantId: number) {
    this.itemVariantWrappers[index].skuDirty = true
    this.itemVariantWrappers[index].checkingSku = true

    if (this.timeout !== undefined && !this.timeout.closed)
      this.timeout.unsubscribe()

    this.timeout = of(event)
      .pipe(
        delay(1000),
        switchMap((ev: KeyboardEvent) => {
          if (value && this.itemVariantWrappers[index].lastSkuChecked) {
            this.itemVariantWrappers[index].lastSkuChecked = value
            this.itemVariantWrappers[index].skuAlreadyInUse = false
            return this.baseItemService
              .checkBaseItemSku(this.userReference?.companyId!, value, variantId)
          }
          return of({isUsed: false, itemNames: []})
        })
      ).subscribe(value => {
        this.itemVariantWrappers[index].skuAlreadyInUse = value.isUsed
        this.itemVariantWrappers[index].itemNamesUsingSameSku = value.itemNames
        this.itemVariantWrappers[index].checkingSku = false
      })
  }
}


/**
 * Used for checking barcode/sku in item variant section
 *
 * @param barcodeDirty True if itemVariant barcode has been changed, False otherwise
 * @param skuDirty True if itemVariant barcode has been changed, False otherwise
 */
export interface ItemVariantWrapper {
  itemNamesUsingSameSku: string[]
  itemNamesUsingSameBarcode: string[]
  barcodeDirty: boolean
  skuDirty: boolean
  checkingBarcode: boolean
  checkingSku: boolean
  lastBarcodeChecked: string
  lastSkuChecked: string
  barcodeAlreadyInUse: boolean
  skuAlreadyInUse: boolean
}

