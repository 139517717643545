import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ResponsiveService} from "../../../services/responsive.service";
import {FormControl, FormGroup} from "@angular/forms";
import {DialogsService} from "../../../services/dialogs.service";
import {BaseFilterComponent} from "../base-filter/base-filter.component";

@Component({
  selector: 'aw-date-filter', templateUrl: './date-filter.component.html', styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent extends BaseFilterComponent<{
  startDate?: string, endDate?: string
}, DateFilterOutput> {
  formGroup: FormGroup

  constructor(public responsiveService: ResponsiveService, protected override dialogsService: DialogsService) {
    super(dialogsService)
    this.formGroup = new FormGroup({
      startDate: new FormControl<Date | undefined>(undefined), endDate: new FormControl<Date | undefined>(undefined),
    })

  }

  @Output() startDateChange: EventEmitter<string> = new EventEmitter<string>()
  @Output() endDateChange: EventEmitter<string> = new EventEmitter<string>()

  // Used by Delivery Date and boh
  @Input() set initialState(initialRange: { startDate?: string, endDate?: string } | undefined) {

    console.log("cambia", initialRange)
    this.isFilterChanged = false
    this.isFiltered = true

    if (initialRange) {
      this.formGroup.controls['startDate'].setValue(initialRange.startDate)
      this.formGroup.controls['endDate'].setValue(initialRange.endDate)

      this.isFiltered = initialRange?.startDate != "" || initialRange?.endDate != ""

      this.startDateChange.emit(initialRange.startDate)
      this.endDateChange.emit(initialRange.endDate)

    }
  }

  emitOnFilter() {
    this.isFiltered = this.formGroup.controls['startDate'].value != undefined || this.formGroup.controls['endDate'].value != undefined

    // If date is not an empty string or undefined, assign a new date (formgroup value can either be a string representing a date or a date object)
    // otherwise assign undefined
    let startDate = this.formGroup.controls['startDate'].value !== "" && this.formGroup.controls['startDate'].value != undefined ?
      new Date(this.formGroup.controls['startDate'].value) :
        undefined



    let endDate = this.formGroup.controls['endDate'].value !== "" && this.formGroup.controls['endDate'].value != undefined ?
      new Date(this.formGroup.controls['endDate'].value) :
        undefined

    this.onFilter.emit({
      startDate, endDate
    })
  }

  override resetFilter() {
    this.formGroup.controls['startDate'].setValue(undefined)
    this.formGroup.controls['endDate'].setValue(undefined)
    super.resetFilter()

  }
}

export interface DateFilterOutput {
  startDate?: Date
  endDate?: Date
}
