import {Component, Inject, OnDestroy} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {GeneralDialogComponent} from "../general-dialog/general-dialog.component";
import {map, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {UserReference} from "../../global-functions-and-types";
import {UtilsService} from "../../../services/utils.service";
import {Constants} from "../../constants";

@Component({
  selector: 'aw-base-form',
  template: '',
})
export class BaseFormComponent<C, D extends {id: number}> implements OnDestroy {

  accessEntities = Constants.accessEntities
  accessLevels = Constants.accessLevels

  // The formgroup
  formGroup: FormGroup = new FormGroup<any>({})
  // The reference to the MatDialog the form is inside which
  dialogRef!: MatDialogRef<GeneralDialogComponent>

  // Any subscription to observable goes here
  subs: Subscription[] = []

  // The entity used to patch the form, usually also the return of POST and PATCH requests
  _entity!: D

  // Onject created from form merge and custom logic performed in extending component
  formEntity!: C;

  // Utility boolean
  saving = false
  loading = false

  // Disables any form field
  readOnly = false

  // Observable "Reference to user"
  userReference$: Observable<UserReference> = new Observable<UserReference>()

  // Reference to user
  userReference!: UserReference

  /**
   * @param createFunction You need to pass parameters as the service function expects them
   * @param updateFunction You need to pass parameters as expected by the service function
   * @param utilsService UtilsService
   */
  protected constructor(
    // Function that creates the entity
    @Inject(Function) protected createFunction: (...args: any[]) => Observable<D>,
    // Function that updates the entity
    @Inject(Function) protected updateFunction: (...args: any[]) => Observable<D>,
    protected utilsService: UtilsService
  )

  {
    this.userReference$ =
      of(true)
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.utilsService.getUserReference()),
          map(userReference => this.userReference = userReference)
        )

    this.subs.push(this.userReference$.subscribe())
  }

  ngOnDestroy() {
    this.subs.forEach(sub =>  sub.unsubscribe())
  }

  logMe(obj: any) {
    console.log(obj)
  }

  createEntity(...args: any[]) {
    this.saving = true
    let formValue = this.formGroup.value

    delete formValue.id;

    this.formEntity = {...this.formEntity, ...formValue}

    this.createFunction(...args, this.formEntity)
      .subscribe((createdEntity: D) => {

        this._entity = createdEntity
        this.formGroup.reset()
        this.formGroup.patchValue(createdEntity)
        this.formEntity = {} as C

        if (this.dialogRef)
          this.dialogRef.close(createdEntity)

        this.saving = false
      })
  }

  updateEntity(...args: any[]) {
    this.saving = true
    let formValue = this.formGroup.value

    this.formEntity = {...this.formEntity, ...formValue}

    this.updateFunction(...args, this.formEntity)
      .subscribe((updatedEntity: D) => {

        this._entity = updatedEntity
        this.formGroup.reset()
        this.formGroup.patchValue(updatedEntity)
        this.formEntity = {} as C

        if (this.dialogRef)
          this.dialogRef.close(updatedEntity)

        this.saving = false
      })
  }

}
