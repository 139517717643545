<!--<ng-container>-->

<div fxFlex fxLayout="row" fxLayoutAlign="start center">
  <button (click)="$event.preventDefault(); $event.stopPropagation(); removeColumn.emit()" mat-icon-button>
    <mat-icon class="close-icon" color="warn" inline="true">
      cancel
    </mat-icon>
  </button>
  {{headerName}}
</div>

<!--</ng-container>-->
