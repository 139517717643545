<table mat-table [dataSource]="dataSource">

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Stato </th>
    <td mat-cell *matCellDef="let element">
      <mat-chip [ngStyle]="{'background-color': getOrderStatusColor(element.key)}" class="mr-4"> {{element.name}}</mat-chip>
    </td>
  </ng-container>

  <!-- Description Column -->
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef> Descrizione </th>
    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
