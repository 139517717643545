import {Component} from '@angular/core';
import {Constants} from "../../../utils/constants";
import {getBackgroundColor, hasPermissionOnEntity} from '../../../utils/global-functions-and-types';
import {UserData} from "../../../interfaces/user";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {UsersService} from "../../../services/users.service";
import {ResponsiveService} from "../../../services/responsive.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {UsersFormComponent} from "../users-form/users-form.component";

@Component({
  selector: 'aw-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent extends BaseTableComponent<UserData> {


  getBackgroundColor = getBackgroundColor


  constructor(private usersService: UsersService,
              public responsiveService: ResponsiveService,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
  ) {

    let deleteFunction =
      (companyId: number, userId: number): Observable<void> =>
        usersService.deleteUser(companyId, userId)

    super('name', Constants.localStorageKeys.usersTable, deleteFunction, utilsService, snackService, dialogService)

    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(() => this.usersService.getAllCompanyUsers(this.userReference.companyId, this.fetchParams)),
          switchMap(users => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<UserData>(users.list)
            this.entityList.push(...users.list)
            this.numEntities = users.num
            return of(users.list)
          }),
          catchError((err) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare gli utenti');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())

    this.displayedColumns = [
      "name",
      "email",
      "phone",
      "address",
      "profiles",
      "roles",
      "action",
    ]


  }

  addUser() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Utente',
        componentData: {companyId: this.userReference.companyId},
        component: UsersFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: () => this.snackService.success('Utente creato'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  editUser(userId: number) {
    this.usersService.getUserById(this.userReference.companyId, userId)
      .subscribe(user => {
        let readonly = !hasPermissionOnEntity(this.userReference.user, {
          accessEntity: this.accessEntities.users,
          accessLevel: this.accessLevels.editor
        }, user)
        this.dialogService.open(GeneralDialogComponent, {
          data: {
            dialogTitle: readonly ? `DETTAGLIO – ${user.name}` : `MODIFICA – ${user.name}`,
            componentData: {entity: user, companyId: this.userReference.companyId, readOnly: readonly},
            component: UsersFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Utente aggiornato');
            },
            error: () => {
              this.snackService.success('Impossibile aggiornare l\'utente');
            },
          })
      })
  }



  getProfileColor() {
    return Constants.profileColor
  }
}


