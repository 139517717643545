import {Component} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from "@angular/router";
import {UserMeDetail} from "../../interfaces/user";
import {environment} from "../../../environments/environment";
import {Constants} from "../../utils/constants";
import {Location} from '@angular/common';

@Component({
  selector: 'aw-private-container',
  templateUrl: './private-container.component.html',
  styleUrls: ['./private-container.component.scss']
})
export class PrivateContainerComponent {
  display = true
  staging = !environment.production;
  version = environment.version;

  accessEntities = Constants.accessEntities
  accessLevels = Constants.accessLevels
  // ctrlCount = 0;
  //
  // @HostListener('document:keyup', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   this.ctrlCount = (this.ctrlCount + 1) % 2;
  //
  // }

  loggedUser?: UserMeDetail;
  constructor(public authService: AuthService, protected _location: Location, public router: Router) {
    authService.onUserChange().subscribe((u) => {
      this.loggedUser = u;
    })
  }

  backClicked() {
    this._location.back();
  }


  isActive(partialUrl: string): boolean {
    return this.router.isActive(
      partialUrl,
      {
        paths: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored',
        queryParams: 'ignored'
      }
    );
  }

  ngOnInit(): void {
    // console.log(this._location.path())
  }

  isPresent() {
    let path = this._location.path()
    //Esclude l'esistenza del pulsante indietro da:
    // Dashboard (path = "/reserved")
    // Dettagli azienda (path include "/reserved/details". Include utilizzato per i queryParam delle tab (e.g. "/reserved/details?tab=1")
    return (path !== '/reserved' && !path.includes('/reserved/details'))
  }
}
