import {Component} from '@angular/core';
import {hasPermissionOnEntity,} from "../../../utils/global-functions-and-types";
import {ShopData} from "../../../interfaces/shop";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {DialogsService} from "../../../services/dialogs.service";
import {ResponsiveService} from "../../../services/responsive.service";
import {SnackService} from "../../../services/snack.service";
import {UtilsService} from "../../../services/utils.service";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {ShopsService} from "../../../services/shops.service";
import {ShopsFormComponent} from "../shops-form/shops-form.component";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {Constants} from "../../../utils/constants";

@Component({
  selector: 'aw-shops-table',
  templateUrl: './shops-table.component.html',
  styleUrls: ['./shops-table.component.scss']
})
export class ShopsTableComponent extends BaseTableComponent<ShopData> {


  constructor(private shopsService: ShopsService,
              public responsiveService: ResponsiveService,
              protected override dialogService: DialogsService,
              public override snackService: SnackService,
              protected override utilsService: UtilsService,
  ) {


    let deleteFunction =
      (companyId: number, shopId: number): Observable<void> =>
        shopsService.deleteShop(companyId, shopId)

    super('name', Constants.localStorageKeys.shopsTable, deleteFunction, utilsService, snackService, dialogService)

    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(userReference => this.shopsService.getAllCompanyShops(this.userReference.companyId, this.utilsService.getEntityAccessIdsForUser(userReference.user.access.shops), this.fetchParams)),
          switchMap(shops => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<ShopData>(shops.list)
            this.entityList.push(...shops.list)
            this.numEntities = shops.num
            return of(shops.list)
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i punti vendita');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())

    this.availableColumns = [
      "name",
      "address",
      "phone",
      "action",
    ]


    this.displayedColumns = this.getDisplayedColumnsFromLocalStorage();
  }


  addShop() {

    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Punto vendita',
        componentData: {
          companyId: this.userReference.companyId,
          hasPermissionOnDefaultWarehouse: this.userReference.user.access.warehouses.userAuthority != undefined || this.userReference.user.access.warehouses.permission.length !== 0
        },
        component: ShopsFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: () => this.snackService.success('Punto vendita creato'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  editShop(shop: ShopData) {

    let withDefaultWarehouse = hasPermissionOnEntity(this.userReference.user, {
      accessEntity: this.accessEntities.warehouses,
      accessLevel: this.accessLevels.reader
    }, {id: shop.defaultWarehouseId})

    this.shopsService.getShop(this.userReference.companyId, shop.id, withDefaultWarehouse)
      .subscribe(shop2 => {
        let readonly = !hasPermissionOnEntity(this.userReference.user, {
          accessEntity: this.accessEntities.shops,
          accessLevel: this.accessLevels.editor
        }, shop2)

        this.dialogService.open(GeneralDialogComponent, {

          data: {
            dialogTitle: readonly ? `DETTAGLIO – ${shop2.name}` : `MODIFICA – ${shop2.name}`,
            componentData: {
              entity: shop2,
              companyId: this.userReference.companyId,
              readOnly: readonly,
              hasPermissionOnDefaultWarehouse: shop.defaultWarehouseId == undefined ? true : withDefaultWarehouse
            },
            component: ShopsFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Punto vendita aggiornato');
            },
            error: () => {
              this.snackService.success('Impossibile aggiornare il punto vendita');
            },
          })
      }, error => () => {
      })
  }

}
