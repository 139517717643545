import {Component} from '@angular/core';
import {DeliveryCompanyData} from "../../../interfaces/delivery-company";
import {hasPermissionOnEntity,} from "../../../utils/global-functions-and-types";
import {ResponsiveService} from "../../../services/responsive.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {GeneralDialogComponent} from "../../../utils/shared-components/general-dialog/general-dialog.component";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {DeliveryCompaniesService} from "../../../services/delivery-companies.service";
import {DeliveryCompaniesFormComponent} from "../delivery-companies-form/delivery-companies-form.component";
import {Constants} from "../../../utils/constants";

@Component({
  selector: 'aw-delivery-companies-table',
  templateUrl: './delivery-companies-table.component.html',
  styleUrls: ['./delivery-companies-table.component.scss']
})
export class DeliveryCompaniesTableComponent extends BaseTableComponent<DeliveryCompanyData> {

  constructor(private deliveryCompaniesService: DeliveryCompaniesService,
              public responsiveService: ResponsiveService,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
  ) {

    let deleteFunction =
      (companyId: number, deliveryCompanyId: number): Observable<void> =>
        deliveryCompaniesService.deleteDeliveryCompany(companyId, deliveryCompanyId)

    super( 'name', Constants.localStorageKeys.deliveryCompaniesTable, deleteFunction, utilsService, snackService, dialogService)


    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(userReference => {
            this.userReference = userReference
            return this.deliveryCompaniesService.getDeliveryCompanies(this.userReference.companyId, this.fetchParams)
          }),
          switchMap(deliveryCompanies => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<DeliveryCompanyData>(deliveryCompanies.list)
            this.entityList.push(...deliveryCompanies.list)
            this.numEntities = deliveryCompanies.num
            return of(deliveryCompanies.list)
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i corrieri');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())

    this.displayedColumns = [
      "name",
      "action",
    ]

  }


  addDeliveryCompany() {
    this.dialogService.open(GeneralDialogComponent, {
      data: {
        dialogTitle: 'CREAZIONE – Corriere',
        componentData: {companyId: this.userReference.companyId},
        component: DeliveryCompaniesFormComponent
      }
    })
      .afterClosed()
      .pipe(switchMap(value => value ? this.observable$ : EMPTY))
      .subscribe({
        next: () => this.snackService.success('Corriere creato'),
        error: (error) => this.snackService.error(error.message),
      })
  }

  editDeliveryCompany(deliveryCompanyId: number) {
    this.deliveryCompaniesService.getDeliveryCompany(this.userReference.companyId, deliveryCompanyId)
      .subscribe(deliveryCompany => {
        let readonly = !hasPermissionOnEntity(this.userReference.user, {
          accessEntity: this.accessEntities.deliveryCompanies,
          accessLevel: this.accessLevels.editor
        }, deliveryCompany)
        this.dialogService.open(GeneralDialogComponent, {
          data: {
            dialogTitle: readonly ? `DETTAGLIO – ${deliveryCompany.name}` : `MODIFICA – ${deliveryCompany.name}`,
            componentData: {entity: deliveryCompany, companyId: this.userReference.companyId, readOnly: readonly},
            component: DeliveryCompaniesFormComponent
          }
        })
          .afterClosed()
          .pipe(switchMap(value => value ? this.observable$ : EMPTY))
          .subscribe({
            next: () => {
              this.snackService.success('Corriere aggiornato');
            },
            error: () => {
              this.snackService.success('Impossibile aggiornare il corriere');
            },
          })
      })
  }

}
