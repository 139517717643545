import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'aw-search-option',
  templateUrl: './search-option.component.html',
  styleUrls: ['./search-option.component.scss']
})
export class SearchOptionComponent implements OnInit {


  // In the component encapsulating this component the filtered list must be pre-compiled.
  // Using an empty list does not open selection
  filtered: any[] = []

  _options: any[] = []

  // All available options.
  @Input() set options(options: any[]) {
    this._options = options
    this.filtered = options
    // this.filteredOptions.emit(this.filtered)
  }

  // Options are filtered by field defined by key. Required if predicate is undefined
  @Input() key: string | undefined

  @Input() predicate: ((item: any, searchValue: string) => boolean) | undefined

  // Emitting list of filtered options
  @Output() filteredOptions = new EventEmitter<any[]>()


  constructor() {
  }

  ngOnInit(): void {
  }

  filterOptions(value: string) {
    if (value.trim() == '') {
      this.filtered = this._options;
      this.filteredOptions.emit(this.filtered)
      return
    }

    const filterValue = value.toLowerCase().trim();
    let p: (item: any, searchValue: string) => boolean = this.predicate ?? ((_options: any, searchValue: string) => _options[this.key!].toLowerCase().includes(searchValue))
    this.filtered = this._options.filter(_options => p(_options, filterValue))

    this.filteredOptions.emit(this.filtered)
  }
}
