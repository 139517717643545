import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiResponse} from "../classes/api-response";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Constants} from "../utils/constants";
import {environment} from "../../environments/environment";
import {CompanyCreate, CompanyData, CompanyDetail} from "../interfaces/company";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";

@Injectable({
  providedIn: 'root'
})

export class CompaniesService {

  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.companies}`;

  constructor(private http: HttpClient) {
  }

  public getCompanyById(companyId: number): Observable<CompanyDetail> {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}`)
      .pipe(map(value => value.data as CompanyDetail))

  }

  public getAllCompanies(paginationParams?: FetchParams): Observable<PageConverter<CompanyData>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}`, {params : sortFilterParams})
      .pipe(map(value => value.data as PageConverter<CompanyData>));
  }

  public createCompany(company: CompanyCreate): Observable<CompanyDetail> {
    return this.http
      .post<ApiResponse>(`${this.crtlFullPath}`, company)
      .pipe(map(value => value.data as CompanyDetail));
  }

  deleteCompany(companyId: number): Observable<void> {
    return this.http
      .delete<ApiResponse>(`${this.crtlFullPath}/${companyId}`)
      .pipe(map(() => {
      }))
  }

  updateCompany(companyId: number, company: CompanyCreate): Observable<CompanyDetail> {
    return this.http
      .patch<ApiResponse>(`${this.crtlFullPath}/${companyId}`, company)
      .pipe(map(value => value.data as CompanyDetail))
  }

}
