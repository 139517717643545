import {Component, Input} from '@angular/core';
import {ItemVariantDetail} from "../../../interfaces/item-variant";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {of} from "rxjs";
import {UtilsService} from "../../../services/utils.service";
import {DialogsService} from "../../../services/dialogs.service";
import {SnackService} from "../../../services/snack.service";
import {MatTableDataSource} from "@angular/material/table";
import {Constants} from "../../../utils/constants";

@Component({
  selector: 'aw-item-variants-table',
  templateUrl: './item-variants-table.component.html',
  styleUrls: ['./item-variants-table.component.scss']
})
export class ItemVariantsTableComponent extends BaseTableComponent<ItemVariantDetail> {
  expandedComposerItems: number[] = [];

  constructor(protected override utilsService: UtilsService,
              protected override dialogService: DialogsService,
              public override snackService: SnackService) {

    let deleteFunction = (() => of())
    super('name', Constants.localStorageKeys.itemVariantsTable, deleteFunction, utilsService, snackService, dialogService);


    this.displayedColumns = [
      'name',
      'description',
      'minimumSellNetPrice',
      'maximumBuyNetPrice',
      // 'unit',
      'barcode',
      'sku',
      'action',
    ]

  }

  @Input() set variants(itemVariantDetails: ItemVariantDetail[]) {
    this.dataSource = new MatTableDataSource<ItemVariantDetail>(itemVariantDetails)
  }

  manageExpandedComposerItemRows(rowId: number) {
    if (this.expandedComposerItems.includes(rowId)) {
      this.expandedComposerItems.splice(this.expandedComposerItems.indexOf(rowId), 1)
    } else {
      this.expandedComposerItems.push(rowId)
    }
  }
}
