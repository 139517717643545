import {Component, OnDestroy, OnInit} from '@angular/core';
import {EMPTY, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {CompaniesService} from "../../../services/companies.service";

import {catchError} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {CompanyData, CompanyDetail} from "../../../interfaces/company";
import {Constants} from "../../../utils/constants";
import {UtilsService} from "../../../services/utils.service";
import {UserReference} from "../../../utils/global-functions-and-types";
import {SnackService} from "../../../services/snack.service";
import {BaseTabGroupComponent} from "../../../utils/shared-components/base-container/base-tab-group.component";


@Component({
  selector: 'aw-company-details',
  templateUrl: './company-details-container.component.html',
  styleUrls: ['./company-details-container.component.scss']
})
export class CompanyDetailsContainer extends BaseTabGroupComponent implements OnInit, OnDestroy {
  isAdmin = false
  loading = false
  saving = false

  company?: CompanyDetail

  accessLevels = Constants.accessLevels
  accessEntities = Constants.accessEntities

  company$: Observable<CompanyData>

  selector = false
  entities = Constants.entities

  userReference: UserReference | null = null

  readOnly = false;
  private subs: Subscription[] = []

  constructor(private companiesService: CompaniesService,
              private snackService: SnackService,
              private authService: AuthService,
              private utilsService: UtilsService,
              protected override router: Router,
              protected override activatedRoute: ActivatedRoute) {

    super(activatedRoute, router);
    this.activatedRoute.paramMap.subscribe({next: paramsMap => this.isAdmin = paramsMap.has('companyId')})


    this.company$ =
      of(true)
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.utilsService.getUserReference()),
          switchMap(userReference => {
            this.userReference = userReference
            return this.companiesService.getCompanyById(this.userReference?.companyId);
          }),
          tap(company => {
            this.loading = false;
            this.company = company;
          }),
          catchError((err, caught) => {
            this.loading = false;
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i dati dell\'azienda')
            return EMPTY;
          })
        )
  }

  ngOnInit(): void {

    this.subs.push(this.company$.subscribe())

    this.subs.push(this.activatedRoute.queryParams.subscribe(params => {
      if (params == undefined) this.selector = false
      else {
        let resp = params as { "itemTypePanel": string }
        this.selector = resp.itemTypePanel == 'true'
      }
    }))
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

}
