import {EventEmitter, Injectable} from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  maxSlideMove: number = environment.maxSlideWindowRatio * window.innerWidth;
  private _onChange = new EventEmitter<'desktop' | 'mobile'>()

  constructor() {
    addEventListener('resize', (event) => {
      this.isMobile = window.innerWidth < 992 || window.innerWidth < window.innerHeight
      this.maxSlideMove = environment.maxSlideWindowRatio * window.innerWidth
    });
  }

  private _isMobile = window.innerWidth < 992 || window.innerWidth < window.innerHeight;

  get isMobile() {
    return this._isMobile;
  }

  set isMobile(value: boolean) {
    this._isMobile = value;
    this._onChange.emit(this._isMobile ? 'mobile' : 'desktop');
  }

  get isDesktop() {
    return !this._isMobile;
  }

  afterChange() {
    return this._onChange.asObservable();
  }


}
