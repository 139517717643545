<ng-template #template>

  <div [ngClass]="{'flex-grow-1': input.value !== ''}" class="search-box" fxLayout="row">

    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-icon (click)=" input.focus()"
                *ngIf="input.value == ''"
                class="cursor-pointer expanded-clickable-area mb-0"
                fxFlexAlign="auto"
      >
        search
      </mat-icon>

      <mat-icon (click)="input.value = ''; clear.emit(); input.blur();" *ngIf="input.value != ''"
                class="cursor-pointer expanded-clickable-area mb-0"
                fxFlexAlign="auto"
      >
        close
      </mat-icon>

    </div>

    <div fxLayout="column" fxFlex fxLayoutAlign="center" class="ml-2">

      <input #input
             (keyup)="emitValue(input.value, $event)"
             [(ngModel)]="searchText"
             matInput
             type="text"/>
    </div>


  </div>

  <div fxLayout="row" class="selection" fxLayoutGap="8">

    <ng-container *ngIf="searchableFields.length > 0">
      <mat-form-field>
        <mat-label>Cerca in</mat-label>
        <mat-select [(ngModel)]="selectedSearchableField" (selectionChange)="setSelectedSearchableField($event)">
          <mat-option *ngFor="let searchableField of searchableFields"
                      [value]="searchableField">{{ searchableField.displayName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button class="border border-accent mr-1" color="accent"
      (click)="selectedSearchableField.displayName !== undefined && searchText.length > 0 ? generateChip(selectedSearchableField.displayName, searchText, selectedSearchableField.fieldName) : null;">
      <i class="fa-solid fa-magnifying-glass"></i>
        &nbsp;<span class="hide-mobile">Cerca</span></button>
    </ng-container>

  </div>

</ng-template>
