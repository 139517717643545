import {Component, EventEmitter, Output} from '@angular/core';
import {CompanyDetail} from "../../../interfaces/company";
import {BaseItemFullDetail} from "../../../interfaces/base-item";
import {FetchParams, GenericFilter, hasPermission} from "../../../utils/global-functions-and-types";
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {UtilsService} from "../../../services/utils.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {EMPTY, of, switchMap, tap} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {catchError} from "rxjs/operators";
import {BaseItemsService} from "../../../services/base-items.service";
import {CompaniesService} from "../../../services/companies.service";
import {ItemTypesService} from "../../../services/item-types.service";
import {Constants} from "../../../utils/constants";
import {OptionFilterItem} from "../../../utils/shared-components/status-filter/column-filter.component";

@Component({
  selector: 'aw-base-items-table',
  templateUrl: './base-items-table.component.html',
  styleUrls: ['./base-items-table.component.scss']
})
export class BaseItemsTableComponent extends BaseTableComponent<BaseItemFullDetail> {

  defaultCompany?: CompanyDetail;

  @Output() addBaseItem = new EventEmitter<void>()
  @Output() editBaseItem = new EventEmitter<BaseItemFullDetail>()
  @Output() duplicateItem = new EventEmitter<BaseItemFullDetail>()
  @Output() showBaseItemDetails = new EventEmitter<BaseItemFullDetail>;

  expandedBaseItems: number[] = [];
  expandedComposerItems: number[] = [];
  availableTypes: OptionFilterItem[] = [];

  constructor(
    private baseItemsService: BaseItemsService,
    private companiesService: CompaniesService,
    private itemTypesService: ItemTypesService,
    protected override utilsService: UtilsService,
    public override snackService: SnackService,
    protected override dialogService: DialogsService
  ) {

    let deleteFunction =
      (companyId: number, baseItemId: number) => this.baseItemsService.deleteCompanyBaseItem(companyId, baseItemId)

    super("name", Constants.localStorageKeys.baseItemsTable, deleteFunction, utilsService, snackService, dialogService)

    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          tap(userReference => {
            this.userReference = userReference
          }),
          switchMap(() => {
            return this.companiesService.getCompanyById(this.userReference.companyId)
          }),
          tap(company => this.defaultCompany = company),
          switchMap(() => {
            return this.itemTypesService.getAllCompanyItemTypes(this.userReference.companyId, {} as FetchParams)
          }),
          switchMap(itemTypes => {
            this.availableTypes = itemTypes.list.map(itemType => {
              return {
                value: itemType.name,
                key: itemType.id
              } as OptionFilterItem
            })
            return this.baseItemsService.getAllCompanyBaseItemsFull(this.userReference.companyId, this.fetchParams);
          }),
          switchMap(items => {
            this.paramLoading = false
            this.dataSource = new MatTableDataSource<BaseItemFullDetail>(items.list)
            this.entityList.push(...items.list)
            //todo per avere tutte le righe già espanse, scommentare
            // la lascio commentata perché secondo me viene una merda
            // this.expandedBaseItems.push(...items.list.map(item => item.id))
            this.numEntities = items.num
            return of(items.list)
          }),
          catchError((err, caught) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare gli ordini');
            return EMPTY;
          })
        )

    this.subs.push(this.observable$.subscribe())


    // todo: get from localstorage
    // todo: aggiungi tutte le colonne possibili
    this.availableColumns = [
      "name",
      "minimumSellNetPrice",
      "maximumBuyNetPrice",
      "unit",
      "itemTypes",
      "action"
    ]

    // todo: get from localstorage
    this.displayedColumns = [
      "name",
      "minimumSellNetPrice",
      "maximumBuyNetPrice",
      "unit",
      "itemTypes",
      "action"
    ]
  }

  hasVariants(baseItem: BaseItemFullDetail) {
    return baseItem.variants.length > 0 && (baseItem.options?.length ?? 0) > 0
  }


  manageExpandedBaseItemRows(rowId: number) {
    if (this.expandedBaseItems.includes(rowId)) {
      this.expandedBaseItems.splice(this.expandedBaseItems.indexOf(rowId), 1)
    } else {
      this.expandedBaseItems.push(rowId)
    }
  }

  manageExpandedComposerItemRows(rowId: number) {
    if (this.expandedComposerItems.includes(rowId)) {
      this.expandedComposerItems.splice(this.expandedComposerItems.indexOf(rowId), 1)
    } else {
      this.expandedComposerItems.push(rowId)
    }
  }

  editBaseItemOrOpenDetail(baseItem: BaseItemFullDetail) {
    let readonly = !hasPermission(this.userReference.user, {
      accessEntity: this.accessEntities.items,
      accessLevel: this.accessLevels.editor
    })

    if (readonly)
      this.showBaseItemDetails.emit(baseItem)
    else
      this.editBaseItem.emit(baseItem)
  }

  duplicate(baseItem: BaseItemFullDetail) {
      let baseItemDuplicate: any = {...baseItem}
      baseItemDuplicate.name = 'Copia di ' +  baseItemDuplicate.name
      delete baseItemDuplicate.id;
      baseItemDuplicate.variants.forEach((variant: { id: any; name: string; }) => {
        delete variant.id
        variant.name = 'Copia di ' + variant.name
      })

      this.duplicateItem.emit(baseItemDuplicate)
  }

  override toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data.filter((element) => element.isDeletable))
  }


  filterByItemTypes(itemTypeIds: number[]) {
    this.loadResults({itemTypeIds} as GenericFilter, this.observable$)
  }

  resetItemTypesFilter() {
    this.loadResults({itemTypeIds: ""}, this.observable$)
  }
}
