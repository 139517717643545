import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ProfileCreate, ProfileDetail,} from "../../../interfaces/profile";
import {
  AccessData,
  AccessPermissionCreate,
  AccessPermissionData,
  AccessPermissionDelete,
  AccessRoleCreate,
  AccessRoleDelete
} from "../../../interfaces/permission";
import {AccessEntities, AccessLevel, FetchParams, UserReference} from "../../../utils/global-functions-and-types";
import {ActivatedRoute, Router} from "@angular/router";
import {Constants} from "../../../utils/constants";
import {EMPTY, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {WarehousesService} from "../../../services/warehouses.service";
import {ShopsService} from "../../../services/shops.service";
import {AuthService} from "../../../services/auth.service";
import {SnackService} from "../../../services/snack.service";
import {UsersService} from "../../../services/users.service";
import {ProfilesService} from "../../../services/profiles.service";
import {PermissionsService} from "../../../services/permissions.service";
import {UtilsService} from "../../../services/utils.service";
import {catchError, map} from "rxjs/operators";
import {ShopDetail} from "../../../interfaces/shop";
import {WarehouseDetail} from "../../../interfaces/warehouse";

@Component({
  selector: 'aw-profiles-permissions-table-container',
  templateUrl: './profile-permissions-table-container.component.html',
  styleUrls: ['./profile-permissions-table-container.component.scss']
})
export class ProfilePermissionsTableContainerComponent {

  @Input() set profile(profile: ProfileDetail | undefined) {
    if (profile) {
      this.computeSetProfile(profile)
    } else {
      this.prepareProfile()
    }
    this.subs.push(this.observable$.subscribe())
  }

  @Output() loadProfile = new EventEmitter<void>()


  accessEntities = Constants.accessEntities
  accessLevels = Constants.accessLevels
  entityIdentifierToAccessEntityMap = Constants.entityIdentifierToAccessEntityMap
  entities = Constants.entities
  roleInfoMessage = Constants.roleInfoMessage
  userReference!: UserReference
  loading = false
  loadingProfile = false
  loadingAccessWarehouses = false
  loadingAccessShops = false
  isEditing = false
  observable$: Observable<void>
  saving = false
  accessWarehouses: AccessPermissionData[] = []
  accessItemWarehouses: AccessPermissionData[] = []
  accessShops: AccessPermissionData[] = []
  accessSellOrderShops: AccessPermissionData[] = []
  profileId: number | null = null
  accessWarehouses$: Observable<void>
  accessShops$: Observable<void>
  // profileAccess$: Observable<ProfileDetail>
  numWarehouses = 0;
  numShops = 0;
  // profileAccess: AccessDetail | undefined = undefined
  formGroup!: FormGroup;
  subs: Subscription[] = []

  warehouses: WarehouseDetail[] = []
  shops: ShopDetail[] = []

  _profile!: ProfileDetail

  profileStructure: ProfileDetail = {
    id: undefined,
    name: undefined,
    access: {
      companies: undefined,
      items: undefined,
      users: undefined,
      customers: undefined,
      deliveryCompanies: undefined,
      profiles: undefined,
      warehouses: {
        userAuthority: undefined,
        permission: []
      },
      itemWarehouses: {
        userAuthority: undefined,
        permission: []
      },
      shops: {
        userAuthority: undefined,
        permission: []
      },
      sellOrderShops: {
        userAuthority: undefined,
        permission: []
      }
    }
  }


  constructor(private warehousesService: WarehousesService,
              private shopsService: ShopsService,
              private authService: AuthService,
              private snackService: SnackService,
              private userService: UsersService,
              private profilesService: ProfilesService,
              private permissionsService: PermissionsService,
              private utilsService: UtilsService,
              protected activatedRoute: ActivatedRoute,
              protected router: Router
  ) {

    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required])
    })

    this.observable$ =
      of(true)
        .pipe(
          tap(() => {
            this.loading = true

          }),
          switchMap(() => this.utilsService.getUserReference()),
          switchMap(userReference => {
            this.userReference = userReference
            return this.accessWarehouses$
          }),
          switchMap(() => this.accessShops$),
          tap(() => this.loading = false),
          catchError(() => {
            this.loading = false
            return EMPTY;
          })
        )

    // // Gets all access of target user
    // this.profileAccess$ =
    //   of(true)
    //     .pipe(
    //       tap(() => this.loadingProfile = true),
    //       switchMap(() => this.isEditing ? this.profilesService.getProfileById(this.userReference.companyId, this.profileId!) : of(this._profile)),
    //       tap(profile => {
    //         this._profile = profile
    //         this.profileAccess = profile?.access
    //         this.loadingProfile = false
    //       }),
    //       catchError((err) => {
    //         this.loadingProfile = false
    //         if (!err.errors.includes('403'))
    //           this.snackService.error("Impossibile caricare i dati del profilo")
    //         return EMPTY;
    //       })
    //     )

    // Gets all warehouses permission of logged user
    this.accessWarehouses$ =
      of(true)
        .pipe(
          tap(() => this.loadingAccessWarehouses = true),
          switchMap(() => this.warehousesService.getAllCompanyWarehouses(this.userReference.companyId,
            this.utilsService.getEntityAccessIdsForUser(this.userReference.user.access.warehouses), {sort: "name asc"} as FetchParams)),
          map(warehouses => {
            this.numWarehouses = warehouses.num
            this.warehouses = warehouses.list
            this.computeAccessWarehouses();
            this.loadingAccessWarehouses = false
          }),
          catchError((err) => {
            this.loadingAccessWarehouses = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i magazzini')
            return EMPTY;
          })
        )


    // Gets all warehouses permission of logged user
    this.accessShops$ =
      of(true)
        .pipe(
          tap(() => this.loadingAccessShops = true),
          switchMap(() => this.shopsService.getAllCompanyShops(this.userReference.companyId,
            this.utilsService.getEntityAccessIdsForUser(this.userReference.user.access.shops), {sort: "name asc"} as FetchParams)),
          map(shops => {
            this.numShops = shops.num
            this.shops = shops.list
            this.computeAccessShops();
            this.loadingAccessShops = false
          }),
          catchError((err) => {
            this.loadingAccessShops = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare i punti vendita')
            return EMPTY;
          })
        )

  }

  private computeAccessShops() {
    this.accessShops = this.utilsService.getAccessEntities(this.shops, this._profile.access?.shops?.permission)
    this.accessSellOrderShops = this.utilsService.getAccessEntities(this.shops, this._profile.access?.sellOrderShops?.permission)
  }

  private computeAccessWarehouses() {
    this.accessWarehouses = this.utilsService.getAccessEntities(this.warehouses, this._profile.access?.warehouses?.permission)
    this.accessItemWarehouses = this.utilsService.getAccessEntities(this.warehouses, this._profile.access?.itemWarehouses?.permission)
  }

  saveProfile() {
    of(true)
      .pipe(
        tap(() => this.saving = true),
        switchMap(() => {
          this._profile.name = this.formGroup.value.name
          return this.profilesService.createProfile(this.userReference.companyId, this._profile as ProfileCreate)
        }),
        tap(createdProfile => {
          this.computeSetProfile(createdProfile)
          this.router.navigate(['..', createdProfile.id],
            {
              relativeTo: this.activatedRoute,
              queryParams: {tab: +this.activatedRoute.snapshot.queryParamMap.get('tab')! ?? 0}
            })
        }),
      ).subscribe({
      next: () => {
        this.loadProfile.emit()
        this.saving = false
        this.snackService.success("Profilo creato")
      },
      error: err => {
        this.saving = false
        this.snackService.error("Impossibile creare il profilo")
      }
    })
  }

  updateProfile() {
    of(true)
      .pipe(
        tap(() => this.saving = true),
        switchMap(() => {
          this._profile.name = this.formGroup.value.name
          delete this._profile.id
          return this.profilesService.updateProfile(this.userReference.companyId, this.profileId!, this._profile as ProfileCreate)
        }),
      ).subscribe({
      next: (updatedProfile) => {
        this.computeSetProfile(updatedProfile)
        this.loadProfile.emit()
        this.saving = false
        this.snackService.success("Profilo aggiornato")
      },
      error: err => {
        this.saving = false
        this.snackService.error("Impossibile aggiornare il profilo")
      }
    })
  }

  onProfilePermissionChange() {
    this.subs.push(this.observable$.subscribe())
  }

  manageSaveRole(roleCreate: AccessRoleCreate) {

    let accessEntity: AccessEntities = this.entityIdentifierToAccessEntityMap[roleCreate.entity];

    let desiredAccess = this.profileStructure.access[accessEntity];

    // If it is AccessLevel
    if (!desiredAccess) {
      (this._profile.access[accessEntity] as AccessLevel) = roleCreate.userAuthority
    } else {
        // If it is AccessData
        let currentState = this._profile.access[accessEntity];
        // If it already has a value I just change it
        if (currentState) {
          if (Constants.accessLevelOrder.includes(currentState as AccessLevel)) {
            (this._profile.access[accessEntity] as AccessLevel) = roleCreate.userAuthority
          } else
            (this._profile.access[accessEntity] as AccessData).userAuthority = roleCreate.userAuthority
        } else {
          // Otherwise I create the structure and give it the value
          (this._profile.access[accessEntity] as AccessData) = this.profileStructure.access[accessEntity] as AccessData
          (this._profile.access[accessEntity] as AccessData).userAuthority = roleCreate.userAuthority
        }
    }


    this.saveOrUpdate()
  }

  private prepareProfile() {
    // If it is undefined I give it a basic empty structure
    if (this._profile == undefined)
      this._profile = this.profileStructure
  }

  manageRemoveRole(roleDelete: AccessRoleDelete) {
    let accessEntity: AccessEntities = this.entityIdentifierToAccessEntityMap[roleDelete.entity];

    let desiredAccess = this._profile.access[accessEntity];

    if (Constants.accessLevelOrder.includes(desiredAccess as AccessLevel))
      (this._profile.access[accessEntity] as AccessLevel) = undefined;
    else
      (this._profile.access[accessEntity] as AccessData).userAuthority = undefined


    this.saveOrUpdate()
  }

  manageSetPermissions(permissionCreateList: AccessPermissionCreate[]) {


    permissionCreateList.forEach(accessPermissionCreate => {

      let accessEntity: AccessEntities = this.entityIdentifierToAccessEntityMap[accessPermissionCreate.entity];

      let permissions = (this._profile.access[accessEntity] as AccessData).permission
      // Check if it exists so I can modify it
      let found = permissions.find(p => p.entityId === accessPermissionCreate.entityId);

      // If i find it, i modify it. Then I remove it from the permissions array and reinsert it
      if (found) {
        found.userAuthority = accessPermissionCreate.userAuthority
        permissions.splice(permissions.indexOf(found), 1, found)
        // Otherwise it shall just be pushed
      } else {
        permissions.push({...accessPermissionCreate})
      }

      (this._profile.access[accessEntity] as AccessData).permission = permissions


    })

    this.saveOrUpdate()


  }

  manageRemovePermissions(permissionDeleteList: AccessPermissionDelete[]) {
    permissionDeleteList.forEach(accessPermissionDelete => {

      let accessEntity: AccessEntities = this.entityIdentifierToAccessEntityMap[accessPermissionDelete.entity];

      let permissions = (this._profile.access[accessEntity] as AccessData).permission

      // Check if it exists so I can modify it
      let found = permissions.find(p => p.entityId === accessPermissionDelete.entityId);

      // If i find it, i modify it. Then I remove it from the permissions array and reinsert it
      if (found)
        permissions.splice(permissions.indexOf(found), 1);

      (this._profile.access[accessEntity] as AccessData).permission = permissions

    })

    this.saveOrUpdate()

  }

  private saveOrUpdate() {
    if (this._profile.id)
      this.updateProfile()
    else
      this.saveProfile()
  }

  private computeSetProfile(profile: ProfileDetail) {
    this._profile = profile
    this.isEditing = !!profile?.id
    this.profileId = profile?.id ?? null
    // this.profileAccess = profile.access
    if (this.isEditing)
      this.formGroup.controls['name'].setValue(this._profile.name)
  }
}
