<ng-template #loadingGeneral>
  <mat-spinner class="center"></mat-spinner>
</ng-template>


<div fxLayout="column">

  <ng-container *ngIf="dataSource && !loading else loadingGeneral">

    <aw-actions-bar
      [requiredCreateAccessLevels]="requiredCreateAccessLevels"
      [requiredDeleteAccessLevels]="requiredDeleteAccessLevels"
      [parentEntity]="{id: shopId}"
      (deleteElements)="deleteEntities($event, userReference.companyId, shopId)"
      (load)="loadOrderSaleResults($event, observable$)"
      (openCreateForm)="addSale()"
      [displayedColumns]="displayedColumns"
      [isAllSelected]="isAllSelected()"
      [selectVisible]="selectVisible"
      [selection]="selection"
      [isDeletable]="isDeleteActionButtonVisibile()"
    >
    </aw-actions-bar>

    <div class="my-2" fxLayout="row">


      <mat-button-toggle-group aria-label="Filtri per data" value="all">
        <mat-button-toggle (click)="resetSaleDateFilter()" value="all">Tutte</mat-button-toggle>
        <mat-button-toggle (click)="getTodaySales()"
                           value="today">
          Vendite odierne
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div fxFlex></div>


      <!--      <button class="border border-accent mr-1" color="accent" mat-button (click)="openLegendStatusesDialog()">Legenda stati</button>-->


    </div>


    <table
      (matSortChange)="loadResults($event, observable$)"
      [dataSource]="dataSource"
      class="w-100 mat-elevation-z2"
      mat-table
      matSort
      matSortActive="saleDate"
      matSortDirection="asc"
      multiTemplateDataRows>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                        (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(row)"
                        *ngIf="!disableDeletionIfContentNotUpdated(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Sale Date Column -->
      <ng-container matColumnDef="saleDate">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>
          <aw-date-filter (onFilter)="filterBySaleDate($event)" (onResetFilter)="resetSaleDateFilter()"
                          (startDateChange)="filterObject.saleFrom = $event"
                          (endDateChange)="filterObject.saleTo = $event"
                          headerName="Data vendita"></aw-date-filter>

        </th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell>
          {{element.c !== null ? getDateDay(element.saleDate) + ',' : null}}
          {{element.saleDate !== null ? (element.saleDate | date: 'dd/MM/yyyy') : 'Data non specificata'}}
        </td>
      </ng-container>

      <!-- Note Column -->
      <ng-container matColumnDef="note">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>
          Note
        </th>
        <!--        todo: inline style to get the highest priority. class "normal-wrap" is overridden by angular classes-->
        <td *matCellDef="let element"
            [style]="{'white-space': 'normal!important'}" class="hide-mobile p-2"
            mat-cell>

          <ng-container *ngIf="element.note.length > 100 && !isNoteExpanded(element.id)">
            {{element.note.substring(0, 100).trimEnd() + '...'}}
            <div (click)="$event.stopPropagation(); manageExpandedNote(element.id)"
                 class="color-primary underline cursor-pointer">mostra tutto
            </div>
          </ng-container>

          <ng-container *ngIf="element.note.length > 100 && isNoteExpanded(element.id)">
            {{element.note}}
            <div (click)="$event.stopPropagation(); manageExpandedNote(element.id)"
                 class="color-accent underline cursor-pointer">nascondi
            </div>
          </ng-container>

          <ng-container *ngIf="element.note.length <= 100">
            {{element.note}}
          </ng-container>
        </td>
      </ng-container>


      <!-- Final price Column -->
      <ng-container matColumnDef="finalPrice">
        <th *matHeaderCellDef mat-header-cell>Prezzo</th>
        <td *matCellDef="let element" mat-cell> {{element.finalPrice ? (element.finalPrice | currency:'EUR') : (element.calculatedPrice | currency:'EUR')}} </td>
      </ng-container>

      <!-- Shipping address Column -->
      <ng-container matColumnDef="shippingAddress">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Indirizzo consegna</th>
        <td *matCellDef="let element" class="hide-mobile" mat-cell>
          {{element.shippingInformation && element.shippingInformation.address ?
          element?.shippingInformation?.address + ', ' + element?.shippingInformation?.city + ', ' + element?.shippingInformation?.zipCode :
          'In negozio'}}
        </td>
      </ng-container>

      <!-- Item number Column -->
      <ng-container matColumnDef="itemNumber">
        <th *matHeaderCellDef mat-header-cell>Numero Articoli</th>
        <td *matCellDef="let element" mat-cell> {{element.content.length}} </td>
      </ng-container>


      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>
          <!--          Stato-->

          <!--          todo: get initial statuses based on user settings-->
          <aw-column-filter (onFilter)="filterBySaleStatus($event)" (onResetFilter)="resetSaleStatusFilter()"
                            [options]="availableStatuses"
                            [(filterField)]="this.filterObject.statuses"
                            [placeholder]="'Stato vendita'"
                            headerName="Stato"></aw-column-filter>

        </th>
        <td *matCellDef="let element" mat-cell>
          <mat-chip-list>

            <mat-chip
              (click)="$event.stopPropagation();editSale(element.id, getConditionalStatus(element.status))"
              [ngStyle]="{'background-color': getColor(element.status)}">
              {{element.status | saleStatus}}
            </mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="hide-mobile" mat-header-cell>Azioni</th>
        <td *matCellDef="let element" class="hide-mobile" fxLayoutGap="8" mat-cell>

          <button (click)="manageExpandedRows(element.id);$event.stopPropagation()"
                  [disabled]="disabled || element.content?.length == 0" mat-icon-button matTooltip="Mostra articoli">
            <mat-icon *ngIf="!isContentExpanded(element.id)">navigate_next</mat-icon>
            <mat-icon *ngIf="isContentExpanded(element.id)">expand_more</mat-icon>
          </button>

          <button #edit
                  (click)="editSale(element.id, false);$event.preventDefault();$event.stopPropagation();"
                  *awAccess="{selected: {id: this.shopId}, accessLevel:{accessEntity: accessEntities.sellOrderShops, accessLevel: accessLevels.editor}}"
                  color="accent"
                  mat-icon-button
                  matTooltip="Modifica"
                  matTooltipPosition="above"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button #delete
                  (click)="deleteEntities([element.id], userReference.companyId, shopId);$event.preventDefault();$event.stopPropagation();"
                  *awAccess="{selected: {id: this.shopId!}, accessLevel:{accessEntity: accessEntities.sellOrderShops, accessLevel: accessLevels.admin}}"
                  [awDisableElement]="{
                    selected: [{id: shopId}],
                    condition: disableDeletionIfContentNotUpdated(element),
                    accessLevels: requiredDeleteAccessLevels,
                    component: delete}"
                  color="warn"
                  mat-icon-button
                  matTooltip="Elimina"
                  matTooltipPosition="above"
          >
            <mat-icon>delete</mat-icon>
          </button>

        </td>

      </ng-container>


      <ng-container matColumnDef="expandedSale">
        <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="p-1" mat-cell>

          <aw-sale-content-table *ngIf="expandedEntities.includes(element.id)"
                                 [defaultWarehouseId]="shop.defaultWarehouseId"
                                 [sale]="element"
                                 [shopId]="shopId"
          ></aw-sale-content-table>
        </td>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr (click)="editSale(row.id, false)" *matRowDef="let row; columns:  displayedColumns" mat-row></tr>
      <tr *matRowDef="let row; columns: ['expandedSale']"
          [fxShow]="expandedEntities.includes(row.id)" mat-row></tr>


      <tr *matNoDataRow class="mat-row">
        <td [colSpan]="displayedColumns.length" class="mat-cell text-center">
          Nessuna vendita trovata
        </td>
      </tr>


    </table>

    <mat-card *ngIf="paramLoading" class="flex justify-content-center align-items-center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </mat-card>

  </ng-container>

  <mat-paginator #paginator
                 (page)="loadResults($event, observable$)"
                 (selectionchange)="logMe($event)"
                 *ngIf="dataSource && numEntities > 0"
                 [length]="numEntities"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 class="mat-elevation-z1"
                 showFirstLastButtons
  >
  </mat-paginator>
</div>
