import {Component, Input} from '@angular/core';
import {BaseTableComponent} from "../../../utils/shared-components/base-table/base-table.component";
import {WarehousesService} from "../../../services/warehouses.service";
import {ResponsiveService} from "../../../services/responsive.service";
import {SnackService} from "../../../services/snack.service";
import {DialogsService} from "../../../services/dialogs.service";
import {UtilsService} from "../../../services/utils.service";
import {EMPTY, forkJoin, Observable, of, switchMap, tap} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {catchError, map} from "rxjs/operators";
import {ItemStocksData, ItemWarehouseCreateRequest, ItemWarehouseDetail} from "../../../interfaces/item-warehouse";
import {Constants} from "../../../utils/constants";
import {ItemCompositionData} from "../../../interfaces/item-composition";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {WarehouseData} from "../../../interfaces/warehouse";
import {ItemVariantDetail} from "../../../interfaces/item-variant";
import {hasPermission, hasPermissionOnEntity} from "../../../utils/global-functions-and-types";

@Component({
  selector: 'aw-item-stocks-table',
  templateUrl: './item-stocks-table.component.html',
  styleUrls: ['./item-stocks-table.component.scss']
})
export class ItemStocksTableComponent extends BaseTableComponent<ItemStocksData> {
  formGroup: FormGroup
  isEditActive = false
  checked = false
  // Reference to the line being updated
  updatingItemWarehouse?: ItemWarehouseDetail = undefined
  allComposersAlreadyInWarehouse = false;
  notYetList: ItemCompositionData[] = [];
  initialItemQty = 0
  allWarehouses: number[] = []
  allWarehouseStocks: { [key: string]: ItemWarehouseDetail[] } = {}
  @Input() unit?: string = ''
  loadingItemWarehouses = false
  loadingAllStocks = false
  allWarehousesStocks$: Observable<WarehouseData[]>
  measureUnit = ''
  warehouseItemsFn: (warehouseId: number) => Observable<ItemWarehouseDetail[]>;
  stateOptions = [{label: 'Si', value: true}, {label: 'No', value: false}];


  //todo: in generale, per le cose copiate da order detail container:
  // ----> cambiare "this.stocks.find()" in "this.allWarehouseStocks[`${warehouseId}`].find()
  // facendosi emettere l'ID dalla cella della tabella
  constructor(private warehousesService: WarehousesService,
              public responsiveService: ResponsiveService,
              public override snackService: SnackService,
              protected override dialogService: DialogsService,
              protected override utilsService: UtilsService,
  ) {


    super('name', Constants.localStorageKeys.itemStocksTable, () => new Observable<any>(), utilsService, snackService, dialogService)

    this.formGroup = new FormGroup({
      id: new FormControl(null),
      quantity: new FormControl('', [Validators.required, Validators.min(0)]),
    })


    this.observable$ =
      of(true)
        .pipe(
          tap(() => this.paramLoading = true),
          switchMap(() => this.userReference$),
          switchMap(() => forkJoin(this._variants.map(variant => this.warehousesService.getAllItemStocks(this.userReference?.companyId!, variant.id, this.utilsService.getEntityAccessIdsForUser(this.userReference.user.access.itemWarehouses), this.fetchParams))!)),
          switchMap(stocks => {
            stocks.map(stock => {
              this.entityList.push(...stock.list.sort((s1, s2) => (s1.warehouseName > s2.warehouseName ? 1 : -1)))
              this.numEntities = stock.num
            })
            this.allWarehouses = this.entityList.map(stock => stock.warehouseId)
            this.dataSource = new MatTableDataSource<ItemStocksData>(this.entityList)
            return this.allWarehousesStocks$
          }),
          switchMap(value => of({} as ItemStocksData[])),
          tap(() => this.paramLoading = false),
          catchError((err) => {
            this.paramLoading = false
            if (!err.errors.includes('403'))
              this.snackService.error('Impossibile caricare le giacenze');
            return EMPTY;
          })
        )


    this.allWarehousesStocks$ =
      of(true)
        .pipe(
          tap(() => this.loadingAllStocks = true),
          switchMap(() => {
            // Se c'è roba, faccio la chiamata
            if (this.allWarehouses.length > 0)
              return forkJoin(this.allWarehouses.map(warehouseId => this.warehouseItemsFn(warehouseId)))

            return of({} as any)
          }),
          tap(() => {
            this.loadingAllStocks = false
            this.loading = false
          }),
          catchError((err, caught) => {
            this.loadingAllStocks = false;
            this.loading = false

            if (!err.errors.includes('403'))
              this.snackService.error("Impossibile caricare le giacenze del magazzino")
            return EMPTY;
          }))


    this.warehouseItemsFn =
      (warehouseId: number) =>
        of(true)
          .pipe(
            tap(() => this.loadingItemWarehouses = true),
            switchMap(() => this.warehousesService.getWarehouseItems(this.userReference.companyId, warehouseId, undefined)),
            map((warehouseItems) => {
              this.allWarehouseStocks[`${warehouseId}`] = warehouseItems.list
              this.loadingItemWarehouses = true
              return warehouseItems.list
            }),
            catchError((err, caught) => {
              this.loading = false;
              this.loadingItemWarehouses = false;

              if (!err.errors.includes('403'))
                this.snackService.error("Impossibile caricare le giacenze del magazzino")
              return EMPTY;
            }))


    this.displayedColumns = [
      "variantName",
      "name",
      "quantity",
    ]

  }

  _variants: ItemVariantDetail[] = []

  @Input() set variants(variants: ItemVariantDetail[]) {
    this._variants = variants

    this.subs.push(this.observable$.subscribe())
  }

  private _getMissingQuantitiesText: string = ''

  get getMissingQuantitiesText(): string {
    return "Al momento mancano:<br>" + this._getMissingQuantitiesText;
  }

  hasUnit(formGroup: FormGroup, warehouseId: number, itemId?: number) {
    let item =
      itemId ?
        this.allWarehouseStocks[`${warehouseId}`].find((stock: ItemWarehouseDetail) => stock.item.id === itemId)!.item :
        this.allWarehouseStocks[`${warehouseId}`].find(stock => stock.item.id === formGroup.value.id)!.item

    if (item && item.unit)
      this.measureUnit = item.unit

    return !!item?.unit
  }

  getMeasureUnit(warehouseId: number, itemId: number) {
    return this.allWarehouseStocks[`${warehouseId}`].find(stock => stock.item.id === itemId)!.item.unit
  }

  onRowEditInit(warehouseId: number, variantId: number) {

    this.allComposersAlreadyInWarehouse = true
    this.notYetList = []

    let itemWarehouse: ItemWarehouseDetail = this.allWarehouseStocks[`${warehouseId}`].find(value => value.item.id === variantId)!

    this.updatingItemWarehouse = itemWarehouse

    this.initialItemQty = itemWarehouse.quantity

    this.formGroup.controls['quantity'].patchValue(this.initialItemQty)

    this.isEditActive = true
    if (itemWarehouse.item.itemComposers && itemWarehouse.item.itemComposers.length > 0) {

      if (itemWarehouse.item && itemWarehouse.item.itemComposers && itemWarehouse.item.itemComposers.length > 0) {

        // se il componente è nella lista di items inseribili allora non è censito, dunque disabilito l'opzione di scalo automatico
        // Lo aggiungo a una lista che uso per comunicare quali items non sono censiti nelle giacenze

        itemWarehouse.item.itemComposers.forEach(composer => {
          let found = this.allWarehouseStocks[`${warehouseId}`].find(iw => iw.item.id === composer.itemComposer.id)
          if (!found) {
            this.notYetList.push(composer)
            this.allComposersAlreadyInWarehouse = false
          }

        })

      }
    }
  }

  onRowEditCancel(stock: ItemStocksData) {
    stock.quantity = this.initialItemQty
    this.isEditActive = false
    this.updatingItemWarehouse = undefined
  }

  decreaseQty(stock: ItemStocksData) {
    if (stock.quantity >= 1)
      this.formGroup.controls['quantity'].patchValue(this.formGroup.controls['quantity'].value - 1)
  }

  increaseQty() {
    this.formGroup.controls['quantity'].patchValue(this.formGroup.controls['quantity'].value + 1)
  }

  missingItemsNames(): string {
    return this.notYetList.map(value => "&#8226; " + value.itemComposer.name).join('<br>')
  }

  canAutoscale(item: ItemVariantDetail, warehouseId: number, currentQuantity: number) {

    let itemsIdQty = this.allWarehouseStocks[`${warehouseId}`].map(stock => {
      return {
        id: stock.item.id,
        qty: stock.quantity
      }
    })

    let x = item.itemComposers?.map((composed) => {
      return {
        itemId: composed.itemComposer.id,
        itemName: composed.itemComposer.name,
        qtyRequired: composed.quantity * (currentQuantity - this.initialItemQty),
        qtyAvailable: itemsIdQty.find((value => value.id == composed.itemComposer.id))?.qty ?? 0
      }
    }) ?? []


    let missingItems = x.filter(value => value.qtyRequired > value.qtyAvailable).map(value =>
      "&#8226; " + value.itemName + " x" + (value.qtyRequired - value.qtyAvailable))

    this._getMissingQuantitiesText = missingItems.join('<br>')

    return x.every((d) => d.qtyRequired <= d.qtyAvailable)

  }

  getAutoScaleText(item: ItemVariantDetail) {
    let headerText = "L'articolo che stai aggiungendo è composto da:<br>"
    let footerText = "Vuoi scalare in automatico gli articoli dal magazzino corrente?"
    let str = ""
    item.itemComposers!.forEach(composer => str = str + `&#8226; ${composer.itemComposer.name + ' x' + composer.quantity} <br>`);

    return headerText + str + footerText
  }

  addItemWarehouseFn(warehouseId: number, variantId: number, quantity: number) {

    let item: ItemWarehouseDetail = this.allWarehouseStocks[`${warehouseId}`].find(stock => stock.item.id === variantId)!

    if (quantity != this.initialItemQty) {

      let createRequest: ItemWarehouseCreateRequest = {
        item: {
          item: item.item,
          quantity: item.item!.itemComposers!.length > 0 && this.checked ? quantity - this.initialItemQty : quantity,
          autoscaling: item.item!.itemComposers!.length > 0 ? this.checked : false,

        },
        createMissingComposers: false
      }
      this.saveItemWarehouse(warehouseId, createRequest)
    }
    this.isEditActive = false
  }

  saveItemWarehouse(warehouseId: number, itemWarehouseCreateRequest: ItemWarehouseCreateRequest) {
    of(true)
      .pipe(
        switchMap(() => {
            if (itemWarehouseCreateRequest.item?.autoscaling)
              return this.warehousesService.postItemWarehouse(this.userReference.companyId, warehouseId, itemWarehouseCreateRequest.item)

            return this.warehousesService.putItemWarehouse(this.userReference.companyId, warehouseId, itemWarehouseCreateRequest.item, itemWarehouseCreateRequest.createMissingComposers)
          }
        ),
        switchMap(() => {
          this.allWarehouseStocks = {}
          this.entityList = []
          this.allWarehouses = []
          this.notYetList = []

          return this.observable$
        })
      ).subscribe({
      next: () => {
        this.snackService.success('Giacenze aggiornate.')
      },
      error: err => {
        this.snackService.error('Impossibile aggiornare le giacenze')
        // this.subs.push(this.itemsWarehouse$.subscribe())
      }
    })
  }

  canEdit(warehouseId: number) {
    return hasPermissionOnEntity(this.userReference.user,
        {
          accessEntity: Constants.accessEntities.itemWarehouses,
          accessLevel: Constants.accessLevels.editor
        }, {id: warehouseId}) &&

      hasPermissionOnEntity(this.userReference.user,
        {
          accessEntity: Constants.accessEntities.warehouses,
          accessLevel: Constants.accessLevels.reader
        }, {id: warehouseId})
      && hasPermission(this.userReference.user,
        {
          accessEntity: Constants.accessEntities.items,
          accessLevel: Constants.accessLevels.reader
        })

  }

  amIUpdatingThis(stock: ItemStocksData) {
    return this.allWarehouseStocks[`${stock.warehouseId}`].find(value => value.item.id === stock.variantId) == this.updatingItemWarehouse
  }

  getItemVariant(stock: ItemStocksData): ItemVariantDetail {
    return this._variants.find(variant => variant.id === stock.variantId)!
  }
}
