<mat-spinner *ngIf="loadingImage || compressing || initializing"></mat-spinner>


<div fxLayout="column" fxLayoutGap="3%">
  <div fxLayout="row">

    <image-cropper (cropperReady)="cropperReady()"
                   (imageCropped)="imageCropped($event)"
                   (imageLoaded)="imageLoaded()"
                   *ngIf="imageToCrop"
                   [aspectRatio]="aspectRatio"
                   [containWithinAspectRatio]="false"
                   [imageBase64]="imageToCrop"
                   [maintainAspectRatio]="true"
                   [ngClass]="{
               'cropper-square-container' : !circlePicture,
               'cropper-circle-container' : circlePicture
                }"
                   format="bmp"></image-cropper>


  </div>
  <div fxLayout="row" fxLayoutGap="3%">


      <button mat-raised-button color="primary" (click)="saveAndCompressImage();confirmed = true" *ngIf="imageToCompress && !confirmed">
        Conferma immagine
      </button>

      <button mat-raised-button color="accent" (click)="chooseFile();confirmed = false" *ngIf="!initializing && !readonly">
        {{imageToCompress ? "Scegli un'altra immagine" : "Scegli immagine"}}
      </button>

      <button mat-button class="border border-warn" color="warn" (click)="cancelSelectedImage(); canceled.emit();confirmed = false" *ngIf="imageToCompress">
        Annulla
      </button>

  </div>

</div>








