import {Injectable} from '@angular/core';
import {ApiResponse} from "../classes/api-response";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Constants} from "../utils/constants";
import {DeliveryCompanyCreate, DeliveryCompanyData} from "../interfaces/delivery-company";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DeliveryCompaniesService {
  private readonly crtlFullPath = `${environment.apiUrl}/${Constants.entities.companies}`;

  constructor(private http: HttpClient) {
  }

  createDeliveryCompany(companyId: number, deliveryCompany: DeliveryCompanyCreate) {
    return this.http
      .post<ApiResponse>(
        `${this.crtlFullPath}/${companyId}/${Constants.entities.deliveryDashCompanies}`, deliveryCompany)
      .pipe(map(value => value.data as DeliveryCompanyData));
  }

  updateDeliveryCompany(companyId: number, deliveryCompanyId: number, deliveryCompany: DeliveryCompanyCreate) {
    return this.http
      .patch<ApiResponse>(
        `${this.crtlFullPath}/${companyId}/${Constants.entities.deliveryDashCompanies}/${deliveryCompanyId}`, deliveryCompany)
      .pipe(map(value => value.data as DeliveryCompanyData));

  }

  getDeliveryCompanies(companyId: number, paginationParams?: FetchParams): Observable<PageConverter<DeliveryCompanyData>> {

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})


    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.deliveryDashCompanies}`, {params: sortFilterParams})
      .pipe(map(value => value.data as PageConverter<DeliveryCompanyData>))
  }


  getDeliveryCompany(companyId: number, deliveryCompanyId: number) {
    return this.http
      .get<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.deliveryDashCompanies}/${deliveryCompanyId}`)
      .pipe(map(value => value.data as DeliveryCompanyData))
  }

  deleteDeliveryCompany(companyId: number, deliveryCompanyId: number) {
    return this.http
      .delete<ApiResponse>(`${this.crtlFullPath}/${companyId}/${Constants.entities.deliveryDashCompanies}/${deliveryCompanyId}`)
      .pipe(map(() => {}))

  }
}
