import {Injectable} from '@angular/core';
import {ApiResponse} from "../classes/api-response";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Constants} from "../utils/constants";
import {ShopCreate, ShopDetail} from "../interfaces/shop";
import {UtilsService} from "./utils.service";
import {FetchParams, PageConverter} from "../utils/global-functions-and-types";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ShopsService {

  constructor(private http: HttpClient) {
  }

  getAllCompanyShops(companyId: number, shopsIds: number[], paginationParams?: FetchParams) : Observable<PageConverter<ShopDetail>>{

    let sortFilterParams = new HttpParams({fromObject: {...paginationParams}})

    let queryParams = UtilsService.buildParams(shopsIds, sortFilterParams);

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}`, {params: queryParams})
      .pipe(map(value => value.data as PageConverter<ShopDetail>))
  }

  getShop(companyId: number, shopId: number, withDefaultWarehouse: boolean = false) {

    let params = new HttpParams()

    params = params.append('warehouse', withDefaultWarehouse.toString())

    return this.http
      .get<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}`, {
        params
      })
      .pipe(map(value => value.data as ShopDetail))
  }

  createShop(companyId: number, shop: ShopCreate) {
    return this.http
      .post<ApiResponse>(
        `${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}`, shop)
      .pipe(map(value => value.data as ShopDetail));
  }

  updateShop(companyId: number, shopId: number, shop: ShopCreate) {
    return this.http
      .patch<ApiResponse>(
        `${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}`, shop)
      .pipe(map(value => value.data as ShopDetail));

  }

  deleteShop(companyId: number, shopId: number) {
    return this.http
      .delete<ApiResponse>(`${environment.apiUrl}/${Constants.entities.companies}/${companyId}/${Constants.entities.shops}/${shopId}`)
      .pipe(map(() => {
      }))

  }
}
