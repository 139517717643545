<div fxLayout="column" fxLayoutGap="10" class="mb-2_5">

  <div fxLayoutAlign="space-between center">

    <!--  todo: https://angular.io/guide/two-way-binding-->

    <div fxLayout="row" class="child" fxLayoutGap="8">
      <aw-search-box
        (clear)="!searchableFields.length && clearAndEmitLoad()"
        (keyup)="lastKey = $event.key"
        (value)="!searchableFields.length && onKeySearch($event)"
        *ngIf="isSearchable"
        [searchableFields]="searchableFields"
        (emitFilterObject)="load.emit($event); filterObject = $event"
        [(chipList)]="chipList"
      >
      </aw-search-box>
    </div>


    <!--  If checkbox column in hidden and entity is creatable-->
    <div fxLayout="row" class="child" fxLayoutGap="8" fxLayoutAlign="end">
      <ng-container *ngIf="!selectVisible && isCreatable">

        <!--  If it is a simple entity-->
        <ng-container *ngIf="accessEntity">

          <button
            (click)="openForm()"

            *awAccess="{accessLevel: {accessEntity: accessEntity, accessLevel: accessLevels.editor, checkRolesOnly: true}}"

            color="primary"
            mat-raised-button
            matTooltip="Aggiungi"
            matTooltipPosition="above"
          >
            <i class="fa-solid fa-plus"></i>
            &nbsp;<span class="hide-mobile">Aggiungi</span>
          </button>
        </ng-container>

        <!--  If it is a derived entity (e.g. itemWarehouses, sellOrderShops-->
        <button
          *ngIf="parentEntity"
          #createButton
          (click)="openForm()"

          [awDisableElement]="{
      logicalAnd: true,
              selected: [parentEntity],
              accessLevels: requiredCreateAccessLevels,
              component: createButton
          }"

          color="primary"
          mat-raised-button
          matTooltip="Aggiungi"
          matTooltipPosition="above"
        >
          <i class="fa-solid fa-plus"></i>
          &nbsp;<span class="hide-mobile">Aggiungi</span>
        </button>


      </ng-container>

      <ng-container *ngIf="isSelectable">
        <button (click)="manageCheckboxColumn()"
                color="accent"
                mat-raised-button
                matTooltip="Seleziona"
                matTooltipPosition="above"
        >
          <i class="fa fa-square-check"></i>
          &nbsp;<span class="hide-mobile">{{selectVisible ? "Nascondi" : "Selezione"}}</span>
        </button>
      </ng-container>
      <ng-container *ngIf="isExportable">
        <button mat-button
                (click)="export.emit()"
                color="accent"
                mat-raised-button
                matTooltip="Esporta"
                matTooltipPosition="above"
        >
          <i class="fa fa-download"></i>
          &nbsp;<span class="hide-mobile">Esporta</span>
        </button>
      </ng-container>

      <ng-container *ngIf="selection.selected.length > 0 && isDeletable">

        <ng-container *ngIf="accessEntity">

          <button #b
                  (click)="deleteSelected()"

                  *awAccess="{accessLevel:{accessEntity: accessEntity, accessLevel: accessLevels.admin}}"

                  [awDisableElement]="{
                  selected: selection.selected,
                  condition: selection.selected.length < 1,
                  accessLevels: [{accessEntity: accessEntity, accessLevel: accessLevels.admin}],
                  component: b}"

                  color="warn"
                  mat-raised-button
                  matTooltip="Elimina selezionati"
                  matTooltipPosition="above"
          >
            <i class="fa fa-trash"></i>

            &nbsp;<span class="hide-mobile">Elimina</span>
          </button>

        </ng-container>

        <ng-container *ngIf="parentEntity">

          <button #b
                  (click)="deleteSelected()"

                  [awDisableElement]="{
                  selected: [{id: parentEntity.id}],
                  logicalAnd: true,
                  condition: selection.selected.length < 1,
                  accessLevels: requiredDeleteAccessLevels,
                  component: b}"

                  color="warn"
                  mat-raised-button
                  matTooltip="Elimina selezionati"
                  matTooltipPosition="above"
          >
            <i class="fa fa-trash"></i>

            &nbsp;<span class="hide-mobile">Elimina</span>
          </button>

        </ng-container>


      </ng-container>
    </div>
  </div>




  <mat-chip-list fxLayout="row" fxLayoutAlign="start" *ngIf="chipList">
    <mat-chip *ngFor="let chip of chipList; let i = index" [removable]="true"
              (removed)="removeChip(i, chip.fieldName)">
      {{ chipList[i].fieldDisplayName + ":"}} {{ chipList[i].value }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>

</div>
