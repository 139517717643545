<ng-template #searchOptionTemplate>
<!--  class="w-full pt-4 mt-4 pb-2"-->
    <mat-form-field appearance="outline" (click)="$event.stopPropagation();$event.preventDefault();"
                    class="w-full">

      <input #input (click)="$event.stopPropagation();$event.preventDefault();"
             (keydown.space)="$event.stopPropagation();$event.preventDefault();input.value = input.value +  ' ' "
             (keyup)="$event.stopPropagation();$event.preventDefault(); filterOptions(input.value)"
             matInput placeholder="Cerca"
             type="text">
      <mat-icon matPrefix>
        search
      </mat-icon>
    </mat-form-field>
</ng-template>

<mat-option class="h-auto pt-1 sticky no-checkbox" (click)="$event.stopPropagation();$event.preventDefault();">
  <ng-container [ngTemplateOutlet]="searchOptionTemplate">
  </ng-container>
</mat-option>
