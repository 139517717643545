<div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

  <span>{{headerName}}</span>


  <button (click)="$event.preventDefault(); $event.stopPropagation();" [disabled]="hiddenColumns.length <= 0" [matMenuTriggerFor]="appMenu"
          color="primary" mat-icon-button>
    <mat-icon
      [matTooltipPosition]="'above'"
      [matTooltip]="hiddenColumns.length <= 0 ? 'Tutte le colonne sono state inserite' : 'Inserisci colonne'" class="mr-2">
      add_circle
    </mat-icon>
  </button>


  <mat-menu #appMenu="matMenu">
    <div *ngIf="hiddenColumns.length > 0" class="my-2 mx-4">
      <div>
        <p class="font-normal">Seleziona le colonne da inserire </p>
        <mat-form-field (click)="$event.stopPropagation()">
          <mat-select (click)="$event.stopPropagation()" [(ngModel)]="selectedColumns"
                      multiple placeholder="Colonne da inserire">
            <mat-option (click)="$event.stopPropagation()" *ngFor="let col of hiddenColumns"
                        [value]="col">{{convertColumnName(col)}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div fxLayout="row">
        <button (click)="insertColumns.emit(selectedColumns)" class="border border-primary mr-1" color="primary" fxFlex
                mat-button>Inserisci colonne
        </button>
      </div>
    </div>

    <div *ngIf="hiddenColumns.length <= 0" class="my-2 mx-4">
      <p class="font-normal">Attualmente tutte le colonne sono mostrate nella tabella </p>
    </div>

  </mat-menu>

</div>
