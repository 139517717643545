import {Component} from '@angular/core';


@Component({
  selector: 'aw-profiles',
  templateUrl: './profiles-container.component.html',
  styleUrls: ['./profiles-container.component.scss']
})
export class ProfilesContainerComponent {

}
