import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EMPTY, Observable, of, switchMap, tap} from "rxjs";
import {Constants} from '../../utils/constants';
import {AccessLevel, EntityIdentifier} from "../../utils/global-functions-and-types";
import {
  AccessPermissionCreate,
  AccessPermissionData,
  AccessPermissionDelete,
  AccessRoleCreate,
  AccessRoleDelete
} from "../../interfaces/permission";
import {BaseTableComponent} from "../../utils/shared-components/base-table/base-table.component";
import {SnackService} from "../../services/snack.service";
import {WarehousesService} from "../../services/warehouses.service";
import {ShopsService} from "../../services/shops.service";
import {DialogsService} from "../../services/dialogs.service";
import {UtilsService} from "../../services/utils.service";
import {MatTableDataSource} from "@angular/material/table";
import {WarehouseData} from "../../interfaces/warehouse";
import {ShopData} from "../../interfaces/shop";


@Component({
  selector: 'aw-permissions-table',
  templateUrl: './permissions-table.component.html',
  styleUrls: ['./permissions-table.component.scss']
})
export class PermissionsTableComponent extends BaseTableComponent<AccessPermissionData> {

  @Input() warehouses: WarehouseData[] = []
  @Input() shops: ShopData[] = []

  @Input() set accessPermissions(accessEntities: AccessPermissionData[]) {
    this._accessEntities = accessEntities
    this.setInitialEntities()
    this.dataSource = new MatTableDataSource<AccessPermissionData>(accessEntities)
  }

  @Input() set permissionOwner(entity: { entityName: EntityIdentifier, permissionOwnerId: number | null }) {
    this._entityName = entity.entityName
    this._permissionOwnerId = entity.permissionOwnerId
  }

  @Input() set role(role: AccessLevel) {
    if (role) {
      this._role = role
      this.initialRole = role
    }
  }

  @Input() globalUserAuthority: AccessLevel
  @Input() _role: AccessLevel = undefined

  _entityName!: EntityIdentifier
  _permissionOwnerId: number | null = null

  initialEntities = new Map<number, AccessLevel>()

  accessLevelOrder = Constants.accessLevelOrder

  _accessEntities: AccessPermissionData[] = []

  permissionsToEdit: AccessPermissionData[] = []

  initialRole: AccessLevel = undefined

  multiplePermissionValue: undefined

  showSaveButton = false
  saving = false


  @Output() onUserPermissionChange = new EventEmitter<void>();
  @Output() onSaveRole: EventEmitter<AccessRoleCreate> = new EventEmitter<AccessRoleCreate>()
  @Output() onRemoveRole: EventEmitter<AccessRoleDelete> = new EventEmitter<AccessRoleDelete>()
  @Output() onSetPermissions: EventEmitter<AccessPermissionCreate[]> = new EventEmitter<AccessPermissionCreate[]>()
  @Output() onRemovePermissions: EventEmitter<AccessPermissionDelete[]> = new EventEmitter<AccessPermissionDelete[]>()

  constructor(
    public warehousesService: WarehousesService,
    public shopsService: ShopsService,
    protected override dialogService: DialogsService,
    public override snackService: SnackService,
    protected override utilsService: UtilsService,

  ) {
    super('name', Constants.localStorageKeys.permissionsTable,() => new Observable<any>(), utilsService, snackService, dialogService)

    this.observable$ =
      of(true)
        .pipe(
          switchMap(() => this.userReference$),
          switchMap(() => of([] as AccessPermissionData[]))
        )

    this.dataSource = new MatTableDataSource<AccessPermissionData>()

    this.displayedColumns = [
      'name',
      'permission',
      'action',
    ]

    this.subs.push(this.observable$.subscribe())

  }

  reset() {
    this._role = this.initialRole
    this.globalUserAuthority = undefined
    this.multiplePermissionValue = undefined
    this._accessEntities?.forEach(value => value.userAuthority = this.initialEntities.get(value.entityId))
    this.showSaveButton = false
  }


  isDeletable(accessPermission: AccessPermissionData) {
    return this.initialEntities.get(accessPermission.entityId) != undefined
  }

  isSelectable() {
    return !this.showSaveButton && !!this._accessEntities.find(accessEntity => accessEntity.userAuthority != undefined)
  }

  shouldISave() {
    this.permissionsToEdit = this._accessEntities?.filter(value => value.userAuthority != this.initialEntities.get(value.entityId))
    this.showSaveButton = this.permissionsToEdit.length > 0 || this._role !== this.initialRole
  }

  setMultiplePermission($event: any) {
    this._accessEntities?.forEach(value => {
      value.userAuthority = $event.value
    })
    this.dataSource.data.forEach(value => {
      value.userAuthority = $event.value
    })

    this.shouldISave()
  }


  saveRoleAndPermissions() {

    if (this.initialRole != this._role) {
      if (this._role != undefined) {
        let role: AccessRoleCreate = {
            userId: this._permissionOwnerId!,
            entity: this._entityName,
            userAuthority: this._role
        }
        this.onSaveRole.emit(role)
      } else {
        let role: AccessRoleDelete = {
          userId: this._permissionOwnerId!,
          entity: this._entityName,
        }
        this.onRemoveRole.emit(role)
      }

    }

    if (this.permissionsToEdit.length > 0)
      this.onSetPermissions.emit(this.permissionsToEdit.map(value => {
        return {
          userId: this._permissionOwnerId!,
          entity: this._entityName,
          userAuthority: value.userAuthority!,
          entityId: value.entityId
        }
      }))
  }

  removeRole() {
    this.dialogService.confirm('Conferma rimozione', "Sei sicuro di voler rimuovere questo ruolo?")
      .pipe(
        switchMap(value => {
          if (value)
            this.onRemoveRole.emit({
              userId: this._permissionOwnerId!,
              entity: this._entityName,
            })
          return EMPTY
        }),
      )
      .subscribe()
  }


  deleteEntity(accessPermissionData?: AccessPermissionData) {
    this.dialogService.confirm('Conferma rimozione', 'Sei sicuro di voler rimuovere i permessi selezionati?')
      .pipe(
        tap(value => {
          if (value) {
            if (!accessPermissionData)
              this.removePermissions(this.selection.selected.filter(value => value.userAuthority))
            else if (accessPermissionData.userAuthority)
              this.removePermissions([accessPermissionData].filter(value => value.userAuthority))
          }
        })).subscribe()
  }

  removePermissions(accessPermissionData: AccessPermissionData[]) {

    this.onRemovePermissions.emit(accessPermissionData.map(value => {
      return {
        userId: this._permissionOwnerId!,
        entity: this._entityName,
        entityId: value.entityId
      }
    }))
  }


  private setInitialEntities() {
    this.initialEntities = new Map<number, AccessLevel>()
    this._accessEntities?.forEach(entity => this.initialEntities.set(entity.entityId, entity.userAuthority))
  }
}

