import {Constants} from "./constants";
import {RequiredAccessLevel} from "../classes/required-access-level";
import {UserDetail} from "../interfaces/user";
import {AccessData, AccessDetail} from "../interfaces/permission";
import {SellOrderFilter} from "../interfaces/sell-order";

// Defines access level
export type AccessLevel = 'OWNER' | 'ADMIN' | 'EDITOR' | 'READER' | undefined

// Defines all entities that have an AccessLevel
export type EntityIdentifier =
  'COMPANIES'
  | 'USERS'
  | 'ITEMS'
  | 'WAREHOUSES'
  | 'ITEMWAREHOUSES'
  | 'SHOPS'
  | 'SELLORDERSHOPS'
  | 'CUSTOMERS'
  | 'SUPPLIERS'
  | 'DELIVERYCOMPANIES'
  | 'PROFILES'


// Used to index AccessDetail
export type AccessEntities = keyof AccessDetail
export type FilterObjectFields = keyof SellOrderFilter
export type FetchParamsFields = keyof FetchParams


/**
 *  - dialogTitle: The title of the dialog
 *  - componentData: Anything the component needs to work as you expect
 *  - component: The component to open inside the dialog
 */
export interface DialogData {
  dialogTitle: string,
  componentData: any,
  component: any
}


export type DisableComponent = { selected?: any, condition?: boolean, logicalAnd?: boolean, accessLevels: RequiredAccessLevel[], component?: any }

export type AwAccessType = { selected?: any, accessLevel: RequiredAccessLevel }

export function hasPermission(user: UserDetail, requiredAccess: RequiredAccessLevel): boolean {

  if (requiredAccess.checkCompany)
    if (user.companyId == null && requiredAccess.needsCompany ||
      user.companyId != null && !requiredAccess.needsCompany)
      return false



  // todo manage unknown provided level (entity is constrained by keyof)
  let userLevel = Constants.accessLevelOrder.includes(user.access[requiredAccess.accessEntity!] as AccessLevel) ?
    Constants.accessLevelOrder.indexOf(user.access[requiredAccess.accessEntity!] as AccessLevel) :
    Constants.accessLevelOrder.indexOf((user.access[requiredAccess.accessEntity!] as AccessData)?.userAuthority as AccessLevel);

  if (requiredAccess.checkRolesOnly)
    return userLevel >= 0 && userLevel <= Constants.accessLevelOrder.indexOf(requiredAccess.accessLevel!)

  // user has role
  if (userLevel >= 0 && userLevel <= Constants.accessLevelOrder.indexOf(requiredAccess.accessLevel!)) {
    return true;
  }

  // need to check permissions
  return (user.access[requiredAccess.accessEntity!] as AccessData)?.permission?.some(
    accessPermission => {
      let userPermissionLevel = Constants.accessLevelOrder.indexOf(accessPermission.userAuthority as AccessLevel)
      return userPermissionLevel <= Constants.accessLevelOrder.indexOf(requiredAccess.accessLevel!) && userPermissionLevel >= 0
    }
  ) ?? false
}


// todo: document
export function partialAccessSanitize(value: RequiredAccessLevel) {
  value.accessEntity = value.accessEntity ?? Constants.defaultAccessLevel.accessEntity
  value.accessLevel = value.accessLevel ?? Constants.defaultAccessLevel.accessLevel
  value.needsCompany = value.needsCompany ?? Constants.defaultAccessLevel.needsCompany
  value.checkRolesOnly = value.checkRolesOnly ?? Constants.defaultAccessLevel.checkRolesOnly
  value.checkCompany = value.checkCompany ?? Constants.defaultAccessLevel.checkCompany
  return value
}

export function getBackgroundColor(role_type: unknown) {
  let rt = role_type as AccessLevel
  return Constants.roleColorConversion[rt!]
}


export function hasPermissionOnEntity(user: UserDetail, requiredAccess: RequiredAccessLevel, entity: any): boolean {

  if (requiredAccess.checkCompany)
    if (user.companyId == null && requiredAccess.needsCompany ||
      user.companyId != null && !requiredAccess.needsCompany)
      return false



  let userLevel = Constants.accessLevelOrder.includes(user.access[requiredAccess.accessEntity!] as AccessLevel) ?
    Constants.accessLevelOrder.indexOf(user.access[requiredAccess.accessEntity!] as AccessLevel) :
    Constants.accessLevelOrder.indexOf((user.access[requiredAccess.accessEntity!] as AccessData)?.userAuthority as AccessLevel);

  if (requiredAccess.checkRolesOnly)
    return userLevel >= 0 && userLevel <= Constants.accessLevelOrder.indexOf(requiredAccess.accessLevel!)


  // user has role
  if (userLevel >= 0 && userLevel <= Constants.accessLevelOrder.indexOf(requiredAccess.accessLevel!)) {
    return true;
  }


  // need to check granular permission on entity

  // determine if user has permission on specified entity
  let found = (user.access[requiredAccess.accessEntity!] as AccessData)?.permission.find(value => value.entityId === entity.id)

  let userPermissionLevel = Constants.accessLevelOrder.indexOf(found?.userAuthority as AccessLevel) ?? -1

  return userPermissionLevel <= Constants.accessLevelOrder.indexOf(requiredAccess.accessLevel!) && userPermissionLevel >= 0

}

/**
 *  Used to build HTTP Parameters for GET requests that return a collection of elements.
 * - page: page number
 * - num : number of elements to fetch
 * - sort: field to sort and sort direction (e.g. "name asc / surname desc")
 * - keyword: keyword used for filtering
 * - itemTypeIds: used for items
 */
export interface FetchParams extends GenericFilter {
  page: number,
  num: number,
  sort: string,
  keyword: string,
  itemTypeIds?: number[]
}

export interface GenericFilter {}


/**
 * - User: The User Data Object
 * - Company ID: Yours, or the one you're navigating (for AW Users)
 * - isAWUser: Tells if user is AW user
 */
export interface UserReference {
  user: UserDetail,
  companyId: number,
  isAWUser: boolean
}

/**
 * Used for Spring pagination
 * - list: Fetched list of entities
 * - num: The count of all entities existing in repository (this is not the number of fetched entites!)
 */
export interface PageConverter<T> {
  list: Array<T>,
  num: number
}

/***
 * Makes combinations of n arrays
 * @param data
 * @returns array of arrays, each inner array contains the combined tuple of T
 */
export function combine<T extends Array<any>>(...data: T[]): T[] {

  if (data.length == 0)
    return [] as T[]

  if (data.length == 1)
    return data[0].map(o => [o]) as T[]

  if (data.length == 2) {
    const res: T[] = [];
    const da: T = data[0]
    const db: T = data[1]

    for (let i = 0; i < da.length; i++) {
      for (let j = 0; j < db.length; j++) {
        res.push([da[i], db[j]].flat() as T)
      }
    }

    return res;
  }

  const [d1, d2, ...c] = data;

  const e = combine(d1 as T, d2 as T);

  return combine(e as T, ...c)

}

export function getOrderStatusColor(status: string) {
  switch (status) {

    case Constants.orderStatuses.toBeAssigned:
      return '#f593a8'
    case Constants.orderStatuses.inProgress:
      return '#bf93f5'
    case Constants.orderStatuses.readyForPickupShop:
    case Constants.orderStatuses.readyForPickupRider:
    case Constants.orderStatuses.readyForPickupCourier:
      return '#ebad73'
    case Constants.orderStatuses.deliveringRider:
    case Constants.orderStatuses.deliveringCourier:
      return '#88adf2'
    case Constants.orderStatuses.deliveryFailedRider:
    case Constants.orderStatuses.deliveryFailedCourier:
    case Constants.orderStatuses.failed:
      return '#ed6f6f'
    case Constants.orderStatuses.deliveredRider:
    case Constants.orderStatuses.deliveredCourier:
    case Constants.orderStatuses.pickedUp:
      return '#b0ed6f'
    case Constants.orderStatuses.completed:
      return '#62b61b'

  }
  return status.includes(Constants.orderStatuses.failed.toUpperCase()) ? '#ed6f6f' : '#88adf2'
}

export function getPaymentStatusColor(status: string) {
  switch (status) {
    case Constants.orderPaymentStatuses.toBePaid:
      return '#f593a8'
    case Constants.orderPaymentStatuses.advance:
      return '#ebad73'
    case Constants.orderPaymentStatuses.paid:
      return '#22A605FF'
  }
  return '#f593a8'
}

export function getSaleStatusColor(status: string): string | void {
  switch (status) {
    case Constants.saleStatuses.open:
      return '#bf93f5'
    case Constants.saleStatuses.canceled:
      return '#ed6f6f'
    case Constants.saleStatuses.closed:
      return '#c4f29d'
  }
}

// value is for automatic stock management
export interface RollbackStatus {
  name: string
  value: boolean
  tooltip: string
}

// Given a string date return the italian day name
export function getDateDay(deliveryDate: string) {
  return Constants.daysTranslation[new Date(deliveryDate).getDay()]
}
