<ng-template #loadingData>
  <mat-spinner class="center mb-4" color="primary"></mat-spinner>
</ng-template>

<!-- region Scegli se vuoi modificare l'anagrafica o il contenuto -->
<div
  *ngIf="(!readOnlyContent && !readOnlySale && isEditing && !(updatingSale || updatingSaleContent || _managingSaleStatus || _managingSaleContent || showOnlyContent))">

  <ng-container *ngIf="availableSaleStates.length > 1">

    <button (click)="updatingSale = true"
            class="w-100 border border-primary mr-1"
            color="primary"
            mat-button
    >Modifica vendita
    </button>

    <mat-divider class="my-2"></mat-divider>

    <ng-container *ngIf="canEditContent()">
      <button (click)="openUpdateSaleContentSection()"
              class="w-100 border border-accent mr-1"
              color="accent"
              mat-button
      >Modifica contenuto vendita
      </button>

      <mat-divider class="my-2"></mat-divider>
    </ng-container>

    <button (click)="openManageSaleStatusSection()"
            class="w-100 border border-accent mr-1"
            color="accent"
            mat-button
    >Gestisci stato vendita
    </button>
  </ng-container>


</div>
<!-- endregion Scegli se vuoi modificare l'anagrafica o il contenuto -->

<!--  region sale status-->
<div *ngIf="_managingSaleStatus && !showOnlyContent && !readOnlySale && !readOnlyContent">

  <div fxLayout="column">

    <div fxLayout="row">


      <mat-form-field fxFlex>
        <mat-label>Stato della vendita</mat-label>

        <mat-select
          (selectionChange)="setSelectedSaleStatus($event);onStatusChange()"
          [disabled]="_entity?.status !== saleStatuses.open"
        >
          <mat-option *ngIf="availableSaleStates.length === 0">
            Nessuno stato disponibile.
          </mat-option>
          <mat-option *ngFor="let status of availableSaleStates" [value]="status">
            {{status | saleStatus}}
          </mat-option>

        </mat-select>

      </mat-form-field>
    </div>

    <div fxLayout="row">

      <div *ngIf="showManageWarehouseButton(selectedStatus)" fxFlex>

        <div *ngIf="!isSaleFailed"
             class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
          <p fxLayoutAlign="center center">
            <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
            Devi gestire tutti gli articoli prima di continuare.
          </p>
        </div>

        <div *ngIf="autoscaleErrorOnSaleClose && !isSaleFailed"
             class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
          <p fxLayoutAlign="center center">
            <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
            Non tutte le giacenze possono essere scalate automaticamente.
            <br>
            Gestiscile nelle impostazioni avanzate.
          </p>
        </div>

        <div *ngIf="isSaleFailed"
             class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
          <p fxLayoutAlign="center center">
            <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
            Devi gestire il rollback delle giacenze prima di continuare.
          </p>
        </div>


        <mat-divider></mat-divider>


        <!--        <div>-->
        <mat-accordion>
          <mat-expansion-panel [expanded]="autoscaleErrorOnSaleClose">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Impostazioni avanzate
              </mat-panel-title>
            </mat-expansion-panel-header>


            <div *ngFor="let item of itemsToHandle; let i = index" fxLayout="column">

              <div fxFlex fxLayout="row">
                <p>{{item.itemVariant.name}} x{{item.quantity}} da {{item.warehouse?.name}}</p>
              </div>

              <mat-form-field fxFlex="100">

                <!--                  If I am completing sale-->
                <mat-select
                  (selectionChange)="canAutoscaleOnSaleClose(); setGlobalErrorOnSaleClose(); setGlobalAutoscaleDropdown()"
                  *ngIf="!isSaleFailed"
                  [(ngModel)]="closingWrappers[i].selectedStatus"
                >
                  <mat-option *ngFor="let status of updatedStates" [value]="status">
                    {{status! | saleContentStatus}}
                  </mat-option>
                </mat-select>
                <button (click)="closingWrappers[i].selectedStatus = undefined;unsetGeneralContentStatus()"
                        *ngIf="closingWrappers[i].selectedStatus"
                        mat-button
                        mat-icon-button
                        matSuffix>
                  <mat-icon>close</mat-icon>
                </button>

                <!--                  If I am canceling sale-->
                <mat-select
                  (selectionChange)="canAutoscaleOnSaleClose(); setGlobalErrorOnSaleClose(); setGlobalRollbackDropdown()"
                  *ngIf="isSaleFailed"
                  [(ngModel)]="closingWrappers[i].selectedRollback"
                  [matTooltip]="closingWrappers[i].selectedRollback?.tooltip ?? ''"
                >
                  <mat-option *ngFor="let status of rollbackStates" [value]="status">
                    {{status.name}}
                  </mat-option>
                </mat-select>
                <button (click)="closingWrappers[i].selectedRollback = undefined;unsetGeneralContentRollback()"
                        *ngIf="closingWrappers[i].selectedRollback"
                        mat-button
                        mat-icon-button
                        matSuffix>
                  <mat-icon>close</mat-icon>
                </button>

              </mat-form-field>
              <div>

                <div *ngIf="closingWrappers[i] && closingWrappers[i].showAutoscaleError"
                     class="border border-warn disclaimer-box mat-background-light p-2_5 mb-2_5" fxFlex>
                  <p>
                    {{closingWrappers[i].autoscaleError!}}
                  </p>
                </div>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>


        <div class="mt-1_5 w-100">

          <!--      in caso di completamento -->
          <mat-form-field *ngIf="!isSaleFailed" fxFlex>
            <mat-label>Gestione generale</mat-label>

            <mat-select
              (selectionChange)="setGeneralStatus($event);updateContentStatusAndCheckErrorsOnSaleClose()"
              *ngIf="!isSaleFailed"
              [(ngModel)]="generalContentStatus"
              [disabled]="_entity?.status !== saleStatuses.open"

            >
              <mat-option *ngIf="updatedStates.length === 0">
                Nessuno stato disponibile.
              </mat-option>
              <mat-option *ngFor="let status of updatedStates" [value]="status">
                {{status | saleContentStatus}}
              </mat-option>
            </mat-select>

            <button (click)="unsetGeneralContentStatus();unsetAllContentStatus()"
                    *ngIf="generalContentStatus"
                    [disabled]="_entity?.status !== saleStatuses.open"
                    mat-button
                    mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>


          <!--      in caso di annullamento -->
          <mat-form-field *ngIf="isSaleFailed">
            <mat-label>Gestione generale</mat-label>

            <mat-select
              (selectionChange)="setGeneralRollback($event);updateContentStatusAndCheckErrorsOnSaleClose();canAutoscaleOnSaleClose();setGlobalErrorOnSaleClose()"
              *ngIf="isSaleFailed"
              [(ngModel)]="generalContentRollback"
              [matTooltip]="generalContentRollback?.tooltip ?? ''"

            >
              <mat-option *ngIf="rollbackStates.length === 0">
                Nessuno stato disponibile.
              </mat-option>
              <mat-option *ngFor="let status of rollbackStates" [value]="status">
                {{status.name}}
              </mat-option>
            </mat-select>

            <button (click)="unsetGeneralContentRollback();unsetAllContentRollback()"
                    *ngIf="generalContentRollback"
                    mat-button
                    mat-icon-button
                    matSuffix>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

      </div>
    </div>


    <div fxLayout="column">

      <div fxLayout="row" fxLayoutAlign="center center">

        <div *ngIf="!canTransition(selectedStatus) && errorToShow"
             class="border border-warn disclaimer-error mat-background-light p-2_5 mb-2_5" fxFlex="45">
          <p fxLayoutAlign="center center">
            {{errorToShow}}
          </p>
        </div>
      </div>

      <div fxLayout="row">

        <button (click)="applyStatusFn(selectedStatus)"
                [disabled]="isApplyStatusButtonDisabled() || (showManageWarehouseButton(selectedStatus) && autoscaleErrorOnSaleClose) || savingStatus"
                [matTooltip]="errorToShow"
                color="primary"
                fxFlex
                mat-raised-button
        >

          <mat-icon>check</mat-icon>
          &nbsp;<span class="hide-mobile">Applica stato</span>

        </button>
      </div>
    </div>
  </div>

</div>
<!--  endregion sale status-->

<!--  region sale creation -->
<div
  *ngIf="((!isEditing || updatingSale || readOnlySale) && !showOnlyContent && !readOnlyContent && !_managingSaleContent) || (_entity?.status === saleStatuses.closed || _entity?.status === saleStatuses.canceled)">
  <mat-accordion>

    <mat-expansion-panel [expanded]="anagraphicOpened">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Anagrafica
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div [formGroup]="formGroupSale" fxLayout="row wrap" fxLayoutAlign="space-between">


        <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">
          <mat-form-field fxFlex="45">
            <mat-label>Data della vendita</mat-label>

            <input (focusin)="saleDatePicker.open()"
                   [disabled]="readOnlySale"
                   [matDatepicker]="saleDatePicker"
                   formControlName="saleDate"
                   matInput
                   name="saleDate"
            >
            <mat-datepicker-toggle [for]="saleDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker-toggle (click)="formGroupSale.controls['saleDate'].setValue(null)" matSuffix>
              <mat-icon matDatepickerToggleIcon (click)="formGroup.markAsDirty()">clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #saleDatePicker></mat-datepicker>
            <mat-error *ngIf="formGroupSale.controls['saleDate'].errors?.['required']"
                       class="p-error block">
              La data è richiesta
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="45">
            <mat-label>Prezzo finale (non calcolato)</mat-label>
            <input
              [matTooltip]="'Prezzo finale della vendita'"
              [min]="0"
              [options]="{ prefix: '€ ', thousands: '.', decimal: ',', precision: 2 }"
              [readonly]="readOnlySale"
              currencyMask
              formControlName="finalPrice"
              id="finalPrice"
              matInput
              type="text"
            >
            <!--            <span matPrefix>€&nbsp;</span>-->
          </mat-form-field>

        </div>


        <mat-form-field fxFlex="100">
          <mat-label>Note</mat-label>
          <textarea [readonly]="readOnlySale"
                    cdkAutosizeMinRows="5"
                    cdkTextareaAutosize
                    formControlName="note"
                    id="note"
                    matInput>
            </textarea>
        </mat-form-field>

      </div>

    </mat-expansion-panel>

  </mat-accordion>

  <button (click)="updateSale()"
          *ngIf="isEditing && !readOnlySale"
          [disabled]="(savingSale || saving || savingSaleContent || savingSaleContent || savingStatus) || (!(formGroupSale.dirty))"
          class="w-full mt-2_5"
          color="primary"
          mat-raised-button

  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Aggiorna vendita</span>
  </button>

</div>
<!--  endregion sale creation-->

<!--  region sale content creation-->
<div *ngIf="(!isEditing || updatingSaleContent || showOnlyContent || readOnlyContent || _managingSaleContent) && !_managingSaleStatus && !readOnlySale"
     class="mt-4">
  <form *ngIf="!loading; else loadingData" [formGroup]="formGroupSaleContent">

    <div *ngIf="creationWrappers.length === 0"
         class="border border-yellow-warn disclaimer-box mat-background-light p-2_5 mb-2_5 mt-2_5"
         fxFlex="100">
      <p fxLayoutAlign="center center">
        <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
        Nessun articolo presente nella vendita.
      </p>
    </div>

    <mat-accordion fxFlex>
      <div *ngFor="let content of saleContents().controls; let i=index" class="mb-4" formArrayName="saleContents">
        <mat-expansion-panel
          [expanded]="(addingSaleContent && i === (saleContents().controls.length - 1)) ||
                          (readOnlyContent && creationWrappers[i].selectedItem?.id == itemToShow &&
                          creationWrappers[i].selectedWarehouse?.id == warehouseToShow)"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{getPanelTitle(i, content)}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div [formGroupName]="i" fxLayout="column">
            <!--            <mat-form-field>-->
            <div>
              <mat-button-toggle-group
                (change)="getStocks($event.value, i)"
                *ngIf="!(isEditing && i < (_entity?.content?.length ?? -1))"
                [disabled]="warehouses.length < 1 || readOnlyContent"
                formControlName="checked"
              >
                <mat-button-toggle *ngFor="let state of stateOptions"
                                   [value]="state.value">{{state.label}}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <!--            </mat-form-field>-->

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">

              <mat-form-field fxFlex>
                <mat-label>{{creationWrappers[i].snapshot ? creationWrappers[i].snapshot?.warehouseName ?? "NON ASSEGNATO" : "Magazzino"}}</mat-label>

                <mat-select
                  (selectionChange)="emitChosenWarehouse($event.value.id, i)"
                  [disabled]="creationWrappers[i].disabledInput || !creationWrappers[i].checked"
                  formControlName="warehouse"
                >
                  <!--                  if not snapshot-->
                  <ng-container *ngIf="!creationWrappers[i].snapshot">

                    <aw-search-option (filteredOptions)="creationWrappers[i].filteredWarehouse = $event"
                                      *ngIf="warehouses.length > 0"
                                      [key]="'name'" [options]="warehouses"></aw-search-option>


                    <mat-option *ngIf="warehouses.length === 0">
                      Nessuno magazzino disponibile.
                    </mat-option>

                    <mat-option *ngIf="creationWrappers[i].filteredWarehouse.length === 0 && warehouses.length !== 0">
                      Nessuno magazzino trovato.
                    </mat-option>

                    <mat-option *ngFor="let warehouse of creationWrappers[i].filteredWarehouse" [value]="warehouse">
                      {{warehouse.name}}
                    </mat-option>
                  </ng-container>


                  <!--                  if snapshot-->
                  <ng-container *ngIf="creationWrappers[i].snapshot">
                    <mat-option>
                      {{creationWrappers[i].snapshot?.warehouseName ?? "Non assegnato"}}
                    </mat-option>
                  </ng-container>


                </mat-select>
                <button (click)="unsetSelectedWarehouse(i);$event.stopPropagation()"
                        *ngIf="creationWrappers[i].selectedWarehouse"
                        [disabled]="creationWrappers[i].disabledInput || !creationWrappers[i].checked"
                        mat-button
                        mat-icon-button
                        matSuffix>
                  <mat-icon>close</mat-icon>
                </button>


              </mat-form-field>

              <mat-form-field fxFlex>
                <mat-label>{{creationWrappers[i].snapshot?.variantItemName ?? "Articolo"}}</mat-label>

                <mat-select
                  (selectionChange)="chosenItem($event.value, i)"
                  [disabled]="readOnlyContent || creationWrappers[i]?.disabledInput || !!creationWrappers[i]?.loadingItems || (!!creationWrappers[i]?.checked && !creationWrappers[i]?.selectedWarehouse)"
                  formControlName="item"
                >
                  <!--                  if not snapshot-->
                  <ng-container *ngIf="!creationWrappers[i].snapshot">

                    <aw-search-option
                      (filteredOptions)="creationWrappers[i].filteredItems = $event"
                      *ngIf="!((creationWrappers[i]?.checked && creationWrappers[i]?.warehouseItems?.length === 0) || (!creationWrappers[i]?.checked && companyItems?.length === 0))"
                      [predicate]="itemPredicate"
                      [options]="(noWarehousesAvailable ? companyItems : creationWrappers?.[i]?.warehouseItems ?? [])"></aw-search-option>


                    <mat-option
                      *ngIf="(creationWrappers[i]?.checked && creationWrappers[i]?.warehouseItems?.length === 0) || (!creationWrappers[i]?.checked && companyItems?.length === 0)">
                      Nessuno articolo disponibile.
                    </mat-option>

                    <mat-option
                      *ngIf="creationWrappers[i].filteredItems.length <= 0 && !((creationWrappers[i]?.checked && creationWrappers[i]?.warehouseItems?.length === 0) || (!creationWrappers[i]?.checked && companyItems?.length === 0))">
                      Nessun articolo trovato
                    </mat-option>

                    <mat-option
                      *ngFor="let item of creationWrappers[i].filteredItems"
                      [value]="item">
                      {{item.name}}
                    </mat-option>
                  </ng-container>

                  <!--                  if snapshot-->
                  <ng-container *ngIf="creationWrappers[i].snapshot">
                    <mat-option>
                      {{creationWrappers[i].snapshot?.variantItemName}}
                    </mat-option>
                  </ng-container>

                </mat-select>
                <button (click)="unsetSelectedItem(i);$event.stopPropagation()"
                        *ngIf="creationWrappers[i].selectedItem"
                        [disabled]="readOnlyContent || creationWrappers[i].disabledInput || !!creationWrappers[i].loadingItems || (!!creationWrappers[i].checked && !creationWrappers[i].selectedWarehouse)"
                        mat-button
                        mat-icon-button
                        matSuffix>
                  <mat-icon>close</mat-icon>
                </button>

              </mat-form-field>
            </div>

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3%">

              <mat-form-field fxFlex>
                <mat-label>
                  Quantità{{creationWrappers?.[i]?.selectedItem?.unit ? (' (' + creationWrappers?.[i]?.selectedItem?.unit) + ')' : ('')}}
                </mat-label>

                <!--                region quantity -->

                <input #quantity
                       (keyup)="computeCalculatedPrice(i, +quantity?.value!); checkIfCanAutoscale(i, +quantity.value!)"
                       *ngIf="!creationWrappers?.[i]?.selectedItem?.unit"
                       [matTooltip]="'Quantità'"
                       [min]="1"
                       [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 0}"
                       [readonly]="readOnlyContent || savingSaleContent || !getSelectedItem(i) || (isEditing && i < (_entity?.content?.length ?? -1) && _entity?.content?.[i]?.status !== saleContentStatuses.notUpdated)"
                       currencyMask
                       formControlName="quantity"
                       matInput
                       type="text"
                >

                <!--                endregion quantity -->

                <!--                region unit_quantity -->


                <input #quantity_unit
                       (keyup)="computeCalculatedPrice(i, +quantity_unit.value!); checkIfCanAutoscale(i, +quantity_unit.value!)"
                       *ngIf="creationWrappers?.[i]?.selectedItem?.unit"
                       [matTooltip]="'Quantità (' + creationWrappers?.[i]?.selectedItem?.unit! + ')'"
                       [min]="1.00"
                       [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2}"
                       [readonly]="readOnlyContent || savingSaleContent || !getSelectedItem(i) || (isEditing && i < (_entity?.content?.length ?? -1) && _entity?.content?.[i]?.status !== saleContentStatuses.notUpdated)"
                       currencyMask
                       formControlName="quantity"
                       matInput
                       type="text"
                >
                <span *ngIf="creationWrappers?.[i]?.selectedItem?.unit"
                      matSuffix>{{creationWrappers?.[i]?.selectedItem?.unit!}}</span>
                <!--                endregion unit_quantity -->

                <mat-error *ngIf="saleContents().controls[i].get('quantity')?.dirty &&
                                                    saleContents().controls[i].hasError('required', 'quantity')"
                >La quantità è obbligatoria
                </mat-error>
                <mat-error *ngIf="saleContents().controls[i].get('quantity')?.dirty &&
                                                    saleContents().controls[i].hasError('min', 'quantity')"
                >La quantità non può essere 0
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex>
                <mat-label>
                  Prezzo{{creationWrappers?.[i]?.selectedItem?.unit ? ('/' + creationWrappers?.[i]?.selectedItem?.unit) : (' unitario')}}</mat-label>

                <input #unitPrice
                       (keyup)="computeCalculatedPrice(i, undefined ,+unitPrice.value);"
                       [matTooltip]="'Prezzo' + (creationWrappers?.[i]?.selectedItem?.unit ? ('/' + creationWrappers?.[i]?.selectedItem?.unit) : (' unitario'))"
                       [min]="0"
                       [options]="{ prefix: '€', thousands: '.', decimal: ',', precision: 2}"
                       [readonly]="readOnlyContent || savingSaleContent || !getSelectedItem(i) || (isEditing && i < (_entity?.content?.length ?? -1) && _entity?.content?.[i]?.status !== saleContentStatuses.notUpdated)"
                       currencyMask
                       formControlName="itemUnitPrice"
                       id="itemUnitPrice"
                       matInput

                       type="text"
                >
                <!--                <span matPrefix>€&nbsp;</span>-->

                <mat-error *ngIf="saleContents().controls[i].get('itemUnitPrice')?.dirty &&
                                                    saleContents().controls[i].hasError('required', 'itemUnitPrice')"
                >Il prezzo è obbligatorio
                </mat-error>

              </mat-form-field>
            </div>


            <div
              *ngIf="(!isEditing && creationWrappers[i].checked) || !(isEditing && creationWrappers[i].selectedWarehouse == undefined)"
              fxLayout="column">
              <div fxLayout="row">
                <div *ngIf="creationWrappers[i] && creationWrappers[i].showAutoscaleError"
                     class="border border-yellow-warn disclaimer-box mat-background-light p-2_5 mb-2_5"
                     fxFlex>
                  <p>
                    <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
                    {{creationWrappers[i].autoscaleError}}
                  </p>
                </div>
              </div>


              <div
                *ngIf="creationWrappers[i].checked"
                fxLayout="column"
                fxLayoutGap="3%"
              >
                <mat-label>Gestione giacenze del magazzino</mat-label>
                <mat-form-field fxFlex>
                  <mat-select
                    (selectionChange)="checkIfCanAutoscale(i)"
                    [disabled]="readOnlyContent || isEditing && i < (_entity?.content?.length ?? -1) && _entity?.content?.[i]?.status !== saleContentStatuses.notUpdated"
                    formControlName="state"
                  >
                    <mat-option *ngIf="saleContentStates.length === 0">
                      Nessuno stato disponibile.
                    </mat-option>
                    <mat-option *ngFor="let status of saleContentStates" [value]="status">
                      {{status | saleContentStatus}}
                    </mat-option>

                  </mat-select>
                  <button (click)="selectedStatus = ''"
                          *ngIf="selectedStatus && !creationWrappers[i]?.disabledInput"
                          [disabled]="_entity?.status !== saleStatuses.open"
                          mat-button
                          mat-icon-button
                          matSuffix>
                    <mat-icon>close</mat-icon>
                  </button>

                </mat-form-field>

              </div>

            </div>

          </div>

          <div fxLayout="row">
            <button (click)="removeSaleContent(i); addingSaleContent = false"
                    *ngIf="!readOnlyContent && !(isEditing && i < (_entity?.content?.length ?? -1) && _entity?.content?.[i]?.status !== saleContentStatuses.notUpdated)"
                    [disabled]="savingSaleContent"
                    class="border border-warn mr-1"
                    color="warn"
                    fxFlex
                    mat-button
            >
              <mat-icon>delete</mat-icon>
              &nbsp;<span class="hide-mobile">Rimuovi articolo</span>

            </button>

          </div>


        </mat-expansion-panel>
      </div>

    </mat-accordion>
  </form>


  <div *ngIf="showDuplicateContentMessage"
       class="border border-warn disclaimer-box mat-background-light p-2_5 mb-2_5"
       fxLayoutAlign="center center"
  >
    <p>
      {{duplicateContentMessage}}
    </p>
  </div>

  <div *ngIf="showPriceChangedMessage"
       class="border border-yellow-warn disclaimer-warning mat-background-light p-2_5 mb-2_5"
       fxLayoutAlign="center center"
  >
    <mat-icon [ngStyle]="{'color': '#FFCA36D8'}">warning</mat-icon> &nbsp;
    <p>
      Attenzione!
      In precedenza hai impostato un prezzo finale che potrebbe non essere più coerente con gli articoli presenti nell'ordine
    </p>
  </div>


  <div *ngIf="saleContents().controls.length > 0  && !readOnlyContent" class="col field">
    <p><strong>Prezzo calcolato:</strong> {{calculatedPrice | currency:'EUR'}}</p>
  </div>

  <button (click)="addSaleContent(); addingSaleContent = true; anagraphicOpened = false"
          *ngIf="(!isEditing || (isEditing && (_entity?.status === saleStatuses.open))) && !readOnlyContent"
          [disabled]="showDuplicateContentMessage || savingSaleContent || savingSaleAndContent || formGroupSaleContent.invalid"
          class="mr-2_5"
          color="accent"
          mat-raised-button
  >
    <mat-icon>add</mat-icon>
    &nbsp;<span class="hide-mobile">Aggiungi articolo</span>
  </button>

  <button (click)="saveNormally()"
          *ngIf="!isEditing && !readOnlyContent"
          [disabled]="showDuplicateContentMessage || savingSaleAndContent || formGroupSale.invalid || formGroupSaleContent.invalid"
          color="primary"
          mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Crea vendita</span>

  </button>

  <button (click)="saveAndCloseSaleAndContent()"
          *ngIf="!isEditing && !readOnlyContent"
          [disabled]="showDuplicateContentMessage || savingSaleAndContent || formGroupSale.invalid || formGroupSaleContent.invalid || !canSaveAndComplete()"
          class="ml-2_5"
          color="primary"
          mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Crea e completa vendita</span>

  </button>

  <button (click)="updateSaleContentPre()"
          *ngIf="isEditing && !readOnlyContent"
          [disabled]="(showDuplicateContentMessage || savingSale || saving || savingSaleContent || savingStatus) || (formGroupSaleContent.invalid) || _entity?.status !== saleStatuses.open"
          [matTooltip]="_entity?.status !== saleStatuses.open ? 'Non è possibile modificare il contenuto': ''"
          color="primary"
          mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Aggiorna contenuto vendita</span>

  </button>

</div>
<!--  endregion sale content creation-->

