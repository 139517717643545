<div fxLayout="column">

  <div fxLayout="row" fxLayoutGap="3%">
    <mat-form-field (click)="$event.stopPropagation()" fxFlex>
      <mat-select
        (click)="$event.stopPropagation()"
        (ngModelChange)="isThisItemComposed($event)" [(ngModel)]="selectedItem"
        placeholder="Seleziona un articolo">

        <aw-search-option *ngIf="items.length > 0" (filteredOptions)="this.filteredOptions = $event" [options]="items" [key]="'name'"></aw-search-option>

        <mat-option *ngIf="items.length === 0">
          Tutti gli articoli sono stati inseriti nel magazzino.
        </mat-option>


        <mat-option *ngIf="(filteredOptions?.length ?? -1) <= 0 && items.length !== 0">
          Nessun articolo trovato.
        </mat-option>

        <ng-container *ngIf="items.length > 0">

        <mat-option (click)="$event.stopPropagation()" *ngFor="let item of filteredOptions"
                    [matTooltip]="item?.description ?? ''" [value]="item">
          {{item.name}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>


    <div [formGroup]="formGroup">
      <mat-form-field fxFlex>

        <mat-label>Quantità</mat-label>

        <input
          *ngIf="hasUnit(formGroup, selectedItem?.id)"
          (change)="setStateIfQtyZero($event)"
          [min]="0"
          [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2}"
          currencyMask
          [matTooltip]="'Inserire la quantità di articolo da aggiungere'"
          formControlName="quantity"
          id="quantity"
          matInput
          type="text">

        <input
          *ngIf="!hasUnit(formGroup, selectedItem?.id)"
          (change)="setStateIfQtyZero($event)"
          [min]="0"
          [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 0}"
          currencyMask
          [matTooltip]="'Inserire la quantità di articolo da aggiungere'"
		  formControlName="quantity"
          id="quantity"
          matInput
          type="text">

        <span *ngIf="hasUnit(formGroup, selectedItem?.id)"
              matSuffix>&nbsp;{{getMeasureUnit(selectedItem?.id!)}}</span>


      </mat-form-field>
    </div>

  </div>


  <div *ngIf="selectedItem && allComposersAlreadyInWarehouse">

    <div *ngIf="(selectedItem?.itemComposers?.length ?? -1) > 0 && autoScale && !canAutoscale(selectedItem!) &&
                (formGroup.controls['quantity'].value && formGroup.controls['quantity'].value != 0)">
      <div class="border border-warn disclaimer-error mat-background-light p-2_5 mb-2_5">
        <span [innerHTML]="missingQuantitiesText"></span>
      </div>
    </div>


    <div *ngIf="(selectedItem?.itemComposers?.length ?? -1) > 0">
      <div class="border border-warn disclaimer-error mat-background-light p-2_5 mb-2_5">
        <span [innerHTML]="getAutoScaleText()"></span>
      </div>

      <mat-button-toggle-group [(ngModel)]="autoScale">

        <ng-container *ngFor="let state of stateOptions">
          <mat-button-toggle [value]="state.value">{{state.label}}</mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
    </div>
  </div>

  <div *ngIf="selectedItem && !allComposersAlreadyInWarehouse">
    <div class="border border-warn disclaimer-warning mat-background-light p-2_5 mb-2_5">
      Le giacenze dei seguenti articoli che compongono articolo scelto non esistono in questo magazzino: <br>
      <span [innerHTML]="missingItemsNames()"></span>
      <br> Vuoi creare queste giacenze vuote?
    </div>
    <mat-button-toggle-group [(ngModel)]="addNotExisting">

      <ng-container *ngFor="let state of stateOptions">
        <mat-button-toggle [value]="state.value">{{state.label}}</mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </div>


  <button
    (click)="addItemWarehouse()"
    [disabled]="formGroup.invalid || !selectedItem || (autoScale && !canAutoscale(selectedItem))"
    color="primary"
    mat-raised-button
  >
    <mat-icon>check</mat-icon>
    &nbsp;<span class="hide-mobile">Aggiungi</span>
  </button>

</div>

